import { CommonUtilsService } from './../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-action-role-function',
  templateUrl: './action-role-function.component.html',
  styleUrls: ['./action-role-function.component.css']
})
export class ActionRoleFunctionComponent implements OnInit, OnDestroy {

  // actions: any[];
  function_actions: any = {};
  check_all: any = {};
  unsubscribe$ = new Subject();

  constructor(public navParams: NavParams,
              public modalCtrl: ModalController,
              public fs: AngularFirestore,
              public commonService: CommonUtilsService) {
    const role_uid = this.navParams.data.modal.componentProps.role_uid;
    this.getFunctionActions(role_uid).then((function_actions: any[]) => {
      if (function_actions.length) {
        this.function_actions = function_actions[0];
      } else {
        this.initFunctionActionDefault();
      }
      this.refreshItemCheckAll();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const role_uid = this.navParams.data.modal.componentProps.role_uid;

      if (this.function_actions.uid) {
        const updateObj = { ...this.function_actions };
        updateObj.update_date = await this.commonService.getServerTime();
        delete updateObj.uid;
        batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('function_actions').doc(this.function_actions.uid), updateObj);
      } else {
        const insertObj = { ...this.function_actions };
        insertObj.create_date = await this.commonService.getServerTime();
        delete insertObj.uid;
        batch.set(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('function_actions').doc(), insertObj);
      }

      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getFunctionActions(role_uid: string) {
    return this.fs.firestore
    .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid)
    .collection('function_actions')
    .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  initFunctionActionDefault() {
    this.function_actions = {
      uid: '',
      function001: false, // Xem danh sách đơn hàng của tất cả nhân viên kinh doanh
      function002: false, // Xem danh sách đơn hàng của tất cả nhân viên chăm sóc khách hàng
      function003: false, // Xem toàn bộ danh sách đơn hàng
      function004: false, // Tìm kiếm đơn hàng theo các tiêu chí
      function274: false, // Xem những đơn hàng chưa có nhân viên phụ trách
      // function275: false, // Xem toàn bộ danh sách đơn hàng của Pandaorder
      function005: false, // Chuyển đến màn hình chi tiết đơn hàng
      function006: false, // Xóa đơn hàng
      function007: false, // Xuất ra file excel
      function008: false, // Xem chi tiết đơn hàng
      function009: false, // Thao tác trong chi tiết đơn hàng tại các trạng thái
      function010: false, // Xem danh sách đơn ký gửi
      function011: false, // Tìm kiếm đơn ký gửi theo các tiêu chí
      function012: false, // Chuyển đến màn hình chi tiết đơn ký gửi
      function013: false, // Xóa đơn ký gửi
      function014: false, // Xuất ra file excel
      function015: false, // Thanh toán ví âm ở danh sách đơn ký gửi
      function016: false, // Giao ký gửi ở danh sách đơn ký gửi
      function017: false, // Xem chi tiết đơn ký gửi
      function018: false, // Thao tác trong chi tiết đơn ký gửi tại các trạng thái
      function019: false, // Xem danh sách mã hóa đơn
      function020: false, // Tìm kiếm theo các tiêu chí
      function021: false, // Xuất ra file excel
      function022: false, // Duyệt mã hóa đơn
      function023: false, // Xóa mã hóa đơn
      function024: false, // Chuyển mã hóa đơn về trạng thái chưa duyệt
      function025: false, // Xem danh sách hoàn tiền
      function026: false, // Tìm kiếm theo các tiêu chí
      function027: false, // Duyệt hoàn tiền
      function028: false, // Xóa hoàn tiền
      function029: false, // Chuyển hoàn tiền về trạng thái chưa duyệt
      function030: false, // Tìm kiếm theo các tiêu chí
      function031: false, // Tải file excel mẫu
      function032: false, // Up file excel lên hệ thống
      function033: false, // Tạo đơn hàng bằng excel
      function034: false, // Tìm kiếm theo các tiêu chí
      function035: false, // Nhập dữ liệu để tạo đơn hàng ngoài
      function036: false, // Tạo đơn hàng ngoài
      function037: false, // Xem danh sách khiếu nại của tất cả nhân viên kinh doanh
      function038: false, // Tìm kiếm theo các tiêu chí
      function039: false, // Chuyển khiếu nại từ chưa xử lý sang đang xử lý trước trạng thái hoàn thành đơn
      function040: false, // Chuyển khiếu nại từ chưa xử lý sang đang xử lý sau trạng thái hoàn thành đơn
      function232: false, // Chuyển khiếu nại từ đang xử lý sang hoàn thành trước trạng thái hoàn thành đơn
      function233: false, // Chuyển khiếu nại từ đang xử lý sang hoàn thành sau trạng thái hoàn thành đơn
      function234: false, // Chuyển khiếu nại từ hoàn thành sang đang xử lý trước trạng thái hoàn thành đơn
      function235: false, // Chuyển khiếu nại từ hoàn thành sang đang xử lý sau trạng thái hoàn thành đơn
      function041: false, // Xóa khiếu nại
      function042: false, // Nhập đơn hàng yêu cầu xuất
      function043: false, // Tìm kiếm theo các tiêu chí
      function044: false, // Xem danh sách yêu cầu xuất
      function045: false, // Sửa khi chưa xử lý
      function046: false, // Xóa khi chưa xử lý
      function047: false, // Sửa khi đã xử lý
      function048: false, // Xóa khi đã xử lý
      function049: false, // Nhập mã vận đơn vào hệ thống
      function050: false, // Tìm kiếm theo các tiêu chí
      function051: false, // Chọn mã vận đơn để đóng bao
      function052: false, // Chọn bao để giao
      function053: false, // Xuất ra file excel
      function054: false, // Sửa thông tin bao
      function055: false, // Xem danh sách mã vận đơn
      function056: false, // Sửa thông tin mã vận đơn
      function057: false, // Xóa mã vận đơn
      function058: false, // Thêm mã vận đơn chuyển phát chậm
      function059: false, // Sửa tham số kho VN
      function060: false, // Sửa thông tin bao
      function061: false, // Tìm kiếm theo các tiêu chí
      function062: false, // Xuất ra file excel
      function063: false, // Xem danh sách mã vận đơn
      function064: false, // Click nhận mã vận đơn
      function065: false, // Chuyển trạng thái về chưa nhận mã vận đơn
      function066: false, // Sửa thông tin mã vận đơn trước khi có ngày xuất
      function067: false, // Sửa thông tin mã vận đơn sau khi có ngày xuất
      function068: false, // Xóa mã vận đơn
      function069: false, // Tìm kiếm theo các tiêu chí
      function070: false, // Xem danh sách giao dịch
      function071: false, // Xuất ra file excel
      function072: false, // Sửa thông tin giao dịch
      function073: false, // Xóa giao dịch
      function074: false, // Tạo lệnh nạp tiền
      function075: false, // Tạo lệnh rút tiền
      function236: false, // Tạo lệnh rút tiền âm
      function076: false, // Tìm kiếm theo các tiêu chí
      function077: false, // Xem danh sách ví điện tử
      function078: false, // Xuất ra file excel
      function079: false, // Xem chi tiết ví điện tử
      function080: false, // Xuất ra file excel chi tiết ví điện tử
      function081: false, // Xem số dư ví điện tử
      function082: false, // Xem số tài khoản âm
      function083: false, // Xem chi tiết số tài khoản âm
      function084: false, // Tìm kiếm theo các tiêu chí
      function085: false, // Xem danh sách hạn mức tín dụng
      function086: false, // Tìm kiếm theo các tiêu chí
      function087: false, // Xuất ra file excel
      function240: false, // Xem danh sách đơn hàng của tất cả nhân viên kinh doanh
      function241: false, // Xem danh sách đơn hàng của tất cả nhân viên chăm sóc khách hàng
      function242: false, // Xem toàn bộ danh sách đơn hàng
      function273: false, // Xem danh sách đơn hàng chưa có nhân viên phụ trách
      function088: false, // Lọc không khớp số lượng
      function089: false, // Lọc không khớp xuất link
      function090: false, // Lọc không khớp xuất mã vận đơn
      function091: false, // Xem danh sách đơn hàng - Mã ĐH
      function092: false, // Xem danh sách đơn hàng - Nhân viên phụ trách
      function093: false, // Xem danh sách đơn hàng - Nhân viên đặt hàng
      function094: false, // Xem danh sách đơn hàng - Tên khách hàng
      function095: false, // Xem danh sách đơn hàng - Trạng thái
      function096: false, // Xem danh sách đơn hàng - Ngày tạo
      function097: false, // Xem danh sách đơn hàng - Ngày đặt cọc
      function098: false, // Xem danh sách đơn hàng - Ngày hoàn thành
      function099: false, // Xem danh sách đơn hàng - Tiền hàng (¥)
      function100: false, // Xem danh sách đơn hàng - Tiền ship (¥)
      function101: false, // Xem danh sách đơn hàng - Phí dịch vụ (¥)
      function102: false, // Xem danh sách đơn hàng - Tổng tiền (¥)
      function103: false, // Xem danh sách đơn hàng - Tiền BK (đ)
      function104: false, // Xem danh sách đơn hàng - Tiền đặt cọc
      function105: false, // Xem danh sách đơn hàng - Tiền thu thêm
      function106: false, // Xem danh sách đơn hàng - Tiền hoàn (Ví điện tử)
      function107: false, // Xem danh sách đơn hàng - Tiền hàng TT (¥)
      function108: false, // Xem danh sách đơn hàng - Tiền ship TT (¥)
      function109: false, // Xem danh sách đơn hàng - Tổng TT (¥)
      function110: false, // Xem danh sách đơn hàng - Trọng lượng
      function111: false, // Xem danh sách đơn hàng - Phí v/c
      function112: false, // Xem danh sách đơn hàng - Phát sinh
      function113: false, // Xem danh sách đơn hàng - Còn thiếu
      function114: false, // Xem danh sách đơn hàng - Shop hoàn tiền
      function115: false, // Xem danh sách đơn hàng - Cước v/c thực
      function116: false, // Xem danh sách đơn hàng - Tỷ giá báo khách
      function117: false, // Xem danh sách đơn hàng - Tỷ giá thực
      function118: false, // Xem danh sách đơn hàng - Chiết khấu
      function119: false, // Xem danh sách đơn hàng - Khớp số lượng
      function120: false, // Xem danh sách đơn hàng - Khớp xuất link
      function121: false, // Xem danh sách đơn hàng - Khớp xuất mã vận đơn
      function122: false, // Xem danh sách đơn hàng - Lợi nhuận
      function123: false, // Xem danh sách đơn hàng - Tất cả
      function124: false, // Xem tổng công nợ
      function125: false, // Xem tổng đơn hàng chưa hoàn thành
      function126: false, // Tìm kiếm theo các tiêu chí
      function127: false, // Xuất ra file excel
      function128: false, // Xem danh sách đơn ký gửi - Tất cả
      function129: false, // Tìm kiếm theo các tiêu chí
      function130: false, // Xuất ra file excel
      function131: false, // Sắp xếp dữ liệu
      function132: false, // Xem danh sách mã vận đơn
      function133: false, // Ghi chú mã vận đơn 1 lần
      function134: false, // Ghi chú mã vận đơn nhiều lần
      function135: false, // Tìm kiếm theo các tiêu chí
      function136: false, // Xuất ra file excel
      function137: false, // Sắp xếp dữ liệu
      function138: false, // Xem danh sách mã vận đơn
      function139: false, // Ghi chú mã vận đơn 1 lần
      function140: false, // Ghi chú mã vận đơn nhiều lần
      function141: false, // Tìm kiếm theo các tiêu chí
      function142: false, // Xuất ra file excel
      function143: false, // Xem danh sách khách hàng có nhân viên phụ trách là nhân viên chăm sóc khách hàng
      function144: false, // Xem danh sách khách hàng có nhân viên phụ trách là nhân viên kinh doanh
      function145: false, // Xem danh sách khách hàng không có nhân viên phụ trách
      function146: false, // Xem toàn bộ danh sách khách hàng
      // function276: false, // Xem những khách hàng thuộc quản lý
      // function277: false, // Xem danh sách KH Socorder
      // function278: false, // Xem danh sách KH Pandaorder
      function147: false, // Xem lịch sử nhân viên phụ trách khách hàng
      function148: false, // Phân nhân viên phụ trách
      function149: false, // Phân cấp độ khách hàng
      function150: false, // Sửa thông tin khách hàng
      function151: false, // Kích hoạt tài khoản khách hàng
      function152: false, // Xóa tài khoản khách hàng
      function272: false, // Cập nhật khách hàng blacklist
      function243: false, // Xem chi tiết thông tin khách hàng
      function244: false, // Sửa mật khẩu khách hàng
      function153: false, // Quản lý thông báo
      function154: false, // Quản lý pop - up
      function155: false, // Quản lý thông tin website
      function156: false, // Quản lý trang
      function157: false, // Quản lý hình ảnh
      function158: false, // Quản lý bài viết
      function159: false, // Xem tham số cố định
      function160: false, // Sửa tham số cố định
      function161: false, // Xem tham số định mức
      function162: false, // Thêm cước vận chuyển nhanh
      function163: false, // Sửa cước vận chuyển nhanh
      function164: false, // Xóa cước vận chuyển nhanh
      function165: false, // Thêm cước vận chuyển chậm
      function166: false, // Sửa cước vận chuyển chậm
      function167: false, // Xóa cước vận chuyển chậm
      function168: false, // Thêm phí dịch vụ
      function169: false, // Sửa phí dịch vụ
      function170: false, // Xóa phí dịch vụ
      function171: false, // Thêm phí kiểm hàng
      function172: false, // Sửa phí kiểm hàng
      function173: false, // Xóa phí kiểm hàng
      function174: false, // Xem tham số cố định
      function175: false, // Sửa tham số cố định
      function176: false, // Xem tham số định mức
      function177: false, // Thêm cước vận chuyển Trung Quốc - Việt Nam
      function178: false, // Sửa cước vận chuyển Trung Quốc - Việt Nam
      function179: false, // Xóa cước vận chuyển Trung Quốc - Việt Nam
      function180: false, // Tạo khuyến mãi
      function181: false, // Xem danh sách khuyến mãi
      function182: false, // Xem chi tiết khuyến mãi
      function183: false, // Xóa kỳ khuyến mãi
      function184: false, // Thêm tài khoản hoặc địa điểm giao dịch
      function185: false, // Xem danh sách tài khoản và địa điểm giao dịch
      function186: false, // Sửa tài khoản mã hóa đơn
      function187: false, // Xóa tài khoản mã hóa đơn
      function188: false, // Sửa tài khoản hoàn tiền
      function189: false, // Xóa tài khoản hoàn tiền
      function190: false, // Sửa địa điểm giao dịch tiền mặt
      function191: false, // Xóa địa điểm giao dịch tiền mặt
      function192: false, // Sửa địa điểm giao dịch chuyển khoản
      function193: false, // Xóa địa điểm giao dịch chuyển khoản
      function194: false, // Thêm cấp độ Vip hoặc cấp độ Vip đặc biệt
      function195: false, // Xem danh sách cấp độ khách hàng
      function196: false, // Sửa cấp độ vip khách hàng
      function197: false, // Xóa cấp độ vip khách hàng
      function198: false, // Sửa cấp độ vip đặc biệt khách hàng
      function199: false, // Xóa cấp độ vip đặc biệt khách hàng
      function200: false, // Xem danh sách quyền
      function201: false, // Thêm mới nhóm quyền
      function202: false, // Sửa nhóm quyền
      function203: false, // Xóa nhóm quyền
      function204: false, // Xem các menu được thao tác
      function205: false, // Phân / Bỏ phân các menu được thao tác cho nhóm quyền
      function206: false, // Xem các chức năng được thao tác
      function207: false, // Phân Bỏ phân các chức năng được thao tác cho nhóm quyền
      function208: false, // Xem danh sách thao tác đơn hàng
      function209: false, // Thêm/ Bỏ thao tác đơn hàng
      function210: false, // Xóa thao tác đơn hàng
      function211: false, // Phân quyền thao tác đơn hàng theo trạng thái
      function212: false, // Xem danh sách thao tác đơn ký gửi
      function213: false, // Thêm / Bỏ thao tác đơn ký gửi
      function214: false, // Xóa thao tác đơn ký gửi
      function215: false, // Phân quyền thao tác đơn ký gửi theo trạng thái
      function216: false, // Xem danh sách thao tác trong đơn hàng
      function217: false, // Xem danh sách thao tác trong đơn ký gửi
      function218: false, // Xem danh sách người dùng
      function219: false, // Thêm mới người dùng
      function220: false, // Phân nhóm quyền người dùng
      function221: false, // Kích hoạt / Bỏ kích hoạt tài khoản người dùng
      function222: false, // Sửa thông tin người dùng
      function223: false, // Xóa tài khoản người dùng
      function224: false, // Xem danh sách cấu hình hạng nhân viên
      function225: false, // Thêm hạng nhân viên
      function226: false, // Sửa hạng nhân viên
      function227: false, // Xóa hạng nhân viên
      function228: false, // Xem danh sách nhân viên
      function229: false, // Xem chi tiết nhân viên
      function230: false, // Sửa thông tin cá nhân
      function231: false, // Đổi mật khẩu cá nhân
      function237: false, // Cập nhật dữ liệu
      function238: false, // Xem danh sách khiếu nại của tất cả nhân viên chăm sóc khách hàng
      function239: false, // Xem danh sách toàn bộ khiếu nại
      function245: false, // Quản lý hình ảnh -> thêm hình
      function246: false, // Quản lý hình ảnh  -> sửa hình ảnh
      function247: false, // Quản lý hình ảnh  -> xóa hình ảnh
      function248: false, // Quản lý trang  -> edit
      function249: false, // Quản lý bài viết -> sửa bài viết
      function250: false, // Quản lý bài viết -> xóa bài viết
      function251: false, // Quản lý bài viết -> them bài viết
      function252: false, // Nạp tiền bằng SMS -> Xem giao dịch nạp tiền bằng SMS
      function253: false, // Nạp tiền bằng SMS -> Lọc giao dịch nạp tiền bằng SMS
      function254: false, // Nạp tiền bằng SMS -> Nạp tiền giao dịch nạp tiền bằng SMS
      function255: false, // Nạp tiền bằng SMS -> Xóa giao dịch nạp tiền bằng SMS
      function256: false, // Danh sách phiếu xuất kho -> Xem toàn bộ phiếu xuất kho
      function257: false, // Danh sách phiếu xuất kho -> Xem phiếu xuất kho tương ứng với khách hàng phụ trách
      function258: false, // Danh sách phiếu xuất kho -> In phiếu xuất kho
      function259: false, // Danh sách phiếu xuất kho -> Xóa phiếu xuất kho
      function260: false, // Tạo phiếu xuất kho -> Truy thu đơn hàng
      function261: false, // Tạo phiếu xuất kho -> Truy thu đơn ký gửi
      function269: false, // Tạo phiếu xuất kho -> Tạo phiếu xuất kho
      function262: false, // Chi tiết phiếu xuất kho -> Truy thu đơn hàng
      function263: false, // Chi tiết phiếu xuất kho -> Truy thu đơn ký gửi
      function264: false, // Chi tiết phiếu xuất kho -> Xoá phiếu xuất kho
      function265: false, // Chi tiết phiếu xuất kho -> In phiếu xuất kho
      function266: false, // Chi tiết phiếu xuất kho -> Phê duyệt phiếu xuất kho
      function267: false, // Chi tiết phiếu xuất kho -> Bỏ phê duyệt phiếu xuất kho
      function268: false, // Chi tiết phiếu xuất kho -> Truy thu toàn bộ
      function270: false, // Chi tiết phiếu xuất kho -> Cập nhật tổng số kiện hàng
      function271: false, // Phân nhân viên sales
      function279: false, // Danh sách đơn hàng chưa cọc -> Xem toàn bộ danh sách đơn hàng
      // function280: false, // Danh sách đơn hàng chưa cọc -> Xem những đơn hàng thuộc quản lý
      function281: false, // Danh sách đơn hàng chưa cọc -> Xem những đơn hàng chưa có nhân viên phụ trách
      // function282: false, // Danh sách đơn hàng chưa cọc -> Xem toàn bộ danh sách đơn hàng của Pandaorder
      function283: false, // Danh sách đơn hàng chưa cọc -> Tìm kiếm đơn hàng theo các tiêu chí
      function284: false, // Danh sách đơn hàng chưa cọc -> Chuyển đến màn hình chi tiết đơn hàng
      function285: false, // Chi tiết đơn hàng chưa cọc -> Xem chi tiết đơn hàng
      function286: false, // Chi tiết đơn hàng chưa cọc -> Sửa giá sản phẩm
    };
  }

  refreshItemCheckAll() {
    this.check_all.danhsachdonhang = (
      this.function_actions.function001
      && this.function_actions.function002
      && this.function_actions.function003
      && this.function_actions.function004
      // && this.function_actions.function273
      && this.function_actions.function274
      // && this.function_actions.function275
      && this.function_actions.function005
      && this.function_actions.function006
      && this.function_actions.function007
    );

    this.check_all.chitietdonhang = (
      this.function_actions.function008
      && this.function_actions.function009
    );

    this.check_all.quanlydonhang = (
      this.check_all.danhsachdonhang
      && this.check_all.chitietdonhang
    );

    this.check_all.danhsachdonhangchuacoc = (
      this.function_actions.function279
      // && this.function_actions.function280
      && this.function_actions.function281
      // && this.function_actions.function282
      && this.function_actions.function283
      && this.function_actions.function284
    );

    this.check_all.chitietdonhangchuacoc = (
      this.function_actions.function285
      && this.function_actions.function286
    );

    this.check_all.quanlydonhangchuacoc = (
      this.check_all.danhsachdonhangchuacoc
      && this.check_all.chitietdonhangchuacoc
    );

    this.check_all.danhsachdonkygui = (
      this.function_actions.function010
      && this.function_actions.function011
      && this.function_actions.function012
      && this.function_actions.function013
      && this.function_actions.function014
      && this.function_actions.function015
      && this.function_actions.function016
    );

    this.check_all.chitietdonkygui = (
      this.function_actions.function017
      && this.function_actions.function018
    );

    this.check_all.quanlydonkygui = (
      this.check_all.danhsachdonkygui
      && this.check_all.chitietdonkygui
    );

    this.check_all.quanlymahoadon = (
      this.function_actions.function019
      && this.function_actions.function020
      && this.function_actions.function021
      && this.function_actions.function022
      && this.function_actions.function023
      && this.function_actions.function024
    );

    this.check_all.quanlyhoantien = (
      this.function_actions.function025
      && this.function_actions.function026
      && this.function_actions.function027
      && this.function_actions.function028
      && this.function_actions.function029
    );

    this.check_all.taodonhangexcel = (
      this.function_actions.function030
      && this.function_actions.function031
      && this.function_actions.function032
      && this.function_actions.function033
    );

    this.check_all.taodonhangngoai = (
      this.function_actions.function034
      && this.function_actions.function035
      && this.function_actions.function036
    );

    this.check_all.tonghopkhieunai = (
      this.function_actions.function037
      && this.function_actions.function238
      && this.function_actions.function239
      && this.function_actions.function038
      && this.function_actions.function039
      && this.function_actions.function040
      && this.function_actions.function232
      && this.function_actions.function233
      && this.function_actions.function234
      && this.function_actions.function235
      && this.function_actions.function041
    );

    this.check_all.yeucauxuat = (
      this.function_actions.function042
      && this.function_actions.function043
      && this.function_actions.function044
      && this.function_actions.function045
      && this.function_actions.function046
      && this.function_actions.function047
      && this.function_actions.function048
    );

    this.check_all.phongkinhdoanh = (
      this.check_all.quanlydonhang
      && this.check_all.quanlydonkygui
      && this.check_all.quanlymahoadon
      && this.check_all.quanlyhoantien
      && this.check_all.taodonhangexcel
      && this.check_all.taodonhangngoai
      && this.check_all.tonghopkhieunai
      && this.check_all.yeucauxuat
    );

    this.check_all.khobangtuong = (
      this.function_actions.function049
      && this.function_actions.function050
      && this.function_actions.function051
      && this.function_actions.function052
      && this.function_actions.function053
      && this.function_actions.function054
      && this.function_actions.function055
      && this.function_actions.function056
      && this.function_actions.function057
    );

    this.check_all.khovietnam = (
      this.function_actions.function058
      && this.function_actions.function059
      && this.function_actions.function060
      && this.function_actions.function061
      && this.function_actions.function062
      && this.function_actions.function063
      && this.function_actions.function064
      && this.function_actions.function065
      && this.function_actions.function066
      && this.function_actions.function067
      && this.function_actions.function068
    );

    this.check_all.phongkho = (
      this.check_all.khobangtuong
      && this.check_all.khovietnam
    );

    this.check_all.thongkegiaodich = (
      this.function_actions.function069
      && this.function_actions.function070
      && this.function_actions.function071
      && this.function_actions.function072
      && this.function_actions.function073
    );

    this.check_all.vidientu = (
      this.function_actions.function074
      && this.function_actions.function075
      && this.function_actions.function236
      && this.function_actions.function076
      && this.function_actions.function077
      && this.function_actions.function078
      && this.function_actions.function079
      && this.function_actions.function080
      && this.function_actions.function081
      && this.function_actions.function082
      && this.function_actions.function083
    );

    this.check_all.hanmuctindung = (
      this.function_actions.function084
      && this.function_actions.function085
    );

    this.check_all.tonghopdonhang = (
      this.function_actions.function086
      && this.function_actions.function087
      && this.function_actions.function240
      && this.function_actions.function241
      && this.function_actions.function242
      && this.function_actions.function273
      && this.function_actions.function088
      && this.function_actions.function089
      && this.function_actions.function090
      && this.function_actions.function091
      && this.function_actions.function092
      && this.function_actions.function093
      && this.function_actions.function094
      && this.function_actions.function095
      && this.function_actions.function096
      && this.function_actions.function097
      && this.function_actions.function098
      && this.function_actions.function099
      && this.function_actions.function100
      && this.function_actions.function101
      && this.function_actions.function102
      && this.function_actions.function103
      && this.function_actions.function104
      && this.function_actions.function105
      && this.function_actions.function106
      && this.function_actions.function107
      && this.function_actions.function108
      && this.function_actions.function109
      && this.function_actions.function110
      && this.function_actions.function111
      && this.function_actions.function112
      && this.function_actions.function113
      && this.function_actions.function114
      && this.function_actions.function115
      && this.function_actions.function116
      && this.function_actions.function117
      && this.function_actions.function118
      && this.function_actions.function119
      && this.function_actions.function120
      && this.function_actions.function121
      && this.function_actions.function122
      && this.function_actions.function123
      && this.function_actions.function124
      && this.function_actions.function125
    );

    this.check_all.tonghopdonkygui = (
      this.function_actions.function126
      && this.function_actions.function127
      && this.function_actions.function128
    );

    this.check_all.phongketoan = (
      this.check_all.thongkegiaodich
      && this.check_all.vidientu
      && this.check_all.hanmuctindung
      && this.check_all.tonghopdonhang
      && this.check_all.tonghopdonkygui
    );

    this.check_all.donhangchuacomvd = (
      this.function_actions.function129
      && this.function_actions.function130
      && this.function_actions.function131
      && this.function_actions.function132
      && this.function_actions.function133
      && this.function_actions.function134
    );

    this.check_all.donhangkhongcotrongluong = (
      this.function_actions.function135
      && this.function_actions.function136
      && this.function_actions.function137
      && this.function_actions.function138
      && this.function_actions.function139
      && this.function_actions.function140
    );

    this.check_all.baodong = (
      this.check_all.donhangchuacomvd
      && this.check_all.donhangkhongcotrongluong
    );

    this.check_all.danhsachkhachhang = (
      this.function_actions.function141
      && this.function_actions.function142
      && this.function_actions.function143
      && this.function_actions.function144
      && this.function_actions.function145
      && this.function_actions.function146
      // && this.function_actions.function276
      // && this.function_actions.function277
      // && this.function_actions.function278
      && this.function_actions.function147
      && this.function_actions.function148
      && this.function_actions.function149
      && this.function_actions.function150
      && this.function_actions.function151
      && this.function_actions.function152
      && this.function_actions.function243
      && this.function_actions.function244
      && this.function_actions.function271
      && this.function_actions.function272
    );

    this.check_all.quanlykhachhang = (
      this.check_all.danhsachkhachhang
    );

    this.check_all.quanlynoidung = (
      this.function_actions.function153
      && this.function_actions.function154
      && this.function_actions.function155
      && this.function_actions.function156
      && this.function_actions.function157
      && this.function_actions.function158
    );

    this.check_all.thamsocodinh = (
      this.function_actions.function159
      && this.function_actions.function160
    );

    this.check_all.thamsodinhmuc = (
      this.function_actions.function161
      && this.function_actions.function162
      && this.function_actions.function163
      && this.function_actions.function164
      && this.function_actions.function165
      && this.function_actions.function166
      && this.function_actions.function167
      && this.function_actions.function168
      && this.function_actions.function169
      && this.function_actions.function170
      && this.function_actions.function171
      && this.function_actions.function172
      && this.function_actions.function173
    );

    this.check_all.thamsocodinhdonkygui = (
      this.function_actions.function174
      && this.function_actions.function175
    );

    this.check_all.thamsodinhmucdonkygui = (
      this.function_actions.function176
      && this.function_actions.function177
      && this.function_actions.function178
      && this.function_actions.function179
    );

    this.check_all.thamsokhuyenmai = (
      this.function_actions.function180
      && this.function_actions.function181
      && this.function_actions.function182
      && this.function_actions.function183
    );

    this.check_all.taikhoanvadiadiemgiaodich = (
      this.function_actions.function184
      && this.function_actions.function185
      && this.function_actions.function186
      && this.function_actions.function187
      && this.function_actions.function188
      && this.function_actions.function189
      && this.function_actions.function190
      && this.function_actions.function191
      && this.function_actions.function192
      && this.function_actions.function193
    );

    this.check_all.capdokhachhang = (
      this.function_actions.function194
      && this.function_actions.function195
      && this.function_actions.function196
      && this.function_actions.function197
      && this.function_actions.function198
      && this.function_actions.function199
    );

    this.check_all.thamsodonhang = (
      this.check_all.thamsocodinh
      && this.check_all.thamsodinhmuc
    );

    this.check_all.thamsodonkygui = (
      this.check_all.thamsocodinhdonkygui
      && this.check_all.thamsodinhmucdonkygui
    );

    this.check_all.quanlythamso = (
      this.check_all.thamsodonhang
      && this.check_all.thamsodonkygui
      && this.check_all.thamsokhuyenmai
      && this.check_all.taikhoanvadiadiemgiaodich
      && this.check_all.capdokhachhang
    );

    this.check_all.danhsachquyen = (
      this.function_actions.function200
      && this.function_actions.function201
      && this.function_actions.function202
      && this.function_actions.function203
    );

    this.check_all.cacmenuduocthaotac = (
      this.function_actions.function204
      && this.function_actions.function205
    );

    this.check_all.cacchucnangduocthaotac = (
      this.function_actions.function206
      && this.function_actions.function207
    );

    this.check_all.cacthaotactrongdonhang = (
      this.function_actions.function208
      && this.function_actions.function209
      && this.function_actions.function210
      && this.function_actions.function211
    );

    this.check_all.cacthaotactrongdonkygui = (
      this.function_actions.function212
      && this.function_actions.function213
      && this.function_actions.function214
      && this.function_actions.function215
    );

    this.check_all.danhsachthaotactrongdonhang = (
      this.function_actions.function216
    );

    this.check_all.danhsachthaotactrongdonkygui = (
      this.function_actions.function217
    );

    this.check_all.quanlynguoidung = (
      this.function_actions.function218
      && this.function_actions.function219
      && this.function_actions.function220
      && this.function_actions.function221
      && this.function_actions.function222
      && this.function_actions.function223
    );

    this.check_all.capdonguoidung = (
      this.function_actions.function224
      && this.function_actions.function225
      && this.function_actions.function226
      && this.function_actions.function227
      && this.function_actions.function228
      && this.function_actions.function229
    );

    this.check_all.quanlyquyen = (
      this.check_all.danhsachquyen
      && this.check_all.cacmenuduocthaotac
      && this.check_all.cacchucnangduocthaotac
      && this.check_all.cacthaotactrongdonhang
      && this.check_all.cacthaotactrongdonkygui
      && this.check_all.danhsachthaotactrongdonhang
      && this.check_all.danhsachthaotactrongdonkygui
    );

    this.check_all.quanlynhansu = (
      this.check_all.quanlyquyen
      && this.check_all.quanlynguoidung
      && this.check_all.capdonguoidung
    );

    this.check_all.taikhoancanhan = (
      this.function_actions.function230
      && this.function_actions.function231
    );

    this.check_all.capnhatdulieu = (
      this.function_actions.function237
    );

    this.check_all.quanlytrang = (
      this.function_actions.function248
    );

    this.check_all.quanlybaiviet = (
      this.function_actions.function249
      && this.function_actions.function250
      && this.function_actions.function251
    );

    this.check_all.quanlyhinhanh = (
      this.function_actions.function245
      && this.function_actions.function246
      && this.function_actions.function247
    );

    this.check_all.naptienbangsms = (
      this.function_actions.function252
      && this.function_actions.function253
      && this.function_actions.function254
      && this.function_actions.function255
    );

    this.check_all.danhsachphieuxuatkho = (
      this.function_actions.function256
      && this.function_actions.function257
      && this.function_actions.function258
      && this.function_actions.function259
    );

    this.check_all.taophieuxuatkho = (
      this.function_actions.function260
      && this.function_actions.function261
      && this.function_actions.function269
    );

    this.check_all.chitietphieuxuatkho = (
      this.function_actions.function262
      && this.function_actions.function263
      && this.function_actions.function264
      && this.function_actions.function265
      && this.function_actions.function266
      && this.function_actions.function267
      && this.function_actions.function268
      && this.function_actions.function270
    );

  }

  onCheckAll() {
    this.check_all.phongkinhdoanh = this.check_all.check_all;
    this.check_all.quanlydonhang = this.check_all.check_all;
    this.check_all.danhsachdonhang = this.check_all.check_all;
    this.check_all.chitietdonhang = this.check_all.check_all;
    this.check_all.quanlydonhangchuacoc = this.check_all.check_all;
    this.check_all.danhsachdonhangchuacoc = this.check_all.check_all;
    this.check_all.chitietdonhangchuacoc = this.check_all.check_all;
    this.check_all.quanlydonkygui = this.check_all.check_all;
    this.check_all.danhsachdonkygui = this.check_all.check_all;
    this.check_all.chitietdonkygui = this.check_all.check_all;
    this.check_all.quanlymahoadon = this.check_all.check_all;
    this.check_all.quanlyhoantien = this.check_all.check_all;
    this.check_all.taodonhangexcel = this.check_all.check_all;
    this.check_all.taodonhangngoai = this.check_all.check_all;
    this.check_all.tonghopkhieunai = this.check_all.check_all;
    this.check_all.yeucauxuat = this.check_all.check_all;
    this.check_all.phongkho = this.check_all.check_all;
    this.check_all.phongketoan = this.check_all.check_all;
    this.check_all.khobangtuong = this.check_all.check_all;
    this.check_all.khovietnam = this.check_all.check_all;
    this.check_all.thongkegiaodich = this.check_all.check_all;
    this.check_all.vidientu = this.check_all.check_all;
    this.check_all.hanmuctindung = this.check_all.check_all;
    this.check_all.tonghopdonhang = this.check_all.check_all;
    this.check_all.tonghopdonkygui = this.check_all.check_all;
    this.check_all.baodong = this.check_all.check_all;
    this.check_all.donhangchuacomvd = this.check_all.check_all;
    this.check_all.donhangkhongcotrongluong = this.check_all.check_all;
    this.check_all.quanlykhachhang = this.check_all.check_all;
    this.check_all.danhsachkhachhang = this.check_all.check_all;
    this.check_all.quanlynoidung = this.check_all.check_all;
    this.check_all.quanlythamso = this.check_all.check_all;
    this.check_all.thamsodonhang = this.check_all.check_all;
    this.check_all.thamsocodinh = this.check_all.check_all;
    this.check_all.thamsodinhmuc = this.check_all.check_all;
    this.check_all.thamsodonkygui = this.check_all.check_all;
    this.check_all.thamsocodinhdonkygui = this.check_all.check_all;
    this.check_all.thamsodinhmucdonkygui = this.check_all.check_all;
    this.check_all.thamsokhuyenmai = this.check_all.check_all;
    this.check_all.taikhoanvadiadiemgiaodich = this.check_all.check_all;
    this.check_all.capdokhachhang = this.check_all.check_all;
    this.check_all.quanlynhansu = this.check_all.check_all;
    this.check_all.quanlyquyen = this.check_all.check_all;
    this.check_all.danhsachquyen = this.check_all.check_all;
    this.check_all.cacmenuduocthaotac = this.check_all.check_all;
    this.check_all.cacchucnangduocthaotac = this.check_all.check_all;
    this.check_all.cacthaotactrongdonhang = this.check_all.check_all;
    this.check_all.cacthaotactrongdonkygui = this.check_all.check_all;
    this.check_all.danhsachthaotactrongdonhang = this.check_all.check_all;
    this.check_all.danhsachthaotactrongdonkygui = this.check_all.check_all;
    this.check_all.quanlynguoidung = this.check_all.check_all;
    this.check_all.capdonguoidung = this.check_all.check_all;
    this.check_all.taikhoancanhan = this.check_all.check_all;
    this.check_all.capnhatdulieu = this.check_all.check_all;
    this.check_all.naptienbangsms = this.check_all.check_all;
    this.check_all.danhsachphieuxuatkho = this.check_all.check_all;
    this.function_actions.function001 = this.check_all.check_all;
    this.function_actions.function002 = this.check_all.check_all;
    this.function_actions.function003 = this.check_all.check_all;
    this.function_actions.function004 = this.check_all.check_all;
    // this.function_actions.function273 = this.check_all.check_all;
    this.function_actions.function274 = this.check_all.check_all;
    // this.function_actions.function275 = this.check_all.check_all;
    this.function_actions.function005 = this.check_all.check_all;
    this.function_actions.function006 = this.check_all.check_all;
    this.function_actions.function007 = this.check_all.check_all;
    this.function_actions.function008 = this.check_all.check_all;
    this.function_actions.function009 = this.check_all.check_all;
    this.function_actions.function010 = this.check_all.check_all;
    this.function_actions.function011 = this.check_all.check_all;
    this.function_actions.function012 = this.check_all.check_all;
    this.function_actions.function013 = this.check_all.check_all;
    this.function_actions.function014 = this.check_all.check_all;
    this.function_actions.function015 = this.check_all.check_all;
    this.function_actions.function016 = this.check_all.check_all;
    this.function_actions.function017 = this.check_all.check_all;
    this.function_actions.function018 = this.check_all.check_all;
    this.function_actions.function019 = this.check_all.check_all;
    this.function_actions.function020 = this.check_all.check_all;
    this.function_actions.function021 = this.check_all.check_all;
    this.function_actions.function022 = this.check_all.check_all;
    this.function_actions.function023 = this.check_all.check_all;
    this.function_actions.function024 = this.check_all.check_all;
    this.function_actions.function025 = this.check_all.check_all;
    this.function_actions.function026 = this.check_all.check_all;
    this.function_actions.function027 = this.check_all.check_all;
    this.function_actions.function028 = this.check_all.check_all;
    this.function_actions.function029 = this.check_all.check_all;
    this.function_actions.function030 = this.check_all.check_all;
    this.function_actions.function031 = this.check_all.check_all;
    this.function_actions.function032 = this.check_all.check_all;
    this.function_actions.function033 = this.check_all.check_all;
    this.function_actions.function034 = this.check_all.check_all;
    this.function_actions.function035 = this.check_all.check_all;
    this.function_actions.function036 = this.check_all.check_all;
    this.function_actions.function037 = this.check_all.check_all;
    this.function_actions.function238 = this.check_all.check_all;
    this.function_actions.function239 = this.check_all.check_all;
    this.function_actions.function038 = this.check_all.check_all;
    this.function_actions.function039 = this.check_all.check_all;
    this.function_actions.function040 = this.check_all.check_all;
    this.function_actions.function232 = this.check_all.check_all;
    this.function_actions.function233 = this.check_all.check_all;
    this.function_actions.function234 = this.check_all.check_all;
    this.function_actions.function235 = this.check_all.check_all;
    this.function_actions.function041 = this.check_all.check_all;
    this.function_actions.function042 = this.check_all.check_all;
    this.function_actions.function043 = this.check_all.check_all;
    this.function_actions.function044 = this.check_all.check_all;
    this.function_actions.function045 = this.check_all.check_all;
    this.function_actions.function046 = this.check_all.check_all;
    this.function_actions.function047 = this.check_all.check_all;
    this.function_actions.function048 = this.check_all.check_all;
    this.function_actions.function049 = this.check_all.check_all;
    this.function_actions.function050 = this.check_all.check_all;
    this.function_actions.function051 = this.check_all.check_all;
    this.function_actions.function052 = this.check_all.check_all;
    this.function_actions.function053 = this.check_all.check_all;
    this.function_actions.function054 = this.check_all.check_all;
    this.function_actions.function055 = this.check_all.check_all;
    this.function_actions.function056 = this.check_all.check_all;
    this.function_actions.function057 = this.check_all.check_all;
    this.function_actions.function058 = this.check_all.check_all;
    this.function_actions.function059 = this.check_all.check_all;
    this.function_actions.function060 = this.check_all.check_all;
    this.function_actions.function061 = this.check_all.check_all;
    this.function_actions.function062 = this.check_all.check_all;
    this.function_actions.function063 = this.check_all.check_all;
    this.function_actions.function064 = this.check_all.check_all;
    this.function_actions.function065 = this.check_all.check_all;
    this.function_actions.function066 = this.check_all.check_all;
    this.function_actions.function067 = this.check_all.check_all;
    this.function_actions.function068 = this.check_all.check_all;
    this.function_actions.function069 = this.check_all.check_all;
    this.function_actions.function070 = this.check_all.check_all;
    this.function_actions.function071 = this.check_all.check_all;
    this.function_actions.function072 = this.check_all.check_all;
    this.function_actions.function073 = this.check_all.check_all;
    this.function_actions.function074 = this.check_all.check_all;
    this.function_actions.function075 = this.check_all.check_all;
    this.function_actions.function236 = this.check_all.check_all;
    this.function_actions.function076 = this.check_all.check_all;
    this.function_actions.function077 = this.check_all.check_all;
    this.function_actions.function078 = this.check_all.check_all;
    this.function_actions.function079 = this.check_all.check_all;
    this.function_actions.function080 = this.check_all.check_all;
    this.function_actions.function081 = this.check_all.check_all;
    this.function_actions.function082 = this.check_all.check_all;
    this.function_actions.function083 = this.check_all.check_all;
    this.function_actions.function084 = this.check_all.check_all;
    this.function_actions.function085 = this.check_all.check_all;
    this.function_actions.function086 = this.check_all.check_all;
    this.function_actions.function087 = this.check_all.check_all;
    this.function_actions.function240 = this.check_all.check_all;
    this.function_actions.function241 = this.check_all.check_all;
    this.function_actions.function242 = this.check_all.check_all;
    this.function_actions.function273 = this.check_all.check_all;
    this.function_actions.function088 = this.check_all.check_all;
    this.function_actions.function089 = this.check_all.check_all;
    this.function_actions.function090 = this.check_all.check_all;
    this.function_actions.function091 = this.check_all.check_all;
    this.function_actions.function092 = this.check_all.check_all;
    this.function_actions.function093 = this.check_all.check_all;
    this.function_actions.function094 = this.check_all.check_all;
    this.function_actions.function095 = this.check_all.check_all;
    this.function_actions.function096 = this.check_all.check_all;
    this.function_actions.function097 = this.check_all.check_all;
    this.function_actions.function098 = this.check_all.check_all;
    this.function_actions.function099 = this.check_all.check_all;
    this.function_actions.function100 = this.check_all.check_all;
    this.function_actions.function101 = this.check_all.check_all;
    this.function_actions.function102 = this.check_all.check_all;
    this.function_actions.function103 = this.check_all.check_all;
    this.function_actions.function104 = this.check_all.check_all;
    this.function_actions.function105 = this.check_all.check_all;
    this.function_actions.function106 = this.check_all.check_all;
    this.function_actions.function107 = this.check_all.check_all;
    this.function_actions.function108 = this.check_all.check_all;
    this.function_actions.function109 = this.check_all.check_all;
    this.function_actions.function110 = this.check_all.check_all;
    this.function_actions.function111 = this.check_all.check_all;
    this.function_actions.function112 = this.check_all.check_all;
    this.function_actions.function113 = this.check_all.check_all;
    this.function_actions.function114 = this.check_all.check_all;
    this.function_actions.function115 = this.check_all.check_all;
    this.function_actions.function116 = this.check_all.check_all;
    this.function_actions.function117 = this.check_all.check_all;
    this.function_actions.function118 = this.check_all.check_all;
    this.function_actions.function119 = this.check_all.check_all;
    this.function_actions.function120 = this.check_all.check_all;
    this.function_actions.function121 = this.check_all.check_all;
    this.function_actions.function122 = this.check_all.check_all;
    this.function_actions.function123 = this.check_all.check_all;
    this.function_actions.function124 = this.check_all.check_all;
    this.function_actions.function125 = this.check_all.check_all;
    this.function_actions.function126 = this.check_all.check_all;
    this.function_actions.function127 = this.check_all.check_all;
    this.function_actions.function128 = this.check_all.check_all;
    this.function_actions.function129 = this.check_all.check_all;
    this.function_actions.function130 = this.check_all.check_all;
    this.function_actions.function131 = this.check_all.check_all;
    this.function_actions.function132 = this.check_all.check_all;
    this.function_actions.function133 = this.check_all.check_all;
    this.function_actions.function134 = this.check_all.check_all;
    this.function_actions.function135 = this.check_all.check_all;
    this.function_actions.function136 = this.check_all.check_all;
    this.function_actions.function137 = this.check_all.check_all;
    this.function_actions.function138 = this.check_all.check_all;
    this.function_actions.function139 = this.check_all.check_all;
    this.function_actions.function140 = this.check_all.check_all;
    this.function_actions.function141 = this.check_all.check_all;
    this.function_actions.function142 = this.check_all.check_all;
    this.function_actions.function143 = this.check_all.check_all;
    this.function_actions.function144 = this.check_all.check_all;
    this.function_actions.function145 = this.check_all.check_all;
    this.function_actions.function146 = this.check_all.check_all;
    // this.function_actions.function276 = this.check_all.check_all;
    // this.function_actions.function277 = this.check_all.check_all;
    // this.function_actions.function278 = this.check_all.check_all;
    this.function_actions.function147 = this.check_all.check_all;
    this.function_actions.function148 = this.check_all.check_all;
    this.function_actions.function271 = this.check_all.check_all;
    this.function_actions.function149 = this.check_all.check_all;
    this.function_actions.function150 = this.check_all.check_all;
    this.function_actions.function151 = this.check_all.check_all;
    this.function_actions.function152 = this.check_all.check_all;
    this.function_actions.function272 = this.check_all.check_all;
    this.function_actions.function243 = this.check_all.check_all;
    this.function_actions.function244 = this.check_all.check_all;
    this.function_actions.function153 = this.check_all.check_all;
    this.function_actions.function154 = this.check_all.check_all;
    this.function_actions.function155 = this.check_all.check_all;
    this.function_actions.function156 = this.check_all.check_all;
    this.function_actions.function157 = this.check_all.check_all;
    this.function_actions.function158 = this.check_all.check_all;
    this.function_actions.function159 = this.check_all.check_all;
    this.function_actions.function160 = this.check_all.check_all;
    this.function_actions.function161 = this.check_all.check_all;
    this.function_actions.function162 = this.check_all.check_all;
    this.function_actions.function163 = this.check_all.check_all;
    this.function_actions.function164 = this.check_all.check_all;
    this.function_actions.function165 = this.check_all.check_all;
    this.function_actions.function166 = this.check_all.check_all;
    this.function_actions.function167 = this.check_all.check_all;
    this.function_actions.function168 = this.check_all.check_all;
    this.function_actions.function169 = this.check_all.check_all;
    this.function_actions.function170 = this.check_all.check_all;
    this.function_actions.function171 = this.check_all.check_all;
    this.function_actions.function172 = this.check_all.check_all;
    this.function_actions.function173 = this.check_all.check_all;
    this.function_actions.function174 = this.check_all.check_all;
    this.function_actions.function175 = this.check_all.check_all;
    this.function_actions.function176 = this.check_all.check_all;
    this.function_actions.function177 = this.check_all.check_all;
    this.function_actions.function178 = this.check_all.check_all;
    this.function_actions.function179 = this.check_all.check_all;
    this.function_actions.function180 = this.check_all.check_all;
    this.function_actions.function181 = this.check_all.check_all;
    this.function_actions.function182 = this.check_all.check_all;
    this.function_actions.function183 = this.check_all.check_all;
    this.function_actions.function184 = this.check_all.check_all;
    this.function_actions.function185 = this.check_all.check_all;
    this.function_actions.function186 = this.check_all.check_all;
    this.function_actions.function187 = this.check_all.check_all;
    this.function_actions.function188 = this.check_all.check_all;
    this.function_actions.function189 = this.check_all.check_all;
    this.function_actions.function190 = this.check_all.check_all;
    this.function_actions.function191 = this.check_all.check_all;
    this.function_actions.function192 = this.check_all.check_all;
    this.function_actions.function193 = this.check_all.check_all;
    this.function_actions.function194 = this.check_all.check_all;
    this.function_actions.function195 = this.check_all.check_all;
    this.function_actions.function196 = this.check_all.check_all;
    this.function_actions.function197 = this.check_all.check_all;
    this.function_actions.function198 = this.check_all.check_all;
    this.function_actions.function199 = this.check_all.check_all;
    this.function_actions.function200 = this.check_all.check_all;
    this.function_actions.function201 = this.check_all.check_all;
    this.function_actions.function202 = this.check_all.check_all;
    this.function_actions.function203 = this.check_all.check_all;
    this.function_actions.function204 = this.check_all.check_all;
    this.function_actions.function205 = this.check_all.check_all;
    this.function_actions.function206 = this.check_all.check_all;
    this.function_actions.function207 = this.check_all.check_all;
    this.function_actions.function208 = this.check_all.check_all;
    this.function_actions.function209 = this.check_all.check_all;
    this.function_actions.function210 = this.check_all.check_all;
    this.function_actions.function211 = this.check_all.check_all;
    this.function_actions.function212 = this.check_all.check_all;
    this.function_actions.function213 = this.check_all.check_all;
    this.function_actions.function214 = this.check_all.check_all;
    this.function_actions.function215 = this.check_all.check_all;
    this.function_actions.function216 = this.check_all.check_all;
    this.function_actions.function217 = this.check_all.check_all;
    this.function_actions.function218 = this.check_all.check_all;
    this.function_actions.function219 = this.check_all.check_all;
    this.function_actions.function220 = this.check_all.check_all;
    this.function_actions.function221 = this.check_all.check_all;
    this.function_actions.function222 = this.check_all.check_all;
    this.function_actions.function223 = this.check_all.check_all;
    this.function_actions.function224 = this.check_all.check_all;
    this.function_actions.function225 = this.check_all.check_all;
    this.function_actions.function226 = this.check_all.check_all;
    this.function_actions.function227 = this.check_all.check_all;
    this.function_actions.function228 = this.check_all.check_all;
    this.function_actions.function229 = this.check_all.check_all;
    this.function_actions.function230 = this.check_all.check_all;
    this.function_actions.function231 = this.check_all.check_all;
    this.function_actions.function237 = this.check_all.check_all;
    this.function_actions.function245 = this.check_all.check_all;
    this.function_actions.function246 = this.check_all.check_all;
    this.function_actions.function247 = this.check_all.check_all;
    this.function_actions.function248 = this.check_all.check_all;
    this.function_actions.function249 = this.check_all.check_all;
    this.function_actions.function250 = this.check_all.check_all;
    this.function_actions.function251 = this.check_all.check_all;
    this.function_actions.function252 = this.check_all.check_all;
    this.function_actions.function253 = this.check_all.check_all;
    this.function_actions.function254 = this.check_all.check_all;
    this.function_actions.function255 = this.check_all.check_all;
    this.function_actions.function256 = this.check_all.check_all;
    this.function_actions.function257 = this.check_all.check_all;
    this.function_actions.function258 = this.check_all.check_all;
    this.function_actions.function259 = this.check_all.check_all;
    this.function_actions.function260 = this.check_all.check_all;
    this.function_actions.function261 = this.check_all.check_all;
    this.function_actions.function269 = this.check_all.check_all;
    this.function_actions.function262 = this.check_all.check_all;
    this.function_actions.function263 = this.check_all.check_all;
    this.function_actions.function264 = this.check_all.check_all;
    this.function_actions.function265 = this.check_all.check_all;
    this.function_actions.function266 = this.check_all.check_all;
    this.function_actions.function267 = this.check_all.check_all;
    this.function_actions.function268 = this.check_all.check_all;
    this.function_actions.function270 = this.check_all.check_all;
  }

  onCheckDanhSachDonHang() {
    const checked = this.check_all.danhsachdonhang;
    this.check_all.danhsachdonhang = checked;
    this.function_actions.function001 = checked; // Xem danh sách đơn hàng của tất cả nhân viên kinh doanh
    this.function_actions.function002 = checked; // Xem danh sách đơn hàng của tất cả nhân viên chăm sóc khách hàng
    this.function_actions.function003 = checked; // Xem toàn bộ danh sách đơn hàng
    this.function_actions.function004 = checked; // Tìm kiếm đơn hàng theo các tiêu chí
    // this.function_actions.function273 = checked; // Xem những đơn hàng thuộc quản lý
    this.function_actions.function274 = checked; // Xem những đơn hàng chưa có nhân viên phụ trách
    // this.function_actions.function275 = checked; // Xem toàn bộ danh sách đơn hàng của Pandaorder
    this.function_actions.function005 = checked; // Chuyển đến màn hình chi tiết đơn hàng
    this.function_actions.function006 = checked; // Xóa đơn hàng
    this.function_actions.function007 = checked; // Xuất ra file excel
    this.refreshItemCheckAll();
  }

  onCheckChiTietDonHang() {
    const checked = this.check_all.chitietdonhang;
    this.function_actions.function008 = checked;
    this.function_actions.function009 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachDonHangChuaCoc() {
    const checked = this.check_all.danhsachdonhangchuacoc;
    this.check_all.danhsachdonhangchuacoc = checked;
    this.function_actions.function279 = checked; // Danh sách đơn hàng chưa cọc -> Xem toàn bộ danh sách đơn hàng
    // this.function_actions.function280 = checked; // Danh sách đơn hàng chưa cọc -> Xem những đơn hàng thuộc quản lý
    this.function_actions.function281 = checked; // Danh sách đơn hàng chưa cọc -> Xem những đơn hàng chưa có nhân viên phụ trách
    // this.function_actions.function282 = checked; // Danh sách đơn hàng chưa cọc -> Xem toàn bộ danh sách đơn hàng của Pandaorder
    this.function_actions.function283 = checked; // Danh sách đơn hàng chưa cọc -> Tìm kiếm đơn hàng theo các tiêu chí
    this.function_actions.function284 = checked; // Danh sách đơn hàng chưa cọc -> Chuyển đến màn hình chi tiết đơn hàng
    this.refreshItemCheckAll();
  }

  onCheckChiTietDonHangChuaCoc() {
    const checked = this.check_all.chitietdonhangchuacoc;
    this.function_actions.function285 = checked;
    this.function_actions.function286 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachDonKyGui() {
    const checked = this.check_all.danhsachdonkygui;
    this.function_actions.function010 = checked;
    this.function_actions.function011 = checked;
    this.function_actions.function012 = checked;
    this.function_actions.function013 = checked;
    this.function_actions.function014 = checked;
    this.function_actions.function015 = checked;
    this.function_actions.function016 = checked;
    this.refreshItemCheckAll();
  }

  onCheckChiTietDonKyGui() {
    const checked = this.check_all.chitietdonkygui;
    this.function_actions.function017 = checked;
    this.function_actions.function018 = checked;
    this.refreshItemCheckAll();
  }

  onCheckQuanLyMaHoaDon() {
    const checked = this.check_all.quanlymahoadon;
    this.function_actions.function019 = checked;
    this.function_actions.function020 = checked;
    this.function_actions.function021 = checked;
    this.function_actions.function022 = checked;
    this.function_actions.function023 = checked;
    this.function_actions.function024 = checked;
    this.refreshItemCheckAll();
  }

  onCheckQuanLyHoanTien() {
    const checked = this.check_all.quanlyhoantien;
    this.function_actions.function025 = checked;
    this.function_actions.function026 = checked;
    this.function_actions.function027 = checked;
    this.function_actions.function028 = checked;
    this.function_actions.function029 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTaoDonHangExcel() {
    const checked = this.check_all.taodonhangexcel;
    this.function_actions.function030 = checked;
    this.function_actions.function031 = checked;
    this.function_actions.function032 = checked;
    this.function_actions.function033 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTaoDonHangNgoai() {
    const checked = this.check_all.taodonhangngoai;
    this.function_actions.function034 = checked;
    this.function_actions.function035 = checked;
    this.function_actions.function036 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTongHopKhieuNai() {
    const checked = this.check_all.tonghopkhieunai;
    this.function_actions.function037 = checked;
    this.function_actions.function238 = checked;
    this.function_actions.function239 = checked;
    this.function_actions.function038 = checked;
    this.function_actions.function039 = checked;
    this.function_actions.function040 = checked;
    this.function_actions.function232 = checked;
    this.function_actions.function233 = checked;
    this.function_actions.function234 = checked;
    this.function_actions.function235 = checked;
    this.function_actions.function041 = checked;
    this.refreshItemCheckAll();
  }

  onCheckYeuCauXuat() {
    const checked = this.check_all.yeucauxuat;
    this.function_actions.function042 = checked;
    this.function_actions.function043 = checked;
    this.function_actions.function044 = checked;
    this.function_actions.function045 = checked;
    this.function_actions.function046 = checked;
    this.function_actions.function047 = checked;
    this.function_actions.function048 = checked;
    this.refreshItemCheckAll();
  }

  onCheckKhoBangTuong() {
    const checked = this.check_all.khobangtuong;
    this.function_actions.function049 = checked;
    this.function_actions.function050 = checked;
    this.function_actions.function051 = checked;
    this.function_actions.function052 = checked;
    this.function_actions.function053 = checked;
    this.function_actions.function054 = checked;
    this.function_actions.function055 = checked;
    this.function_actions.function056 = checked;
    this.function_actions.function057 = checked;
    this.refreshItemCheckAll();
  }

  onCheckKhoVietNam() {
    const checked = this.check_all.khovietnam;
    this.function_actions.function058 = checked;
    this.function_actions.function059 = checked;
    this.function_actions.function060 = checked;
    this.function_actions.function061 = checked;
    this.function_actions.function062 = checked;
    this.function_actions.function063 = checked;
    this.function_actions.function064 = checked;
    this.function_actions.function065 = checked;
    this.function_actions.function066 = checked;
    this.function_actions.function067 = checked;
    this.function_actions.function068 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThongKeGiaoDich() {
    const checked = this.check_all.thongkegiaodich;
    this.function_actions.function069 = checked;
    this.function_actions.function070 = checked;
    this.function_actions.function071 = checked;
    this.function_actions.function072 = checked;
    this.function_actions.function073 = checked;
    this.refreshItemCheckAll();
  }

  onCheckViDienTu() {
    const checked = this.check_all.vidientu;
    this.function_actions.function074 = checked;
    this.function_actions.function075 = checked;
    this.function_actions.function236 = checked;
    this.function_actions.function076 = checked;
    this.function_actions.function077 = checked;
    this.function_actions.function078 = checked;
    this.function_actions.function079 = checked;
    this.function_actions.function080 = checked;
    this.function_actions.function081 = checked;
    this.function_actions.function082 = checked;
    this.function_actions.function083 = checked;
    this.refreshItemCheckAll();
  }

  onCheckHanMucTinDung() {
    const checked = this.check_all.hanmuctindung;
    this.function_actions.function084 = checked;
    this.function_actions.function085 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTongHopDonHang() {
    const checked = this.check_all.tonghopdonhang;
    this.function_actions.function086 = checked;
    this.function_actions.function087 = checked;
    this.function_actions.function240 = checked;
    this.function_actions.function241 = checked;
    this.function_actions.function242 = checked;
    this.function_actions.function273 = checked;
    this.function_actions.function088 = checked;
    this.function_actions.function089 = checked;
    this.function_actions.function090 = checked;
    this.function_actions.function091 = checked;
    this.function_actions.function092 = checked;
    this.function_actions.function093 = checked;
    this.function_actions.function094 = checked;
    this.function_actions.function095 = checked;
    this.function_actions.function096 = checked;
    this.function_actions.function097 = checked;
    this.function_actions.function098 = checked;
    this.function_actions.function099 = checked;
    this.function_actions.function100 = checked;
    this.function_actions.function101 = checked;
    this.function_actions.function102 = checked;
    this.function_actions.function103 = checked;
    this.function_actions.function104 = checked;
    this.function_actions.function105 = checked;
    this.function_actions.function106 = checked;
    this.function_actions.function107 = checked;
    this.function_actions.function108 = checked;
    this.function_actions.function109 = checked;
    this.function_actions.function110 = checked;
    this.function_actions.function111 = checked;
    this.function_actions.function112 = checked;
    this.function_actions.function113 = checked;
    this.function_actions.function114 = checked;
    this.function_actions.function115 = checked;
    this.function_actions.function116 = checked;
    this.function_actions.function117 = checked;
    this.function_actions.function118 = checked;
    this.function_actions.function119 = checked;
    this.function_actions.function120 = checked;
    this.function_actions.function121 = checked;
    this.function_actions.function122 = checked;
    this.function_actions.function123 = checked;
    this.function_actions.function124 = checked;
    this.function_actions.function125 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTongHopDonKyGui() {
    const checked = this.check_all.tonghopdonkygui;
    this.function_actions.function126 = checked;
    this.function_actions.function127 = checked;
    this.function_actions.function128 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDonHangChuaCoMvd() {
    const checked = this.check_all.donhangchuacomvd;
    this.function_actions.function129 = checked;
    this.function_actions.function130 = checked;
    this.function_actions.function131 = checked;
    this.function_actions.function132 = checked;
    this.function_actions.function133 = checked;
    this.function_actions.function134 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDonHangKhongCoTrongLuong() {
    const checked = this.check_all.donhangkhongcotrongluong;
    this.function_actions.function135 = checked;
    this.function_actions.function136 = checked;
    this.function_actions.function137 = checked;
    this.function_actions.function138 = checked;
    this.function_actions.function139 = checked;
    this.function_actions.function140 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachKhachHang() {
    const checked = this.check_all.danhsachkhachhang;
    this.function_actions.function141 = checked;
    this.function_actions.function142 = checked;
    this.function_actions.function143 = checked;
    this.function_actions.function144 = checked;
    this.function_actions.function145 = checked;
    this.function_actions.function146 = checked;
    // this.function_actions.function276 = checked;
    // this.function_actions.function277 = checked;
    // this.function_actions.function278 = checked;
    this.function_actions.function147 = checked;
    this.function_actions.function148 = checked;
    this.function_actions.function271 = checked;
    this.function_actions.function149 = checked;
    this.function_actions.function150 = checked;
    this.function_actions.function151 = checked;
    this.function_actions.function152 = checked;
    this.function_actions.function272 = checked;
    this.function_actions.function243 = checked;
    this.function_actions.function244 = checked;
    this.refreshItemCheckAll();
  }

  onCheckQuanLyNoiDung() {
    const checked = this.check_all.quanlynoidung;
    this.function_actions.function153 = checked;
    this.function_actions.function154 = checked;
    this.function_actions.function155 = checked;
    this.function_actions.function156 = checked;
    this.function_actions.function157 = checked;
    this.function_actions.function158 = checked;
    this.function_actions.function250 = checked;
    this.function_actions.function251 = checked;
    this.function_actions.function249 = checked;
    this.function_actions.function248 = checked;
    this.function_actions.function247 = checked;
    this.function_actions.function246 = checked;
    this.function_actions.function245 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThamSoCoDinh() {
    const checked = this.check_all.thamsocodinh;
    this.function_actions.function159 = checked;
    this.function_actions.function160 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThamSoDinhMuc() {
    const checked = this.check_all.thamsodinhmuc;
    this.function_actions.function161 = checked;
    this.function_actions.function162 = checked;
    this.function_actions.function163 = checked;
    this.function_actions.function164 = checked;
    this.function_actions.function165 = checked;
    this.function_actions.function166 = checked;
    this.function_actions.function167 = checked;
    this.function_actions.function168 = checked;
    this.function_actions.function169 = checked;
    this.function_actions.function170 = checked;
    this.function_actions.function171 = checked;
    this.function_actions.function172 = checked;
    this.function_actions.function173 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThamSoCoDinhDonKyGui() {
    const checked = this.check_all.thamsocodinhdonkygui;
    this.function_actions.function174 = checked;
    this.function_actions.function175 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThamSoDinhMucDonKyGui() {
    const checked = this.check_all.thamsodinhmucdonkygui;
    this.function_actions.function176 = checked;
    this.function_actions.function177 = checked;
    this.function_actions.function178 = checked;
    this.function_actions.function179 = checked;
    this.refreshItemCheckAll();
  }

  onCheckThamSoKhuyenMai() {
    const checked = this.check_all.thamsokhuyenmai;
    this.function_actions.function180 = checked;
    this.function_actions.function181 = checked;
    this.function_actions.function182 = checked;
    this.function_actions.function183 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTaiKhoanVaDiaDiemGiaoDich() {
    const checked = this.check_all.taikhoanvadiadiemgiaodich;
    this.function_actions.function184 = checked;
    this.function_actions.function185 = checked;
    this.function_actions.function186 = checked;
    this.function_actions.function187 = checked;
    this.function_actions.function188 = checked;
    this.function_actions.function189 = checked;
    this.function_actions.function190 = checked;
    this.function_actions.function191 = checked;
    this.function_actions.function192 = checked;
    this.function_actions.function193 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCapDoKhachHang() {
    const checked = this.check_all.capdokhachhang;
    this.function_actions.function194 = checked;
    this.function_actions.function195 = checked;
    this.function_actions.function196 = checked;
    this.function_actions.function197 = checked;
    this.function_actions.function198 = checked;
    this.function_actions.function199 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachQuyen() {
    const checked = this.check_all.danhsachquyen;
    this.function_actions.function200 = checked;
    this.function_actions.function201 = checked;
    this.function_actions.function202 = checked;
    this.function_actions.function203 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCacMenuDuocThaoTac() {
    const checked = this.check_all.cacmenuduocthaotac;
    this.function_actions.function204 = checked;
    this.function_actions.function205 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCacChucNangDuocThaoTac() {
    const checked = this.check_all.cacchucnangduocthaotac;
    this.function_actions.function206 = checked;
    this.function_actions.function207 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCacThaoTacTrongDonHang() {
    const checked = this.check_all.cacthaotactrongdonhang;
    this.function_actions.function208 = checked;
    this.function_actions.function209 = checked;
    this.function_actions.function210 = checked;
    this.function_actions.function211 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCacThaoTacTrongDonKyGui() {
    const checked = this.check_all.cacthaotactrongdonkygui;
    this.function_actions.function212 = checked;
    this.function_actions.function213 = checked;
    this.function_actions.function214 = checked;
    this.function_actions.function215 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachThaoTacTrongDonHang() {
    const checked = this.check_all.danhsachthaotactrongdonhang;
    this.function_actions.function216 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachThaoTacTrongDonKyGui() {
    const checked = this.check_all.danhsachthaotactrongdonkygui;
    this.function_actions.function217 = checked;
    this.refreshItemCheckAll();
  }

  onCheckQuanLyNguoiDung() {
    const checked = this.check_all.quanlynguoidung;
    this.function_actions.function218 = checked;
    this.function_actions.function219 = checked;
    this.function_actions.function220 = checked;
    this.function_actions.function221 = checked;
    this.function_actions.function222 = checked;
    this.function_actions.function223 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCapDoNguoiDung() {
    const checked = this.check_all.capdonguoidung;
    this.function_actions.function224 = checked;
    this.function_actions.function225 = checked;
    this.function_actions.function226 = checked;
    this.function_actions.function227 = checked;
    this.function_actions.function228 = checked;
    this.function_actions.function229 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTaiKhoanCaNhan() {
    const checked = this.check_all.taikhoancanhan;
    this.function_actions.function230 = checked;
    this.function_actions.function231 = checked;
    this.refreshItemCheckAll();
  }

  onCheckCapNhatDuLieu() {
    const checked = this.check_all.capnhatdulieu;
    this.function_actions.function237 = checked;
    this.refreshItemCheckAll();
  }

  onCheckQuanLyDonHang() {
    const checked = this.check_all.quanlydonhang;
    this.check_all.danhsachdonhang = checked;
    this.onCheckDanhSachDonHang();
    this.check_all.chitietdonhang = checked;
    this.onCheckChiTietDonHang();
  }

  onCheckQuanLyDonHangChuaCoc() {
    const checked = this.check_all.quanlydonhangchuacoc;
    this.check_all.danhsachdonhangchuacoc = checked;
    this.onCheckDanhSachDonHangChuaCoc();
    this.check_all.chitietdonhangchuacoc = checked;
    this.onCheckChiTietDonHangChuaCoc();
  }

  onCheckQuanLyDonKyGui() {
    const checked = this.check_all.quanlydonkygui;
    this.check_all.danhsachdonkygui = checked;
    this.onCheckDanhSachDonKyGui();
    this.check_all.chitietdonkygui = checked;
    this.onCheckChiTietDonKyGui();
  }

  onCheckPhongKinhDoanh() {
    const checked = this.check_all.phongkinhdoanh;
    this.check_all.quanlydonhang = checked;
    this.onCheckQuanLyDonHang();
    this.check_all.quanlydonkygui = checked;
    this.onCheckQuanLyDonKyGui();
    this.check_all.quanlymahoadon = checked;
    this.onCheckQuanLyMaHoaDon();
    this.check_all.quanlyhoantien = checked;
    this.onCheckQuanLyHoanTien();
    this.check_all.taodonhangexcel = checked;
    this.onCheckTaoDonHangExcel();
    this.check_all.taodonhangngoai = checked;
    this.onCheckTaoDonHangNgoai();
    this.check_all.tonghopkhieunai = checked;
    this.onCheckTongHopKhieuNai();
    this.check_all.yeucauxuat = checked;
    this.onCheckYeuCauXuat();
  }

  onCheckPhongKho() {
    const checked = this.check_all.phongkho;
    this.check_all.khobangtuong = checked;
    this.onCheckKhoBangTuong();
    this.check_all.khovietnam = checked;
    this.onCheckKhoVietNam();
  }

  onCheckPhongKeToan() {
    const checked = this.check_all.phongketoan;
    this.check_all.thongkegiaodich = checked;
    this.onCheckThongKeGiaoDich();
    this.check_all.vidientu = checked;
    this.onCheckViDienTu();
    this.check_all.hanmuctindung = checked;
    this.onCheckHanMucTinDung();
    this.check_all.tonghopdonhang = checked;
    this.onCheckTongHopDonHang();
    this.check_all.tonghopdonkygui = checked;
    this.onCheckTongHopDonKyGui();
  }

  onCheckBaoDong() {
    const checked = this.check_all.baodong;
    this.check_all.donhangchuacomvd = checked;
    this.onCheckDonHangChuaCoMvd();
    this.check_all.donhangkhongcotrongluong = checked;
    this.onCheckDonHangKhongCoTrongLuong();
  }

  onCheckQuanLyKhachHang() {
    const checked = this.check_all.quanlykhachhang;
    this.check_all.danhsachkhachhang = checked;
    this.onCheckDanhSachKhachHang();
  }

  onCheckThamSoDonHang() {
    const checked = this.check_all.thamsodonhang;
    this.check_all.thamsocodinh = checked;
    this.onCheckThamSoCoDinh();
    this.check_all.thamsodinhmuc = checked;
    this.onCheckThamSoDinhMuc();
  }

  onCheckThamSoDonKyGui() {
    const checked = this.check_all.thamsodonkygui;
    this.check_all.thamsocodinhdonkygui = checked;
    this.onCheckThamSoCoDinhDonKyGui();
    this.check_all.thamsodinhmucdonkygui = checked;
    this.onCheckThamSoDinhMucDonKyGui();
  }

  onCheckQuanLyThamSo() {
    const checked = this.check_all.quanlythamso;
    this.check_all.thamsodonhang = checked;
    this.onCheckThamSoDonHang();
    this.check_all.thamsodonkygui = checked;
    this.onCheckThamSoDonKyGui();
    this.check_all.thamsokhuyenmai = checked;
    this.onCheckThamSoKhuyenMai();
    this.check_all.taikhoanvadiadiemgiaodich = checked;
    this.onCheckTaiKhoanVaDiaDiemGiaoDich();
    this.check_all.capdokhachhang = checked;
    this.onCheckCapDoKhachHang();
  }

  onCheckQuanLyQuyen() {
    const checked = this.check_all.quanlyquyen;
    this.check_all.danhsachquyen = checked;
    this.onCheckDanhSachQuyen();
    this.check_all.cacmenuduocthaotac = checked;
    this.onCheckCacMenuDuocThaoTac();
    this.check_all.cacchucnangduocthaotac = checked;
    this.onCheckCacChucNangDuocThaoTac();
    this.check_all.cacthaotactrongdonhang = checked;
    this.onCheckCacThaoTacTrongDonHang();
    this.check_all.cacthaotactrongdonkygui = checked;
    this.onCheckCacThaoTacTrongDonKyGui();
    this.check_all.danhsachthaotactrongdonhang = checked;
    this.onCheckDanhSachThaoTacTrongDonHang();
    this.check_all.danhsachthaotactrongdonkygui = checked;
    this.onCheckDanhSachThaoTacTrongDonKyGui();
  }

  onCheckQuanLyNhanSu() {
    const checked = this.check_all.quanlynhansu;
    this.check_all.quanlyquyen = checked;
    this.onCheckQuanLyQuyen();
    this.check_all.quanlynguoidung = checked;
    this.onCheckQuanLyNguoiDung();
    this.check_all.capdonguoidung = checked;
    this.onCheckCapDoNguoiDung();
  }

  onCheckQuanLyTrang() {
    const checked = this.check_all.quanlytrang;
    this.function_actions.function248 = checked;
  }

  onCheckQuanLyBaiViet() {
    const checked = this.check_all.quanlybaiviet;
    this.function_actions.function249 = checked;
    this.function_actions.function250 = checked;
    this.function_actions.function251 = checked;
  }

  onCheckQuanLyHinhAnh() {
    const checked = this.check_all.quanlyhinhanh;
    this.function_actions.function245 = checked;
    this.function_actions.function246 = checked;
    this.function_actions.function247 = checked;
  }

  onCheckNapTienBangSMS() {
    const checked = this.check_all.naptienbangsms;
    this.function_actions.function252 = checked;
    this.function_actions.function253 = checked;
    this.function_actions.function254 = checked;
    this.function_actions.function255 = checked;
    this.refreshItemCheckAll();
  }

  onCheckDanhSachPhieuXuatKho() {
    const checked = this.check_all.danhsachphieuxuatkho;
    this.function_actions.function256 = checked;
    this.function_actions.function257 = checked;
    this.function_actions.function258 = checked;
    this.function_actions.function259 = checked;
    this.refreshItemCheckAll();
  }

  onCheckTaoPhieuXuatKho() {
    const checked = this.check_all.taophieuxuatkho;
    this.function_actions.function260 = checked;
    this.function_actions.function261 = checked;
    this.function_actions.function269 = checked;
    this.refreshItemCheckAll();
  }

  onCheckChiTietPhieuXuatKho() {
    const checked = this.check_all.chitietphieuxuatkho;
    this.function_actions.function262 = checked;
    this.function_actions.function263 = checked;
    this.function_actions.function264 = checked;
    this.function_actions.function265 = checked;
    this.function_actions.function266 = checked;
    this.function_actions.function267 = checked;
    this.function_actions.function268 = checked;
    this.function_actions.function270 = checked;
    this.refreshItemCheckAll();
  }

}
