import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomConfirm, FIREBASE_STRUCT, validObject } from '../../app.constant';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { CalculateFeeService } from '../../services/calculate-fee/calculate-fee.service';
import { Subject } from 'rxjs';
import { Decimal } from '../../app.models';

@Component({
  selector: 'app-add-slow-transfer',
  templateUrl: './add-slow-transfer.component.html',
  styleUrls: ['./add-slow-transfer.component.css']
})
export class AddSlowTransferComponent implements OnInit, OnDestroy {
  temp_transports: any[];
  transport_bag_fee: number;
  extra_fee: number;
  bag_weight: number;
  row_number = 5;
  weightConversionFactor = 0;
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public calcFeeService: CalculateFeeService,
    public commonService: CommonUtilsService
  ) {
    this.temp_transports = [];
    for (let index = 0; index < 1; index++) {
      const temp_tran = {
        transport_code: null,
        transport_real_kg: null,
        transport_exchanged_kg: null,
        transport_length: null,
        transport_width: null,
        transport_height: null,
        transport_quantity: null,
        transport_product_type: null
      };
      this.temp_transports.push(temp_tran);
    }
  }

  async ngOnInit() {
    this.weightConversionFactor = Number(await this._getWeightConversionFactor());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onAddTran() {
    for (let index = 0; index < this.row_number; index++) {
      const temp_tran = {
        transport_code: null,
        transport_real_kg: null,
        transport_exchanged_kg: null,
        transport_length: null,
        transport_width: null,
        transport_height: null,
        transport_quantity: null,
        transport_product_type: null
      };
      this.temp_transports.push(temp_tran);
    }
  }

  onDeleteTran(index) {
    this.temp_transports.splice(index, 1);
  }

  dataChanged(index) {
    this.temp_transports[index].transport_exchanged_kg = new Decimal(this.temp_transports[index].transport_length)
      .mul(this.temp_transports[index].transport_width)
      .mul(this.temp_transports[index].transport_height)
      .div(7000)
      .roundUp(2)
      .toNumber();
  }

  private _getWeightConversionFactor() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() as any })),
      first()
    ).toPromise();
  }

  checkTrans() {
    let is_validated = true;
    let l = 0;
    this.temp_transports.forEach(tran => {
      if (
        !(!tran.transport_code && !tran.transport_real_kg && !tran.transport_product_quantity && !tran.transport_product_type)
      ) {
        if (this.validTran(tran)) {
          l++;
        } else {
          is_validated = false;
        }
      }
    });
    return is_validated && l > 0;
  }

  validTran(tran) {
    if (
      (
        Number(tran.transport_length) > 0 ||
        Number(tran.transport_width) > 0 ||
        Number(tran.transport_height) > 0
      )
      && Number(tran.transport_exchanged_kg) === 0) {
      return false;
    } else {
      if (
        !tran.transport_code || !tran.transport_real_kg || !tran.transport_product_quantity || !tran.transport_product_type
      ) { return false; } else {
        return true;
      }
    }
  }

  @CustomConfirm('Xác nhận thêm các mã vận đơn này?')
  async onSubmit() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      const param = { transport_bag_fee: this.transport_bag_fee, extra_fee: this.extra_fee, bag_weight: this.bag_weight, motorbike_fee: 0 };
      const current = await this.commonService.getServerTime();
      const id = await fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.BAG_CPC_UID).get().then(doc => {
        return Number(doc.get('id') || 0) + 1;
      });

      batch.set(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.BAG_CPC_UID), { id }, { merge: true });
      const bagUid = this.fs.firestore.collection(FIREBASE_STRUCT.BAGS.NODE).doc().id;
      batch.set(fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc(bagUid), {
        is_bag_cpc: true,
        is_bagging: true,
        is_delivered: false,
        bag_code: `ĐH_${id}_${this.bag_weight}`,
        bag_id: `${id}`,
        bag_uid: bagUid,
        ...param
      });

      let weight = 0;

      for (const tran of this.temp_transports) {
        if (Number(tran.transport_exchanged_kg) > Number(tran.transport_real_kg)) {
          weight = new Decimal(weight).add(tran.transport_exchanged_kg).roundUp(2).toNumber();
        } else {
          weight = new Decimal(weight).add(tran.transport_real_kg).roundUp(2).toNumber();
        }
      }

      const transport_price_mvd: number = new Decimal(param.transport_bag_fee)
        .mul(param.bag_weight)
        .add(param.extra_fee)
        .div(weight)
        .roundUp(0)
        .toNumber();

      for (let index = 0; index < this.temp_transports.length; index++) {
        const tran = this.temp_transports[index];
        const t = await this.getTransportDetailPromise(tran.transport_code);
        if (t) { throw new Error(`Mã vận đơn '${tran.transport_code}' đã tồn tại. Vui lòng nhập lại`); }
        if (this.validTran(tran)) {
          validObject(tran);
          if (Number(tran.transport_length) > 0 &&
            Number(tran.transport_width) > 0 &&
            Number(tran.transport_height) > 0) {
            tran.transport_size = `${tran.transport_length}/${tran.transport_width}/${tran.transport_height}`;
          }
          tran.is_bagging = true;
          tran.transport_cpc = true;
          tran.bag_code = `ĐH_${id}_${this.bag_weight}`;
          tran.bag_weight = param.bag_weight;
          /* tran.transport_weight = tran.transport_real_kg;
          if (Number(tran.transport_exchanged_kg) > Number(tran.transport_real_kg)) {
            tran.transport_weight = tran.transport_exchanged_kg;
          } */
          tran.transport_weight = this._roundTransportWeight(tran);
          tran.transport_price_mvd = transport_price_mvd;

          tran.is_warehouse = true;
          tran.transport_date_created = current;
          tran.date_deliver_vn = current;
          tran.date_receive_vn = current;

          if (index === this.temp_transports.length - 1) {
            tran.transport_transport_bag_fee = param.transport_bag_fee;
            tran.transport_extra_fee = param.extra_fee;
            tran.transport_motorbike_fee = param.motorbike_fee;
          } else {
            tran.transport_transport_bag_fee = 0;
            tran.transport_extra_fee = 0;
            tran.transport_motorbike_fee = 0;
          }
          const transportUid = fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc().id;
          tran.transport_uid = transportUid;
          batch.set(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transportUid),
            { ...tran },
            { merge: true }
          );
        }
      }

      await batch.commit();

      await this.modalCtrl.dismiss();
      return 'Đóng bao thành công';
    } catch (error) {
      console.log(error);
      throw new Error('Có lỗi xảy ra khi đóng bao');
    }
  }

  getParameterVnWarehouse() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_VN_WAREHOUSE.NODE).snapshotChanges().pipe(
      map(actions => {
        const rs = {};
        actions.forEach(action => {
          rs[action.payload.doc.id] = action.payload.doc.data();
        });
        return rs;
      }),
    );
  }

  getTransportDetail(transportUid: string) {
    return this.fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc<any>(transportUid).valueChanges();
  }

  getTransportDetailPromise(transportUid) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transportUid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }));

  }

  private _roundTransportWeight(transport) {
    const transportRealKg = Number(transport.transport_real_kg || 0);
    const transportExchangedKg = Number(transport.transport_exchanged_kg || 0);
    let transport_weight = 0;
    if ((transportExchangedKg - transportRealKg) < 3) {
      transport_weight = transportRealKg;
    } else {
      transport_weight = transportRealKg >= transportExchangedKg ? transportRealKg : transportExchangedKg;
    }
    transport_weight = new Decimal(transport_weight).roundUp(2).toNumber();
    return transport_weight;
  }

}
