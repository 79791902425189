import { FIREBASE_STRUCT } from './../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-history-customer',
  templateUrl: './payment-history-customer.component.html',
  styleUrls: ['./payment-history-customer.component.css']
})
export class PaymentHistoryCustomerComponent implements OnInit, OnDestroy {

  uid;
  historys: any[] = [];
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getPaymentHistory(this.uid).subscribe(historys => {
      this.historys = historys;
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getPaymentHistory(customer_id) {
    return this.fs.collection(`${FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE}`, ref => {
      return ref.where('customer_id', '==', customer_id).orderBy('payment_date_created', 'desc');
    }).snapshotChanges().pipe(
      map(docs => docs.map(doc => ({ uid: doc.payload.doc.id, ...doc.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

}
