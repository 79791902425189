import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { BILL_STATUS, CustomConfirm, CustomLoading, FIREBASE_STRUCT, ORDER_STATUS, SHOP_REFUND_STATUS, validObject } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../services/roles/roles.service';
import { Decimal } from '../../app.models';

@Component({
  selector: 'app-bill-add',
  templateUrl: './bill-add.component.html',
  styleUrls: ['./bill-add.component.css']
})
export class BillAddComponent implements OnInit, OnDestroy {
  order_accounts: any[] = [];
  temp_bill: any[] = [];
  order: any;
  uid: any;
  unsubscribe$ = new Subject();
  user_login: any;
  list_roles: any[] = [];
  user_role: any = {};
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public loadingCtrl: LoadingController,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;

    const temp_bill = {
      uid: null,
      bill_code: null,
      account_order: null,
      order_money: null,
      ship_money: null,
      web_key: null,
      web_text: null,
      note: null,
      is_auto_get_transports_code: true,
      branch_uid: null
    };


    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });
    this.getOrderAccounts().subscribe(acs => {
      this.order_accounts = acs;
    });

    this.getOrder(this.uid).subscribe(async (order) => {
      this.order = order;
      this.user_role = await rolesService.getRoles(this.order.order_status_key);
      if (this.user_role.MHD_NTTCTMHD) { this.temp_bill = [temp_bill]; }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  conpareFn(val1, val2) {
    if (!val1 || !val2) { return false; }
    return val1.uid === val2.uid;
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
    );
  }

  getOrderAccounts() {
    return this.fs.collection<any[]>(FIREBASE_STRUCT.ACCOUNT_BILL.NODE, query => query.where('is_active', '==', true)).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getOrder(orderUid: string) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(orderUid).snapshotChanges().pipe(
      switchMap(sn => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('bills').snapshotChanges().pipe(
          switchMap(sns => {
            const obs = sns.map(_sn => this.fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc<any>(_sn.payload.doc.id).snapshotChanges().pipe(
              map(__sn => ({ uid: __sn.payload.id, ...__sn.payload.data(), account_order: { uid: __sn.payload.data().account_order_uid, name: __sn.payload.data().account_order_name, branch_uid: __sn.payload.data().branch_uid } }))
            ));
            if (obs.length) { return combineLatest(obs); } else { return of([]); }
          }),
          map(bills => ({ uid: sn.payload.id, ...sn.payload.data(), bills }))
        );
      }),
    );
  }

  getOrderShopRefund(orderUid: string) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('shop_refunds').get().then(snapshot => {
      return snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id }));
    });
  }

  async validateBill() {
    for (let i = 0; i < this.temp_bill.length; i++) {
      const bill_code_1 = this.temp_bill[i].bill_code;
      for (let j = i + 1; j < this.temp_bill.length; j++) {
        const bill_code_2 = this.temp_bill[j].bill_code;
        if (bill_code_1 === bill_code_2) {
          return 'Tồn tại 2 mã hóa đơn trùng nhau. Vui lòng nhập lại';
        }
      }
    }

    const bills: any[] = [...this.order.bills];

    for (const bill_1 of this.temp_bill) {
      const bill_code_1 = bill_1.bill_code;
      for (const bill_2 of bills) {
        const bill_code_2 = bill_2.bill_code;
        if (bill_code_1 === bill_code_2) {
          return 'Tồn tại 2 mã hóa đơn trùng nhau. Vui lòng nhập lại';
        }
      }
    }

    for (let i = 0; i < bills.length; i++) {
      const bill_code_1 = bills[i].bill_code;
      for (let j = i + 1; j < bills.length; j++) {
        const bill_code_2 = bills[j].bill_code;
        if (bill_code_1 === bill_code_2) {
          return 'Tồn tại 2 mã hóa đơn trùng nhau. Vui lòng nhập lại';
        }
      }
    }

    for (const _bill of this.temp_bill) {
      const _existBill = await this._existBill(_bill.bill_code);
      if (_existBill.exist) {
        return 'Mã hóa đơn đã tồn tại trong hệ thống. Vui lòng kiểm tra lại.';
      }
    }

    let total_order_money = 0;
    let total_ship_money = 0;
    this.temp_bill.forEach(bill => {
      validObject(bill);
      total_order_money = new Decimal(total_order_money).add(bill.order_money).toNumber();
      total_ship_money = new Decimal(total_ship_money).add(bill.ship_money).toNumber();
    });
    this.order.bills.forEach(bill => {
      validObject(bill);
      total_order_money = new Decimal(total_order_money).add(bill.order_money).toNumber();
      total_ship_money = new Decimal(total_ship_money).add(bill.ship_money).toNumber();
    });

    if (total_order_money > this.order.order_price_cny) {
      return 'Tiền hàng TT <= Tiền hàng';
    }

    if (total_ship_money > this.order.order_ship_price_cny) {
      return 'Tiền ship TT <= Tiền ship';
    }

    return '';
  }

  @CustomLoading()
  async onAddBillCode() {
    for (let index = 0; index < this.temp_bill.length; index++) {
      const bill = this.temp_bill[index];

      if (!bill.bill_code || !bill.account_order || !(Number(bill.order_money) >= 0) || !(Number(bill.ship_money) >= 0)) {
        this.temp_bill.splice(index);
        index--;
      }
    }

    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      const msg = await this.validateBill();
      if (msg !== '') {
        throw msg;
      }

      const current = await this.commonService.getServerTime();
      let total_order_money = 0;
      let total_ship_money = 0;
      const bills: any[] = [...this.order.bills];
      const order_date_book_item = this.order.order_date_book_item;
      const sellerId = await this._getSellerId(this.uid);
      const currentDateTime = await this.commonService.getServerTime();

      for (const bill of this.temp_bill) {
        validObject(bill);
        bill.order_money = !bill.order_money ? 0 : Number(bill.order_money);
        total_order_money = new Decimal(total_order_money).add(bill.order_money).toNumber();
        bill.ship_money = !bill.ship_money ? 0 : Number(bill.ship_money);
        total_ship_money = new Decimal(total_ship_money).add(bill.ship_money).toNumber();
        bill.bill_code = bill.bill_code.trim();

        const total_money = new Decimal(bill.order_money).add(bill.ship_money).toNumber();
        const account_order = { ...bill.account_order };
        delete bill.account_order;
        const bill_uid = fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc().id;

        batch.set(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(bill_uid), {
          ...bill,
          order_uid: this.order.uid,
          account_order_uid: account_order.uid,
          account_order_name: account_order.name,
          order_code: this.order.order_code,
          order_code_2: this.order.order_code_2,
          order_product_link: this.order.order_product_link || '',
          order_had_transport: this.order.order_had_transport || false,
          total_money,
          bill_status: BILL_STATUS.CHUAXULY.VALUE,
          date_created: current,
          order_website: this.order.order_website || 'Others',
          order_website_key: this.getOrderWebsiteKey(this.order.order_website),
          people_created: this.auth.auth.currentUser.displayName,
          people_created_uid: this.auth.auth.currentUser.uid,
          order_status_key: this.order.order_status_key,
          order_status_text: this.order.order_status_text,
          order_date_deposit: this.order.order_date_deposit,
          seller_id: sellerId,
          customer_care_emp_uid: this.order.customer_care_emp_uid,
          customer_care_emp_full_name: this.order.customer_care_emp_full_name,
          in_charge_emp_uid: this.order.in_charge_emp_uid,
          in_charge_emp_full_name: this.order.in_charge_emp_full_name,
          order_emp_uid: this.order.order_emp_uid,
          order_emp_full_name: this.order.order_emp_full_name,
          owned_uid_admin_users: this.order.owned_uid_admin_users,
          is_order_commission: this.order.is_order_commission || false,
          branch_uid: this.order.branch_uid
        }, { merge: true });

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection('bills').doc(bill_uid), {
          uid: bill_uid
        }, { merge: true });

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
          element: 'Thêm mới mã hóa đơn',
          date: currentDateTime,
          user: this.user_login.full_name,
          old_value: '',
          new_value: bill.bill_code
        }, { merge: true });
      }

      for (const bill of bills) {
        total_order_money = new Decimal(total_order_money).add(bill.order_money).toNumber();
        total_ship_money = new Decimal(total_ship_money).add(bill.ship_money).toNumber();
        const total_money = new Decimal(bill.order_money).add(bill.ship_money).toNumber();
        const account_order = { ...bill.account_order };
        bill.bill_code = bill.bill_code.trim();

        delete bill.account_order;
        batch.update(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(bill.uid), {
          ...bill,
          account_order_uid: account_order.uid,
          account_order_name: account_order.name,
          total_money,
          people_updated: this.auth.auth.currentUser.displayName,
          date_updated: current
        });

        fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(bill.uid).get().then(doc => {
          batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
            element: 'Cập nhật mã hóa đơn',
            date: currentDateTime,
            user: this.user_login.full_name,
            old_value: doc.get('bill_code'),
            new_value: bill.bill_code
          }, { merge: true });
        });
      }

      const order_shop_refund: any[] = await this.getOrderShopRefund(this.uid);
      let total_refund_amount = 0;
      let total_ship_amount = 0;
      if (order_shop_refund && order_shop_refund.length) {
        order_shop_refund.forEach(shopRefund => {
          if (shopRefund.status_key === SHOP_REFUND_STATUS.DAXULY.KEY) {
            total_refund_amount = new Decimal(total_refund_amount).add(shopRefund.refund_amount).toNumber();
            total_ship_amount = new Decimal(total_ship_amount).add(shopRefund.ship_amount).toNumber();
          }
        });
      }

      total_order_money = new Decimal(total_order_money).sub(total_refund_amount).toNumber();
      total_ship_money = new Decimal(total_ship_money).sub(total_ship_amount).toNumber();

      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid), {
        order_status_key: this.order.order_status_key >= ORDER_STATUS.DATCOC.KEY && this.order.order_status_key < ORDER_STATUS.DATHANG.KEY ? ORDER_STATUS.DATHANG.KEY : this.order.order_status_key,
        order_status_text: this.order.order_status_key >= ORDER_STATUS.DATCOC.KEY && this.order.order_status_key < ORDER_STATUS.DATHANG.KEY ? ORDER_STATUS.DATHANG.VALUE : this.order.order_status_text,
        order_price_real_cny: total_order_money,
        order_ship_real_cny: total_ship_money,
        order_total_real_cny: new Decimal(total_order_money).add(total_ship_money).toNumber(),
        order_date_book_item: order_date_book_item ? order_date_book_item : current
      });

      await batch.commit();

      this.modalCtrl.dismiss();
      return 'Cập nhật mã hóa đơn thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  checkBills() {
    let is_validated = true;
    this.temp_bill.forEach(bill => {
      if (bill.bill_code || bill.account_order || Number(bill.order_money) || bill.ship_money) {
        if (!bill.bill_code || !bill.account_order || !(Number(bill.order_money) >= 0) || !(Number(bill.ship_money) >= 0)) {
          is_validated = false;
        }
      }
    });
    return is_validated;
  }

  onAddBill() {
    const temp_bill = {
      uid: null,
      bill_code: null,
      account_order: null,
      order_money: null,
      ship_money: null,
      web_key: null,
      web_text: null,
      note: null,
      is_auto_get_transports_code: true
    };
    this.temp_bill.push(temp_bill);
  }

  @CustomConfirm('Bạn muốn xóa hóa đơn này?')
  onDeleteBill(index) {
    this.temp_bill.splice(index, 1);
    return 'Xóa đơn thành công';
  }

  @CustomConfirm('Bạn muốn xóa hóa đơn này?')
  async onDeleteBillFromOrder(bill) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      const billList: any[] = await this.fs.firestore.collection(FIREBASE_STRUCT.BILLS.NODE)
        .where('order_code', '==', bill.order_code)
        .where('branch_uid', '==', bill.branch_uid)
        .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));

      const _bills = [];
      let total_order_money = 0;
      let total_ship_money = 0;
      billList.forEach(b => {
        if (b.uid !== bill.uid) {
          total_order_money = new Decimal(total_order_money).add(b.order_money).toNumber();
          total_ship_money = new Decimal(total_ship_money).add(b.ship_money).toNumber();
          _bills.push(b);
        }
      });

      let order_status_key = this.order.order_status_key;
      let order_status_text = this.order.order_status_text;
      let order_emp_uid = bill.people_created_uid || '';
      let order_emp_full_name = bill.people_created;
      let order_date_book_item = bill.date_created;
      if (!_bills.length) {
        order_status_key = ORDER_STATUS.DATCOC.KEY;
        order_status_text = ORDER_STATUS.DATCOC.VALUE;
        order_emp_uid = firebase.firestore.FieldValue.delete();
        order_emp_full_name = firebase.firestore.FieldValue.delete();
        order_date_book_item = firebase.firestore.FieldValue.delete();
      } else {
        order_emp_uid = _bills[0].people_created || '';
        order_emp_full_name = _bills[0].people_created;
        order_date_book_item = _bills[0].date_created;
      }

      batch.delete(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(bill.uid));
      batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(bill.order_uid).collection('bills').doc(bill.uid));
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(bill.order_uid), {
        order_status_key,
        order_status_text,
        order_price_real_cny: total_order_money,
        order_ship_real_cny: total_ship_money,
        order_total_real_cny: new Decimal(total_order_money).add(total_ship_money).toNumber(),
        order_date_book_item
      });

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Xóa mã hóa đơn',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: '',
        new_value: bill.bill_code
      }, { merge: true });

      await batch.commit();
      return 'Xóa hóa đơn thành công';
    } catch (error) {
      throw new Error('Có lỗi khi xử lý xóa đơn');
    }
  }

  private async _existBill(bill_code) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.BILLS.NODE)
      .where('bill_code', '==', bill_code)
      .where('branch_uid', '==', bill_code)
      .get().then(snapshot => ({ exist: snapshot.size > 0 }));
  }

  getOrderWebsiteKey(order_website) {
    if (order_website === 'taobao') {
      return 1;
    } else if (order_website === 'tmall') {
      return 1;
    } else if (order_website === '1688') {
      return 2;
    }
    return 9;
  }

  public onCheckedAutoGetTransportsCode(bill) {
    bill.is_auto_get_transports_code = !bill.is_auto_get_transports_code;
  }

  private async _getSellerId(orderUid) {
    const fs = firebase.firestore();

    const products = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('products').get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push({ uid: doc.id, ...doc.data() });
      });
      return rs;
    });

    for (const product of products) {
      if (product.seller_id) { return product.seller_id; }
      if (product.shop_uid) { return product.shop_uid; }
    }

    return '';
  }
}
