import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-order-param-transport-slow',
  templateUrl: './order-param-transport-slow.component.html',
  styleUrls: ['./order-param-transport-slow.component.css']
})
export class OrderParamTransportSlowComponent implements OnInit, OnDestroy {
  uid: any;
  branchUid: any;

  param_transport: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
    if (this.uid !== '') {
      this.db.doc(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_SLOW.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.param_transport = obj;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();

      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_SLOW.NODE}/${this.uid}`).update({
          ...this.param_transport,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        await this.db.collection(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_SLOW.NODE}`).add({
          ...this.param_transport,
          branchUid: this.branchUid,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

}
