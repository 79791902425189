import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { firestore } from 'firebase';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomLoading, FIREBASE_STRUCT } from 'src/app/app.constant';
import { BRANCH_CONFIG } from 'src/app/constants/constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-lucky-wheel-promotion',
  templateUrl: './lucky-wheel-promotion.component.html',
  styleUrls: ['./lucky-wheel-promotion.component.scss'],
})
export class LuckyWheelPromotionComponent implements OnInit, OnDestroy {
  temp_items: any[] = [];
  param_promotion: any;
  uid: any;
  branch_uid: any;
  startDate: any;
  endDate: any;
  delete_items: any[] = [];

  temp_item = {
    uid: null,
    name: null,
    rate_draw: 0,
    is_active: true
  };

  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branch_uid = this.navParams.data.modal.componentProps.branch_uid;

    const temp_item = {
      uid: null,
      name: null,
      rate_draw: 0,
      is_active: true,
      branch_uid: this.branch_uid
    };

    this.temp_items.push(temp_item);
  }

  ngOnInit() {
    if (this.uid) {
      this.getParamPromotion(this.uid).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((param) => {
        this.param_promotion = param;
        if (param.promotions.length > 0) {
          this.temp_items = param.promotions;
        }
      });
    } else {
      this.param_promotion = {
        name: null,
        promotions: [this.temp_item],
        is_active: true,
        branch_uid: this.branch_uid
      };
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onAddItem() {
    const tempItem = {
      uid: null,
      name: null,
      rate_draw: 0,
      is_active: true,
    };
    this.temp_items.push(tempItem);
  }

  onDeleteItem(index) {
    this.delete_items.push(this.temp_items[index]);
    this.temp_items.splice(index, 1);
  }

  onCheckPromotionActive(promotion) {
    promotion.is_active = !promotion.is_active;
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const now = await this.commonService.getServerTime();
      const _promotion = { ...this.param_promotion };
      const startDate = new Date(_promotion.start_date_year, _promotion.start_date_month - 1, _promotion.start_date_day, _promotion.start_date_hour, _promotion.start_date_minute, _promotion.start_date_second);
      const endDate = new Date(_promotion.end_date_year, _promotion.end_date_month - 1, _promotion.end_date_day, _promotion.end_date_hour, _promotion.end_date_minute, _promotion.end_date_second);

      const promotions = this.temp_items.filter(item => item.name !== null && item.name !== '');

      const deleteItems = this.delete_items;

      const fs = firestore();
      const batch = fs.batch();

      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}/${this.uid}`).update({
          ...this.param_promotion,
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          update_user: this.auth.auth.currentUser.displayName,
          update_date: now
        });

        deleteItems.forEach(item => {
          if (item.uid !== null) {
            batch.update(fs.collection(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}`).doc(this.uid).collection('promotions').doc(item.uid), {
              is_deleted: true,
            });
          }
        })

        promotions.forEach(promotion => {
          const promotionId = promotion.uid === null ? fs.collection(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}`).doc(this.uid).collection('promotions').doc().id : promotion.uid;
          batch.set(fs.collection(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}`).doc(this.uid).collection('promotions').doc(promotionId), {
            ...promotion,
            uid: promotionId,
            is_deleted: false,
            branch_uid: this.branch_uid,
            create_user: this.auth.auth.currentUser.displayName,
            create_date: now
          });
        });
      } else {
        const newParamCollection = fs.collection(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}`);
        const uid = newParamCollection.doc().id;
        const newParamRef = newParamCollection.doc(uid);
        const promotion_program = newParamRef.collection('promotions');

        batch.set(newParamRef, {
          ...this.param_promotion,
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          branch_uid: this.branch_uid,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: now
        });

        promotions.forEach(promotion => {
          const promotionId = promotion.uid === null ? fs.collection(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}`).doc(uid).collection('promotions').doc().id : promotion.uid;
          batch.set(promotion_program.doc(promotionId), {
            ...promotion,
            uid: promotionId,
            is_deleted: false,
            branch_uid: this.branch_uid,
            create_user: this.auth.auth.currentUser.displayName,
            create_date: now
          });
        });
      }

      await batch.commit();
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      console.log(error)
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  private getParamPromotion(uid: string) {
    return this.db.doc<any>(`${FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE}/${uid}`).valueChanges().pipe(
      switchMap(param => {
        return this.db.collection(FIREBASE_STRUCT.PARAMETER_LUCKY_WHEEL_PROMOTION.NODE).doc(uid).collection('promotions', query => {
          return query.where('is_deleted', '==', false).orderBy('create_date', 'desc')
        }).valueChanges({ idField: "uid" }).pipe(
          map(promotions => ({ uid, ...param, promotions: promotions })),
        );
      })
    );
  }
}
