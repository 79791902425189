import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonUtilsService } from '../../services/utils/common-utils.service';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-branch-add',
  templateUrl: './branch-add.component.html',
  styleUrls: ['./branch-add.component.css']
})
export class BranchAddComponent implements OnInit, OnDestroy {
  branch: any = {};
  uid = '';
  uns = new Subject();
  constructor(
    private modalCtrl: ModalController,
    private commonService: CommonUtilsService,
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid && this.uid !== '') {
      this.getBranch(this.uid).pipe(
        takeUntil(this.uns)
      ).subscribe(branch => this.branch = branch);
    }
  }

  ngOnDestroy() {
    this.uns.next();
    this.uns.complete();
  }

  onDismiss(data?: any) {
    return this.modalCtrl.dismiss(data);
  }

  getBranch(branchUid: string) {
    return this.db.collection(FIREBASE_STRUCT.BRANCHS.NODE).doc<any>(branchUid).valueChanges();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();
      if (this.uid !== '') {
        await this.db.collection(FIREBASE_STRUCT.BRANCHS.NODE).doc(this.uid).update({
          ...this.branch,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        await this.db.collection(FIREBASE_STRUCT.BRANCHS.NODE).add({
          ...this.branch,
          create_user: this.auth.auth.currentUser.displayName,
          update_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }
      this.modalCtrl.dismiss();
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

}
