import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Decimal } from '../../../app.models';
import { AngularFireAuth } from '@angular/fire/auth';
import { CalculateFeeService } from '../../../services/calculate-fee/calculate-fee.service';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../../services/roles/roles.service';

@Component({
  selector: 'app-consignment-extra-fee',
  templateUrl: './consignment-extra-fee.component.html',
  styleUrls: ['./consignment-extra-fee.component.css']
})
export class ConsignmentExtraFeeComponent implements OnInit, OnDestroy {
  temps_extra = [];
  unsubscribe$ = new Subject();
  uid: any;
  extra_fees: any[];
  consignment_order: any = {};
  roles: any = {};
  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public translateService: TranslateService,
    public rolesService: RolesService,
    public calcFeeService: CalculateFeeService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.getExtraFee(this.uid).subscribe(extra => {
      this.extra_fees = extra;
    });

    this.getConsignmentOrder(this.uid).subscribe(async (consignmentOrder) => {
      this.consignment_order = consignmentOrder;
      this.roles = await rolesService.getActionRolesConsignmentOrder(this.consignment_order);
    });

    this.temps_extra = [];
    const temps = {
      name: null,
      value: null,
      note: null,
      created_date: 'Tự động',
      created_user: 'Tự động',
      type: 1
    };
    this.temps_extra.push(temps);
  }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getExtraFee(uid: string) {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(uid).collection(FIREBASE_STRUCT.CONSIGNMENT_EXTRA_FEE.NODE).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getExtraFeePromise(uid: string) {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE)
      .doc(uid)
      .collection(FIREBASE_STRUCT.CONSIGNMENT_EXTRA_FEE.NODE)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  async onAddExtra(temp) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const consignmentOrder: any = { ...this.consignment_order };
    try {

      if (!temp.name || !temp.value) {
        throw new Error('Chưa nhập đủ dữ liệu cần thiết. Vui lòng kiểm tra lại.');
      }

      if (temp.value <= 0) {
        throw new Error('Giá trị phát sinh không hợp lệ. Vui lòng kiểm tra lại.');
      }

      temp.created_user = this.auth.auth.currentUser.displayName;
      temp.created_date = await this.commonService.getServerTime();
      let extraFeeManual = consignmentOrder.extra_fee_manual || 0;
      let extraFee = consignmentOrder.extra_fee || 0;
      let totalPrice = consignmentOrder.total_price || 0;
      let totalFee = consignmentOrder.total_fee || 0;
      let lackOfPaid = consignmentOrder.lack_of_paid || 0;
      const paid = consignmentOrder.paid || 0;
      const price = consignmentOrder.price || 0;
      const transportFee = consignmentOrder.transport_fee || 0;
      const refundFee = consignmentOrder.refund_fee || 0;

      extraFeeManual = new Decimal(extraFeeManual).add(temp.value).toNumber();
      extraFee = new Decimal(extraFee).add(temp.value).toNumber();
      totalPrice = new Decimal(price).add(transportFee).add(extraFee).toNumber();
      totalFee = new Decimal(transportFee).add(extraFee).toNumber();
      lackOfPaid = new Decimal(totalFee).sub(paid).add(refundFee).toNumber();

      batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection('extra_fees').doc(), temp);
      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid), {
        extra_fee_manual: extraFeeManual,
        extra_fee: extraFee,
        total_fee: totalFee,
        total_price: totalPrice,
        lack_of_paid: lackOfPaid
      });

      const user_login: any = await this.getUserLogin(this.auth.auth.currentUser.uid);
      batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Thêm phí phát sinh',
        date: await this.commonService.getServerTime(),
        user: user_login.full_name,
        old_value: '',
        new_value: temp.value
      }, { merge: true });

      await batch.commit();

      this.temps_extra = [];
      const temps = {
        name: null,
        value: null,
        note: null,
        created_user: null
      };
      this.temps_extra.push(temps);
    } catch (error) {
      throw error;
    }

  }

  @CustomConfirm('Xác nhận chỉnh sửa phí phát sinh này?')
  async onUpdateExtraFee(temp, i) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const consignmentOrder: any = { ...this.consignment_order };
    try {
      let extraFeeAuto = 0;
      let extraFeeManual = 0;
      let extraFee = 0;
      temp.updated_user = this.auth.auth.currentUser.displayName;
      temp.updated_date = await this.commonService.getServerTime();
      this.extra_fees.forEach(element => {
        if (element.type === 0) {
          extraFeeAuto = new Decimal(extraFeeAuto).add(element.value).toNumber();
        } else {
          extraFeeManual = new Decimal(extraFeeManual).add(element.value).toNumber();
        }
      });
      extraFee = new Decimal(extraFeeAuto).add(extraFeeManual).toNumber();
      const transportFee = consignmentOrder.transport_fee || 0;
      const refundFee = consignmentOrder.refund_fee || 0;
      const paid = consignmentOrder.paid || 0;
      const price = consignmentOrder.price || 0;

      const totalFee = new Decimal(transportFee).add(extraFee).toNumber();
      const totalPrice = new Decimal(price).add(totalFee).toNumber();
      const lackOfPaid = new Decimal(totalFee).sub(paid).add(refundFee).toNumber();

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid).collection('extra_fees').doc(temp.uid), temp);

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid), {
        extra_fee_auto: extraFeeAuto,
        extra_fee_manual: extraFeeManual,
        extra_fee: extraFee,
        total_fee: totalFee,
        total_price: totalPrice,
        lack_of_paid: lackOfPaid
      });

      const user_login: any = await this.getUserLogin(this.auth.auth.currentUser.uid);
      const extraFees: any[] = await this.getExtraFeePromise(this.uid);
      batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Cập nhật phí phát sinh',
        date: await this.commonService.getServerTime(),
        user: user_login.full_name,
        old_value: extraFees[i].value,
        new_value: temp.value
      }, { merge: true });

      await batch.commit();

      return 'Chỉnh sửa phí phát sinh thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi thực hiện chỉnh sửa');
    }

  }

  @CustomConfirm('Xác nhận xóa phí phát sinh này?')
  async onDelete(temp) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const consignmentOrder: any = { ...this.consignment_order };
    try {
      let extraFeeAuto = consignmentOrder.extra_fee_auto || 0;
      let extraFeeManual = consignmentOrder.extra_fee_manual || 0;
      let extraFee = consignmentOrder.extra_fee || 0;
      let totalPrice = consignmentOrder.total_price || 0;
      let totalFee = consignmentOrder.total_fee || 0;
      let lackOfPaid = consignmentOrder.lack_of_paid || 0;

      if (temp.type === 1) {
        extraFeeManual = new Decimal(extraFeeManual).sub(temp.value).toNumber();
      } else {
        extraFeeAuto = new Decimal(extraFeeAuto).sub(temp.value).toNumber();
      }
      extraFee = new Decimal(extraFeeManual).add(extraFeeAuto).toNumber();
      totalPrice = new Decimal(totalPrice).sub(temp.value).toNumber();
      totalFee = new Decimal(totalFee).sub(temp.value).toNumber();
      lackOfPaid = new Decimal(lackOfPaid).sub(temp.value).toNumber();

      batch.delete(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid).collection('extra_fees').doc(temp.uid));
      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid), {
        extra_fee_auto: extraFeeAuto,
        extra_fee_manual: extraFeeManual,
        extra_fee: extraFee,
        total_fee: totalFee,
        total_price: totalPrice,
        lack_of_paid: lackOfPaid
      });

      await batch.commit();

      return 'Xóa phí phát sinh thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi thực hiện chỉnh sửa');
    }

  }

  getConsignmentOrder(uid: string) {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  getUserLogin(uid) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(uid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }));
  }

}
