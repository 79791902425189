import { OrderParamPhoneReceiptCommodityComponent } from './order-param-phone-receipt-commodity/order-param-phone-receipt-commodity.component';
import { OrderParamTransportSgWarehouseComponent } from './order-param-transport-sg-warehouse/order-param-transport-sg-warehouse.component';
import { ConsignmentOrderRequestExportEditComponent } from './business/consignment-order-request-export-edit/consignment-order-request-export-edit.component';
import { ConsignmentOrderRequestExportAddComponent } from './business/consignment-order-request-export-add/consignment-order-request-export-add.component';
import { StatisticPaymentEditComponent } from './accounting/statistic-payment-edit/statistic-payment-edit.component';
import { EWalletAccountNegativeBalanceComponent } from './e-wallet-account-negative-balance/e-wallet-account-negative-balance.component';
import { ConsignmentOrderActionAddComponent } from './consignment-order-action-add/consignment-order-action-add.component';
import { OrderActionAddComponent } from './order-action-add/order-action-add.component';
import { PaymentHistoryCustomerComponent } from './payment-history-customer/payment-history-customer.component';
import { OrderDeliverComponent } from './order-deliver/order-deliver.component';
import { OrderDetailPaymentHistoryComponent } from './order-detail-payment-history/order-detail-payment-history.component';
import { OrderDetailActionHistoryComponent } from './order-detail-action-history/order-detail-action-history.component';
import { ShopRefundComponent } from './shop-refund/shop-refund.component';
import { OrderLinkActionComponent } from './order-link-action/order-link-action.component';
import { OrderLinkComponent } from './order-link/order-link.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TransportCodeAddComponent } from './warehouse/transport-code-add/transport-code-add.component';
import { BaggingComponent } from './warehouse/bagging/bagging.component';
import { UserAddComponent } from './user-add/user-add.component';
import { RoleAddComponent } from './role-add/role-add.component';
import { BillAddComponent } from './bill-add/bill-add.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeliverBagComponent } from './warehouse/deliver-bag/deliver-bag.component';
import { OrderParamDefaultComponent } from './order-param-default/order-param-default.component';
import { OrderParamTransportFastComponent } from './order-param-transport-fast/order-param-transport-fast.component';
import { OrderParamTransportSlowComponent } from './order-param-transport-slow/order-param-transport-slow.component';
import { OrderParamServiceFeeComponent } from './order-param-service-fee/order-param-service-fee.component';
import { OrderParamGoodsCheckFeeComponent } from './order-param-goods-check-fee/order-param-goods-check-fee.component';
import { DetailTransportCodeAddComponent } from './detail-transport-code-add/detail-transport-code-add.component';
import { ConsignmentOrderParamDefaultComponent } from './consignment-order-param-default/consignment-order-param-default.component';
import { ConsignmentParamTransportSgWarehouseComponent } from './consignment-param-transport-sg-warehouse/consignment-param-transport-sg-warehouse.component';
import { ConsignmentOrderParamTransportComponent } from './consignment-order-param-transport/consignment-order-param-transport.component';
import { ConsignmentOrderParamInsurranceComponent } from './consignment-order-param-insurrance/consignment-order-param-insurrance.component';
import { WithdrawMoneyComponent } from './accounting/withdraw-money/withdraw-money.component';
import { RechargeMoneyComponent } from './accounting/recharge-money/recharge-money.component';
import { LocationPaymentTranferComponent } from './location-payment-tranfer/location-payment-tranfer.component';
import { LocationPaymentCashComponent } from './location-payment-cash/location-payment-cash.component';
import { AccountRefundComponent } from './account-refund/account-refund.component';
import { AccountBillComponent } from './account-bill/account-bill.component';
import { PipesModule } from '../pipes/pipes';
import { ConsignmentExtraFeeComponent } from './business/consignment-extra-fee/consignment-extra-fee.component';
import { PromotionComponent } from './promotion/promotion.component';
import { OrderExtraFeeComponent } from './order-extra-fee/order-extra-fee.component';
import { OrderRefundComponent } from './order-refund/order-refund.component';
import { DirectiveslsModule } from '../directives/directives';
import { OrderSpecialParameterComponent } from './order-special-parameter/order-special-parameter.component';
import { CustomerInfomationComponent } from './business/customer-infomation/customer-infomation.component';
import { CustomerMngEditComponent } from './customer-mng-edit/customer-mng-edit.component';
import { QuickActionComponent } from './quick-action/quick-action.component';
import { ActionRoleOrderComponent } from './action-role-order/action-role-order/action-role-order.component';
import { ActionRoleConsignmentComponent } from './action-role-consignment/action-role-consignment/action-role-consignment.component';
import { ActionRoleMenuComponent } from './action-role-menu/action-role-menu/action-role-menu.component';
import { ActionRoleFunctionComponent } from './action-role-function/action-role-function/action-role-function.component';
import { ConsignmentSpecialParammeterComponent } from './business/consignment-special-parammeter/consignment-special-parammeter.component';
import { OrderRequestExportShipAddComponent } from './order-request-export-ship-add/order-request-export-ship-add.component';
import { OrderRequestExportCPNAddComponent } from './order-request-export-cpn-add/order-request-export-cpn-add.component';
import { ConsignmentLinkComponent } from './business/consignment-link/consignment-link.component';
import { ConsignmentRefundComponent } from './business/consignment-refund/consignment-refund.component';
import { DeliveryDetailComponent } from './business/delivery-detail/delivery-detail.component';
import { VipSpecialAddComponent } from './vip-special-add/vip-special-add.component';
import { VipNormalAddComponent } from './vip-normal-add/vip-normal-add.component';
import { ParameterVnWarehouseComponent } from './parameter-vn-warehouse/parameter-vn-warehouse.component';
import { ConsignmentDetailActionHistoryComponent } from './business/consignment-detail-action-history/consignment-detail-action-history.component';
import { ConsignmentDetailPaymentHistoryComponent } from './business/consignment-detail-payment-history/consignment-detail-payment-history.component';
import { EditBagComponent } from './edit-bag/edit-bag.component';
import { AddSlowTransferComponent } from './add-slow-transfer/add-slow-transfer.component';
import { TransportCodeEditComponent } from './warehouse/transport-code-edit/transport-code-edit.component';
import { OrderRequestExportShipEditComponent } from './order-request-export-ship-edit/order-request-export-ship-edit.component';
import { OrderRequestExportCPNEditComponent } from './order-request-export-cpn-edit/order-request-export-cpn-edit.component';
import { ParameterLevelEmployeeComponent } from './parameter-level-employee/parameter-level-employee.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { ConsignmentVipSpecialComponent } from './consignment-vip-special/consignment-vip-special.component';
import { AlertComponent } from './alert/alert.component';
import { CreateNewPageComponent } from './content-manager/create-new-page/create-new-page.component';
import { CreateNewPostComponent } from './content-manager/create-new-post/create-new-post.component';
import { FragmentsModule } from '../fragments/fragments.module';
import { CreateNewGuideComponent } from './content-manager/create-new-guide/create-new-guide.component';
import { SmsRechargeDetailComponent } from './accounting/sms-recharge-detail/sms-recharge-detail.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CreateByImportExcelComponent } from './warehouse/create-by-import-excel/create-by-import-excel.component';
import { ParameterConsignmentOrderTransportFeeAddOrEditComponent } from './parameter-consignment-order-transport-fee-add-or-edit/parameter-consignment-order-transport-fee-add-or-edit.component';
import { ParameterConsignmentOrderSgWarehouseAddOrEditComponent } from './parameter-consignment-order-sg-warehouse-add-or-edit/parameter-consignment-order-sg-warehouse-add-or-edit.component';
import { TaskAddOrUpdateComponent } from './task/task-add/task-add-or-update.component';
import { BranchAddComponent } from './branch-add/branch-add.component';
import { OrdersDeliveryComponent } from './orders-delivery/orders-delivery.component';
import { InternalTransportComponent } from './internal-transport/internal-transport.component';
import { BillsSyncCommissionComponent } from './bills-sync-commission/bills-sync-commission';
import { ParameterConsignmentOrderTransportConverterAddOrEditComponent } from './parameter-consignment-order-transport-converter-add-or-edit/parameter-consignment-order-transport-converter-add-or-edit.component';
import { BarcodeReaderInputTransportsAddOrEditComponent } from './warehouse/barcode-reader-input-transports-add-or-edit/barcode-reader-input-transports-add-or-edit.component';
import { SaleVerifyCustomerInfoComponent } from './sale-verify-customer-info/sale-verify-customer-info.component';
import { SaleOnlineParamDefaultAddOrEditComponent } from './sale-online-param-default-add-or-edit/sale-online-param-default-add-or-edit.component';
import { SaleOnlineParamLevelAddOrEditComponent } from './sale-online-param-level-add-or-edit/sale-online-param-level-add-or-edit.component';
import { SaleOnlineWithdrawalRequestComponent } from './sale-online-withdrawal-request/sale-online-withdrawal-request.component';
import { SalesOnlineRechargeMoneyComponent } from './accounting/sales-online-recharge-money/sales-online-recharge-money.component';
import { SaleVerifyIdentificationComponent } from './sale-verify-identification/sale-verify-identification.component';
import { OrderParamPercentDepositComponent } from './order-param-percent-deposit/order-param-percent-deposit.component';
import { PaymentRequestComponent } from './accounting/payment-request/payment-request.component';
import { OrderParamTransportFeeComponent } from './order-param-transport-fee/order-param-transport-fee.component';
import { ActionRoleUserComponent } from './action-role-user/action-role-user.component';
import { BillOrderEditComponent } from './bill-order-edit/bill-order-edit.component';
import { LuckyWheelPromotionComponent } from './lucky-wheel-promotion/lucky-wheel-promotion.component';

@NgModule({
    imports: [
        CommonModule,
        FragmentsModule,
        FormsModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        NgSelectModule,
        PipesModule,
        DirectiveslsModule,
        NgbModule,
        CKEditorModule
    ],
    declarations: [
        AlertComponent,
        TransportCodeAddComponent,
        BaggingComponent,
        BranchAddComponent,
        UserAddComponent,
        RoleAddComponent,
        BillAddComponent,
        DeliverBagComponent,
        OrderParamDefaultComponent,
        OrderParamTransportFastComponent,
        OrderParamTransportSlowComponent,
        OrderParamTransportSgWarehouseComponent,
        OrderParamServiceFeeComponent,
        OrderParamTransportFeeComponent,
        OrderParamPercentDepositComponent,
        OrderParamGoodsCheckFeeComponent,
        DetailTransportCodeAddComponent,
        ConsignmentOrderParamDefaultComponent,
        ConsignmentOrderParamTransportComponent,
        ConsignmentParamTransportSgWarehouseComponent,
        ConsignmentOrderParamInsurranceComponent,
        RechargeMoneyComponent,
        WithdrawMoneyComponent,
        PaymentRequestComponent,
        AccountBillComponent,
        BillOrderEditComponent,
        AccountRefundComponent,
        LocationPaymentCashComponent,
        LocationPaymentTranferComponent,
        LocationPaymentTranferComponent,
        ConsignmentExtraFeeComponent,
        PromotionComponent,
        OrderRefundComponent,
        OrderExtraFeeComponent,
        OrderSpecialParameterComponent,
        CustomerInfomationComponent,
        OrderLinkComponent,
        OrderLinkActionComponent,
        CustomerMngEditComponent,
        ActionRoleOrderComponent,
        ActionRoleConsignmentComponent,
        ActionRoleMenuComponent,
        ActionRoleUserComponent,
        ActionRoleFunctionComponent,
        QuickActionComponent,
        ShopRefundComponent,
        ConsignmentSpecialParammeterComponent,
        OrderRequestExportShipAddComponent,
        OrderRequestExportCPNAddComponent,
        ConsignmentLinkComponent,
        ConsignmentRefundComponent,
        DeliveryDetailComponent,
        VipNormalAddComponent,
        VipSpecialAddComponent,
        ParameterVnWarehouseComponent,
        OrderDetailPaymentHistoryComponent,
        OrderDetailActionHistoryComponent,
        ConsignmentDetailActionHistoryComponent,
        ConsignmentDetailPaymentHistoryComponent,
        EditBagComponent,
        AddSlowTransferComponent,
        OrderDeliverComponent,
        TransportCodeEditComponent,
        OrderRequestExportShipEditComponent,
        OrderRequestExportCPNEditComponent,
        PaymentHistoryCustomerComponent,
        OrderActionAddComponent,
        ConsignmentOrderActionAddComponent,
        EWalletAccountNegativeBalanceComponent,
        ParameterLevelEmployeeComponent,
        PromotionListComponent,
        StatisticPaymentEditComponent,
        ConsignmentVipSpecialComponent,
        ConsignmentOrderRequestExportAddComponent,
        ConsignmentOrderRequestExportEditComponent,
        CreateNewPageComponent,
        CreateNewPostComponent,
        CreateNewGuideComponent,
        OrderParamPhoneReceiptCommodityComponent,
        SmsRechargeDetailComponent,
        CreateByImportExcelComponent,
        ParameterConsignmentOrderTransportFeeAddOrEditComponent,
        ParameterConsignmentOrderTransportConverterAddOrEditComponent,
        ParameterConsignmentOrderSgWarehouseAddOrEditComponent,
        TaskAddOrUpdateComponent,
        BranchAddComponent,
        OrdersDeliveryComponent,
        InternalTransportComponent,
        BillsSyncCommissionComponent,
        BarcodeReaderInputTransportsAddOrEditComponent,
        SaleVerifyCustomerInfoComponent,
        SaleOnlineParamDefaultAddOrEditComponent,
        SaleOnlineParamLevelAddOrEditComponent,
        SaleOnlineWithdrawalRequestComponent,
        SalesOnlineRechargeMoneyComponent,
        SaleVerifyIdentificationComponent,
        LuckyWheelPromotionComponent
    ],
    entryComponents: [
        AlertComponent,
        TransportCodeAddComponent,
        BaggingComponent,
        BranchAddComponent,
        UserAddComponent,
        RoleAddComponent,
        BillAddComponent,
        DeliverBagComponent,
        OrderParamDefaultComponent,
        OrderParamTransportFastComponent,
        OrderParamTransportSlowComponent,
        OrderParamTransportSgWarehouseComponent,
        OrderParamServiceFeeComponent,
        OrderParamTransportFeeComponent,
        OrderParamPercentDepositComponent,
        OrderParamGoodsCheckFeeComponent,
        DetailTransportCodeAddComponent,
        ConsignmentOrderParamDefaultComponent,
        ConsignmentOrderParamTransportComponent,
        ConsignmentParamTransportSgWarehouseComponent,
        ConsignmentOrderParamInsurranceComponent,
        RechargeMoneyComponent,
        WithdrawMoneyComponent,
        PaymentRequestComponent,
        AccountBillComponent,
        BillOrderEditComponent,
        AccountRefundComponent,
        LocationPaymentCashComponent,
        LocationPaymentTranferComponent,
        LocationPaymentTranferComponent,
        ConsignmentExtraFeeComponent,
        PromotionComponent,
        OrderRefundComponent,
        OrderExtraFeeComponent,
        OrderSpecialParameterComponent,
        CustomerInfomationComponent,
        OrderLinkComponent,
        OrderLinkActionComponent,
        CustomerMngEditComponent,
        ActionRoleOrderComponent,
        ActionRoleConsignmentComponent,
        ActionRoleMenuComponent,
        ActionRoleUserComponent,
        ActionRoleFunctionComponent,
        QuickActionComponent,
        ShopRefundComponent,
        ConsignmentSpecialParammeterComponent,
        OrderRequestExportShipAddComponent,
        OrderRequestExportCPNAddComponent,
        ConsignmentLinkComponent,
        ConsignmentRefundComponent,
        DeliveryDetailComponent,
        VipNormalAddComponent,
        VipSpecialAddComponent,
        ParameterVnWarehouseComponent,
        OrderDetailPaymentHistoryComponent,
        OrderDetailActionHistoryComponent,
        ConsignmentDetailActionHistoryComponent,
        ConsignmentDetailPaymentHistoryComponent,
        EditBagComponent,
        AddSlowTransferComponent,
        OrderDeliverComponent,
        TransportCodeEditComponent,
        OrderRequestExportShipEditComponent,
        OrderRequestExportCPNEditComponent,
        PaymentHistoryCustomerComponent,
        OrderActionAddComponent,
        ConsignmentOrderActionAddComponent,
        EWalletAccountNegativeBalanceComponent,
        ParameterLevelEmployeeComponent,
        PromotionListComponent,
        StatisticPaymentEditComponent,
        ConsignmentVipSpecialComponent,
        ConsignmentOrderRequestExportAddComponent,
        ConsignmentOrderRequestExportEditComponent,
        CreateNewPageComponent,
        CreateNewPostComponent,
        CreateNewGuideComponent,
        OrderParamPhoneReceiptCommodityComponent,
        SmsRechargeDetailComponent,
        CreateByImportExcelComponent,
        ParameterConsignmentOrderTransportFeeAddOrEditComponent,
        ParameterConsignmentOrderTransportConverterAddOrEditComponent,
        ParameterConsignmentOrderSgWarehouseAddOrEditComponent,
        TaskAddOrUpdateComponent,
        OrdersDeliveryComponent,
        InternalTransportComponent,
        BillsSyncCommissionComponent,
        BarcodeReaderInputTransportsAddOrEditComponent,
        SaleVerifyCustomerInfoComponent,
        SaleOnlineParamDefaultAddOrEditComponent,
        SaleOnlineParamLevelAddOrEditComponent,
        SaleOnlineWithdrawalRequestComponent,
        SalesOnlineRechargeMoneyComponent,
        SaleVerifyIdentificationComponent,
        LuckyWheelPromotionComponent
    ],
    exports: [

    ]
})
export class ModalsModule { }
