import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomConfirm, CustomLoading, FIREBASE_STRUCT, SHOP_REFUND_STATUS } from './../../app.constant';
import { Subject, combineLatest, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { RolesService } from '../../services/roles/roles.service';
import { Decimal } from '../../app.models';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-shop-refund',
  templateUrl: './shop-refund.component.html',
  styleUrls: ['./shop-refund.component.css']
})
export class ShopRefundComponent implements OnInit, OnDestroy {

  order_accounts: any[] = [];
  // shop_refunds: any[];
  shop_refund: any = {};
  order: any;
  uid: any;
  unsubscribe$ = new Subject();
  list_roles: any[] = [];
  user_role: any = {};
  user_login: any;

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public loadingCtrl: LoadingController,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });

    this.getOrderAccounts().subscribe(acs => {
      this.order_accounts = acs;
    });

    // this.getShopRefunds().subscribe(refunds => {
    //   this.shop_refunds = refunds;
    // });

    this.getOrder(this.uid).subscribe(async (order) => {
      this.order = order;
      this.user_role = await rolesService.getRoles(this.order.order_status_key);
    });

    this.shop_refund = {
      image_link: null,
      refund_amount: null,
      ship_amount: null,
      account_refund: null,
      payment_number: null,
      note: null
    };
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  conpareFn(val1, val2) {
    if (!val1 || !val2) { return false; }
    return val1.uid === val2.uid;
  }

  getOrderAccounts() {
    return this.fs.collection<any[]>(`${FIREBASE_STRUCT.ACCOUNT_REFUND.NODE}`, query => {
      return query.orderBy('create_date', 'asc');
    }).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getShopRefunds() {
    return this.fs.collection<any[]>(`${FIREBASE_STRUCT.SHOP_REFUND.NODE}`).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getOrder(orderUid: string) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(orderUid).snapshotChanges().pipe(
      switchMap(sn => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('shop_refunds').snapshotChanges().pipe(
          switchMap(sns => {
            const obs = sns.map(_sn => this.fs.doc<any>(`${FIREBASE_STRUCT.SHOP_REFUND.NODE}/${_sn.payload.doc.id}`).snapshotChanges().pipe(
              map(__sn => ({ uid: __sn.payload.id, ...__sn.payload.data() }))
            ));
            if (obs.length) { return combineLatest(obs); } else { return of([]); }
          }),
          map(shop_refunds => ({ uid: sn.payload.id, ...sn.payload.data(), shop_refunds }))
        );
      }),
    );
  }

  validObject(shop_refund) {
    if (!shop_refund.image_link || Number(shop_refund.refund_amount) < 0 || Number(shop_refund.ship_amount) < 0 || !shop_refund.account_refund) {
      return false;
    }

    return true;
  }

  async _shopRefundValid(shopRefund, isCreateNewShopRefund) {
    const image_link = shopRefund.image_link;
    const account_refund = shopRefund.account_refund;
    const payment_number = shopRefund.payment_number;

    if (isCreateNewShopRefund) {
      if (!this.validObject(shopRefund)) {
        return 'Các hạng mục bắt buộc nhập không hợp lệ. Vui lòng kiểm tra lại.';
      }

      if (await this.checkLinkDuplicateForCreate(image_link)) {
        return 'Link hoàn tiền bị trùng lặp. Vui lòng kiểm tra lại.';
      }
    } else {
      if (await this.checkLinkDuplicateForUpdate(image_link, shopRefund.uid)) {
        return 'Link hoàn tiền bị trùng lặp. Vui lòng kiểm tra lại.';
      }

      this.order.shop_refunds.forEach(shop_refund => {
        if (!this.validObject(shop_refund)) {
          return 'Các hạng mục bắt buộc nhập không hợp lệ. Vui lòng kiểm tra lại.';
        }
      });
    }

    if (this.isAccountRefundOthers(account_refund)) {
      if (!payment_number) {
        return 'Số giao dịch chưa được nhập. Vui lòng kiểm tra lại.';
      } else {
        if (await this._isDuplicatePaymentNumber(payment_number, shopRefund.uid)) {
          return 'Số giao dịch đã tồn tại. Vui lòng kiểm tra lại.';
        }

      }
    } else {
      shopRefund.payment_number = '';
    }

    return '';
  }

  @CustomLoading()
  async onAddShopRefund() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const id = fs.collection(`${FIREBASE_STRUCT.SHOP_REFUND.NODE}`).doc().id;
      const shopRefund = { ...this.shop_refund, uid: id };
      const msg = await this._shopRefundValid(shopRefund, true);
      if (msg !== '') {
        throw new Error(msg);
      }

      const shop_refund_add = {
        ...shopRefund,
        order_uid: this.uid,
        order_code: this.order.order_code,
        order_code_2: this.order.order_code_2,
        order_status: this.order.order_status_text,
        status_key: SHOP_REFUND_STATUS.CHUAXULY.KEY,
        status: SHOP_REFUND_STATUS.CHUAXULY.VALUE,
        user_create: this.auth.auth.currentUser.displayName,
        date_created: await this.commonService.getServerTime(),
        customer_care_emp_uid: this.order.customer_care_emp_uid,
        customer_care_emp_full_name: this.order.customer_care_emp_full_name,
        in_charge_emp_uid: this.order.in_charge_emp_uid,
        in_charge_emp_full_name: this.order.in_charge_emp_full_name,
        order_emp_uid: this.order.order_emp_uid,
        order_emp_full_name: this.order.order_emp_full_name,
        owned_uid_admin_users: this.order.owned_uid_admin_users,
        branch_uid: this.order.branch_uid
      };

      batch.set(fs.collection(`${FIREBASE_STRUCT.SHOP_REFUND.NODE}`).doc(id), shop_refund_add);
      batch.set(fs
        .collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid)
        .collection('shop_refunds').doc(id),
        {
          uid: id
        }
      );

      batch.set(fs
        .collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid)
        .collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(),
        {
          element: 'Thêm mới shop hoàn tiền',
          date: await this.commonService.getServerTime(),
          user: this.user_login.full_name,
          old_value: '',
          new_value: shop_refund_add.refund_amount + ' - ' + shop_refund_add.ship_amount || 0
        },
        { merge: true }
      );

      await batch.commit();

      this.shop_refund = {
        image_link: null,
        refund_amount: null,
        ship_amount: null,
        account_refund: null,
        payment_number: null,
        note: null
      };

      return 'Thêm shop hoàn tiền thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  @CustomLoading()
  async onUpdateRefund(shop_refund) {
    const fs = firestore();
    const batch = fs.batch();

    try {
      let total_refund_amount = 0;
      let total_ship_amount = 0;
      let errorMsg: any;

      for (const shopRefund of this.order.shop_refunds) {
        errorMsg = await this._shopRefundValid(shopRefund, false);

        if (shopRefund.status_key === SHOP_REFUND_STATUS.DAXULY.KEY) {
          total_refund_amount = new Decimal(total_refund_amount).add(shopRefund.refund_amount).toNumber();
          total_ship_amount = new Decimal(total_ship_amount).add(shopRefund.ship_amount).toNumber();
        }
      }

      if (errorMsg) { throw new Error(errorMsg); }

      const shop_refund_update = {
        ...shop_refund,
        user_update: this.auth.auth.currentUser.displayName,
        date_update: await this.commonService.getServerTime(),
      };
      batch.update(fs.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE).doc(shop_refund.uid), shop_refund_update);

      if (shop_refund.status_key === SHOP_REFUND_STATUS.DAXULY.KEY) {
        const shop_refund_org = await fs.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE).doc(shop_refund.uid).get().then(doc => {
          return { refund_amount: doc.get('refund_amount'), ship_amount: doc.get('ship_amount') };
        });

        let order_price_real_cny = this.order.order_price_real_cny;
        let order_ship_real_cny = this.order.order_ship_real_cny;
        let order_total_real_cny = this.order.order_total_real_cny;

        order_price_real_cny = new Decimal(order_price_real_cny).add(shop_refund_org.refund_amount).sub(total_refund_amount).toNumber();
        order_ship_real_cny = new Decimal(order_ship_real_cny).add(shop_refund_org.ship_amount).sub(total_ship_amount).toNumber();
        order_total_real_cny = new Decimal(order_price_real_cny).add(order_ship_real_cny).toNumber();

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid), {
          order_price_real_cny,
          order_ship_real_cny,
          order_total_real_cny
        });

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
          element: 'Cập nhật shop hoàn tiền',
          date: await this.commonService.getServerTime(),
          user: this.user_login.full_name,
          old_value: shop_refund_org.refund_amount + ' - ' + shop_refund_org.ship_amount,
          new_value: shop_refund.refund_amount + ' - ' + shop_refund.ship_amount
        }, { merge: true });
      }

      await batch.commit();

      return 'Cập nhật shop hoàn tiền thành công';
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Xác nhận xóa shop hoàn tiền?')
  async onDeleteRefund(shop_refund) {
    const fs = firestore();
    const batch = fs.batch();
    try {

      if (shop_refund.status_key === SHOP_REFUND_STATUS.DAXULY.KEY) {
        const order_price_real_cny = new Decimal(this.order.order_price_real_cny).add(shop_refund.refund_amount).toNumber();
        const order_ship_real_cny = new Decimal(this.order.order_ship_real_cny).add(shop_refund.ship_amount).toNumber();
        const order_total_real_cny = new Decimal(order_price_real_cny).add(order_ship_real_cny).toNumber();

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(shop_refund.order_uid), {
          order_price_real_cny,
          order_ship_real_cny,
          order_total_real_cny
        });
      }

      batch.delete(fs.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE).doc(shop_refund.uid));
      batch.delete(fs
        .collection(FIREBASE_STRUCT.ORDERS.NODE).doc(shop_refund.order_uid)
        .collection('shop_refunds').doc(shop_refund.uid)
      );

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Xóa shop hoàn tiền',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: '',
        new_value: shop_refund.refund_amount + ' - ' + shop_refund.ship_amount
      });

      await batch.commit();
      return 'Xóa shop hoàn tiền thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra trong quá trình xóa shop hoàn tiền');
    }
  }

  async checkLinkDuplicateForCreate(link) {
    const existLink = await this.fs.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE, q => {
      return q.where('image_link', '==', link);
    }).snapshotChanges().pipe(
      map(sn => {
        return sn.length > 0;
      }),
      first()
    ).toPromise();

    return existLink;
  }

  async checkLinkDuplicateForUpdate(link, shopRefundUid) {
    const shopRefunds = await this.fs.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE, q => {
      return q.where('image_link', '==', link);
    }).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id }))),
      first()
    ).toPromise();

    let isDuplicate = false;
    for (const shopRefund of shopRefunds) {
      if (shopRefund.uid !== shopRefundUid) {
        isDuplicate = true;
        break;
      }
    }

    return isDuplicate;
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  public isAccountRefundOthers(accountRefundName) {
    if (accountRefundName === 'Ngoài đơn hàng') {
      return true;
    }
    return false;
  }

  private async _isDuplicatePaymentNumber(paymentNumber, shopRefundUid) {
    const shopRefunds = await this.fs.firestore.collection(FIREBASE_STRUCT.SHOP_REFUND.NODE)
      .where('payment_number', '==', paymentNumber)
      .get().then(snapshot => snapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() })));

    return shopRefunds.length > 1 ? true : shopRefunds.length === 1 ? shopRefunds[0].uid !== shopRefundUid : false;
  }

}
