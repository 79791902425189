import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomConfirm, FIREBASE_STRUCT, PAYMENT_REQUEST_STATUS } from 'src/app/app.constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-payment-request',
  templateUrl: './payment-request.component.html',
  styleUrls: ['./payment-request.component.scss'],
})
export class PaymentRequestComponent implements OnInit, OnDestroy {
  uid: string;
  request: any = {};
  unsubscribe$ = new Subject();
  PAYMENT_REQUEST_STATUS = PAYMENT_REQUEST_STATUS;
  request_status = [
    { key: 0, value: 'Gửi yêu cầu' },
    { key: 1, value: 'Đang xử lý' },
    { key: 2, value: 'Hoàn thành' }
  ]

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.fs.doc(`${FIREBASE_STRUCT.PAYMENT_REQUESTS.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(async (request: any) => {
        this.request = request;
        //console.log(request);
      });
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Thay đổi trạng thái?')
  async onSubmit() {
    const request = { ...this.request };
    const status = this.request_status[request.status_key];
    try {
      await this.fs.doc(`${FIREBASE_STRUCT.PAYMENT_REQUESTS.NODE}/${this.uid}`).update({
        status_key: status.key,
        status_text: status.value,
        execute_date: await this.commonService.getServerTime(),
        execute_user: this.auth.auth.currentUser.displayName,
        note: request.note || ''
      });

      if (status.key === PAYMENT_REQUEST_STATUS.HUY.KEY) {
        // TODO: Send notification to chat
      }
      this.modalCtrl.dismiss();
      return 'Thay đổi trạng thái thành công';
    } catch (error) {
      throw error;
    }
  }
}
