export const ORDER_DEFAULT = {
    NGAYBATDAUTINHLUUKHO: {
        KEY: 'BioN6OJ0VDtswg37bPYy',
    },
    NGAYTUDONGXOASANPHAM: {
        KEY: 'DrsAg36opEXjsRXvxbe9'
    },
    TYGIATHUC: {
        KEY: 'Pfrruaf39VjFSYc5oRIY'
    },
    NGAYTUDONGHOANTHANH: {
        KEY: 'VNhrfymq0UhhnGJksrut'
    },
    PHICHUYENKHOAN: {
        KEY: 'Z7BUTCLnGn6gXwMioEXX'
    },
    PHIDICHVUTOITHIEU: {
        KEY: 'cK61l9jySLeF3IzvWW2r'
    },
    NGAYTUDONGXOADON: {
        KEY: 'fvBdRAhvTAdH165HJtbZ'
    },
    PHILUUKHO: {
        KEY: 'hTOBXuzefiQWDmqlQJWj'
    },
    TYGIABAOKHACH: {
        KEY: 'qD2eyt3ZNdmQkXNNvayJ'
    },
    PHIDONGGOKGTIEPTHEO: {
        KEY: 'uw4Kdk6QXb1XiTSBpoPd'
    },
    PHIDONGGOKGDAU: {
        KEY: 'vcyFUSotXvoM3WA1KHPn'
    },
    TRONGLUONGCHENHLECHTOITHIEU: {
        KEY: 'CdeeHGXcfl96DQS2bSJu'
    },
    HESOQUYDOICONGTHUCCANNANG: {
        KEY: 'kl5Gjtebo0wrBchXxPXF'
    }
};

export const PARAMETER_VN_WAREHOUSE = {
    CUOCVANCHUYENBAO: {
        KEY: 'J3XJxionaJyFK3oUViUo',
    },
    PHIPHATSINH: {
        KEY: 'LICTo04qGg9ayRtwLVCp'
    },
    CUOCXEOM: {
        KEY: 'rIw71pR7irKDj3mayMyi'
    }
};

export const VN_WAREHOUSES: any[] = [
    {
        key: 0,
        id: 'HN',
        value: 'Kho Hà Nội'
    },
    {
        key: 1,
        id: 'SG',
        value: 'Kho Sài Gòn'
    }
];

export const HN_WAREHOUSE_CODE = {
    id: 'HN',
    value: 'Kho Hà Nội'
};

export const SG_WAREHOUSE_CODE = {
    id: 'SG',
    value: 'Kho Sài Gòn'
};

export const PARAMETER_PHONE_RECEIPT_COMMODITY = {
    SAIGON: {
        KEY: 'FOOfUWJo3dEHZnNPrSEo',
    },
    HANOI: {
        KEY: 'a7QqtC9nKw8dioSwFuAZ'
    },
};

export const PHILUUKHOSG = 'philuukhosg';
export const PHILUUKHO = 'philuukho';

