export const environment = {
  production: false,
  apiUrl: 'https://admin.nhaphangorder.vn/api',
  firebase: {
    apiKey: 'AIzaSyAXqXYlnRjgC1R8yVG-HBPMuTMyjr1Weuo',
    authDomain: 'nhaphangorder-45c36.firebaseapp.com',
    databaseURL: 'https://nhaphangorder-45c36-default-rtdb.firebaseio.com',
    projectId: 'nhaphangorder-45c36',
    storageBucket: 'nhaphangorder-45c36.appspot.com',
    messagingSenderId: '464079873077',
    appId: '1:464079873077:web:2a5e397e0d522cca15fd7c',
    measurementId: 'G-YDGGP716M6',
  },
};
