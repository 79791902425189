import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { firestore } from 'firebase/app';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FIREBASE_STRUCT } from 'src/app/app.constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  //#region chat properties
  public messages: Array<any> = [] // messages array (base on order conversation)
  temp: any; // for handling temporory data from observables.
  message: string = ''; // the message to be sent
  showChat = false; //Toggle to select a conversation.
  user: any;
  conversationInCharge: any[] = [];
  selectedConversation: any = {};
  selectedCustomerUid: string;
  selectedCustomerUidSub = new BehaviorSubject<any>('');
  admin_uid: string = 'TIMEQkoqY9Z85jjH7wUw';
  //#endregion chat properties

  unsubscribe$ = new Subject();
  @ViewChildren('scrollbar') scrollbar: QueryList<ElementRef>;

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService) {

    const uid = this.auth.auth.currentUser.uid;

    combineLatest([this._getUserInfo(), this._getConversationInCharge(uid)]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([userInfo, customers]) => {
      // this.conversationInCharge = conversations;
      this.user = userInfo;
      customers = customers.sort((firstItem, secondItem) => secondItem.last_message_date - firstItem.last_message_date);
      this.conversationInCharge = customers.map(customer => {
        return ({ customer_uid: customer.uid, customer_name: customer.full_name, conversation: customer.conversation });
      })
      this.selectedConversation = this.selectedConversation.customer_uid === undefined ? this.conversationInCharge[0] : this.selectedConversation;
      this.scrollChatView(this.scrollbar);
    });

    this.selectedCustomerUidSub.pipe(
      switchMap(customerUid => {
        if (customerUid) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.USERS.NODE}`).doc<any>(customerUid).snapshotChanges().pipe(
            map(doc => ({ uid: doc.payload.id, ...doc.payload.data() })),
            switchMap((user: any) => {
              let ob: Observable<any>;
              ob = this.fs.collection<any>(`${FIREBASE_STRUCT.CONVERSATIONS.NODE}`).doc<any>(user.conversations[1]).valueChanges();
              return ob.pipe(
                map(conversation => ({ ...user, conversation: { ...conversation } }))
              );
            }),
            map(user => {
              return ({ customer_uid: user.uid, customer_name: user.full_name, conversation: user.conversation, last_message_date: user.conversation.date_conversation_update });
            })
          );
        } else {
          return of(null);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      if (user !== null) {
        this.selectedConversation = user;
        this.scrollChatView(this.scrollbar);
        this.readConversation(user.conversation);
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChangeConversation(customerUid: any) {
    this.selectedCustomerUidSub.next(customerUid);
  }

  private _getConversationInCharge(userUid: string) {
    return this.fs.collection(FIREBASE_STRUCT.USERS.NODE, ref => {
      let query = ref.where('customer_care_emp_uid', '==', userUid)
        .where('conversations', 'array-contains', 'exist');

      return query;
    }).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({
        ...sn.payload.doc.data() as any,
        uid: sn.payload.doc.id,
      }))),
      switchMap((users: any[]) => {
        const obs = users.map(user => {
          let ob: Observable<any>;
          ob = this.fs.collection(FIREBASE_STRUCT.CONVERSATIONS.NODE).doc<any>(user.conversations[1]).valueChanges();
          return ob.pipe(
            map(conversation => {
              const messagesLength = conversation.messages.length || 0;
              return { ...user, conversation: { ...conversation }, last_message_date: conversation.date_conversation_update };
            })
          );
        });
        if (obs.length) { return combineLatest(obs); } else { return of([]); }
      }),
    )
  }

  private _getUserInfo() {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .doc<any>(this.auth.auth.currentUser.uid).snapshotChanges()
      .pipe(
        map(action => ({ uid: action.payload.id, ...action.payload.data() })),
      );
  }

  //#region Chat functions
  async toggleMessages() {
    // Show chat modal
    this.showChat = !this.showChat;

    this.scrollChatView(this.scrollbar);

    // If the toggle OFF, do not do anything, just show off modal
    if (!this.showChat) {
      return;
    } else {
      this.readConversation(this.selectedConversation.conversation);
    }
  }

  async sendMessage() {
    const message = this.message;

    // If message string is empty
    if (message === '') {
      return;
    }
    this.message = ''; // set the global message to empty

    const now = await this.commonService.getServerTime();

    //set the message object 
    let msg = {
      senderId: this.auth.auth.currentUser.uid,
      senderName: this.user.full_name,
      timestamp: now,
      content: message
    };

    //update 
    if (this.selectedConversation.conversation.messages === undefined) {
      this.selectedConversation.conversation.messages = [];
    }
    this.selectedConversation.conversation.messages.push(msg);
    this._pushNewMessage(this.selectedConversation);

    this.scrollChatView(this.scrollbar);
  }

  private scrollChatView(ele: QueryList<ElementRef>) {
    setTimeout(() => {
      const element = ele.last || ele.last;
      if (element) {
        const nativeElement = element.nativeElement as HTMLDivElement;
        nativeElement.scrollIntoView();
      }
    }, 100);
  }

  private async _pushNewMessage(userConversation: any) {
    if (userConversation.customer_uid !== undefined && userConversation.conversation.uid !== undefined) {
      const fs = firestore();
      const batch = fs.batch();
      const now = await this.commonService.getServerTime();

      try {
        const conversations_ref = this.fs.firestore
          .collection(FIREBASE_STRUCT.CONVERSATIONS.NODE)
          .doc(userConversation.conversation.uid);

        const conversation_existed: boolean = await conversations_ref.get().then((doc) => doc.exists);

        if (conversation_existed) {
          const current_customer_unread = Number((await conversations_ref.get()).data().customer_unread) || 0;
          batch.update(conversations_ref, {
            messages: userConversation.conversation.messages,
            date_conversation_update: now,
            customer_unread: current_customer_unread + 1
          })
        } else {
          throw ("Đoạn chat đã có thể bị xoá, xin kiểm tra lại")
        }

        await batch.commit();
        return 'Gửi tin nhắn thành công';
      } catch (error) {
        throw (error);
      }
    }
  }

  private async readConversation(conversation: any) {
    const fs = firestore();
    const batch = fs.batch();

    try {
      if (conversation.uid !== undefined) {
        const conversations_ref = this.fs.firestore
          .collection(FIREBASE_STRUCT.CONVERSATIONS.NODE)
          .doc(conversation.uid);

        const conversation_existed: boolean = await conversations_ref.get().then((doc) => doc.exists);

        if (conversation_existed) {
          batch.update(conversations_ref, {
            employee_unread: 0
          })
        } else {
          throw ("Đoạn chat đã có thể bị xoá, xin kiểm tra lại")
        }
      }

      await batch.commit();
      return 'Đã đọc tin nhắn';
    } catch (error) {
      throw (error);
    }
  }

  public isShowChatBagde() {
    const conversationInCharge = this.conversationInCharge;
    if (conversationInCharge && conversationInCharge.length > 0) {
      const has_unread_message = conversationInCharge.filter(c => c.conversation.employee_unread > 0);
      return has_unread_message && has_unread_message.length > 0;
    }

    return false;
  }
  //#endregion Chat functions
}
