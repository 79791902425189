import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.css']
})
export class RoleAddComponent implements OnInit, OnDestroy {

  uid;
  role: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.getRole().subscribe(role => {
        this.role = role;
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getRole() {
    return this.db.doc(`${FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE}/${this.uid}`).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onSubmit() {
    try {
      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE}/${this.uid}`).update({
          name: this.role.name || '',
          description: this.role.description || '',
          update_date: await this.commonService.getServerTime()
        });
      } else {
        this.db.collection(`${FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE}`).add({
          name: this.role.name || '',
          description: this.role.description || '',
          create_date: await this.commonService.getServerTime()
        });
      }

      this.modalCtrl.dismiss();

      return 'Cập nhật role thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }
}
