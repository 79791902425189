import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CustomConfirm, FIREBASE_STRUCT, PAYMENT_HISTORY_TYPE } from '../../../app.constant';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Decimal } from '../../../app.models';

@Component({
  selector: 'app-recharge-money',
  templateUrl: './recharge-money.component.html',
  styleUrls: ['./recharge-money.component.css']
})
export class RechargeMoneyComponent implements OnInit, OnDestroy {

  uid;
  user: any;
  recharge_types: any[];
  recharge_type: any;
  recharge_locations: any[];
  recharge_location: any;
  recharge_type_sub = new BehaviorSubject<number>(0);
  recharge_money: number;
  note: string;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public translateService: TranslateService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.user = obj;
      });
    }

    this.recharge_types = [
      { key: 1, value: 'Tiền mặt' },
      { key: 2, value: 'Chuyển khoản' }
    ];

    this.getRechargeLocations().subscribe(locations => {
      this.recharge_location = null;
      this.recharge_locations = locations;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectType() {
    this.recharge_type_sub.next(this.recharge_type);
  }

  getRechargeLocations() {
    return this.recharge_type_sub.pipe(
      switchMap(type => {
        if (Number(type) === 1) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_CASH.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else if (Number(type) === 2) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_TRANFER.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else { return of([]); }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Xác nhận nạp tiền?')
  async onSubmit() {
    const fs = firebase.firestore();
    try {
      const c = await this.commonService.getServerTime();
      const recharge_money = Number(this.recharge_money || 0);
      if (this.uid !== '') {
        const user = { ...this.user };
        const recharge_type = this.recharge_type - 1;

        await fs.runTransaction(transaction => {
          return Promise.all([
            transaction.get(fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`)),
            transaction.get(fs.collection(FIREBASE_STRUCT.STATISTIC.NODE).doc('statistic_payment')),
            transaction.get(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID))
          ]).then(([user_doc, statistic_doc, uid_doc]) => {
            let accountBalance = Number(user_doc.get('account_balance') || 0);
            let availableLimit = Number(user_doc.get('available_limit') || 0);
            const creditLimit = Number(user_doc.get('credit_limit') || 0);
            let rechargedMoney: number = Number(user_doc.get('recharged_money') || 0);
            accountBalance = new Decimal(accountBalance).add(recharge_money).toNumber();
            availableLimit = new Decimal(creditLimit).add(accountBalance).toNumber();
            rechargedMoney = new Decimal(rechargedMoney).add(recharge_money).toNumber();
            transaction.update(fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`), {
              account_balance: accountBalance,
              available_limit: availableLimit,
              recharged_money: rechargedMoney,
              date_recharged_money_last: new Date().getTime()
            });

            let totalRevenueTranfer: number = Number(statistic_doc.get('total_revenue_tranfer') || 0);
            let totalRevenueCash: number = Number(statistic_doc.get('total_revenue_cash') || 0);
            totalRevenueTranfer = this.recharge_types[recharge_type].value === 'Chuyển khoản' ? (new Decimal(totalRevenueTranfer).add(recharge_money).toNumber()) : totalRevenueTranfer;
            totalRevenueCash = this.recharge_types[recharge_type].value === 'Tiền mặt' ? (new Decimal(totalRevenueCash).add(recharge_money).toNumber()) : totalRevenueCash;
            transaction.set(fs.collection(FIREBASE_STRUCT.STATISTIC.NODE).doc('statistic_payment'), {
              total_revenue_tranfer: totalRevenueTranfer,
              total_revenue_cash: totalRevenueCash,
            }, { merge: true });

            const paymentId: number = Number(uid_doc.get('id') || 0) + 1;
            const user_payment_id: number = Number(uid_doc.get(this.uid) || 0) + 1;
            transaction.set(uid_doc.ref, {
              id: paymentId,
              [this.uid]: user_payment_id
            }, { merge: true });

            transaction.set(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(), {
              payment_date_created: c,
              payment_type_key: PAYMENT_HISTORY_TYPE.NAPTIEN.KEY,
              payment_type: PAYMENT_HISTORY_TYPE.NAPTIEN.VALUE,
              payment_content: `${this.note ? 'Nạp tiền - ' + this.note : 'Nạp tiền'}`,
              payment_amount: recharge_money,
              payment_code: paymentId,
              payment_create_user: this.auth.auth.currentUser.displayName,
              payment_name: this.recharge_types[recharge_type].value,
              customer_account_balance: accountBalance,
              customer_id: user.id,
              customer_full_name: user.full_name,
              customer_phone_number: user.phone,
              customer_email: user.email,
              customer_user_uid: this.uid,
              location: this.recharge_location.name,
              note: this.note || '',
              customer_payment_code: user_payment_id,
              branch_uid: user.branch_uid
            });
          });
        });
      } else {
        return '';
      }
      this.modalCtrl.dismiss();
      return 'Nạp tiền thành công';
    } catch (error) {
      throw error;
    }
  }

}
