import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { first } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-create-new-guide',
    templateUrl: './create-new-guide.component.html',
    styleUrls: ['./create-new-guide.component.css']
})

export class CreateNewGuideComponent implements OnInit, OnDestroy {
    @Input() guideId: any;
    @Input() guideCat: any;
    guide: any = {};
    unsubcribed$ = new Subject();

    constructor(
        private modalCtrl: ModalController,
        private auth: AngularFireAuth,
        private fs: AngularFirestore,
    ) { }


    ngOnInit() {
        if (this.guideId) {
            this.fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc(FIREBASE_STRUCT.PARAMETER_CONTENT.CUSTOMER_GUIDE.NODE).collection(this.guideCat).doc(this.guideId)
                .valueChanges()
                .pipe(first()).subscribe((guide) => {
                    if (guide) {
                        this.guide = guide;
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.unsubcribed$.next();
        this.unsubcribed$.complete();
    }

    @CustomConfirm('Xác nhận thay đổi hướng dẫn ?')
    async onSubmit() {
        try {
            const fs = firestore();
            const batch = fs.batch();
            const cat = fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc(FIREBASE_STRUCT.PARAMETER_CONTENT.CUSTOMER_GUIDE.NODE).collection(this.guideCat);
            const guideId = this.guideId || cat.doc().id;
            this.guideId = guideId;
            this.guide = {
                ...this.guide, ...{
                    id: this.guideId,
                    modifiedAt: Date.now(),
                    createAt: this.guide.createAt || Date.now(),
                    author: this.auth.auth.currentUser.displayName
                }
            };
            batch.set(cat.doc(this.guideId), this.guide, { merge: true });
            await batch.commit();
            this.onDismiss();
            return 'Cập nhật thành công';
        } catch (error) {
            throw error;
        }
    }

    onDismiss() {
        this.modalCtrl.dismiss();
    }
}
