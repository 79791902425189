import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { firestore } from 'firebase/app';
import { first } from 'rxjs/operators';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';

@Component({
    selector: 'app-create-new-post',
    templateUrl: './create-new-post.component.html',
    styleUrls: ['./create-new-post.component.css']
})


export class CreateNewPostComponent implements OnInit {

    constructor(
        public modalCtrl: ModalController,
        public auth: AngularFireAuth,
        public fs: AngularFirestore
    ) {
    }

    @Input() postId: any;
    @Input() postCat: any;
    @Input() branchUid: any;
    post: any = {};
    previewLink;
    startDate: any;
    endDate: any;

    onChange(event: CKEditor4.EventInfo) {
        this.post.content_html = event.editor.getData();
    }

    ngOnInit() {
        if (this.postId) {
            this.fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc(this.postCat).collection('list').doc(this.postId)
                .valueChanges()
                .pipe(first()).subscribe((x) => {
                    if (x) {
                        this.post = x;
                        if (this.post.startDate) {
                            const startDate = new Date(this.post.startDate);
                            this.startDate = {
                                year: startDate.getFullYear(),
                                day: startDate.getDate(),
                                month: startDate.getMonth() + 1
                            };
                        }
                        if (this.post.endDate) {
                            const endDate = new Date(this.post.endDate);
                            this.endDate = {
                                year: endDate.getFullYear(),
                                day: endDate.getDate(),
                                month: endDate.getMonth() + 1
                            };
                        }
                    }
                });
        }

    }

    @CustomConfirm('Xác nhận thay đổi trang ?')
    public async onSubmit() {
        // convert to num
        if (this.startDate) {
            const { year, month, day } = this.startDate;
            this.post.startDate = new Date(year, month - 1, day, 1, 1, 1).getTime();
        }
        if (this.endDate) {
            const { year, month, day } = this.endDate;
            this.post.endDate = new Date(year, month - 1, day, 23, 59, 59).getTime();
        }
        try {
            const fs = firestore();
            const batch = fs.batch();
            const list = fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE
            ).doc(this.postCat).collection('list');
            // const route = this.convertToSlug(this.post.title);
            this.postId =this.postId || list.doc().id;
            this.post = {
                ...this.post, ...{
                    modifiedAt: Date.now(),
                    createAt: this.post.createAt || Date.now(),
                    id: this.postId,
                    author: this.auth.auth.currentUser.displayName,
                    branchUid: this.branchUid || 'lqB5AqKzNjT2LFrsfcaI'
                }
            };
            batch.set(list.doc(this.postId), this.post, { merge: true });
            await batch.commit();
            this.onDismiss();
            return 'Cập nhật thành công';
        } catch (error) {
            throw error;
        }
    }

    public onDismiss() {
        this.modalCtrl.dismiss();
    }

    private convertToSlug(Text) {
        const textInEnglish = this.nonAccentVietnamese(Text);
        return textInEnglish
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }

    private nonAccentVietnamese(str) {
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
        str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
        return str;
    }
}
