import { FIREBASE_STRUCT } from './../../app.constant';
import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-detail-payment-history',
  templateUrl: './order-detail-payment-history.component.html',
  styleUrls: ['./order-detail-payment-history.component.css']
})
export class OrderDetailPaymentHistoryComponent implements OnInit, OnDestroy {

  uid;
  payment_histories: any[] = [];
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.db.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(`${FIREBASE_STRUCT.ORDER_DETAIL_PAYMENT_HISTORY.NODE}`).snapshotChanges().pipe(
        map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.payment_histories = obj;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

}
