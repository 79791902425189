import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Decimal } from '../../app.models';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-parameter-level-employee',
  templateUrl: './parameter-level-employee.component.html',
  styleUrls: ['./parameter-level-employee.component.css']
})
export class ParameterLevelEmployeeComponent implements OnDestroy, OnInit {

  uid: any;
  param: any = {};
  unsubscsribe = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid) {
      this.getParam(this.uid).subscribe(p => {
        this.param = p;
        this.param.bonus_for_revenue = new Decimal(this.param.bonus_for_revenue).mul(100).toNumber();
      });
    } else {
      this.getCurrentLevel().then(id => {
        this.param.name = 'Hạng ' + id;
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscsribe.next();
    this.unsubscsribe.complete();
  }

  onDismiss() {
    return this.modalCtrl.dismiss();
  }

  getParam(uid) {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_LEVEL_EMPLOYEE.NODE).doc(uid).valueChanges().pipe(
      takeUntil(this.unsubscsribe)
    );
  }

  getCurrentLevel() {
    const fs = firestore();
    return fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.LEVEL_EMPLOYEE_UID).get().then(doc => {
      return (doc.get('id') || 0) + 1;
    });
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    try {
      const param = { ...this.param };
      if (!this.isValid(param)) { throw new Error('Vui lòng nhập đúng thông tin'); }
      param.bonus_for_revenue = param.bonus_for_revenue / 100;
      if (this.uid) {
        await batch.update(fs.collection(FIREBASE_STRUCT.PARAMETER_LEVEL_EMPLOYEE.NODE).doc(this.uid), {
          ...param
        });
        await batch.commit();
        await this.onDismiss();
        return 'Cập nhật cấp độ nhân viên thành công';
      } else {
        const id = await this.getCurrentLevel();
        param.date_created = current;
        param.value = id;
        param.is_active = true;
        batch.set(fs.collection(FIREBASE_STRUCT.PARAMETER_LEVEL_EMPLOYEE.NODE).doc(), {
          ...param
        });
        batch.set(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.LEVEL_EMPLOYEE_UID), {
          id
        });
        await batch.commit();
        await this.onDismiss();
        return 'Thêm mới cấp độ nhân viên thành công';
      }
    } catch (error) {
      throw error;
    }
  }

  isValid(param) {
    if (!param) { return false; }
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        const element = param[key];
        if (element === null || element === undefined) { return false; }
      }
    }
    return true;
  }

}
