import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import {
  ACTION_URL,
  COMPLAIN_STATUS,
  CustomLoading,
  FIREBASE_STRUCT
} from '../../app.constant';
import { ORDER_DEFAULT } from '../../constants/order';
import { QuickActionComponent } from '../../modals/quick-action/quick-action.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  authInfo: any;
  rate: any;
  pushRightClass = 'push-right';
  unsubscribe$ = new Subject();
  actions = [];
  total_complain = 0;
  total_request = 0;
  total_message = 0;
  statisticSms;
  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public translate: TranslateService,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    private router: Router
  ) {
    this.translate.addLangs([
      'en',
      'fr',
      'ur',
      'es',
      'it',
      'fa',
      'de',
      'zh-CHS'
    ]);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en'
    );

    this.getUserInfo().subscribe((u) => {
      this.authInfo = u;
      if (this.authInfo.quick_actions) {
        this.actions = this.authInfo.quick_actions.map((act) => {
          return {
            key: act,
            router: ACTION_URL[act].router,
            label: ACTION_URL[act].label
          };
        });
      }
    });

    this.getTotalComplain().subscribe((complains) => {
      this.total_complain = complains.count;
    });

    this.getTotalRequestCount().subscribe((d: any) => {
      this.total_request = d && d.id ? d.id : 0;
    });

    const uid = this.auth.auth.currentUser
      ? this.auth.auth.currentUser.uid
      : null;
    this.fs
      .collection(FIREBASE_STRUCT.MESSAGE_ROOMS.NODE, (q) =>
        q
          .where('admin_uid', '==', uid)
          .where('user_last_send_message', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => snaps.map((snap) => ({ uid: snap.payload.doc.id }))),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((p) => {
        this.total_message = p.length;
      });

    this.getPaymentStatistic()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((statisticSms) => {
        this.statisticSms = statisticSms;
      });
  }

  getPaymentStatistic() {
    return this.fs.collection('STATISTIC').doc('statistic_sms').valueChanges();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.isMobile()) {
      this.onToggleMenu(false);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToHome() {
    this.navCtrl.navigateRoot('home');
  }

  goToLogin() {
    this.navCtrl.navigateRoot('login');
  }

  navigateByUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  getUserInfo() {
    const uid = this.auth.auth.currentUser
      ? this.auth.auth.currentUser.uid
      : null;
    return this.fs
      .doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${uid}`)
      .snapshotChanges()
      .pipe(
        map((sn) => ({ uid, ...sn.payload.data() })),
        switchMap((user) =>
          this.getRate().pipe(
            map((rate) => {
              user.rate = rate;
              return user;
            })
          )
        ),
        takeUntil(this.unsubscribe$)
      );
  }

  getRate() {
    return this.fs
      .doc(
        `${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}/${ORDER_DEFAULT.TYGIABAOKHACH.KEY}`
      )
      .valueChanges();
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('rtl');
  }

  addQuickAction() {
    this.modalCtrl
      .create({
        component: QuickActionComponent
      })
      .then((modal) => {
        modal.present();
      });
  }

  @CustomLoading()
  onLoggedout() {
    return this.auth.auth.signOut().then(() => {
      window.location.href = '/';
    });
  }

  changeLang(language: string) {
    this.translate.use(language);
  }

  getTotalComplain() {
    return this.fs
      .collection(FIREBASE_STRUCT.COMPLAINS.NODE, (query) => {
        return query.where(
          'complain_status_key',
          '==',
          COMPLAIN_STATUS.CHUAXULY.KEY
        );
      })
      .snapshotChanges()
      .pipe(
        map((sns) => ({ count: sns.length })),
        takeUntil(this.unsubscribe$)
      );
  }

  getTotalRequestCount() {
    return this.fs
      .collection(FIREBASE_STRUCT.UID_INFO.NODE)
      .doc('request_not_handle_count')
      .valueChanges();
  }

  isMobile() {
    let isMobile = false; // initiate as false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }

    return isMobile;
  }

  onToggleMenu(is_menu_click) {
    let is_toggle_menu = localStorage.getItem('is_toggle_menu');
    is_toggle_menu = !is_toggle_menu ? 'true' : is_toggle_menu;

    if (is_menu_click) {
      if (is_toggle_menu === 'true') {
        is_toggle_menu = 'false';
        localStorage.setItem('is_toggle_menu', 'false');

        $('.sidebar-panel').on('mouseenter', (event) => {
          event.preventDefault();
          if (is_toggle_menu === 'false') {
            $('.sidebar-panel').show();
            $('.sidebar-panel').animate(
              { width: sidebarWidth, opacity: '1' },
              'fast'
            );
            $('.sidebar').removeClass('sidebar-minimum');
            $('.main-panel').removeClass('main-panel-maximum');
          }
        });

        $('.sidebar-panel').on('mouseleave', (event) => {
          event.preventDefault();
          if (is_toggle_menu === 'false') {
            $('.sidebar-panel').animate(
              { width: sidebarMinimumWidth, opacity: '1' },
              'fast'
            );
            $('.sidebar').addClass('sidebar-minimum');
            $('.main-panel').addClass('main-panel-maximum');
          }
        });
      } else {
        is_toggle_menu = 'true';
        localStorage.setItem('is_toggle_menu', 'true');

        $('.sidebar-panel').off('mouseenter mouseleave');
      }
    }

    const sidebarWidth = 260;
    const sidebarMinimumWidth = 60;
    if (is_toggle_menu === 'true') {
      $('.sidebar-panel').animate(
        { width: sidebarWidth, opacity: '1' },
        'fast'
      );
      $('.sidebar').removeClass('sidebar-minimum');
      $('.main-panel').removeClass('main-panel-maximum');
      $('.mdi.mdi-menu').addClass('menu-active');
    } else {
      $('.sidebar-panel').animate(
        { width: sidebarMinimumWidth, opacity: '01' },
        'fast'
      );
      $('.sidebar').addClass('sidebar-minimum');
      $('.main-panel').addClass('main-panel-maximum');
      $('.mdi.mdi-menu').removeClass('menu-active');
    }
  }
}
