import { FIREBASE_STRUCT } from './../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-e-wallet-account-negative-balance',
  templateUrl: './e-wallet-account-negative-balance.component.html',
  styleUrls: ['./e-wallet-account-negative-balance.component.css']
})
export class EWalletAccountNegativeBalanceComponent implements OnInit, OnDestroy {

  ewallets: any[] = [];
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth) {
    this.getEWallets().subscribe(ewallets => {
      this.ewallets = ewallets;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getEWallets() {
    return this.fs.collection<any>(FIREBASE_STRUCT.USERS.NODE, query => {
      return query.where('account_balance', '<', 0);
    }).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );


  }
}
