import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { takeUntil } from 'rxjs/operators';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit, OnDestroy {

  uid: any;
  branchUid: any;

  promotion: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
    if (this.uid !== '') {
      this.db.doc(`${FIREBASE_STRUCT.PROMOTION.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.promotion = obj;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const c = await this.commonService.getServerTime();
      if (!this.promotion.content) { throw new Error('Chưa nhập nội dung khuyễn mãi'); }
      if (!this.promotion.start_date) { throw new Error('Chưa nhập ngày bắt đầu'); }
      if (!this.promotion.end_date) { throw new Error('Chưa nhập ngày kết thức'); }
      if (!this.promotion.value_per_account) { throw new Error('Chưa nhập giá trị khuyên mãi'); }
      const promotion = { ...this.promotion };
      const s_date: { year: number, month: number, day: number } = promotion.start_date;
      promotion.start_date = new Date(s_date.year, s_date.month - 1, s_date.day).getTime();
      const e_date: { year: number, month: number, day: number } = promotion.end_date;
      promotion.end_date = new Date(e_date.year, e_date.month - 1, e_date.day, 23, 59, 59).getTime();
      if (this.uid !== '') {
        batch.update(fs.doc(`${FIREBASE_STRUCT.PROMOTION.NODE}/${this.uid}`), {
          ...promotion,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        batch.set(fs.collection(`${FIREBASE_STRUCT.PROMOTION.NODE}`).doc(), {
          ...promotion,
          branchUid: this.branchUid,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }

      await batch.commit();
      await this.modalCtrl.dismiss();

      return 'Cập nhật khuyễn mãi thành công';
    } catch (error) {
      throw error;
    }
  }

}
