export const ADMIN_USERS = {
    NHANVIENDATHANG: {
        KEY: 'um7XDDHI1bVBZCBUFh1n',
    },
    NHANVIENCHAMSOCKHACHHANG: {
        KEY: 'EOygYEWktZqF4sXGgukr'
    },
    NHANVIENKINHDOANH: {
        KEY: 'CZxYOOnHeJC65hyo9EvB'
    },
    TRUONGPHONGCHAMSOCKHACHHANG: {
        KEY: 'owkdqtsRL0TIkaKntYDX'
    },
    TRUONGPHONGKINHDOANH: {
        KEY: 'Iph8KNXQnTXHXWudwtk7'
    },
    TRUONGPHONGCHAMSOCKHACHHANGSOCORDER: {
        KEY: 'QKQJYA3lgNGz1cSjqfJd'
    },
    TRUONGPHONGCHAMSOCKHACHHANGPANDAORDER: {
        KEY: 'JSa2xbkIoef2VCLD5hEX'
    },
    NHANVIENSALE: {
        KEY: 'oRwY5CHFY994CkfBbWBH'
    },
    ADMINHETHONG: {
        KEY: 's1L44OZG7wPTS6dpYOrb'
    },
    NHANVIENKHOVIETNAM: {
        KEY: '4xdBM7kz84QAGgvOlBbg'
    },
    NHANVIENKETOAN: {
        KEY: 'y8Enx1tZfnp5pMheGsuW'
    },
    TRUONGPHONGKHO: {
        KEY: '4B4NuCouVFBZ3Ziks1E5'
    },
};
