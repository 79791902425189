import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { CustomLoading, FIREBASE_STRUCT, ORDER_STATUS } from './../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, takeUntil } from 'rxjs/operators';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-order-deliver',
  templateUrl: './order-deliver.component.html',
  styleUrls: ['./order-deliver.component.css']
})
export class OrderDeliverComponent implements OnInit, OnDestroy {

  uid;
  order_auto_finish;
  deliver_info: any = {};
  unsubscribe$ = new Subject();
  deliver_hour = 0;
  deliver_type = 'Giao trực tiếp';
  deliver_detail: string;

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.order_auto_finish = Boolean(this.navParams.data.modal.componentProps.order_auto_finish);
    if (this.uid) {
      this.getDeliverInfo().subscribe(deliverInfos => {
        if (deliverInfos.length) {
          this.deliver_info = deliverInfos[0];
        } else {
          this.setDefaultDeliverInfo();
        }
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const deliverInfo = { ...this.deliver_info };
      if (deliverInfo.uid) {
        batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('deliver_info').doc(deliverInfo.uid));
      }

      if (this.order_auto_finish) {
        batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS_AUTO_FINISH.NODE).doc(this.uid));
      }

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('deliver_info').doc(), {
        is_direct_deliver: deliverInfo.is_direct_deliver,
        is_ship_internal: deliverInfo.is_ship_internal,
        is_ship_external: deliverInfo.is_ship_external,
        is_ship_viettel_fast: deliverInfo.is_ship_viettel_fast,
        is_ship_viettel_v60: deliverInfo.is_ship_viettel_v60,
        is_ship_viettel_normal: deliverInfo.is_ship_viettel_normal,
        is_ship_nhattin_fast: deliverInfo.is_ship_nhattin_fast,
        is_ship_nhattin_mes: deliverInfo.is_ship_nhattin_mes,
        is_ship_nhattin_normal: deliverInfo.is_ship_nhattin_normal,
        is_ship_minhtrieu: deliverInfo.is_ship_minhtrieu,
        is_ship_aal_express: deliverInfo.is_ship_aal_express,
        is_ship_aal_fast: deliverInfo.is_ship_aal_fast,
        is_ship_aal_ship: deliverInfo.is_ship_aal_ship,
        is_ship_giabao_fast: deliverInfo.is_ship_giabao_fast,
        is_ship_giabao_slow: deliverInfo.is_ship_giabao_slow,
        is_ship_giabao_ship: deliverInfo.is_ship_giabao_ship,
        is_ship_other: deliverInfo.is_ship_other,
        date_direct_deliver: deliverInfo.date_direct_deliver,
        date_ship_internal: deliverInfo.date_ship_internal,
        date_ship_external: deliverInfo.date_ship_external,
        date_ship_viettel_fast: deliverInfo.date_ship_viettel_fast,
        date_ship_viettel_v60: deliverInfo.date_ship_viettel_v60,
        date_ship_viettel_normal: deliverInfo.date_ship_viettel_normal,
        date_ship_nhattin_fast: deliverInfo.date_ship_nhattin_fast,
        date_ship_nhattin_mes: deliverInfo.date_ship_nhattin_mes,
        date_ship_nhattin_normal: deliverInfo.date_ship_nhattin_normal,
        date_ship_minhtrieu: deliverInfo.date_ship_minhtrieu,
        date_ship_aal_express: deliverInfo.date_ship_aal_express,
        date_ship_aal_fast: deliverInfo.date_ship_aal_fast,
        date_ship_aal_ship: deliverInfo.date_ship_aal_ship,
        date_ship_giabao_fast: deliverInfo.date_ship_giabao_fast,
        date_ship_giabao_slow: deliverInfo.date_ship_giabao_slow,
        date_ship_giabao_ship: deliverInfo.date_ship_giabao_ship,
        date_ship_other: deliverInfo.date_ship_other
      });

      const deliverDate = this.addHourToDate(this.deliver_hour);
      if (this.deliver_hour > 0) {
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), {
          order_status_key: ORDER_STATUS.GIAOHANG.KEY,
          order_status_text: ORDER_STATUS.GIAOHANG.VALUE,
          order_date_deliver: await this.commonService.getServerTime(),
          order_date_delivered: '',
          order_date_finish: '',
          order_deliver_type: this.deliver_type || '',
          order_deliver_detail: this.deliver_detail || '',
          order_auto_finish: false,
          is_finished: false
          /* order_date_delivered: deliverDate, */
        });

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS_AUTO_FINISH.NODE).doc(this.uid), {
          key: ORDER_STATUS.GIAOHANG.KEY,
          date_end: deliverDate
        });
      } else {
        const curentTime = await this.commonService.getServerTime();
        const time_order_finish = await fs.collection('PARAMETER_ORDER_DEFAULT').doc('VNhrfymq0UhhnGJksrut').get().then(doc => {
          return Number(doc.get('value'));
        });
        const finishDate = new Date(curentTime);
        finishDate.setDate(finishDate.getDate() + time_order_finish);
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), {
          order_status_key: ORDER_STATUS.DAGIAO.KEY,
          order_status_text: ORDER_STATUS.DAGIAO.VALUE,
          order_date_deliver: await this.commonService.getServerTime(),
          order_date_delivered: deliverDate,
          order_deliver_type: this.deliver_type || '',
          order_deliver_detail: this.deliver_detail || '',
          order_auto_finish: true,
          is_finished: true
        });
        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS_AUTO_FINISH.NODE).doc(this.uid), {
          key: ORDER_STATUS.DAGIAO.KEY,
          date_end: finishDate.getTime()
        });
      }

      await batch.commit();
      await this.modalCtrl.dismiss();
      return 'Cập nhật giao hàng thành công';
    } catch (error) {
      throw error;
    }
  }

  addHourToDate(hour: number) {
    const date = new Date();
    date.setHours(date.getHours() + hour);
    return date.getTime();
  }

  getDeliverInfo() {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('deliver_info').snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  onCheckDirectDeliver() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_direct_deliver = !this.deliver_info.is_direct_deliver;
    this.deliver_hour = this.deliver_info.date_direct_deliver;
    this.deliver_type = 'Giao trực tiếp';
  }

  onCheckShipInternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_internal = !this.deliver_info.is_ship_internal;
    this.deliver_hour = this.deliver_info.date_ship_internal;
    this.deliver_type = 'Ship nội thành';
  }

  onCheckShipExternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_external = !this.deliver_info.is_ship_external;
    this.deliver_hour = this.deliver_info.date_ship_external;
    this.deliver_type = 'Ship xe khách';
  }

  onCheckShipViettelFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_fast = !this.deliver_info.is_ship_viettel_fast;
    this.deliver_hour = this.deliver_info.date_ship_viettel_fast;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipViettelV60() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_v60 = !this.deliver_info.is_ship_viettel_v60;
    this.deliver_hour = this.deliver_info.date_ship_viettel_v60;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'V60';
  }

  onCheckShipViettelNormal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_normal = !this.deliver_info.is_ship_viettel_normal;
    this.deliver_hour = this.deliver_info.date_ship_viettel_normal;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'Chuyển thường';
  }

  onCheckShipNhatTinFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_fast = !this.deliver_info.is_ship_nhattin_fast;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_fast;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipNhatTinMes() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_mes = !this.deliver_info.is_ship_nhattin_mes;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_mes;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'V60';
  }

  onCheckShipNhatTinNormal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_normal = !this.deliver_info.is_ship_nhattin_normal;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_normal;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'Chuyển thường';
  }

  onCheckShipMinhTrieu() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_minhtrieu = !this.deliver_info.is_ship_minhtrieu;
    this.deliver_hour = this.deliver_info.date_ship_minhtrieu;
    this.deliver_type = 'Minh Triệu';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipAALExpress() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_express = !this.deliver_info.is_ship_aal_express;
    this.deliver_hour = this.deliver_info.date_ship_aal_express;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Hỏa tốc';
  }

  onCheckShipAALFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_fast = !this.deliver_info.is_ship_aal_fast;
    this.deliver_hour = this.deliver_info.date_ship_aal_fast;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipAALShip() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_ship = !this.deliver_info.is_ship_aal_ship;
    this.deliver_hour = this.deliver_info.date_ship_aal_ship;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipGiaBaoFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_fast = !this.deliver_info.is_ship_giabao_fast;
    this.deliver_hour = this.deliver_info.date_ship_giabao_fast;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển bay nhanh';
  }

  onCheckShipGiaBaoSlow() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_slow = !this.deliver_info.is_ship_giabao_slow;
    this.deliver_hour = this.deliver_info.date_ship_giabao_slow;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển bay chậm';
  }

  onCheckShipGiaBaoShip() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_ship = !this.deliver_info.is_ship_giabao_ship;
    this.deliver_hour = this.deliver_info.date_ship_giabao_ship;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipOther() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_other = !this.deliver_info.is_ship_other;
    this.deliver_hour = this.deliver_info.date_ship_other;
    this.deliver_type = 'Khác';
  }

  onChangeShipOther(ev: any) {
    this.deliver_info.date_ship_other = Number(ev);
    this.deliver_hour = this.deliver_info.date_ship_other;
  }


  setDefaultDeliverInfo() {
    this.deliver_info = {
      is_direct_deliver: false,
      is_ship_internal: false,
      is_ship_external: false,
      is_ship_viettel_fast: false,
      is_ship_viettel_v60: false,
      is_ship_viettel_normal: false,
      is_ship_nhattin_fast: false,
      is_ship_nhattin_mes: false,
      is_ship_nhattin_normal: false,
      is_ship_minhtrieu: false,
      is_ship_aal_express: false,
      is_ship_aal_fast: false,
      is_ship_aal_ship: false,
      is_ship_giabao_fast: false,
      is_ship_giabao_slow: false,
      is_ship_giabao_ship: false,
      is_ship_other: false,
      date_direct_deliver: 0,
      date_ship_internal: 24,
      date_ship_external: 48,
      date_ship_viettel_fast: 84,
      date_ship_viettel_v60: 108,
      date_ship_viettel_normal: 156,
      date_ship_nhattin_fast: 60,
      date_ship_nhattin_mes: 84,
      date_ship_nhattin_normal: 156,
      date_ship_minhtrieu: 180,
      date_ship_aal_express: 36,
      date_ship_aal_fast: 36,
      date_ship_aal_ship: 156,
      date_ship_giabao_fast: 60,
      date_ship_giabao_slow: 84,
      date_ship_giabao_ship: 156,
      date_ship_other: '',
      uid: this.deliver_info.uid ? this.deliver_info.uid : ''
    };
  }
}
