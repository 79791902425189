import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  constructor(private navCtrl: NavController, private auth: AngularFireAuth) {

  }
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {


    return from(this.auth.authState).pipe(
      map(user => !!user),
      take(1),
      tap(allowed => {
        if (!allowed) {
          this.navCtrl.navigateRoot('login');
        }
      })
    );
  }
}
