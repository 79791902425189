import { FIREBASE_STRUCT } from '../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  authInfo: any;
  menu_actions: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public navCtrl: NavController,
    public fs: AngularFirestore,
    public auth: AngularFireAuth
  ) {
    this.getMenuActions().subscribe(menu_actions => {
      if (menu_actions.length) {
        this.menu_actions = menu_actions[0];
      } else {
        this.menu_actions = {};
      }
    });

    this.getUserInfo().subscribe(u => {
      this.authInfo = u;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /* onClick(id) {
    $(`.collapse.show:not(".${id}")`).removeClass('show');
  } */

  getMenuActions() {
    const uid = this.auth.auth.currentUser ? this.auth.auth.currentUser.uid : null;
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      switchMap(userAdmin => {
        return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(userAdmin.payload.data().role_uid).collection('menu_actions').snapshotChanges().pipe(
          map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getUserInfo() {
    const uid = this.auth.auth.currentUser ? this.auth.auth.currentUser.uid : null;
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(`${uid}`).snapshotChanges().pipe(
      switchMap(sn => {
        return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc<any>(`${sn.payload.data().role_uid}`).snapshotChanges().pipe(
          map(_sn => ({ uid, ...sn.payload.data(), role_name: _sn.payload.get('name') })),
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }

}
