import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { CalculateFeeService } from './../../services/calculate-fee/calculate-fee.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomConfirm, CustomLoading, FIREBASE_STRUCT, isNullOrEmpty } from '../../app.constant';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../services/roles/roles.service';
import { Decimal } from '../../app.models';

@Component({
  selector: 'app-order-extra-fee',
  templateUrl: './order-extra-fee.component.html',
  styleUrls: ['./order-extra-fee.component.css']
})
export class OrderExtraFeeComponent implements OnInit, OnDestroy {
  temps_extra = [];
  unsubscribe$ = new Subject();
  uid: string;
  extra_fees: any[];
  order: any;
  user_login: any;
  list_roles: any[] = [];
  user_role: any = {};
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public calcFeeService: CalculateFeeService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getExtraFee(this.uid).subscribe(extra => {
      this.extra_fees = extra;
    });

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });

    this.getOrders(this.uid).subscribe(async (o) => {
      this.order = o;
      this.user_role = await rolesService.getRoles(this.order.order_status_key);
    });

  }

  ngOnInit() {
    this.temps_extra = [];
    const temps = {
      name: null,
      value: null,
      note: null,
      created_date: 'Tự động',
      created_user: 'Tự động',
      type: 1
    };
    this.temps_extra.push(temps);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getExtraFee(uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(uid).collection(FIREBASE_STRUCT.EXTRA_FEES.NODE).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getOrders(uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
    );
  }

  @CustomLoading()
  async addExtra(temp) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      if (isNullOrEmpty(temp.name) || isNullOrEmpty(temp.value)) {
         throw new Error('Chưa nhập đủ dữ liệu cần thiết');
      }

      temp.disabled = false;
      let order_extra_fee = this.order.order_extra_fee || 0;
      temp.created_user = this.user_login.full_name;
      temp.created_date = await this.commonService.getServerTime();

      order_extra_fee = new Decimal(order_extra_fee).add(temp.value).toNumber();
      const order_total_price = new Decimal(this.order.order_total_price).add(temp.value).toNumber();
      const order_deposit = this.order.order_deposit || 0;
      const order_paid = this.order.order_paid || 0;
      const order_refund = this.order.order_refund || 0;
      const order_lack_of_paid = new Decimal(order_total_price).sub(order_deposit).sub(order_paid).add(order_refund).toNumber();

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.EXTRA_FEES.NODE).doc(), temp);
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), {
        order_extra_fee,
        order_total_price,
        order_lack_of_paid
      });

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Tạo mới phí phát sinh',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: this.order.order_extra_fee || 0,
        new_value: order_extra_fee
      });

      await batch.commit();

      this.temps_extra = [];
      const temps = {
        name: null,
        value: null,
        note: null,
        created_date: 'Tự động',
        created_user: 'Tự động'
      };
      this.temps_extra.push(temps);
      return 'Thêm mới thành công';
    } catch (error) {
      throw error;
    }
  }

  @CustomLoading()
  async onUpdateExtraFee(temp) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      if (!temp.disabled) {
        if (isNullOrEmpty(temp.name) || isNullOrEmpty(temp.value)) { throw new Error('Chưa nhập đủ dữ liệu cần thiết'); }

        const order_price = this.order.order_price || 0;
        const order_ship_price = this.order.order_ship_price || 0;
        const order_service_fee = this.order.order_service_fee || 0;
        const order_transport_fee = this.order.order_transport_fee || 0;
        const order_deposit = this.order.order_deposit || 0;
        const order_paid = this.order.order_paid || 0;
        const order_refund = this.order.order_refund || 0;

        temp.created_user = this.user_login.full_name;

        let order_extra_fee = 0;
        this.extra_fees.forEach(element => {
          order_extra_fee = new Decimal(order_extra_fee).add(element.value).toNumber();
        });
        const order_total_price = new Decimal(order_price)
          .add(order_extra_fee)
          .add(order_ship_price)
          .add(order_service_fee)
          .add(order_transport_fee)
          .toNumber();

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.EXTRA_FEES.NODE).doc(temp.uid), temp);
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), {
          order_extra_fee,
          order_total_price,
          order_lack_of_paid: new Decimal(order_total_price).sub(order_deposit).sub(order_paid).add(order_refund).toNumber()
        });

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
          element: 'Cập nhật phí phát sinh',
          date: await this.commonService.getServerTime(),
          user: this.user_login.full_name,
          old_value: this.order.order_extra_fee || 0,
          new_value: order_extra_fee
        });

        await batch.commit();

        return 'Chỉnh sửa thành công';
      }
    } catch (error) {
      throw error;
    }

  }

  @CustomConfirm('Bạn muốn xóa phí này?')
  async onDelete(temp) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      const order_price = this.order.order_price || 0;
      const order_ship_price = this.order.order_ship_price || 0;
      const order_service_fee = this.order.order_service_fee || 0;
      const order_transport_fee = this.order.order_transport_fee || 0;
      const order_deposit = this.order.order_deposit || 0;
      const order_paid = this.order.order_paid || 0;
      const order_refund = this.order.order_refund || 0;

      temp.created_user = this.user_login.full_name;

      let order_extra_fee = 0;
      this.extra_fees.forEach(element => {
        order_extra_fee = new Decimal(order_extra_fee).add(element.value).toNumber();
      });
      order_extra_fee = new Decimal(order_extra_fee).sub(temp.value).toNumber();
      const order_total_price = new Decimal(order_price)
        .add(order_extra_fee)
        .add(order_ship_price)
        .add(order_service_fee)
        .add(order_transport_fee)
        .toNumber();

      batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('extra_fees').doc(temp.uid));
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), {
        order_extra_fee,
        order_total_price,
        order_lack_of_paid: new Decimal(order_total_price).sub(order_deposit).sub(order_paid).add(order_refund).toNumber()
      });

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Xóa phí phát sinh',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: this.order.order_extra_fee,
        new_value: order_extra_fee
      });

      await batch.commit();

      return 'Thực hiện xóa thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi thực hiện xóa');
    }

  }
}
