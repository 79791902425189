import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() { }

  public exportExcel(header: any[], dataExport: any[], fileName: string, currentDateTime, params?: any) {
    const workbook = new ExcelJS.Workbook();
    const sheetName = (params && params.sheet_name) ? params.sheet_name : 'Export Data';
    const worksheet = workbook.addWorksheet(sheetName);

    if (header) {
      // Add header
      const headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00FFFFFF' }
        };

        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        cell.font = { bold: true };
      });
    }

    // Add data
    dataExport.forEach(data => {
      const row = worksheet.addRow(data);

      row.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00FFFFFF' }
        };

        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    if (params && params.col_width) {
      for (let i = 0; i < params.col_width.length; i++) {
        worksheet.getColumn(i + 1).width = params.col_width[i];
      }
    }

    fileName += '_' + this.convertDateToString(currentDateTime) + EXCEL_EXTENSION;

    return workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, fileName);
    });
  }

  public exportCustomers(header: any[], dataExport: any[], fileName: string, currentDateTime, params?: any) {
    const workbook = new ExcelJS.Workbook();
    const sheetName = (params && params.sheet_name) ? params.sheet_name : 'Export Data';
    const worksheet = workbook.addWorksheet(sheetName);

    if (header) {
      // Add header
      const headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00FFFFFF' }
        };

        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        cell.font = { bold: true };
      });
    }

    // Add data
    dataExport.forEach(data => {
      const row = worksheet.addRow(data);
      if (data[28] === 'Yes') {
        row.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF6347' },
            bgColor: { argb: '00FFFFFF' }
          };

          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
      } else {
        row.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' }
          };

          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
      }
    });

    if (params && params.col_width) {
      for (let i = 0; i < params.col_width.length; i++) {
        worksheet.getColumn(i + 1).width = params.col_width[i];
      }
    }

    fileName += '_' + this.convertDateToString(currentDateTime) + EXCEL_EXTENSION;

    return workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, fileName);
    });
  }

  public exportExcelByTable(tableId: string, fileName: string, currentDateTime, wscols?: any) {
    let sheetName;
    const table = document.getElementById(tableId);
    const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, { raw: true });
    if (wscols) { workSheet['!cols'] = wscols; }
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    sheetName = 'Data';

    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);

    fileName += '_' + this.convertDateToString(currentDateTime) + EXCEL_EXTENSION;
    XLSX.writeFile(workBook, fileName, { type: 'base64', cellStyles: true });
  }

  /* public exportAsExcelFile(json: any[], excelFileName: string, currentDateTime): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName, currentDateTime);
  }

  private saveAsExcelFile(buffer: any, fileName: string, currentDateTime): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + currentDateTime + EXCEL_EXTENSION);
  } */

  public processFileToJson(file: File, header?): Promise<any> {
    const reader = new FileReader();
    const object: any = {};
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        const data = e.target.result;

        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        object.sheets = this.parseWorksheet(workbook, true, true, header);
        resolve(object);
      };
      reader.readAsBinaryString(file);
    });
  }

  private parseWorksheet(workbook, readCells, toJSON, header?) {
    if (toJSON === true) {
      return this.to_json(workbook, header);
    }
    const sheets = {};
    _.forEachRight(workbook.SheetNames, (sheetName) => {
      const sheet = workbook.Sheets[sheetName];
      sheets[sheetName] = this.parseSheet(sheet, readCells);
    });
    return sheets;
  }

  private parseSheet(sheet, readCells) {
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const sheetData = [];

    if (readCells === true) {
      _.forEachRight(_.range(range.s.r, range.e.r + 1), (row) => {
        const rowData = [];
        _.forEachRight(_.range(range.s.c, range.e.c + 1), (column) => {
          const cellIndex = XLSX.utils.encode_cell({
            c: column,
            r: row
          });
          const cell = sheet[cellIndex];
          rowData[column] = cell ? cell.v : undefined;
        });
        sheetData[row] = rowData;
      });
    }

    return {
      sheet: sheetData,
      name: sheet.name,
      col_size: range.e.c + 1,
      row_size: range.e.r + 1
    };
  }

  private to_json(workbook, header?: string[]) {
    const result = {};
    workbook.SheetNames.forEach((sheetName) => {
      const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header, });
      if (roa.length > 0) {
        result[sheetName] = roa;
      }
    });
    return result;
  }

  private convertDateToString(time: number) {
    const dateConvert = new Date(time);
    return dateConvert.getFullYear()
      + '_' + (dateConvert.getMonth() + 1)
      + '_' + dateConvert.getDay()
      + '_' + dateConvert.getHours()
      + '_' + dateConvert.getMinutes() + '_'
      + dateConvert.getSeconds();
  }

  public exportAsExcelFile(header: any[], json: any[], excelFileName: string, colWidths: number[]) {
    const items = [...json];
    items.unshift(header);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(items, { skipHeader: true, cellStyles: true });
    const wscols = colWidths.map(width => {
      return { width };
    });
    worksheet['!cols'] = wscols;

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_' + this.convertDateToString(Date.now()) + EXCEL_EXTENSION);
  }

}
