import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { takeUntil } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-sale-verify-customer-info',
  templateUrl: './sale-verify-customer-info.component.html',
  styleUrls: ['./sale-verify-customer-info.component.css']
})
export class SaleVerifyCustomerInfoComponent implements OnInit, OnDestroy {
  componentProps = {
    customerInfo: {
      email: '',
      phone: ''
    }
  };
  model: any = {
    email: '',
    phoneNumber: ''
  };
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.componentProps.customerInfo = this.navParams.data.modal.componentProps.customerInfo;
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  public async onVerifyCustomerInfo() {
    const _model = { ...this.model };
    if (this.componentProps.customerInfo.email === this.model.email
      && this.componentProps.customerInfo.phone === this._convertPhoneNumberVN(this.model.phoneNumber)) {
      const fs = firestore();
      const batch = fs.batch();

      const currentAccountUid = this.auth.auth.currentUser ? this.auth.auth.currentUser.uid : null;
      if (currentAccountUid) {
        const _currentAccount: any = await fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(currentAccountUid).get().then(doc => {
          return { uid: doc.id, ...doc.data() };
        });

        const _customer =  await fs.collection(FIREBASE_STRUCT.USERS.NODE).where('email', '==', _model.email).get().then(actions => {
          const ret = [];

          actions.forEach(doc => {
            ret.push({
              uid: doc.id,
              ...doc.data()
            });
          });

          if (ret.length) { return ret[0]; }
          return null;
        });

        const ownedUidAdminUsers = _customer.owned_uid_admin_users || [];
        ownedUidAdminUsers.push(_currentAccount.uid);
        console.log(_customer);
        if (_customer) {
          batch.update(fs.collection(FIREBASE_STRUCT.USERS.NODE).doc(_customer.uid), {
            exist_sales_online: true,
            sales_online_uid: _currentAccount.uid,
            sales_online: { ..._currentAccount },
            owned_uid_admin_users: ownedUidAdminUsers
          });
        }

      } else {
        throw new Error('Tài khoản đăng nhập không hợp lệ');
      }

      /* await batch.commit();
      this,this.modalCtrl.dismiss(); */

      return 'Xác thực thông tin khách hàng thành công';
    } else {
      return 'Xác thực thông tin khách hàng không thành công';
    }
  }

  private _convertPhoneNumberVN(phoneNumber) {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'VN');
    return phoneUtil.format(number, PhoneNumberFormat.E164);
  } catch (error) {
    return phoneNumber;
  }
}

}
