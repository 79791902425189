import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { FIREBASE_STRUCT } from '../../app.constant';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.css']
})
export class PromotionListComponent implements OnDestroy, OnInit {
  uid: any;
  branchUid: any;

  users: any[];
  unsubscribe = new Subject();
  constructor(
    public navParams: NavParams,
    public fs: AngularFirestore,
    public modalCtrl: ModalController
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
    this.getPromotionUsers(this.uid).subscribe(users => {
      this.users = users;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onDismiss() {
    return this.modalCtrl.dismiss();
  }

  onExport() {

  }

  getPromotionUsers(promotion_uid) {
    return this.fs.collection(FIREBASE_STRUCT.PROMOTION.NODE).doc(promotion_uid).collection('users').valueChanges().pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
