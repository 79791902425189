import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { CONSIGNMENT_INTERNAL_STATUS, CONSIGNMENT_STATUS, CustomConfirm, CustomLoading, FIREBASE_STRUCT, ORDER_INTERNAL_STATUS, SG_RECEIVE_WAREHOUSE, validObject } from '../../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Decimal } from '../../../app.models';
import { firestore } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-barcode-reader-input-transports-add-or-edit',
  templateUrl: './barcode-reader-input-transports-add-or-edit.component.html',
  styleUrls: ['./barcode-reader-input-transports-add-or-edit.component.css']
})
export class BarcodeReaderInputTransportsAddOrEditComponent implements OnDestroy, OnInit {
  model: any = {
    currentDateTime: '',
    bagCode: null,
    transportCode: '',
    lastTransportCode: ''
  };
  componentProps = {
    uid: ''
  };
  transports: any[] = [];
  bag: any = {};
  notExistInTransports: any[] = [];
  isExistInTransports = false;

  totalBags: any[] = [];
  startAtBag = new Subject();
  endAtBag = new Subject();
  startAtBagObs = this.startAtBag.asObservable();
  endAtBagObs = this.endAtBag.asObservable();
  searchColumnBag;

  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService,
    public apiService: ApiService
  ) {
    this.model.currentDateTime = this._getCurrentDateTime();
    this.componentProps.uid = this.navParams.data.modal.componentProps.uid;
    console.log(this.componentProps.uid);
  }

  @CustomLoading()
  async ngOnInit() {
    if (this.componentProps.uid) {
      const _history = await this._getHistory(this.componentProps.uid);
      this.model = _history.model;
      this.transports = _history.transports;
      this.bag = _history.bag;
      this.notExistInTransports = _history.notExistInTransports;
    }

    combineLatest([this.startAtBagObs, this.endAtBagObs]).subscribe((value) => {
      this._searchBags(value[0], value[1], this.searchColumnBag).subscribe((bags) => {
        this.totalBags = bags;
      });
    });

    if (!this.searchColumnBag) {
      this._searchBags(null, null, '').subscribe((bags) => {
        this.totalBags = bags;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  public async onSearchTransports() {
    const fs = firestore();
    const _model = { ...this.model };

    const transports = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).where('bag_code', '==', _model.bagCode).get().then(_transports => {
      const ret = [];
      _transports.forEach(_transport => {
        ret.push({ uid: _transport.id, ..._transport.data(), checked: false, isAllowReceiveTransport: false });
      });
      return ret;
    });

    const bag = await fs.collection(FIREBASE_STRUCT.BAGS.NODE).where('bag_code', '==', _model.bagCode).get().then(_bags => {
      const ret = [];
      _bags.forEach(_bag => {
        ret.push({ uid: _bag.id, ..._bag.data() });
      });
      if (ret.length) { return ret[0]; }
      return {};
    });

    this.transports = transports;
    this.bag = bag;
  }

  public onCheckReceiveTransportCode() {
    if (this.model.transportCode.trim() !== '') {
      let _isExistInTransports = false;
      this.isExistInTransports = false;
      for (let i = 0; i < this.transports.length; i++) {
        const transport = this.transports[i];
        if (transport.transport_code === this.model.transportCode) {
          transport.isAllowReceiveTransport = this._isAllowReceiveTransport(transport);
          transport.checked = transport.isAllowReceiveTransport;
          _isExistInTransports = true;
          this._arrayMove(this.transports, i, 0);
          this.isExistInTransports = true;
          break;
        }
      }

      if (!_isExistInTransports) {
        this.notExistInTransports.push({
          transport_code: this.model.transportCode
        });

        this._arrayMove(this.notExistInTransports, this.notExistInTransports.length - 1, 0);
      }

      this.model.lastTransportCode = this.model.transportCode;
      this.model.transportCode = '';
    }
  }

  @CustomLoading()
  public async onCaculateExchangeKg(transport) {
    const weightConversionFactor = await this._getWeightConversionFactor(transport);

    transport.transport_exchanged_kg = new Decimal(transport.transport_length)
      .mul(transport.transport_width)
      .mul(transport.transport_height)
      .div(weightConversionFactor)
      .roundUp(2)
      .toNumber();

    const fs = firestore();
    const batch = fs.batch();

    batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
      transport_exchanged_kg: transport.transport_exchanged_kg
    });

    await batch.commit();
  }

  public async onChangedTransport(transport) {
    const fs = firestore();
    const batch = fs.batch();

    batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
      transport_product_quantity: transport.transport_product_quantity,
      tq_note: transport.tq_note || ''
    });
  }

  @CustomLoading()
  public async onTransportRemarks(transport) {
    const fs = firestore();
    const batch = fs.batch();

    batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
      remarks: transport.remarks || ''
    });

    await batch.commit();

    return 'Cập nhật ghi chú thành công';
  }

  @CustomConfirm('Xác nhận nhận hết mã vận đơn đã chọn?')
  public async onReceiveAllTransports() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      for (const tran of this.transports) {
        if (tran.checked) {
          if (tran.bag_code.startsWith(SG_RECEIVE_WAREHOUSE.CODE)) {
            await this._receiveTransportSG(tran, batch);
          } else {
            await this._receiveTransportHN(tran, batch);
          }
        }
      }
      await batch.commit();

      return 'Nhận tất cả mã vận đơn đã chọn thành công';
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Xác nhận nhận mã vận đơn này?')
  public async onReceiveTransport(transport) {
    let isReceiveHN = false;
    if (this.model.bagCode.substring(0, 2) === 'BT') {
      isReceiveHN = true;
    }

    const fs = firestore();
    const batch = fs.batch();
    if (isReceiveHN) {
      await this._receiveTransportHN(transport, batch);
    } else {
      await this._receiveTransportSG(transport, batch);
    }

    await batch.commit();

    return 'Nhận mã vận đơn thành công';
  }

  @CustomLoading()
  public async onAddNewHistory() {
    const fs = firestore();
    const batch = fs.batch();
    const _currentDateTime = await this.commonService.getServerTime();

    batch.set(fs.collection(FIREBASE_STRUCT.BARCODE_READER_INPUT_TRANSPORTS_HISTORIES.NODE).doc(), {
      input_date: this.model.currentDateTime,
      bag_code: this.model.bagCode,
      created_by: this.auth.auth.currentUser.displayName,
      created_at: _currentDateTime,
      updated_by: this.auth.auth.currentUser.displayName,
      updated_at: _currentDateTime,
      model: this.model,
      transports: this.transports,
      bag: this.bag,
      notExistInTransports: this.notExistInTransports
    });

    await batch.commit();

    return 'Thêm mới lịch sử nhập hàng thành công';
  }

  @CustomLoading()
  public async onUpdateHistory() {
    const fs = firestore();
    const batch = fs.batch();
    const _currentDateTime = await this.commonService.getServerTime();

    const uid = this.componentProps.uid;
    batch.update(fs.collection(FIREBASE_STRUCT.BARCODE_READER_INPUT_TRANSPORTS_HISTORIES.NODE).doc(uid), {
      input_date: this.model.currentDateTime,
      bag_code: this.model.bagCode,
      updated_by: this.auth.auth.currentUser.displayName,
      updated_at: _currentDateTime,
      model: this.model,
      transports: this.transports,
      notExistInTransports: this.notExistInTransports
    });

    await batch.commit();

    return 'Cập nhật lịch sử nhập hàng thành công';
  }

  @CustomLoading()
  public async onSyncAllTransports() {
    const fs = firestore();
    const batch = fs.batch();
    const _model = { ...this.model };
    const _currentDateTime = await this.commonService.getServerTime();

    const syncTransports = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).where('bag_code', '==', _model.bagCode).get().then(_transports => {
      const ret = [];
      _transports.forEach(_transport => {
        ret.push({ uid: _transport.id, ..._transport.data(), checked: false, isAllowReceiveTransport: false });
      });
      return ret;
    });

    for (const syncTransport of syncTransports) {
      for (const transport of this.transports) {
        if (syncTransport.transport_uid === transport.transport_uid) {
          syncTransport.checked = transport.checked;
          syncTransport.isAllowReceiveTransport = this._isAllowReceiveTransport(transport);
          break;
        }
      }
    }

    for (let i = 0; i < this.notExistInTransports.length; i++) {
      const notExistInTransport = this.notExistInTransports[i];
      for (const syncTransport of syncTransports) {
        if (notExistInTransport.transport_code === syncTransport.transport_code) {
          this.notExistInTransports.splice(i, 1);
          i--;
          break;
        }
      }
    }

    this.transports = syncTransports;

    const uid = this.componentProps.uid;
    batch.update(fs.collection(FIREBASE_STRUCT.BARCODE_READER_INPUT_TRANSPORTS_HISTORIES.NODE).doc(uid), {
      input_date: this.model.currentDateTime,
      bag_code: this.model.bagCode,
      updated_by: this.auth.auth.currentUser.displayName,
      updated_at: _currentDateTime,
      model: this.model,
      transports: this.transports,
      bag: this.bag,
      notExistInTransports: this.notExistInTransports
    });

    await batch.commit();

    return 'Đồng bộ lịch sử nhập hàng thành công';
  }

  @CustomLoading()
  public async onEditBag() {
    const bag = { ...this.bag };
    const httpOptions = {
      headers: new HttpHeaders({
        skipauthorization: 'true'
      })
    };

    const data: any = {};
    data.bag = bag;
    await this.apiService.post('admin/warehouses/updateBagInfo', data, httpOptions);

    return 'Cập nhật bao thành công';
  }

  public onSearchBag($event, searchColumnBag) {
    let q = $event.target.value;
    if (isNaN(+q)) {
      searchColumnBag = 'bag_code';
      q = q.toUpperCase();
    }
    this.searchColumnBag = searchColumnBag;
    this.startAtBag.next(q);
    this.endAtBag.next(q + '\uf8ff');
  }

  private async _getHistory(uid) {
    return this.fs.collection<any>(FIREBASE_STRUCT.BARCODE_READER_INPUT_TRANSPORTS_HISTORIES.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(doc => ({ exists: doc.payload.exists, uid: doc.payload.id, ...doc.payload.data() })),
      first()
    ).toPromise();
  }

  private _getCurrentDateTime() {
    const _currentDateTime = new Date();
    const year = _currentDateTime.getFullYear();
    const month = (_currentDateTime.getMonth() < 9) ? String('0' + (_currentDateTime.getMonth() + 1)) : String(_currentDateTime.getMonth() + 1);
    const day = (_currentDateTime.getDate() < 10) ? String('0' + _currentDateTime.getDate()) : String(_currentDateTime.getDate());

    return year + '-' + month + '-' + day;
  }

  private async _getWeightConversionFactor(transport) {
    const fs = firestore();

    let isConsignmentOrder = false;

    if (transport.consignments && transport.consignments.length) {
      isConsignmentOrder = true;
    }

    if (isConsignmentOrder) {
      const consignmentOrder = await fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(transport.consignments[0].consignment_uid).get().then(_consignmentOrder => {
        return { ..._consignmentOrder.data() };
      });

      const transportConverterParams = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_CONVERTER.NODE).orderBy('application_start_date_text', 'desc').get().then(colection => {
        const ret = [];
        colection.forEach(doc => {
          ret.push({ ...doc.data() });
        });

        return ret;
      });

      let weightConversionFactor = 0;
      if (!consignmentOrder.transport_date_created) {
        weightConversionFactor = Number(transportConverterParams[0].parameters[1].value);
      } else {
        for (const transportConverterParam of transportConverterParams) {
          if (consignmentOrder.transport_date_created > new Date(transportConverterParam.application_start_date_text).getTime()) {
            weightConversionFactor = Number(transportConverterParam.parameters[1].value);
            break;
          }
        }
      }

      return weightConversionFactor;
    } else {
      if (transport.orders && transport.orders.length) {
        const order = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(transport.orders[0].order_uid).get().then(_order => {
          return { ..._order.data() };
        });

        const weightConversionFactor = order.normal_param ? order.normal_param.hesoquydoicongthuccannang || 7000 : 7000;

        return Number(weightConversionFactor);
      } else {
        const weightConversionFactor = await fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').get().then(doc => {
          return doc.get('value');
        });

        return Number(weightConversionFactor);
      }
    }
  }

  private _isAllowReceiveTransport(transport) {
    let isReceiveHN = false;
    if (this.model.bagCode.substring(0, 2) === 'BT') {
      isReceiveHN = true;
    }

    if (isReceiveHN) {
      if (!transport.date_receive_hn_vn && transport.date_deliver_vn && transport.bag_code && transport.bag_code.substring(0, 2) === 'BT') {
        return true;
      }
    } else {
      if (!transport.date_receive_sg_vn && transport.date_deliver_hn_sg && transport.bag_code && transport.bag_code.substring(0, 2) === 'SG') {
        return true;
      }
    }

    return false;
  }

  private async _receiveTransportHN(tran, batch: firebase.firestore.WriteBatch) {
    const fs = firestore();
    const transport_uid = tran.uid;
    const current = await this.commonService.getServerTime();
    batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport_uid), {
      date_receive_vn: current,
      date_receive_hn_vn: current,
      transport_receive_vn_flag: 1,
      transport_day_in_warehouse: 0
    });

    batch.set(fs.collection(FIREBASE_STRUCT.TRANSPORTS_AUTO_COUNT_DAY.NODE).doc(transport_uid), {
      count: true
    });

    if (tran.consignments.length) {
      for (const consignmentTran of tran.consignments) {
        if (await this._existConsignmentOrderByTransportCode(consignmentTran.consignment_uid, tran.transport_code)) {
          batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentTran.consignment_uid), {
            status_key: CONSIGNMENT_STATUS.DAVEKHO.KEY,
            status_text: CONSIGNMENT_STATUS.DAVEKHO.VALUE,
            consignment_order_internal_status_key: CONSIGNMENT_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.KEY,
            consignment_order_internal_status_text: CONSIGNMENT_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.VALUE,
            date_receive_vn: current,
            date_receive_hn_vn: current
          });
        } else {
          const deleteConsignmentTransportUids = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE)
            .where('consignment_code', '==', consignmentTran.consignment_code)
            .where('transport_code', '==', tran.transport_code)
            .get().then(actions => {
              const rs = [];
              actions.docs.forEach(doc => {
                rs.push(doc.id);
              });
              return rs;
            });
          for (const deleteConsignmentTransportUid of deleteConsignmentTransportUids) {
            batch.delete(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).doc(deleteConsignmentTransportUid));
          }
        }
      }
    }

    if (tran.orders.length) {
      for (const orderTran of tran.orders) {
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid).collection('transports').doc(orderTran.order_transport_uid),
          {
            date_receive_vn: current,
            date_receive_hn_vn: current,
            transport_receive_vn_flag: 1
          }
        );

        const _products = await this._getProductOrderByOrderUid(orderTran.order_uid);
        let orderProductQuantity = 0;
        let orderQuantityOrder = 0;
        let orderQuantityWarehouse = 0;
        for (const _product of _products) {
          orderProductQuantity = new Decimal(orderProductQuantity).add(_product.product_quantity).toNumber();
          orderQuantityOrder = new Decimal(orderQuantityOrder).add(_product.quantity_order).toNumber();
          orderQuantityWarehouse = new Decimal(orderQuantityWarehouse).add(_product.quantity_order).toNumber();

          batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid).collection('products').doc(_product.uid), {
            quantity_warehouse: _product.quantity_order
          });
        }

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid), {
          order_internal_status_key: ORDER_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.KEY,
          order_internal_status_text: ORDER_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.VALUE,
          order_product_quantity: orderProductQuantity,
          order_quantity_order: orderQuantityOrder,
          order_quantity_warehouse: orderQuantityWarehouse
        });
      }
    }

    tran.date_receive_vn = current;
    tran.date_receive_hn_vn = current;
    tran.transport_receive_vn_flag = 1;
    tran.transport_day_in_warehouse = 0;
    tran.isAllowReceiveTransport = false;
  }

  private async _receiveTransportSG(tran, batch: firebase.firestore.WriteBatch) {
    const fs = firestore();
    const transport_uid = String(tran.uid);
    const current = await this.commonService.getServerTime();
    batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport_uid), {
      date_receive_vn: current,
      date_receive_sg_vn: current,
      transport_receive_vn_flag: 1,
      transport_day_in_warehouse: 0
    });

    batch.set(fs.collection(FIREBASE_STRUCT.TRANSPORTS_AUTO_COUNT_DAY.NODE).doc(transport_uid), {
      count: true
    });

    if (tran.consignments.length) {
      for (const consignmentTran of tran.consignments) {
        if (await this._existConsignmentOrderByTransportCode(consignmentTran.consignment_uid, tran.transport_code)) {
          batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentTran.consignment_uid), {
            status_key: CONSIGNMENT_STATUS.DAVEKHO.KEY,
            status_text: CONSIGNMENT_STATUS.DAVEKHO.VALUE,
            consignment_order_internal_status_key: CONSIGNMENT_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.KEY,
            consignment_order_internal_status_text: CONSIGNMENT_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.VALUE,
            date_receive_vn: current,
            date_receive_sg_vn: current,
            transport_receive_vn_flag: 1
          });
        } else {
          const deleteConsignmentTransportUids = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE)
            .where('consignment_code', '==', consignmentTran.consignment_code)
            .where('transport_code', '==', tran.transport_code)
            .get().then(actions => {
              const rs = [];
              actions.docs.forEach(doc => {
                rs.push(doc.id);
              });
              return rs;
            });
          for (const deleteConsignmentTransportUid of deleteConsignmentTransportUids) {
            batch.delete(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).doc(deleteConsignmentTransportUid));
          }
        }
      }
    }

    if (tran.orders.length) {
      for (const orderTran of tran.orders) {
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid).collection('transports').doc(orderTran.order_transport_uid),
          {
            date_receive_vn: current,
            date_receive_sg_vn: current,
            transport_receive_vn_flag: 1
          }
        );

        const _products = await this._getProductOrderByOrderUid(orderTran.order_uid);
        let orderProductQuantity = 0;
        let orderQuantityOrder = 0;
        let orderQuantityWarehouse = 0;
        for (const _product of _products) {
          orderProductQuantity = new Decimal(orderProductQuantity).add(_product.product_quantity).toNumber();
          orderQuantityOrder = new Decimal(orderQuantityOrder).add(_product.quantity_order).toNumber();
          orderQuantityWarehouse = new Decimal(orderQuantityWarehouse).add(_product.quantity_order).toNumber();

          batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid).collection('products').doc(_product.uid), {
            quantity_warehouse: _product.quantity_order
          });
        }

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderTran.order_uid), {
          order_internal_status_key: ORDER_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.KEY,
          order_internal_status_text: ORDER_INTERNAL_STATUS.EXTERNAL_STATUS_CONTINUOUS.VALUE,
          order_product_quantity: orderProductQuantity,
          order_quantity_order: orderQuantityOrder,
          order_quantity_warehouse: orderQuantityWarehouse
        });
      }
    }

    tran.date_receive_vn = current;
    tran.date_receive_sg_vn = current;
    tran.transport_receive_vn_flag = 1;
    tran.transport_day_in_warehouse = 0;
    tran.isAllowReceiveTransport = false;
  }

  private async _existConsignmentOrderByTransportCode(consignmentUid: string, transportCode: string) {
    const fs = firestore();
    return fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentUid)
      .collection('transports')
      .where('transport_code', '==', transportCode).limit(1)
      .get().then(snapshot => !!snapshot.size);
  }

  private async _getProductOrderByOrderUid(orderUid): Promise<any[]> {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('products').snapshotChanges().pipe(
      map(actions => actions.map(action => ({ uid: action.payload.doc.id, ...action.payload.doc.data() }))),
      first()
    ).toPromise();
  }

  private _searchBags(start, end, searchColumnBag) {
    if (searchColumnBag) {
      return this.fs.collection(FIREBASE_STRUCT.BAGS.NODE, query => {
        return query.limit(10).orderBy(searchColumnBag).startAt(start).endAt(end);
      }).valueChanges();
    } else {
      return this.fs.collection(FIREBASE_STRUCT.BAGS.NODE, query => {
        return query.limit(10).orderBy('bag_code');
      }).valueChanges();
    }
  }

  private _arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
}
