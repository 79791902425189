import { CommonUtilsService } from './../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-action-role-menu',
  templateUrl: './action-role-menu.component.html',
  styleUrls: ['./action-role-menu.component.css']
})
export class ActionRoleMenuComponent implements OnDestroy, OnInit {

  actions: any[];
  check_all: any = {};
  menu_actions: any = {};
  unsubscribe$ = new Subject();
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService
  ) {
    const role_uid = this.navParams.data.modal.componentProps.role_uid;
    this.getMenuActions(role_uid).then((menu_actions: any[]) => {
      if (menu_actions.length) {
        this.menu_actions = menu_actions[0];
      } else {
        this.initMenuActionDefault();
      }
      this.refreshItemCheckAll();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const role_uid = this.navParams.data.modal.componentProps.role_uid;

      if (this.menu_actions.uid) {
        const updateObj = { ...this.menu_actions };
        updateObj.update_date = await this.commonService.getServerTime();
        delete updateObj.uid;
        batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('menu_actions').doc(this.menu_actions.uid), updateObj);
      } else {
        const insertObj = { ...this.menu_actions };
        insertObj.create_date = await this.commonService.getServerTime();
        delete insertObj.uid;
        batch.set(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('menu_actions').doc(), insertObj);
      }

      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getMenuActions(role_uid: string) {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid)
      .collection('menu_actions')
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  refreshItemCheckAll() {
    this.check_all.phongkinhdoanh = (
      this.menu_actions.quanlydonhang
      && this.menu_actions.quanlydonhangchuacoc
      && this.menu_actions.quanlydonkygui
      && this.menu_actions.quanlymahoadon
      && this.menu_actions.quanlyhoantien
      && this.menu_actions.taodonhangexcel
      && this.menu_actions.taodonhangngoai
      && this.menu_actions.tonghopkhieunai
      && this.menu_actions.yeucauxuat
      && this.menu_actions.yeucauxuatcpn
      && this.menu_actions.ketquaquayso
      && this.menu_actions.mavandondonhang
    );

    this.check_all.phongkho = (
      this.menu_actions.khobangtuong
      && this.menu_actions.khovietnam
      && this.menu_actions.taophieuxuatkho
      && this.menu_actions.danhsachphieuxuatkho
      && this.menu_actions.lichsunhaphang
    );

    this.check_all.phongketoan = (
      this.menu_actions.thongkegiaodich
      && this.menu_actions.vidientu
      && this.menu_actions.vidientusalesonline
      && this.menu_actions.danhsacchyeucauruttiencuasalesonline
      && this.menu_actions.hanmuctindung
      && this.menu_actions.tonghopdonhang
      && this.menu_actions.tonghopdonkygui
      && this.menu_actions.tonghopdonhangcuasales
      && this.menu_actions.tonghopdonkyguicuasales
    );

    this.check_all.baodong = (
      this.menu_actions.donhangchuacomvd
      && this.menu_actions.donhangkhongcotrongluong
    );

    this.check_all.quanlykhachhang = (
      this.menu_actions.danhsachkhachhang
    );

    this.check_all.quanlysalesonline = (
      /* this.menu_actions.nhankhachhang
      && this.menu_actions.thongkecuasales
      &&  */this.menu_actions.phankhachhangchosales
      && this.menu_actions.thongkekhachhangcuasales
      && this.menu_actions.tonghopsales
      /* && this.menu_actions.quanlythunhap */
      && this.menu_actions.giamsathethongsales
    );

    this.check_all.quanlynoidung = (
      this.menu_actions.quanlythongbao
      && this.menu_actions.quanpypopup
      && this.menu_actions.quanlythongtinwebsite
      && this.menu_actions.quanlymenu
      && this.menu_actions.quanlyhinhanh
      && this.menu_actions.quanlymenu2
    );

    this.check_all.quanlythamso = (
      this.menu_actions.thamsodonhang
      && this.menu_actions.thamsodonkygui
      && this.menu_actions.thamsokhuyenmai
      && this.menu_actions.taikhoanvadiadiemgiaodich
      && this.menu_actions.capdokhachhang
      && this.menu_actions.salesonline
    );

    this.check_all.quanlynhansu = (
      this.menu_actions.quanlyquyen
      && this.menu_actions.quanlynguoidung
      && this.menu_actions.capdonguoidung
    );

    this.check_all.quanlycongviec = (
      this.menu_actions.cauhinhcongviec
      && this.menu_actions.danhsachcongviec
    );
  }

  initMenuActionDefault() {
    this.menu_actions = {
      uid: '',
      quanlydonhang: false,
      quanlydonhangchuacoc: false,
      quanlydonkygui: false,
      quanlymahoadon: false,
      quanlyhoantien: false,
      taodonhangexcel: false,
      taodonhangngoai: false,
      tonghopkhieunai: false,
      yeucauxuat: false,
      yeucauxuatcpn: false,
      ketquaquayso: false,
      mavandondonhang: false,
      khobangtuong: false,
      khovietnam: false,
      taophieuxuatkho: false,
      danhsachphieuxuatkho: false,
      lichsunhaphang: false,
      thongkegiaodich: false,
      vidientu: false,
      vidientusalesonline: false,
      danhsacchyeucauruttiencuasalesonline: false,
      hanmuctindung: false,
      tonghopdonhang: false,
      tonghopdonkygui: false,
      tonghopdonhangcuasales: false,
      tonghopdonkyguicuasales: false,
      donhangchuacomvd: false,
      donhangkhongcotrongluong: false,
      danhsachkhachhang: false,
      phankhachhangchosales: false,
      thongkekhachhangcuasales: false,
      tonghopsales: false,
      /* nhankhachhang: false, */
      /* thongkecuasales: false, */
      quanlythunhap: false,
      giamsathethongsales: false,
      quanlythongbao: false,
      quanpypopup: false,
      quanlythongtinwebsite: false,
      quanlymenu: false,
      quanlyhinhanh: false,
      quanlytrang: false,
      quanlybaiviet: false,
      quanlymenu2: false,
      thamsodonhang: false,
      thamsodonkygui: false,
      thamsokhuyenmai: false,
      taikhoanvadiadiemgiaodich: false,
      capdokhachhang: false,
      salesonline: false,
      quanlyquyen: false,
      quanlynguoidung: false,
      capdonguoidung: false,
      taikhoancanhan: false,
      thongke: false,
      capnhatdulieu: false,
      naptiensms: false
    };
  }

  onCheckAll() {
    this.check_all.phongkinhdoanh = this.check_all.check_all;
    this.check_all.phongkho = this.check_all.check_all;
    this.check_all.phongketoan = this.check_all.check_all;
    this.check_all.baodong = this.check_all.check_all;
    this.check_all.quanlykhachhang = this.check_all.check_all;
    this.check_all.quanlysalesonline = this.check_all.check_all;
    this.check_all.quanlynoidung = this.check_all.check_all;
    this.check_all.quanlythamso = this.check_all.check_all;
    this.check_all.quanlynhansu = this.check_all.check_all;
    this.check_all.quanlycongviec = this.check_all.check_all;
    this.menu_actions.quanlydonhang = this.check_all.check_all;
    this.menu_actions.quanlydonhangchuacoc = this.check_all.check_all;
    this.menu_actions.quanlydonkygui = this.check_all.check_all;
    this.menu_actions.quanlymahoadon = this.check_all.check_all;
    this.menu_actions.quanlyhoantien = this.check_all.check_all;
    this.menu_actions.taodonhangexcel = this.check_all.check_all;
    this.menu_actions.taodonhangngoai = this.check_all.check_all;
    this.menu_actions.tonghopkhieunai = this.check_all.check_all;
    this.menu_actions.yeucauxuat = this.check_all.check_all;
    this.menu_actions.yeucauxuatcpn = this.check_all.check_all;
    this.menu_actions.ketquaquayso = this.check_all.check_all;
    this.menu_actions.mavandondonhang = this.check_all.check_all;
    this.menu_actions.khobangtuong = this.check_all.check_all;
    this.menu_actions.khovietnam = this.check_all.check_all;
    this.menu_actions.taophieuxuatkho = this.check_all.check_all;
    this.menu_actions.danhsachphieuxuatkho = this.check_all.check_all;
    this.menu_actions.lichsunhaphang = this.check_all.check_all;
    this.menu_actions.thongkegiaodich = this.check_all.check_all;
    this.menu_actions.vidientu = this.check_all.check_all;
    this.menu_actions.vidientusalesonline = this.check_all.check_all;
    this.menu_actions.danhsacchyeucauruttiencuasalesonline = this.check_all.check_all;
    this.menu_actions.hanmuctindung = this.check_all.check_all;
    this.menu_actions.tonghopdonhang = this.check_all.check_all;
    this.menu_actions.tonghopdonkygui = this.check_all.check_all;
    this.menu_actions.tonghopdonhangcuasales = this.check_all.check_all;
    this.menu_actions.tonghopdonkyguicuasales = this.check_all.check_all;
    this.menu_actions.donhangchuacomvd = this.check_all.check_all;
    this.menu_actions.donhangkhongcotrongluong = this.check_all.check_all;
    this.menu_actions.danhsachkhachhang = this.check_all.check_all;
    this.menu_actions.phankhachhangchosales = this.check_all.check_all;
    this.menu_actions.thongkekhachhangcuasales = this.check_all.check_all;
    this.menu_actions.tonghopsales = this.check_all.check_all;
    /* this.menu_actions.nhankhachhang = this.check_all.check_all; */
    /* this.menu_actions.thongkecuasales = this.check_all.check_all; */
    /* this.menu_actions.quanlythunhap = this.check_all.check_all; */
    this.menu_actions.giamsathethongsales = this.check_all.check_all;
    this.menu_actions.quanlythongbao = this.check_all.check_all;
    this.menu_actions.quanpypopup = this.check_all.check_all;
    this.menu_actions.quanlythongtinwebsite = this.check_all.check_all;
    this.menu_actions.quanlymenu = this.check_all.check_all;
    this.menu_actions.quanlyhinhanh = this.check_all.check_all;
    this.menu_actions.quanlymenu2 = this.check_all.check_all;
    this.menu_actions.thamsodonhang = this.check_all.check_all;
    this.menu_actions.thamsodonkygui = this.check_all.check_all;
    this.menu_actions.thamsokhuyenmai = this.check_all.check_all;
    this.menu_actions.taikhoanvadiadiemgiaodich = this.check_all.check_all;
    this.menu_actions.capdokhachhang = this.check_all.check_all;
    this.menu_actions.salesonline = this.check_all.check_all;
    this.menu_actions.quanlyquyen = this.check_all.check_all;
    this.menu_actions.quanlynguoidung = this.check_all.check_all;
    this.menu_actions.capdonguoidung = this.check_all.check_all;
    this.menu_actions.taikhoancanhan = this.check_all.check_all;
    this.menu_actions.thongke = this.check_all.check_all;
    this.menu_actions.capnhatdulieu = this.check_all.check_all;
    this.menu_actions.naptiensms = this.check_all.check_all;
  }

  onCheckPhongKinhDoanh() {
    const checked = this.check_all.phongkinhdoanh;
    this.menu_actions.quanlydonhang = checked;
    this.menu_actions.quanlydonhangchuacoc = checked;
    this.menu_actions.quanlydonkygui = checked;
    this.menu_actions.quanlymahoadon = checked;
    this.menu_actions.quanlyhoantien = checked;
    this.menu_actions.taodonhangexcel = checked;
    this.menu_actions.taodonhangngoai = checked;
    this.menu_actions.tonghopkhieunai = checked;
    this.menu_actions.yeucauxuat = checked;
    this.menu_actions.yeucauxuatcpn = checked;
    this.menu_actions.ketquaquayso = checked;
    this.menu_actions.mavandondonhang = checked;
  }

  onCheckPhongKho() {
    const checked = this.check_all.phongkho;
    this.menu_actions.khobangtuong = checked;
    this.menu_actions.khovietnam = checked;
    this.menu_actions.taophieuxuatkho = checked;
    this.menu_actions.danhsachphieuxuatkho = checked;
    this.menu_actions.lichsunhaphang = checked;
  }

  onCheckPhongKeToan() {
    const checked = this.check_all.phongketoan;
    this.menu_actions.thongkegiaodich = checked;
    this.menu_actions.vidientu = checked;
    this.menu_actions.vidientusalesonline = checked;
    this.menu_actions.danhsacchyeucauruttiencuasalesonline = checked;
    this.menu_actions.hanmuctindung = checked;
    this.menu_actions.tonghopdonhang = checked;
    this.menu_actions.tonghopdonkygui = checked;
    this.menu_actions.tonghopdonhangcuasales = checked;
    this.menu_actions.tonghopdonkyguicuasales = checked;
  }

  onCheckBaoDong() {
    const checked = this.check_all.baodong;
    this.menu_actions.donhangchuacomvd = checked;
    this.menu_actions.donhangkhongcotrongluong = checked;
  }

  onCheckQuanLyKhachHang() {
    const checked = this.check_all.quanlykhachhang;
    this.menu_actions.danhsachkhachhang = checked;
  }

  onCheckQuanLySalesOnline() {
    const checked = this.check_all.quanlysalesonline;
    /* this.menu_actions.nhankhachhang = checked; */
    this.menu_actions.phankhachhangchosales = checked;
    this.menu_actions.thongkekhachhangcuasales = checked;
    this.menu_actions.tonghopsales = checked;
    /* this.menu_actions.thongkecuasales = checked; */
    /* this.menu_actions.quanlythunhap = checked; */
    this.menu_actions.giamsathethongsales = checked;
  }

  onCheckQuanLyNoiDung() {
    const checked = this.check_all.quanlynoidung;
    this.menu_actions.quanlythongbao = checked;
    this.menu_actions.quanpypopup = checked;
    this.menu_actions.quanlythongtinwebsite = checked;
    this.menu_actions.quanlymenu = checked;
    this.menu_actions.quanlyhinhanh = checked;
    this.menu_actions.quanlymenu2 = checked;
  }

  onCheckQuanLyThamSo() {
    const checked = this.check_all.quanlythamso;
    this.menu_actions.thamsodonhang = checked;
    this.menu_actions.thamsodonkygui = checked;
    this.menu_actions.thamsokhuyenmai = checked;
    this.menu_actions.taikhoanvadiadiemgiaodich = checked;
    this.menu_actions.capdokhachhang = checked;
    this.menu_actions.salesonline = checked;
  }

  onCheckQuanLyNhanSu() {
    const checked = this.check_all.quanlynhansu;
    this.menu_actions.quanlyquyen = checked;
    this.menu_actions.quanlynguoidung = checked;
    this.menu_actions.capdonguoidung = checked;
  }

  onCheckQuanLyCongViec() {
    const checked = this.check_all.quanlycongviec;
    this.menu_actions.cauhinhcongviec = checked;
    this.menu_actions.danhsachcongviec = checked;
  }

}
