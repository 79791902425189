import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { ModalController, NavParams } from '@ionic/angular';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { firestore } from 'firebase/app';

import {
  ACTION_URL,
  CustomLoading,
  FIREBASE_STRUCT,
  MAX_QUICK_ACTION_NUMBER
} from '../../app.constant';

import { CommonUtilsService } from '../../services/utils/common-utils.service';

@Component({
  selector: 'app-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.css'],
})
export class QuickActionComponent implements OnInit, OnDestroy {

  selectedQuickActions = [];
  user: any;
  actions: Observable <any[]>;
  unsubscribe$ = new Subject();
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService,
    private _auth: AngularFireAuth
  ) {}

  ngOnInit() {
    const userId = this._auth.auth.currentUser.uid;
    this.actions = this.getUser(userId)
      .pipe(
        switchMap((user: any) => {
          return this.getMenuActions(user.role_uid)
            .pipe(
              map((actionMaps: {[key: string]: any}[]) => {
                this.user = _.cloneDeep(user);
                this.selectedQuickActions = user.quick_actions || [];
                const allowedActions: {[key: string]: any}[] = [];
                actionMaps.forEach(actionMap => {
                  const listKey: {[key: string]: any}[] = [];
                  for (const key in actionMap) {
                    if (actionMap.hasOwnProperty(key) && actionMap[key] === true) {
                      listKey.push({ label: key });
                    }
                  }
                  allowedActions.push(...listKey);
                });
                return allowedActions.map(action => {
                  action.name = ACTION_URL[action.label].label;
                  return action;
                });
              })
            );
        }),
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onSelectCheckbox(event: any, label: string) {
    if (event.target.checked) {
      this.selectedQuickActions.push(label);
      if (this.selectedQuickActions.length > MAX_QUICK_ACTION_NUMBER) {
        this.selectedQuickActions.shift();
      }
    } else {
      this.selectedQuickActions = this.selectedQuickActions.filter(ele => ele !== label);
    }
  }

  getMenuActions(roleId: string): Observable < any > {
    return this.fs
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE)
      .doc(roleId)
      .collection('menu_actions')
      .valueChanges();
  }

  getUser(userId: string): Observable < any > {
    return this.fs
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .doc(userId)
      .valueChanges();
  }

  isChecked(key: string) {
    return this.selectedQuickActions.includes(key);
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      batch.update(
        fs
          .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
          .doc(this._auth.auth.currentUser.uid),
          { quick_actions : this.selectedQuickActions }
      );
      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
        console.log(error);
        throw error;
    }
  }
}
