import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Decimal } from 'src/app/app.models';
import firebase from 'firebase/app';
import { BRANCH_CONFIG } from 'src/app/constants/constant';

@Component({
  selector: 'app-order-param-service-fee',
  templateUrl: './order-param-service-fee.component.html',
  styleUrls: ['./order-param-service-fee.component.css']
})
export class OrderParamServiceFeeComponent implements OnInit, OnDestroy {
  uid: any;
  branchUid: any;

  param_service: any = {};
  unsubscribe$ = new Subject();
  param_percent_deposit: any[];
  service_fee_deposit_value: any[];

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  async ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;

    // Get param percent deposit
    this._getParamPercentDeposit().snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    ).subscribe(p => {
      this.param_percent_deposit = p;
    });

    if (this.uid !== '') {
      this.db.doc<any>(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.param_service = obj;
        if (this.branchUid === BRANCH_CONFIG.SOC_ORDER.UID || this.branchUid === BRANCH_CONFIG.PANDA_ORDER.UID) {
          this.param_percent_deposit = obj.fee_value_deposit;
        }
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();
      const fee_value_deposit = [...this.param_percent_deposit.map(({ uid, name, value, fee_value }) => {
        return { uid, name, value, fee_value };
      })];

      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}/${this.uid}`).update({
          ...this.param_service,
          fee_value_deposit: fee_value_deposit,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        await this.db.collection(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`).add({
          ...this.param_service,
          branchUid: this.branchUid,
          fee_value_deposit: fee_value_deposit,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      console.log(error)
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  private async _getServiceFeeParams() {
    const fs = firebase.firestore();
    return await fs.collection(FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE).where('branchUid', '==', 'lqB5AqKzNjT2LFrsfcaI').orderBy('create_date', 'asc').get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.data());
      });
      return rs;
    });
  }

  private _getParamPercentDeposit() {
    return this.db.collection<any>(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}`, q =>
      q.where('branchUid', '==', this.branchUid).orderBy('create_date', 'asc')
    );
  }
}
