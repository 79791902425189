import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomConfirm, FIREBASE_STRUCT, validObject } from '../../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { CalculateFeeService } from '../../../services/calculate-fee/calculate-fee.service';
import * as firebase from 'firebase/app';
import { Decimal } from '../../../app.models';

@Component({
  selector: 'app-transport-code-add',
  templateUrl: './transport-code-add.component.html',
  styleUrls: ['./transport-code-add.component.css']
})
export class TransportCodeAddComponent implements OnInit, OnDestroy {
  temp_transports: any[];
  weightConversionFactor = 0;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public calcFeeService: CalculateFeeService,
    public commonService: CommonUtilsService
  ) { }

  async ngOnInit() {
    this.temp_transports = [];

    for (let index = 0; index < 5; index++) {
      const temp_tran = {
        transport_code: null,
        transport_real_kg: null,
        transport_exchanged_kg: null,
        transport_length: null,
        transport_width: null,
        transport_height: null,
        transport_product_quantity: null,
        transport_product_type: null
      };
      this.temp_transports.push(temp_tran);
    }

    this.weightConversionFactor = Number(await this._getWeightConversionFactor());
  }

  ngOnDestroy() { }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  dataChanged(index) {
    this.temp_transports[index].transport_exchanged_kg = new Decimal(this.temp_transports[index].transport_length)
      .mul(this.temp_transports[index].transport_width)
      .mul(this.temp_transports[index].transport_height)
      .div(7000)
      .roundUp(2)
      .toNumber();
  }

  onAddTran() {
    for (let index = 0; index < 5; index++) {
      const temp_tran = {
        transport_code: null,
        transport_real_kg: null,
        transport_exchanged_kg: null,
        transport_length: null,
        transport_width: null,
        transport_height: null,
        transport_product_quantity: null,
        transport_product_type: null
      };
      this.temp_transports.push(temp_tran);
    }
  }

  checkTrans(transports) {
    let is_validated = true;
    let l = 0;
    transports.forEach(tran => {
      if (
        !(!tran.transport_code && !tran.transport_real_kg && !tran.transport_product_quantity && !tran.transport_product_type)
      ) {
        if (this.validTran(tran)) {
          l++;
        } else {
          is_validated = false;
        }
      }
    });
    return is_validated && l > 0;
  }

  validTran(tran) {
    if (
      (
        Number(tran.transport_length) > 0 ||
        Number(tran.transport_width) > 0 ||
        Number(tran.transport_height) > 0
      )
      && Number(tran.transport_exchanged_kg) === 0) {
      return false;
    } else {
      if (
        !tran.transport_code || !tran.transport_real_kg || !tran.transport_product_quantity || !tran.transport_product_type
      ) { return false; } else {
        return true;
      }
    }
  }

  onDeleteTran(index) {
    this.temp_transports.splice(index, 1);
  }

  @CustomConfirm('Xác nhận thêm các mã vận đơn này?')
  async onSubmit() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    try {
      for (const [index, tran] of this.temp_transports.entries()) {
        tran.transport_code = String(tran.transport_code || '').trim();
        if (this.validTran(tran)) {
          validObject(tran);
          const existTranInput = this.temp_transports.find((temp_tran, i) =>
            tran.transport_code === String(temp_tran.transport_code || '').trim() && index !== i);

          if (existTranInput) {
            throw new Error(`Mã vận đơn '${tran.transport_code}' bị trùng. Vui lòng nhập lại`);
          }
          const existTran = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
            .where('transport_code', '==', tran.transport_code).limit(1)
            .get().then(snapshot => snapshot.size);
          if (existTran) { throw new Error(`Mã vận đơn '${tran.transport_code}' đã tồn tại. Vui lòng nhập lại`); }
          tran.transport_cpc = false;
          tran.transport_date_created = current;
          tran.transport_price_mvd = 0;
          tran.transport_receive_vn_flag = 0;
          if (Number(tran.transport_length) > 0 &&
            Number(tran.transport_width) > 0 &&
            Number(tran.transport_height) > 0) {
            tran.transport_size = `${tran.transport_length}/${tran.transport_width}/${tran.transport_height}`;

            const order = await this._getOrderByTransportCode(tran.transport_code);
            if (order) {
              // Re-calc the transport exchange kg
              const newWeightConversionFactor = await this._getNewWeightConversionFactor(order);
              tran.transport_exchanged_kg = new Decimal(Number(tran.transport_length))
                .mul(Number(tran.transport_width))
                .mul(Number(tran.transport_height))
                .div(newWeightConversionFactor)
                .roundUp(2)
                .toNumber();
            }
          } else {
            tran.transport_size = '';
          }
          tran.is_bagging = false;
          /* if (Number(tran.transport_exchanged_kg || 0) > Number(tran.transport_real_kg)) {
            tran.transport_weight = new Decimal(tran.transport_exchanged_kg).roundUp(2).toNumber();
          } else {
            tran.transport_weight = new Decimal(tran.transport_real_kg).roundUp(2).toNumber();
          } */
          tran.transport_weight = this._roundTransportWeight(tran);

          const transportUid = fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc().id;
          tran.transport_uid = transportUid;

          batch.set(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transportUid), {
            ...tran,
            transport_date_release_customer: 0
          });
        }
      }

      await batch.commit();

      await this.modalCtrl.dismiss();

      return 'Thêm mã vận đơn thành công';
    } catch (error) {
      throw error;
    }

  }

  onNextElement(e) {
    if ((e.which === 13 || e.keyCode === 13)) {
      e.preventDefault();
      const elements = $('input');
      let i = 0;
      for (i = 0; i < elements.length; i++) {
        if (elements[i] === e.srcElement) {
          if (i + 1 < elements.length) {
            elements[i + 1].focus();
          }
        }
      }
    }
  }

  private async _getOrderByTransportCode(transportCode: string) {
    const fs = firebase.firestore();
    return await fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE)
      .where('transport_code', '==', transportCode).limit(1)
      .get().then(async snapshot => {
        if (snapshot.empty) return null;
        const orderUid = snapshot.docs[0].get('order_uid');
        return await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).get().then((doc) => {
          return ({
            ...doc.data()
          });
        })
      });
  }

  private _getWeightConversionFactor() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() as any })),
      first()
    ).toPromise();
  }

  private _roundTransportWeight(transport) {
    const transportRealKg = Number(transport.transport_real_kg || 0);
    const transportExchangedKg = Number(transport.transport_exchanged_kg || 0);
    let transport_weight = 0;
    if ((transportExchangedKg - transportRealKg) < 3) {
      transport_weight = transportRealKg;
    } else {
      transport_weight = transportRealKg >= transportExchangedKg ? transportRealKg : transportExchangedKg;
    }
    transport_weight = new Decimal(transport_weight).roundUp(2).toNumber();
    return transport_weight;
  }

  private async _getNewWeightConversionFactor(order) {
    const fs = firebase.firestore();

    const defaultWeightConversion = await fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').get().then(doc => {
      return doc.get('value');
    });

    const weightConversionFactor = order.normal_param ? order.normal_param.hesoquydoicongthuccannang || Number(defaultWeightConversion) : Number(defaultWeightConversion);
    return Number(weightConversionFactor);
  }
}
