import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { PARAMETER_VN_WAREHOUSE } from '../../../constants/order';
import { CalculateFeeService } from '../../../services/calculate-fee/calculate-fee.service';
import { Decimal } from '../../../app.models';

@Component({
  selector: 'app-bagging',
  templateUrl: './bagging.component.html',
  styleUrls: ['./bagging.component.css']
})
export class BaggingComponent implements OnInit, OnDestroy {
  item;
  trans: any[];
  selected_trans: any[] = [];
  bag_weight: number;
  unsubscribe$ = new Subject();
  is_bagging_sg = false;
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public calcFeeService: CalculateFeeService
  ) {
    this.is_bagging_sg = this.navParams.data.modal.componentProps.is_bagging_sg;

    this.getTrans().subscribe(trans => {
      this.trans = trans;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTrans() {
    return this.fs.collection<any>(FIREBASE_STRUCT.TRANSPORTS.NODE, q => q.where('is_bagging', '==', false)).snapshotChanges().pipe(
      map(actions => actions.map(action => ({ ...action.payload.doc.data(), uid: action.payload.doc.id }))),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Xác nhận đóng bao với các mã vận đơn này?')
  async onSubmit() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      const param = await this.getParameterVnWarehouse().pipe(first()).toPromise();
      const selected_trans = [...this.selected_trans];

      const id = await fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.BAG_UID).get().then(doc => {
        return Number(doc.get('id') || 0) + 1;
      });

      const bagUid = fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc().id;

      const tran_update: any = {};

      tran_update.is_bagging = true;
      tran_update.bag_code = !this.is_bagging_sg ? `BT_${id}_${this.bag_weight}` : `SG_${id}_${this.bag_weight}`;
      tran_update.bag_uid = bagUid;
      tran_update.bag_weight = Number(this.bag_weight);

      batch.set(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.BAG_UID), {
        id
      },
        { merge: true }
      );
      batch.set(fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc(bagUid),
        {
          is_bag_cpc: false,
          is_bagging: true,
          is_delivered: false,
          bag_weight: Number(this.bag_weight),
          transport_bag_fee: Number(param[PARAMETER_VN_WAREHOUSE.CUOCVANCHUYENBAO.KEY].value),
          extra_fee: Number(param[PARAMETER_VN_WAREHOUSE.PHIPHATSINH.KEY].value),
          motorbike_fee: Number(param[PARAMETER_VN_WAREHOUSE.CUOCXEOM.KEY].value),
          bag_uid: bagUid,
          bag_code: tran_update.bag_code,
          bag_id: `${id}`
        }
      );

      let weight = 0;
      this.selected_trans.forEach((tran) => {
        if (Number(tran.transport_exchanged_kg) > Number(tran.transport_real_kg)) {
          weight = new Decimal(weight).add(tran.transport_exchanged_kg).roundUp(2).toNumber();
        } else {
          weight = new Decimal(weight).add(tran.transport_real_kg).roundUp(2).toNumber();
        }
      });

      const transport_price_mvd: number = new Decimal(param[PARAMETER_VN_WAREHOUSE.CUOCVANCHUYENBAO.KEY].value)
        .mul(this.bag_weight)
        .add(param[PARAMETER_VN_WAREHOUSE.PHIPHATSINH.KEY].value)
        .add(param[PARAMETER_VN_WAREHOUSE.CUOCXEOM.KEY].value)
        .div(weight)
        .roundUp(0)
        .toNumber();
      tran_update.transport_price_mvd = transport_price_mvd;

      for (let j = 0; j < selected_trans.length; j++) {
        const tran = selected_trans[j];
        if (j === selected_trans.length - 1) {
          tran_update.transport_transport_bag_fee = Number(param[PARAMETER_VN_WAREHOUSE.CUOCVANCHUYENBAO.KEY].value);
          tran_update.transport_extra_fee = Number(param[PARAMETER_VN_WAREHOUSE.PHIPHATSINH.KEY].value);
          tran_update.transport_motorbike_fee = Number(param[PARAMETER_VN_WAREHOUSE.CUOCXEOM.KEY].value);
        } else {
          tran_update.transport_transport_bag_fee = 0;
          tran_update.transport_extra_fee = 0;
          tran_update.transport_motorbike_fee = 0;
        }
        batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), tran_update);

      }

      await batch.commit();

      await this.modalCtrl.dismiss();
      return 'Đóng bao thành công';
    } catch (error) {
      console.log(error);
      throw new Error('Có lỗi xảy ra khi đóng bao');
    }
  }

  getParameterVnWarehouse() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_VN_WAREHOUSE.NODE).snapshotChanges().pipe(
      map(actions => {
        const rs = {};
        actions.forEach(action => {
          rs[action.payload.doc.id] = action.payload.doc.data();
        });
        return rs;
      }),
      takeUntil(this.unsubscribe$)
    );
  }

}
