import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomLoading, FIREBASE_STRUCT } from 'src/app/app.constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-order-param-percent-deposit',
  templateUrl: './order-param-percent-deposit.component.html',
  styleUrls: ['./order-param-percent-deposit.component.scss'],
})
export class OrderParamPercentDepositComponent implements OnInit, OnDestroy {
  uid: any;
  branchUid: any;

  param_percent_deposit: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.db.doc(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.param_percent_deposit = obj;
        this.param_percent_deposit.uid = this.uid;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();


      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}/${this.uid}`).update({
          ...this.param_percent_deposit,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });

        this.db.collection<any>(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`, query => {
          return query.where('branchUid', '==', this.branchUid);
        }).snapshotChanges().pipe(
          map(docs => docs.map(doc => {
            const fee_value_deposit = doc.payload.doc.data().fee_value_deposit;

            let data = fee_value_deposit.map(obj => {
              if (obj.uid === this.param_percent_deposit.uid) {
                return ({uid: this.param_percent_deposit.uid, name: this.param_percent_deposit.name, value: this.param_percent_deposit.value, fee_value: obj.fee_value})
              }
              return obj;
            })
            return ({ uid: doc.payload.doc.id, ...doc.payload.doc.data(), fee_value_deposit: data });
          })))
          .subscribe(async params => {
            for (let index = 0; index < params.length; index++) {
              const dataUpdate = {...params[index]};
              await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}/${dataUpdate.uid}`).update({
                fee_value_deposit: dataUpdate.fee_value_deposit,
                update_user: this.auth.auth.currentUser.displayName,
                update_date: c
              });
            }
          });
      } else {
        await this.db.collection(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}`).add({
          ...this.param_percent_deposit,
          branchUid: this.branchUid,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  private _getParamServices(branch: any) {
    return this.db.collection<any>(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`, q =>
      q.where('branchUid', '==', branch.uid).orderBy('create_date', 'asc')
    ).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
    );
  }

  private _getParamPercentDeposit(branch: any) {
    return this.db.collection<any>(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}`, q =>
      q.where('branchUid', '==', branch.uid).orderBy('create_date', 'asc')
    ).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
    );
  }
}
