import { CommonUtilsService } from './../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomConfirm, CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-action-role-order',
  templateUrl: './action-role-order.component.html',
  styleUrls: ['./action-role-order.component.scss']
})
export class ActionRoleOrderComponent implements OnDestroy, OnInit {
  temp_default = {
    order_action_uid: '',
    order_action_code: '',
    order_action_name: '',
    chobaogia: false,
    dabaogia: false,
    datcoc: false,
    choxacnhan: false,
    daxacnhan: false,
    dathang: false,
    thanhtoanchoshop: false,
    davekhotq: false,
    vanchuyentqvn: false,
    davekhovn: false,
    thanhtoan: false,
    giaohang: false,
    dagiao: false,
    hoanthanh: false,
    huydon: false
  };
  temp = { ...this.temp_default };
  unsubscribe$ = new Subject();
  actions_added: any[] = [];
  actions_added_default: any[] = [];
  order_actions: any[] = [];
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService
  ) {
    const role_uid = this.navParams.data.modal.componentProps.role_uid;
    this.getOrderActions(role_uid).then(actions => {
      this.actions_added = actions;
      this.actions_added_default = actions.map(x => Object.assign({}, x));
    });

    this.getOrderActionList().then(orderActionList => {
      this.order_actions = orderActionList;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const role_uid = this.navParams.data.modal.componentProps.role_uid;

      this.actions_added_default.forEach(action => {
        batch.delete(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('order_actions').doc(action.uid));
      });

      const currentDateTime = await this.commonService.getServerTime();
      this.actions_added.forEach(action => {
        const u = { ...action };
        delete u.uid;
        u.create_date = currentDateTime;
        batch.set(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('order_actions').doc(), u);
      });

      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  @CustomLoading()
  async onAddOrderAction() {
    try {

      if (!this.temp.order_action_uid) {
        throw new Error('Chưa chọn thao tác. Vui lòng nhập lại');
      }

      this.actions_added.forEach(action_added => {
        if (action_added.order_action_uid === this.temp.order_action_uid) {
          throw new Error('Mã thao tác đơn hàng này đã được thêm. Vui lòng nhập lại');
        }
      });

      this.actions_added.push(this.temp);

      this.temp = { ...this.temp_default };

    } catch (error) {
      console.log(error);
      throw error;
    }

  }
  @CustomLoading()
  onAddAllRoles() {
    try {
      this.actions_added = [];
      this.order_actions.forEach(order_action => {
        this.temp.order_action_name = order_action.name;
        this.temp.order_action_code = order_action.code;

        this.actions_added.push(this.temp);

        this.temp = { ...this.temp_default };
      });
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Bạn chắc chắn muốn xóa thao tác này?')
  async onDeleteOrderAction(index) {
    try {
      this.actions_added.splice(index, 1);
    } catch (error) {
      throw error;
    }
  }

  getOrderActions(role_uid) {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid)
      .collection('order_actions')
      .orderBy('create_date')
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  getOrderActionList() {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ORDER_ACTIONS.NODE)
      .orderBy('create_date', 'asc')
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  onChooseAction() {
    this.order_actions.forEach(order_action => {
      if (order_action.uid === this.temp.order_action_uid) {
        this.temp.order_action_name = order_action.name;
        this.temp.order_action_code = order_action.code;
      }
    });
  }

  @CustomLoading()
  onCheckAll() {
    try {
      this.actions_added.forEach(action => {
        action.chobaogia = true;
        action.dabaogia = true;
        action.datcoc = true;
        action.choxacnhan = true;
        action.daxacnhan = true;
        action.dathang = true;
        action.thanhtoanchoshop = true;
        action.davekhotq = true;
        action.vanchuyentqvn = true;
        action.davekhovn = true;
        action.thanhtoan = true;
        action.giaohang = true;
        action.dagiao = true;
        action.hoanthanh = true;
        action.huydon = true;
      });
    } catch (error) {
      throw error;
    }
  }

}
