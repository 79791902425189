import { CommonUtilsService } from './../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { CustomLoading } from '../../../app.constant';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../../services/roles/roles.service';
import { DatePipe } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-task-add-or-update',
  templateUrl: './task-add-or-update.component.html',
  styleUrls: ['./task-add-or-update.component.css'],
  providers: [DatePipe]
})
export class TaskAddOrUpdateComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  componentProps = {
    uid: ''
  };
  projects: any[] = [];
  types: any[] = [];
  priorities: any[] = [];
  statuses: any[] = [];
  currentTaskId = 0;
  model: any = {
    taskId: '',
    selectedProject: null,
    selectedType: null,
    selectedPriority: null,
    selectedStatus: null,
    taskDescription: null,
    taskContent: null,
    taskFunctionInfluence: null,
    deadline: this._getToday(),
    duration: 0
  };

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public loadingCtrl: LoadingController,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public commonService: CommonUtilsService,
    private datePipe: DatePipe
  ) {
    this.componentProps.uid = this.navaparam.data.modal.componentProps.uid;

    this._getProjects().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(_projects => {
      this.projects = _projects.projects;
    });

    this._getTypes().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(_types => {
      this.types = _types.types;
    });

    this._getPriorities().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(_priorities => {
      this.priorities = _priorities.priorities;
    });

    this._getStatuses().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(_statuses => {
      this.statuses = _statuses.status;
    });

    this._getCurrentTaskId().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(_currentTaskId => {
      this.currentTaskId = _currentTaskId.id;
    });
  }

  ngOnInit() {
    this._initial();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  public async onAddOrUpdateTask() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    let ret = '';

    if (!this.componentProps.uid) {
      // Create Task
      const messageError = this._validateModel();

      if (messageError) {
        throw new Error(messageError);
      } else {
        const model = { ...this.model };
        const taskId = this.currentTaskId + 1;
        const currentTime = new Date().getTime();

        batch.set(fs.collection('TASKS').doc(), {
          id: taskId,
          projectKey: model.selectedProject,
          projectValue: this._getValueFormArrayByKey(model.selectedProject, this.projects),
          typeKey:  model.selectedType,
          typeValue:  this._getValueFormArrayByKey(model.selectedType, this.types),
          priorityKey:  model.selectedPriority,
          priorityValue:  this._getValueFormArrayByKey(model.selectedPriority, this.priorities),
          statusKey:  'back_log',
          statusValue:  this._getValueFormArrayByKey('back_log', this.statuses),
          description:  model.taskDescription,
          content:  model.taskContent,
          functionInfluence:  model.taskFunctionInfluence,
          deadline:  this._getDeadline(model.deadline),
          duration: model.duration,
          markedDeadline: false,
          markedDone: false,
          createdAt: currentTime,
          createdBy: this.auth.auth.currentUser.displayName,
          modifiedAt: currentTime,
          modifiedBy: this.auth.auth.currentUser.displayName
        });

        batch.update(fs.collection('UID_INFO').doc('task_uid'), {
          id: taskId
        });

        ret = 'Thêm mới công việc thành công';
      }
    } else {
      // Update Task
      const messageError = this._validateModel();
      if (messageError) {
        throw new Error(messageError);
      } else {
        const model = { ...this.model };
        const currentTime = new Date().getTime();
        const uid = this.componentProps.uid;

        let markedDone = false;
        if (model.selectedStatus === 'done' || model.selectedStatus === 'ignore') {
          markedDone = true;
        }

        batch.update(fs.collection('TASKS').doc(uid), {
          projectKey: model.selectedProject,
          projectValue: this._getValueFormArrayByKey(model.selectedProject, this.projects),
          typeKey: model.selectedType,
          typeValue: this._getValueFormArrayByKey(model.selectedType, this.types),
          priorityKey: model.selectedPriority,
          priorityValue:  this._getValueFormArrayByKey(model.selectedPriority, this.priorities),
          statusKey: model.selectedStatus,
          statusValue: this._getValueFormArrayByKey(model.selectedStatus, this.statuses),
          description: model.taskDescription,
          type: model.taskDescription,
          content: model.taskContent,
          functionInfluence: model.taskFunctionInfluence,
          deadline: this._getDeadline(model.deadline),
          duration: model.duration,
          markedDone,
          modifiedAt: currentTime,
          modifiedBy: this.auth.auth.currentUser.displayName
        });
      }

      ret = 'Cập nhật công việc thành công';
    }

    await batch.commit();
    this.modalCtrl.dismiss();

    return ret;
  }

  private _initial() {
    if (!this.componentProps.uid) {
      const deadlineDate = this._getYearMonthDayFromDateNumber(this._getToday().getTime());
      this.model.deadline = new NgbDate(deadlineDate.year, deadlineDate.month, deadlineDate.day);
    } else {
      this._getTask().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(_task => {
        this.model.taskId = _task.id;
        this.model.selectedProject = _task.projectKey;
        this.model.selectedType = _task.typeKey;
        this.model.selectedPriority = _task.priorityKey;
        this.model.selectedStatus = _task.statusKey;
        this.model.taskDescription = _task.description;
        this.model.taskContent = _task.content;
        this.model.taskFunctionInfluence = _task.functionInfluence;
        this.model.deadline = this._getYearMonthDayFromDateNumber(_task.deadline);
      });
    }
  }

  private _getTask(): Observable<any> {
    const uid = this.componentProps.uid;
    return this.fs.collection('TASKS').doc(uid).valueChanges();
  }

  private _getProjects(): Observable<any> {
    return this.fs.collection('TASK_CONFIG').doc('PROJECTS').valueChanges();
  }

  private _getTypes(): Observable<any> {
    return this.fs.collection('TASK_CONFIG').doc('TASK_TYPE').valueChanges();
  }

  private _getPriorities(): Observable<any> {
    return this.fs.collection('TASK_CONFIG').doc('TASK_PRIORITY').valueChanges();
  }

  private _getStatuses(): Observable<any> {
    return this.fs.collection('TASK_CONFIG').doc('TASK_STATUS').valueChanges();
  }

  private _getCurrentTaskId(): Observable<any> {
    return this.fs.collection('UID_INFO').doc('task_uid').valueChanges();
  }

  private _getYearMonthDayFromDateNumber(dateNumber) {
    if (!dateNumber) {
      return {
        year: 2021,
        month: 1,
        day: 1
      };
    }

    const _date = new Date(dateNumber);
    const dateString = this.datePipe.transform(_date, 'yyyy-MM-dd');
    const dates = dateString.split('-');
    return {
      year: Number(dates[0]),
      month: Number(dates[1]),
      day: Number(dates[2])
    };
  }

  private _validateModel() {
    const model = { ...this.model };

    if (!this.currentTaskId) {
      return 'Không lấy được thông tin [ID công việc]';
    }

    if (!model.selectedProject) {
      return 'Chưa nhập thông tin [Hệ thống]';
    }

    if (!model.selectedType) {
      return 'Chưa nhập thông tin [Phân loại]';
    }

    if (!model.selectedPriority) {
      return 'Chưa nhập thông tin [Độ ưu tiên]';
    }

    if (!model.taskDescription) {
      return 'Chưa nhập thông tin [Mô tả công việc]';
    }

    if (!model.taskContent) {
      return 'Chưa nhập thông tin [Nội dung công việc]';
    }

    if (!model.taskFunctionInfluence) {
      return 'Chưa nhập thông tin [Chức năng ảnh hưởng]';
    }

    return '';
  }

  private _getDeadline(ngbDateDeadline) {
    const { year, month, day } = ngbDateDeadline;
    return new Date(year, month - 1, day).getTime();
  }

  private _getValueFormArrayByKey(key, array) {
    for (const item of array) {
      if (key === item.key) {
        return item.value;
      }
    }

    return '';
  }

  private _getToday() {
    return new Date();
  }
}
