import { AngularFireAuth } from '@angular/fire/auth';
import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { CONSIGNMENT_STATUS, CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-delivery-detail',
  templateUrl: './delivery-detail.component.html',
  styleUrls: ['./delivery-detail.component.css']
})
export class DeliveryDetailComponent implements OnInit, OnDestroy {

  uid: any;
  consignments_auto_finish;
  deliver_info: any = {};
  unsubscribe$ = new Subject();
  deliver_hour = 0;
  deliver_type = 'Giao trực tiếp';
  deliver_detail: string;

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.consignments_auto_finish = Boolean(this.navParams.data.modal.componentProps.consignments_auto_finish);
    if (this.uid) {
      this.getDeliverInfo().subscribe(deliverInfos => {
        if (deliverInfos.length) {
          this.deliver_info = deliverInfos[0];
        } else {
          this.setDefaultDeliverInfo();
        }
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const deliverInfo = { ...this.deliver_info };
      if (deliverInfo.is_direct_deliver) {
        this.deliver_hour = deliverInfo.date_direct_deliver;
      } else if (deliverInfo.is_ship_internal) {
        this.deliver_hour = deliverInfo.date_ship_internal;
      } else if (deliverInfo.is_ship_external) {
        this.deliver_hour = deliverInfo.date_ship_external;
      } else if (deliverInfo.is_ship_deliver) {
        this.deliver_hour = deliverInfo.date_ship_deliver;
      }
      if (deliverInfo.uid) {
        batch.delete(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection('deliver_info').doc(deliverInfo.uid));
      }

      if (this.consignments_auto_finish) {
        batch.delete(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS_AUTO_FINISH.NODE).doc(this.uid));
      }

      batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection('deliver_info').doc(), {
        is_direct_deliver: deliverInfo.is_direct_deliver,
        is_ship_internal: deliverInfo.is_ship_internal,
        is_ship_external: deliverInfo.is_ship_external,
        is_ship_deliver: deliverInfo.is_ship_deliver,
        date_direct_deliver: deliverInfo.date_direct_deliver,
        date_ship_internal: deliverInfo.date_ship_internal,
        date_ship_external: deliverInfo.date_ship_external,
        date_ship_deliver: deliverInfo.date_ship_deliver
      });

      const deliverDate = this.addHourToDate(this.deliver_hour);
      if (this.deliver_hour > 0) {
        batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid), {
          status_key: CONSIGNMENT_STATUS.GIAOHANG.KEY,
          status_text: CONSIGNMENT_STATUS.GIAOHANG.VALUE,
          date_delivery: await this.commonService.getServerTime(),
          date_delivered: '',
          date_finish: '',
          consignment_order_deliver_type: this.deliver_type || '',
          consignment_order_deliver_detail: this.deliver_detail || '',
          is_finished: false,
          consignments_auto_finish: true
        });

        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS_AUTO_FINISH.NODE).doc(this.uid), {
          key: CONSIGNMENT_STATUS.GIAOHANG.KEY,
          date_end: deliverDate
        });
      } else {
        const curentTime = await this.commonService.getServerTime();
        const time_order_finish = await fs.collection('PARAMETER_ORDER_DEFAULT').doc('VNhrfymq0UhhnGJksrut').get().then(doc => {
          return Number(doc.get('value'));
        });
        const finishDate = new Date(curentTime);
        finishDate.setDate(finishDate.getDate() + time_order_finish);
        batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid), {
          status_key: CONSIGNMENT_STATUS.DAGIAO.KEY,
          status_text: CONSIGNMENT_STATUS.DAGIAO.VALUE,
          date_delivery: curentTime,
          date_delivered: curentTime,
          consignment_order_deliver_type: this.deliver_type || '',
          consignment_order_deliver_detail: this.deliver_detail || '',
          is_finished: true,
          consignments_auto_finish: false
        });
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS_AUTO_FINISH.NODE).doc(this.uid), {
          key: CONSIGNMENT_STATUS.DAGIAO.KEY,
          date_end: finishDate.getTime()
        });

        const user_login: any = await this.getUserLogin(this.auth.auth.currentUser.uid);
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
          element: 'Cập nhật giao hàng',
          date: await this.commonService.getServerTime(),
          user: user_login.full_name,
          old_value: '',
          new_value: this.deliver_type
        }, { merge: true });
      }

      await batch.commit();
      await this.modalCtrl.dismiss();

      return 'Cập nhật giao hàng thành công';
    } catch (error) {
      throw error;
    }

  }

  addHourToDate(hour: number) {
    const date = new Date();
    date.setHours(date.getHours() + hour);
    return date.getTime();
  }

  getDeliverInfo() {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection('deliver_info').snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  setDefaultDeliverInfo() {
    this.deliver_info = {
      is_direct_deliver: false,
      date_direct_deliver: 0,
      is_ship_internal: false,
      date_ship_internal: 24,
      is_ship_external: false,
      date_ship_external: '',
      is_ship_deliver: false,
      date_ship_deliver: '',
      uid: this.deliver_info.uid ? this.deliver_info.uid : ''
    };
  }

  onCheckDirectDeliver() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_direct_deliver = !this.deliver_info.is_direct_deliver;
    this.deliver_hour = this.deliver_info.date_direct_deliver;
    this.deliver_type = 'Giao trực tiếp';
  }

  onCheckShipInternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_internal = !this.deliver_info.is_ship_internal;
    this.deliver_hour = this.deliver_info.date_ship_internal;
    this.deliver_type = 'Ship nội thành';
  }

  onCheckShipExternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_external = !this.deliver_info.is_ship_external;
    this.deliver_hour = this.deliver_info.date_ship_external;
    this.deliver_type = 'Ship xe khách';
  }

  onCheckShipDeliver() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_deliver = !this.deliver_info.is_ship_deliver;
    this.deliver_hour = this.deliver_info.date_ship_deliver;
    this.deliver_type = 'Ship chuyển phát';
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      first()
    ).toPromise();
  }

}
