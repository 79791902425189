export const BRANCH_CONFIG = {
    SOC_ORDER: {
        UID: 'UhQDbDi42iR7UFdIVpRt',
        TENANT_ID: 'socorder-3yi93'
    },
    PANDA_ORDER: {
        UID: '0MT41D5Z6pFwTLgtQ14c',
        TENANT_ID: 'pandaorder-u9xmr'
    },
    DEFAULT: {
        UID: 'lqB5AqKzNjT2LFrsfcaI',
        TENANT_ID: ''
    }
};