import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { map, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { CalculateFeeService } from '../../../services/calculate-fee/calculate-fee.service';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-deliver-bag',
  templateUrl: './deliver-bag.component.html',
  styleUrls: ['./deliver-bag.component.css']
})
export class DeliverBagComponent implements OnInit, OnDestroy {

  bags: any[];
  selected_bags: any[] = [];
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public calcFeeService: CalculateFeeService,
    public commonService: CommonUtilsService
  ) {
    this.getBags().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(bags => {
      this.bags = bags;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getBags() {
    return this.fs.collection(FIREBASE_STRUCT.BAGS.NODE, q => {
      return q.where('is_bag_cpc', '==', false).where('is_delivered', '==', false);
    }).valueChanges({ idField: 'uid' });
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Xác nhận giao các bao này?')
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    try {
      for (const bag of this.selected_bags) {
        const bag_code = bag;
        const transports: firestore.DocumentData[] = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
          .where('bag_code', '==', bag_code)
          .get().then(actions => actions.docs.map(doc => ({ ...doc.data(), uid: doc.id })));

        transports.forEach(transport => {
          batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
            date_deliver_vn: current
          });
        });

        const _bags: firestore.DocumentData[] = await fs.collection(FIREBASE_STRUCT.BAGS.NODE)
          .where('bag_code', '==', bag_code)
          .get().then(actions => actions.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
        _bags.forEach(_bag => {
          batch.update(fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc(_bag.uid), {
            is_delivered: true
          });
        });
      }

      await batch.commit();

      await this.modalCtrl.dismiss();

      return 'Giao bao thành công';
    } catch (error) {
      throw error;
    }
  }
}
