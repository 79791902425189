import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomConfirm, FIREBASE_STRUCT, validObject } from '../../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Decimal } from '../../../app.models';
import { firestore } from 'firebase/app';
import { BRANCH_CONFIG } from 'src/app/constants/constant';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-transport-code-edit',
  templateUrl: './transport-code-edit.component.html',
  styleUrls: ['./transport-code-edit.component.css']
})
export class TransportCodeEditComponent implements OnDestroy, OnInit {
  transport_uid: string;
  transport: any;
  weightConversionFactor = 0;
  unsubscribe$ = new Subject();
  lastCode: string;
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService,
    private fireDb: AngularFireDatabase,
  ) {
    this.transport_uid = this.navParams.data.modal.componentProps.transport_uid;
    this.getTransport(this.transport_uid).subscribe(transport => {
      this.transport = transport;
      this.lastCode = transport.transport_code;

      this._getWeightConversionFactor(transport).then(_weightConversionFactor => {
        this.weightConversionFactor = _weightConversionFactor;
      });
    });
  }


  async ngOnInit() {
    // this.weightConversionFactor = Number(await this._getWeightConversionFactor());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTransport(transport_uid): Observable<any> {
    return this.fs.collection<any>(FIREBASE_STRUCT.TRANSPORTS.NODE).doc<any>(transport_uid).snapshotChanges().pipe(
      map(doc => ({ exists: doc.payload.exists, uid: doc.payload.id, ...doc.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  dataChanged(transport) {
    transport.transport_exchanged_kg = new Decimal(transport.transport_length)
      .mul(transport.transport_width)
      .mul(transport.transport_height)
      .div(this.weightConversionFactor)
      .roundUp(2)
      .toNumber();
  }

  @CustomConfirm('Xác nhận cập nhật mã vận đơn này?')
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const transport = { ...this.transport };
      const transportCode = String(transport.transport_code).trim();
      const current = await this.commonService.getServerTime();
      if (this.validTran(transport)) {
        validObject(transport);

        if (this.lastCode !== transportCode) {
          const existsTran = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
            .where('transport_code', '==', transportCode)
            .get().then(snapshot => !!snapshot.size);
          if (existsTran) {
            throw new Error(`Mã vận đơn '${transport.transport_code}' đã tồn tại. Vui lòng nhập lại`);
          }
          transport.orders.forEach(order => {
            if (order.transport_code !== transport.transport_code) {
              batch.update(
                fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid)
                  .collection('transports').doc(order.order_transport_uid),
                {
                  transport_weight: firestore.FieldValue.delete(),
                  transport_real_kg: firestore.FieldValue.delete(),
                  transport_exchanged_kg: firestore.FieldValue.delete(),
                  transport_size: firestore.FieldValue.delete(),
                  transport_price_mvd: firestore.FieldValue.delete(),
                  transport_day_in_warehouse: firestore.FieldValue.delete(),
                  transport_uid: firestore.FieldValue.delete(),
                  bag_weight: firestore.FieldValue.delete(),
                  transport_transport_bag_fee: firestore.FieldValue.delete(),
                  transport_extra_fee: firestore.FieldValue.delete(),
                  transport_motorbike_fee: firestore.FieldValue.delete(),
                  transport_date_updated: current,
                }
              );
            }
          });

          transport.consignments.forEach(consignment => {
            if (consignment.transport_code !== transport.transport_code) {
              batch.update(
                fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignment.consignment_uid)
                  .collection('transports').doc(consignment.consignment_transport_uid),
                {
                  transport_weight: firestore.FieldValue.delete(),
                  transport_real_kg: firestore.FieldValue.delete(),
                  transport_exchanged_kg: firestore.FieldValue.delete(),
                  transport_size: firestore.FieldValue.delete(),
                  transport_price_mvd: firestore.FieldValue.delete(),
                  transport_day_in_warehouse: firestore.FieldValue.delete(),
                  transport_uid: firestore.FieldValue.delete(),
                  bag_weight: firestore.FieldValue.delete(),
                  transport_transport_bag_fee: firestore.FieldValue.delete(),
                  transport_extra_fee: firestore.FieldValue.delete(),
                  transport_motorbike_fee: firestore.FieldValue.delete(),
                  transport_date_updated: current
                }
              );
            }
          });
        }

        if (Number(transport.transport_length) > 0 &&
          Number(transport.transport_width) > 0 &&
          Number(transport.transport_height) > 0) {
          transport.transport_size = `${transport.transport_length}/${transport.transport_width}/${transport.transport_height}`;
        } else {
          transport.transport_size = '';
        }

        /* if (Number(transport.transport_real_kg) > Number(transport.transport_exchanged_kg || 0)) {
          transport.transport_weight = new Decimal(transport.transport_real_kg).roundUp(2).toNumber();
        } else {
          transport.transport_weight = new Decimal(transport.transport_exchanged_kg).roundUp(2).toNumber();
        } */
        transport.transport_weight = this._roundTransportWeight(transport);

        if (transport.is_bagging) {
          const bag_code: string = transport.bag_code;
          const bagUid: string = transport.bag_uid;
          const bag: any = await fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc(bagUid)
            .get().then(doc => {
              return { exists: doc.exists, uid: doc.id, ...doc.data() };
            });
          const transports: any[] = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
            .where('bag_code', '==', bag_code)
            .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));

          let weight = 0;
          for (const tran of transports) {
            if (tran.uid !== transport.uid) {
              if (Number(tran.transport_exchanged_kg) > Number(tran.transport_real_kg)) {
                weight = new Decimal(weight).add(tran.transport_exchanged_kg).roundUp(2).toNumber();
              } else {
                weight = new Decimal(weight).add(tran.transport_real_kg).roundUp(2).toNumber();
              }
            }
          }
          weight = new Decimal(weight).add(transport.transport_weight).roundUp(2).toNumber();

          const transport_price_mvd = new Decimal(bag.transport_bag_fee)
            .mul(bag.bag_weight)
            .add(bag.extra_fee)
            .add(bag.motorbike_fee)
            .div(weight)
            .roundUp(0)
            .toNumber();

          transport.transport_price_mvd = transport_price_mvd;
          for (const tran of transports) {
            if (tran.uid !== transport.uid) {
              batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
                transport_price_mvd
              });
            }
          }
        }

        batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), transport);



      }

      await batch.commit();

      this.onDismiss();

      return 'Cập nhật thành công';
    } catch (error) {
      throw error;
    }
  }

  validTran(transport) {
    if (
      (
        Number(transport.transport_length) > 0 ||
        Number(transport.transport_width) > 0 ||
        Number(transport.transport_height) > 0
      )
      && Number(transport.transport_exchanged_kg) === 0) {
      return false;
    } else {
      if (
        !transport.transport_code || !transport.transport_product_quantity || !transport.transport_product_type
      ) { return false; } else {
        return true;
      }
    }
  }

  getParameterVnWarehouse() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_VN_WAREHOUSE.NODE).snapshotChanges().pipe(
      map(actions => {
        const rs = {};
        actions.forEach(action => {
          rs[action.payload.doc.id] = action.payload.doc.data();
        });
        return rs;
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  private async _getWeightConversionFactor(transport) {
    const fs = firestore();

    let isConsignmentOrder = false;

    if (transport.consignments && transport.consignments.length) {
      isConsignmentOrder = true;
    }

    if (isConsignmentOrder) {
      const consignmentOrder = await fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(transport.consignments[0].consignment_uid).get().then(_consignmentOrder => {
        return { ..._consignmentOrder.data() };
      });

      const transportConverterParams = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_CONVERTER.NODE).orderBy('application_start_date_text', 'desc').get().then(colection => {
        const ret = [];
        colection.forEach(doc => {
          ret.push({ ...doc.data() });
        });

        return ret;
      });

      let weightConversionFactor = 0;
      if (!consignmentOrder.transport_date_created) {
        weightConversionFactor = Number(transportConverterParams[0].parameters[1].value);
      } else {
        for (const transportConverterParam of transportConverterParams) {
          if (consignmentOrder.transport_date_created > new Date(transportConverterParam.application_start_date_text).getTime()) {
            weightConversionFactor = Number(transportConverterParam.parameters[1].value);
            break;
          }
        }
      }

      return weightConversionFactor;
    } else {
      

      if (transport.orders && transport.orders.length) {
        const order = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(transport.orders[0].order_uid).get().then(_order => {
          return { ..._order.data() };
        });

        if (order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID
          || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID) {
          return await this.fireDb
            .database
            .ref(
              `BRANCH_CONFIG/${order.branch_uid}/hesoquydoicongthuccannang`
            )
            .once('value')
            .then((snapshot) => snapshot.val());
        }
        
        const weightConversionFactor = order.normal_param ? order.normal_param.hesoquydoicongthuccannang || 7000 : 7000;

        return Number(weightConversionFactor);
      } else {
        const weightConversionFactor = await fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').get().then(doc => {
          return doc.get('value');
        });

        return Number(weightConversionFactor);
      }
    }
  }

  private _roundTransportWeight(transport) {
    const transportRealKg = Number(transport.transport_real_kg || 0);
    const transportExchangedKg = Number(transport.transport_exchanged_kg || 0);
    let transport_weight = 0;
    if ((transportExchangedKg - transportRealKg) < 3) {
      transport_weight = transportRealKg;
    } else {
      transport_weight = transportRealKg >= transportExchangedKg ? transportRealKg : transportExchangedKg;
    }
    transport_weight = new Decimal(transport_weight).roundUp(2).toNumber();
    return transport_weight;
  }

}
