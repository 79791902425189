import { FIREBASE_STRUCT } from './../../app.constant';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    public fs: AngularFirestore,
    public auth: AngularFireAuth
  ) { }

  public async getRoles(order_status_key: number) {
    const userLogin: any = await this.getUserLogin();
    const actionRoles = await this.fs.collection<any[]>(`${FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE}/${userLogin.role_uid}/order_actions`).snapshotChanges().pipe(
      map(actions => actions.map(action => ({ uid: action.payload.doc.id, ...action.payload.doc.data() }))),
      first()
    ).toPromise();

    const role_item: any = {};
    let value = false;

    actionRoles.forEach((r: any) => {
      switch (order_status_key) {
        case 0: value = r.chobaogia; break;
        case 1: value = r.dabaogia; break;
        case 2: value = r.datcoc; break;
        case 2.1: value = r.choxacnhan; break;
        case 2.2: value = r.daxacnhan; break;
        case 3: value = r.dathang; break;
        case 4: value = r.thanhtoanchoshop; break;
        case 5: value = r.davekhotq; break;
        case 6: value = r.vanchuyentqvn; break;
        case 7.1: value = r.davekhovn; break;
        case 7.2: value = r.davekhovn; break;
        case 8: value = r.thanhtoan; break;
        case 9: value = r.giaohang; break;
        case 10: value = r.dagiao; break;
        case 11: value = r.hoanthanh; break;
        case 12: value = r.huydon; break;
        default: value = false;
      }
      switch (r.order_action_code) {
        case 'LMĐH': role_item.LMDH = value; break;
        case 'CBCĐHĐB': role_item.CBCDHDB = value; break;
        case 'XTSCĐH': role_item.XTSCDH = value; break;
        case 'STSCĐHKCSĐHĐB': role_item.STSCDHKCSDHDB = value; break;
        case 'XTTKH': role_item.XTTKH = value; break;
        case 'XLSTC': role_item.XLSTC = value; break;
        case 'BĐVNDT-XPDV': role_item.BDVNDT_XPDV = value; break;
        case 'BĐVNDT-STHTT': role_item.BDVNDT_STHTT = value; break;
        case 'BĐVNDT-STSBK': role_item.BDVNDT_STSBK = value; break;
        case 'BĐVVNĐ-XPDV': role_item.BDVVND_XPDV = value; break;
        case 'BĐVVNĐ-XPPS': role_item.BDVVND_XPPS = value; break;
        case 'BĐVVNĐ-TPPS': role_item.BDVVND_TPPS = value; break;
        case 'BĐVVNĐ-SPPS': role_item.BDVVND_SPPS = value; break;
        case 'BĐVVNĐ-XOPPS': role_item.BDVVND_XOPPS = value; break;
        case 'BĐVNDT-XPVCT': role_item.BDVVND_XPVCT = value; break;
        case 'BTK-XHT': role_item.BTK_XHT = value; break;
        case 'BTK-TGDHT': role_item.BTK_TGDHT = value; break;
        case 'BTK-XTT': role_item.BTK_XTT = value; break;
        case 'BYC-CBĐG': role_item.BYC_CBDG = value; break;
        case 'BYC-CBKG': role_item.BYC_CBKG = value; break;
        case 'BYC-CBCPCTQVN': role_item.BYC_CBCPCTQVN = value; break;
        case 'BYC-CBNPCTQVN': role_item.BYC_CBNPCTQVN = value; break;
        case 'SGCCKH': role_item.SGCCKH = value; break;
        case 'SGCCCTCKH': role_item.SGCCCTCKH = value; break;
        case 'SGCNB': role_item.SGCNB = value; break;
        case 'CNVPTKH': role_item.CNVPTKH = value; break;
        case 'CNVPTĐH': role_item.CNVPTDH = value; break;
        case 'SKNCKH': role_item.SKNCKH = value; break;
        case 'ĐTT-STTNTĐH': role_item.DTT_STTNTDH = value; break;
        case 'ĐTT-STTĐBG': role_item.DTT_STTDBG = value; break;
        case 'ĐTT-STTĐC': role_item.DTT_STTDC = value; break;
        case 'ĐTT-STTĐH': role_item.DTT_STTDH = value; break;
        case 'ĐTT-STTTTCS': role_item.DTT_STTTTCS = value; break;
        case 'ĐTT-STTĐVKTQ': role_item.DTT_STTĐVKTQ = value; break;
        case 'ĐTT-STTVCTQVN': role_item.DTT_STTVCTQVN = value; break;
        case 'ĐTT-STTĐVKVN': role_item.DTT_STTĐVKVN = value; break;
        case 'ĐTT-STTTT': role_item.DTT_STTTT = value; break;
        case 'ĐTT-STTGH': role_item.DTT_STTGH = value; break;
        case 'ĐTT-STTĐG': role_item.DTT_STTĐG = value; break;
        case 'ĐTT-STTKN': role_item.DTT_STTKN = value; break;
        case 'ĐTT-STTHT': role_item.DTT_STTHT = value; break;
        case 'ĐTT-STTHĐ': role_item.DTT_STTHD = value; break;
        case 'STS': role_item.STS = value; break;
        case 'MHĐ-XCT': role_item.MHD_XCT = value; break;
        case 'MHĐ-NTTCTMHĐ': role_item.MHD_NTTCTMHD = value; break;
        case 'MHĐ-STTMHĐ': role_item.MHD_STTMHD = value; break;
        case 'MHĐ-XTTMHĐ': role_item.MHD_XTTMHD = value; break;
        case 'MVĐ-XCT': role_item.MVD_XCT = value; break;
        case 'MVĐ-NTTCTMVĐ': role_item.MVD_NTTCTMVD = value; break;
        case 'MVĐ-STTMVĐ': role_item.MVD_STTMVD = value; break;
        case 'MVĐ-XTTMVĐ': role_item.MVD_XTTMVD = value; break;
        case 'MVĐ-HTXVP': role_item.MVD_HTXVP = value; break;
        case 'MVĐ-XBXVP': role_item.MVD_XBXVP = value; break;
        case 'MVĐ-XBXKH': role_item.MVD_XBXKH = value; break;
        case 'SHT-XCT': role_item.SHT_XCT = value; break;
        case 'SHT-NTTCTSHT': role_item.SHT_NTTCTSHT = value; break;
        case 'SHT-STTSHT': role_item.SHT_STTSHT = value; break;
        case 'SHT-XTTSHT': role_item.SHT_XTTSHT = value; break;
        case 'TSKN': role_item.TSKN = value; break;
        case 'TMLH': role_item.TMLH = value; break;
        case 'IĐH': role_item.IDH = value; break;
        case 'XRFE': role_item.XRFE = value; break;
        case 'GH': role_item.GH = value; break;
        case 'TTVA': role_item.TTVA = value; break;
        case 'XCTGD': role_item.XCTGD = value; break;
        case 'DSLH-TTCL-ĐĐĐH': role_item.DSLH_TTCL_DDDH = value; break;
        case 'DSLH-TTCL-VKĐH': role_item.DSLH_TTCL_VKDH = value; break;
        case 'DSLH-TTCL-BĐĐH': role_item.DSLH_TTCL_BDDH = value; break;
        case 'DSLH-TTCL-BVKĐH': role_item.DSLH_TTCL_BVKDH = value; break;
        case 'DSLH-TTCL-HTLCXVP': role_item.DSLH_TTCL_HTLCXVP = value; break;
        case 'DSLH-TTCL-XVP': role_item.DSLH_TTCL_XVP = value; break;
        case 'DSLH-TTCL-XKH': role_item.DSLH_TTCL_XKH = value; break;
        case 'DSLH-TTCL-BXVP': role_item.DSLH_TTCL_BXVP = value; break;
        case 'DSLH-TTCL-BXKH': role_item.DSLH_TTCL_BXKH = value; break;
        case 'DSLH-LCLCTCCLH': role_item.DSLH_LCLCTCCLH = value; break;
        case 'DSLH-HTXVP': role_item.DSLH_HTXVP = value; break;
        case 'DSLH-XBXVP': role_item.DSLH_XBXVP = value; break;
        case 'DSLH-XBXKH': role_item.DSLH_XBXKH = value; break;
        case 'DSLH-SSLKĐ': role_item.DSLH_SSLKD = value; break;
        case 'DSLH-SSLĐH': role_item.DSLH_SSLDH = value; break;
        case 'DSLH-SSLVK': role_item.DSLH_SSLVK = value; break;
        case 'DSLH-SĐGSP': role_item.DSLH_SDGSP = value; break;
        case 'DSLH-HH': role_item.DSLH_HH = value; break;
        case 'DSLH-XLSP': role_item.DSLH_XLSP = value; break;
        case 'XKNH': role_item.XKNH = value; break;
        case 'SKNH': role_item.SKNH = value; break;
        case 'SNVDH': role_item.SNVDH = value; break;
        case 'CKDH-VHCK': role_item.CKDH_VHCK = value; break;
        case 'CKDH-XCK': role_item.CKDH_XCK = value; break;
        case 'XNVS': role_item.XNVS = value; break;
        default: break;
      }
    });
    return role_item;
  }

  public async getActionRolesConsignmentOrder(consignment_order) {
    const userLogin: any = await this.getUserLogin();
    const actionRoles = await this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(userLogin.role_uid).collection('consignment_order_actions').snapshotChanges().pipe(
      map(actions => actions.map(action => ({ uid: action.payload.doc.id, ...action.payload.doc.data() }))),
      first()
    ).toPromise();

    const consignmentOrder = { ...consignment_order };

    const role: any = {};

    actionRoles.forEach((actionRole: any) => {
      switch (actionRole.consignment_order_action_code) {
        case 'ĐKGLMĐH': role.DKGLMDH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGSTSCĐKGKCĐKGĐB': role.DKGSTSCDKGKCDKGDB = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGCBCĐKGĐB': role.DKGCBCDKGDB = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGXTTKH': role.DKGXTTKH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGXTSCĐKG': role.DKGXTSCDKG = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTGH': role.DKGBTTK_DSTTGH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTVCTQVN': role.DKGBTTK_DSTTVCTQVN = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTĐG': role.DKGBTTK_DSTTDG = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-TGDHT': role.DKGBTK_TGDHT = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGGH': role.DKGGH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGXLSTC': role.DKGXLSTC = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGTTVA': role.DKGTTVA = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-XHT': role.DKGBTK_XHT = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGDSMVĐ-SMVĐ': role.DKGDSMVD_SMVD = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGDSMVĐ-XMVĐ': role.DKGDSMVD_XMVD = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTHT': role.DKGBTTK_DSTTHT = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTNKVN': role.DKGBTTK_DSTTNKVN = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGCTTGD': role.DKGCTTGD = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGIP': role.DKGIP = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-XOPPS': role.DKGBTK_XOPPS = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-TPPS': role.DKGBTK_TPPS = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTĐTT': role.DKGBTTK_DSTTDTT = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTNKTQ': role.DKGBTTK_DSTTNKTQ = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-SPPS': role.DKGBTK_SPPS = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTK-XPPS': role.DKGBTK_XPPS = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGTL': role.DKGTL = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-ĐSTTTNKTQ': role.DKGBTTK_DSTTTNKTQ = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'ĐKGBTTK-XPVCT': role.DKGBTTK_XPVCT = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'XKNH': role.XKNH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'SKNH': role.SKNH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'SNVDH': role.SNVDH = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'BYC-CTTCPTTQVN': role.BYC_CTTCPTTQVN = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'BYC-CTTCPDBTQVN': role.BYC_CTTCPDBTQVN = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        case 'XNVS': role.XNVS = this.hasRoleConsignmentOrder(consignmentOrder.status_key, actionRole); break;
        default: break;
      }
    });
    return role;
  }

  public async getMenuActions() {
    const uid = this.auth.auth.currentUser ? this.auth.auth.currentUser.uid : null;

    const menuActions = await this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      switchMap(userAdmin => {
        return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(userAdmin.payload.data().role_uid).collection('menu_actions').snapshotChanges().pipe(
          map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
        );
      }),
      first()
    ).toPromise();
    if (menuActions.length) { return menuActions[0]; }
    return {};
  }

  public async getFunctionAction() {
    const uid = this.auth.auth.currentUser ? this.auth.auth.currentUser.uid : null;
    const functionActions = await this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      switchMap(userAdmin => {
        return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(userAdmin.payload.data().role_uid).collection('function_actions').snapshotChanges().pipe(
          map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
        );
      }),
      first()
    ).toPromise();
    if (functionActions.length) { return functionActions[0]; }
    return {};
  }

  private hasRoleConsignmentOrder(status_key: number, actionRole): boolean {
    switch (status_key) {
      case 0: return actionRole.truocnhapkhotq;
      case 1: return actionRole.nhapkhotq;
      case 2: return actionRole.vanchuyentqvn;
      case 3.1: return actionRole.davekhovn;
      case 3.2: return actionRole.davekhovn;
      case 4: return actionRole.thanhtoan;
      case 5: return actionRole.giaohang;
      case 6: return actionRole.dagiao;
      case 7: return actionRole.hoanthanh;
      default: return false;
    }
  }

  private getUserLogin() {
    const uid = this.auth.auth.currentUser.uid;

    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      first()
    ).toPromise();
  }

}
