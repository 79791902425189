import { RolesService } from './../../services/roles/roles.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomConfirm, FIREBASE_STRUCT, ORDER_STATUS } from '../../app.constant';
import { first, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { CalculateFeeService } from '../../services/calculate-fee/calculate-fee.service';
import { firestore } from 'firebase/app';
import { OrderUpdate } from '../../model/order.update';
import { OrderParam } from '../../model/order.param';
import { Decimal } from '../../app.models';
import { PHILUUKHO } from '../../constants/order';
import { BRANCH_CONFIG } from 'src/app/constants/constant';

// haodt2
@Component({
  selector: 'app-order-special-parameter',
  templateUrl: './order-special-parameter.component.html',
  styleUrls: ['./order-special-parameter.component.css']
})
export class OrderSpecialParameterComponent implements OnInit, OnDestroy {

  order: any;
  special_param: OrderParam;
  user_role: any = {};
  phantramphidichvudacbiet = 0;

  constructor(
    public modalCtrl: ModalController,
    public navaparam: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public calcFeeService: CalculateFeeService,
    public roleService: RolesService
  ) {

  }

  getOrder(order_uid) {
    return this.calcFeeService.getOrderWithParam(order_uid);
  }

  async ngOnInit() {
    const order_uid = this.navaparam.data.modal.componentProps.order_uid;
    this.getOrder(order_uid).subscribe(async (order) => {
      this.order = order;
      if (!order.order_special) {
        this.special_param = await this.calcFeeService.getParam(order).pipe(first()).toPromise();

        switch (order.branch_uid) {
          case BRANCH_CONFIG.SOC_ORDER.UID:
          case BRANCH_CONFIG.PANDA_ORDER.UID:
            this.special_param.cuocvanchuyen = this.special_param.cuocvanchuyen;
            break;
          default:
            this.special_param.cuocvanchuyen = order.order_cpn ? this.special_param.cuocvanchuyennhanh : this.special_param.cuocvanchuyencham;
            break;
        }
      } else {
        this.special_param = order.param;
      }

      this.phantramphidichvudacbiet = new Decimal(this.special_param.phidichvudacbiet).mul(100).toNumber();

      this.user_role = await this.roleService.getRoles(order.order_status_key);
    });
  }

  ngOnDestroy() {
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Bạn muốn thực hiện cập nhật này?')
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const order = { ...this.order };
      const user_login = await this.getUserLogin(this.auth.auth.currentUser.uid).toPromise();
      this.special_param.phidichvudacbiet = new Decimal(this.phantramphidichvudacbiet).div(100).toNumber();
      order.param = this.special_param;
      const order_update: OrderUpdate = await this.calcFeeService.getOrderUpdate(order);

      await this.calcFeeService.revokeOrder(order, {
        special_param: this.special_param,
        ...order_update
      }, user_login, batch);
      if (order.order_status_key === ORDER_STATUS.DAVEKHO.KEY) {
        const { transports } = order;
        let daily_order_storage_fee = 0;
        let orderTransportUid;
        const promises = [];
        transports.forEach(transport => {
          if (transport.date_receive_vn) {
            const storing_days = Math.ceil((new Date().getTime() - transport.date_receive_vn) / 86400000 - Number(this.special_param.ngaybatdauluukho));
            if (storing_days > 0) {
              orderTransportUid = transport.order_transport_uid;
              const warehouse_fee = new Decimal(transport.transport_weight).mul(storing_days).mul(this.special_param.philuukho).toNumber();
              daily_order_storage_fee = new Decimal(warehouse_fee).add(daily_order_storage_fee).roundUp(0).toNumber();
              if (transport.transport_uid) {
                promises.push(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid).update({ warehouse_fee }));
              }
            }
          }
        });
        if (daily_order_storage_fee > 0 && orderTransportUid) {
          const storage_fee_obj = {
            name: 'Phí lưu kho',
            value: daily_order_storage_fee,
            note: 'Hệ thống tự động tính',
            created_date: new Date().getTime(),
            created_user: '',
            type: 1,
            disabled: true
          };
          promises.push(
            fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid)
              .collection('extra_fees').doc(PHILUUKHO).set(storage_fee_obj)
          );
          promises.push(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid)
            .collection('transports').doc(orderTransportUid).update({ storage_fee_date: Date.now() })
          );

          await Promise.all(promises).then(() => {
            return fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(PHILUUKHO).set(storage_fee_obj).then(() => {
              return fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid)
                .collection('transports').doc(orderTransportUid).update({ storage_fee_date: Date.now() });
            });
          }).catch(err => {
            console.log(err);
          });
        }
      }
      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      throw error;
    }
  }

  getUserLogin(uid) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${uid}`).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      first()
    );
  }

  isDefaultBranch() {
    const order = { ...this.order };
    if (order.branch_uid === BRANCH_CONFIG.DEFAULT.UID) return true;
    return false;
  }

  isNewBranch() {
    const order = { ...this.order };
    if (order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID) return true;
    return false;
  }
}
