import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { FIREBASE_STRUCT } from './../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, takeUntil } from 'rxjs/operators';
import { CustomLoading } from '../../app.constant';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-consignment-order-action-add',
  templateUrl: './consignment-order-action-add.component.html',
  styleUrls: ['./consignment-order-action-add.component.css']
})
export class ConsignmentOrderActionAddComponent implements OnInit, OnDestroy {

  uid;
  order_action: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    if (this.uid !== '') {
      this.getOrderAction().subscribe(order_action => {
        this.order_action = order_action;
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getOrderAction() {
    return this.fs.doc(`${FIREBASE_STRUCT.USERS_ADMIN_CONSIGNMENT_ORDER_ACTIONS.NODE}/${this.uid}`).snapshotChanges().pipe(
      map(doc => ({ uid: doc.payload.id, ...doc.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    try {
      if (this.uid !== '') {
        batch.update(fs.doc(`${FIREBASE_STRUCT.USERS_ADMIN_CONSIGNMENT_ORDER_ACTIONS.NODE}/${this.uid}`), {
          code: this.order_action.code || '',
          name: this.order_action.name || '',
          update_date: current
        });
      } else {
        batch.set(fs.collection(`${FIREBASE_STRUCT.USERS_ADMIN_CONSIGNMENT_ORDER_ACTIONS.NODE}`).doc(), {
          code: this.order_action.code || '',
          name: this.order_action.name || '',
          create_date: current
        });

      }

      await batch.commit();
      await this.modalCtrl.dismiss();
      return 'Cập nhật thao tác thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

}
