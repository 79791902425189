import { CustomConfirm, FIREBASE_STRUCT, PAYMENT_HISTORY_TYPE } from '../../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, of } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, map, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-statistic-payment-edit',
  templateUrl: './statistic-payment-edit.component.html',
  styleUrls: ['./statistic-payment-edit.component.css']
})
export class StatisticPaymentEditComponent implements OnInit, OnDestroy {

  uid: any;
  user_login: any;
  statistic_payment: any;
  recharge_type: any;
  recharge_types: any[];
  recharge_locations: any[];
  user: any;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public auth: AngularFireAuth,
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;
    this.recharge_types = [
      { key: 1, value: 'Tiền mặt' },
      { key: 2, value: 'Chuyển khoản' }
    ];

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });

    this.getStatisticPayment(this.uid).subscribe(async (statistic_payment) => {
      this.statistic_payment = statistic_payment;
      this.user = await this.getUserCustomer(this.statistic_payment.customer_user_uid);
      this.recharge_types.forEach(async (recharge_type) => {
        if (this.statistic_payment.payment_name === recharge_type.value) {
          this.recharge_type = recharge_type.key;
          this.recharge_locations = await this.getRechargeLocations(this.recharge_type);
        }
      });
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() as any })),
      takeUntil(this.unsubscribe$)
    );
  }

  getStatisticPayment(uid) {
    return this.fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() as any })),
      takeUntil(this.unsubscribe$)
    );
  }

  onSelectType() {
    const statistic_payment = { ...this.statistic_payment };
    this.recharge_types.forEach(async (recharge_type) => {
      if (statistic_payment.payment_name === recharge_type.value) {
        this.recharge_type = recharge_type.key;
        this.recharge_locations = await this.getRechargeLocations(this.recharge_type);
        this.statistic_payment.location = this.recharge_locations[0].name;
      }
    });
  }

  getRechargeLocations(type): Promise<any[]> {
    if (Number(type) === 1) {
      return this.fs.firestore
        .collection(FIREBASE_STRUCT.LOCATION_PAYMENT_CASH.NODE)
        .orderBy('create_date', 'asc')
        .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
    } else if (Number(type) === 2) {
      return this.fs.firestore
        .collection(FIREBASE_STRUCT.LOCATION_PAYMENT_TRANFER.NODE)
        .orderBy('create_date', 'asc')
        .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
    } else {
      return Promise.resolve([]);
    }

  }

  @CustomConfirm('Xác nhận chỉnh sửa giao dịch này?')
  async onSubmit() {
    try {
      const fs = firebase.firestore();
      const batch = fs.batch();
      if (this.statistic_payment.payment_type_key === PAYMENT_HISTORY_TYPE.NAPTIEN.KEY) {
        await this.updateRechargeMoney(batch);
      } else if (this.statistic_payment.payment_type_key === PAYMENT_HISTORY_TYPE.RUTTIEN.KEY) {
        await this.updateWithDrawMoney(batch);
      }

      await batch.commit();

      this.modalCtrl.dismiss();
      return 'Cập nhật chỉnh sửa thành công';
    } catch (error) {
      throw error;
    }

  }

  async updateRechargeMoney(batch: firebase.firestore.WriteBatch) {
    const fs = firebase.firestore();
    const statisticPayment = { ...this.statistic_payment };
    batch.update(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(this.uid), {
      payment_amount: statisticPayment.payment_amount,
      payment_name: statisticPayment.payment_name,
      location: statisticPayment.location,
      note: statisticPayment.note || '',
    });
  }

  async updateWithDrawMoney(batch: firebase.firestore.WriteBatch) {
    const fs = firebase.firestore();
    const statisticPayment = { ...this.statistic_payment };

    batch.update(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(this.uid), {
      payment_amount: statisticPayment.payment_amount,
      payment_name: statisticPayment.payment_name,
      location: statisticPayment.location,
      note: statisticPayment.note || '',
    });
  }

  getUserCustomer(uid) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.USERS.NODE).doc(uid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }));
  }

}
