import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomLoading, FIREBASE_STRUCT } from 'src/app/app.constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-bill-order-edit',
  templateUrl: './bill-order-edit.component.html',
  styleUrls: ['./bill-order-edit.component.scss'],
})
export class BillOrderEditComponent implements OnInit, OnDestroy {
  uid: any;

  param: any = {};
  unsubscribe$ = new Subject();
  
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid !== '') {
      this.fs.doc(`${FIREBASE_STRUCT.BILL_ORDER_TIME.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(param => {
        this.param = param;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();
      if (this.uid !== '') {
        await this.fs.doc(`${FIREBASE_STRUCT.BILL_ORDER_TIME.NODE}/${this.uid}`).update({
          ...this.param,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      }

      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }
}
