import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { FIREBASE_STRUCT } from '../../../app.constant';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-consignment-detail-action-history',
  templateUrl: './consignment-detail-action-history.component.html',
  styleUrls: ['./consignment-detail-action-history.component.css']
})
export class ConsignmentDetailActionHistoryComponent implements OnInit, OnDestroy {

  action_histories: any[] = [];
  unsubscribe$ = new Subject();
  uid: any;
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public db: AngularFirestore
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
  }

  ngOnInit() {
    this.getActionHistories().subscribe(o => {
      this.action_histories = o;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getActionHistories() {
    return this.db.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(`${this.uid}`).collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY.NODE, query => {
      return query.orderBy('date', 'desc');
    }).snapshotChanges().pipe(
      map(histories => {
        return histories.map(con => ({ uid: con.payload.doc.id, ...con.payload.doc.data() }));
      }),
      takeUntil(this.unsubscribe$)
    );
  }

}
