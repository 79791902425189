import { first, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { FIREBASE_STRUCT } from './../../app.constant';
import { DatePipe } from '@angular/common';
import { CustomLoading } from '../../../app/app.constant';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-parameter-consignment-order-transport-fee-add-or-edit',
  templateUrl: './parameter-consignment-order-transport-fee-add-or-edit.component.html',
  styleUrls: ['./parameter-consignment-order-transport-fee-add-or-edit.component.scss'],
  providers: [DatePipe]
})
export class ParameterConsignmentOrderTransportFeeAddOrEditComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  branchUid: any;
  transportTypeName = '';
  applicationStartDateText = '';
  parameterModel: any = {};

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private datePipe: DatePipe,
    public commonService: CommonUtilsService,
    public fs: AngularFirestore) {
    this.transportTypeName = this.navParams.data.modal.componentProps.transportTypeName;
    this.applicationStartDateText = this.navParams.data.modal.componentProps.applicationStartDateText;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
  }

  async ngOnInit() {
    await this._constructorModel();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  public onAddParameter() {
    const addParameter = {
      name: '',
      fromValue: '',
      toValue: '',
      feeValue: ''
    };
    this.parameterModel.parameters.push(addParameter);
  }

  public onDeleteParameter(index) {
    this.parameterModel.parameters.splice(index, 1);
  }

  @CustomLoading()
  public async onSubmitParameters() {
    try {
      await this._addOrEditParameters();

      this.modalCtrl.dismiss();
    } catch (error) {
      console.log(error);
    }
    return 'Hoàn thành cập nhật tham số đơn ký gửi';
  }

  private async _constructorModel() {
    this.parameterModel.addParameter = {};
    this.parameterModel.parameters = [];
    if (this.transportTypeName === 'fast') {
      if (this.applicationStartDateText) {
        const applicationStartDates = this.applicationStartDateText.split('-');
        const year = Number(applicationStartDates[0]);
        const month = Number(applicationStartDates[1]);
        const day = Number(applicationStartDates[2]);
        this.parameterModel.applicationStartDate = { year, month, day };

        const applicationStartDateTexts: any[] = await this.fs.collection<any[]>(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL.NODE, q => q.where('application_start_date_text', '==', this.applicationStartDateText)).snapshotChanges().pipe(
          map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
          first()
        ).toPromise();

        this.parameterModel.parameters = applicationStartDateTexts[0].parameters;
        this.parameterModel.uid = applicationStartDateTexts[0].uid;
      } else {
        const applicationStartDateTexts: any[] = await this.fs.collection<any[]>(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL.NODE, q => q.orderBy('application_start_date_text', 'desc')).snapshotChanges().pipe(
          map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
          first()
        ).toPromise();

        this.parameterModel.parameters = applicationStartDateTexts[0].parameters;
        this.parameterModel.uid = applicationStartDateTexts[0].uid;
      }
    } else if (this.transportTypeName === 'special') {
      if (this.applicationStartDateText) {
        const applicationStartDates = this.applicationStartDateText.split('-');
        const year = Number(applicationStartDates[0]);
        const month = Number(applicationStartDates[1]);
        const day = Number(applicationStartDates[2]);
        this.parameterModel.applicationStartDate = { year, month, day };

        const applicationStartDateTexts: any[] = await this.fs.collection<any[]>(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL.NODE, q => q.where('application_start_date_text', '==', this.applicationStartDateText)).snapshotChanges().pipe(
          map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
          first()
        ).toPromise();

        this.parameterModel.parameters = applicationStartDateTexts[0].parameters;
        this.parameterModel.uid = applicationStartDateTexts[0].uid;
      } else {
        const applicationStartDateTexts: any[] = await this.fs.collection<any[]>(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL.NODE, q => q.orderBy('application_start_date_text', 'desc')).snapshotChanges().pipe(
          map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
          first()
        ).toPromise();

        this.parameterModel.parameters = applicationStartDateTexts[0].parameters;
        this.parameterModel.uid = applicationStartDateTexts[0].uid;
      }
    }
  }

  private async _addOrEditParameters() {
    const fs = firestore();
    const batch = fs.batch();

    const { year, month, day } = this.parameterModel.applicationStartDate;
    const parameters = this.parameterModel.parameters;
    const applicationStartDate = new Date(year, month - 1, day);
    const applicationStartDateText = this.datePipe.transform(applicationStartDate, 'yyyy-MM-dd');
    const currentDateTime = await this.commonService.getServerTime();

    if (this.transportTypeName === 'fast') {
      const _parameters: any[] = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL.NODE).where('application_start_date_text', '==', applicationStartDateText).get().then(actions => actions.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
      if (_parameters && _parameters.length) {
        batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL.NODE).doc(_parameters[0].uid), {
          application_start_date_text: applicationStartDateText,
          parameters,
          updated_at: currentDateTime
        });
      } else {
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL.NODE).doc(), {
          application_start_date_text: applicationStartDateText,
          parameters,
          branchUid: this.branchUid,
          created_at: currentDateTime,
          updated_at: currentDateTime
        }, { merge: true });
      }
    } else if (this.transportTypeName === 'special') {
      const _parameters: any[] = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL.NODE).where('application_start_date_text', '==', applicationStartDateText).get().then(actions => actions.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
      if (_parameters && _parameters.length) {
        batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL.NODE).doc(_parameters[0].uid), {
          application_start_date_text: applicationStartDateText,
          parameters,
          updated_at: currentDateTime
        });
      } else {
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL.NODE).doc(), {
          application_start_date_text: applicationStartDateText,
          parameters,
          branchUid: this.branchUid,
          created_at: currentDateTime,
          updated_at: currentDateTime
        }, { merge: true });
      }
    }

    await batch.commit();
  }

}
