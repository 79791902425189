import { first, map, takeUntil } from 'rxjs/operators';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CONSIGNMENT_ORDER_REQUEST_EXPORT_STATUS, FIREBASE_STRUCT } from '../../../app.constant';
import { CustomLoading } from '../../../app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { CommonUtilsService } from '../../../services/utils/common-utils.service';

@Component({
  selector: 'app-consignment-order-request-export-add',
  templateUrl: './consignment-order-request-export-add.component.html',
  styleUrls: ['./consignment-order-request-export-add.component.css']
})
export class ConsignmentOrderRequestExportAddComponent implements OnInit, OnDestroy {

  uid: any;
  request: any = {};
  requests: any[] = [];
  unsubscribe$ = new Subject();
  consignment_orders: any[] = [];

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;

    this.request = {
      order: [],
      requester_note: null,
      request_priority: 'Bình thường'
    };
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUser(uid) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${uid}`).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onFindCustomer(ev) {
    try {
      const user: any = await this.fs.firestore.collection(FIREBASE_STRUCT.USERS.NODE)
        .where('id', '==', `${this.request.customer_id}`)
        .get().then(snapshot => {
          if (snapshot.size) {
            const d = snapshot.docs[0];
            return { ...d.data(), uid: d.id };
          } else {
            return undefined;
          }
        });
      if (user) {
        this.request.customer_name = user.full_name;
        this.request.branch_uid = user.branch_uid;
        this.request.orders_search = await this.getConsignmentOrders(this.request.customer_id);
      } else {
        throw new Error('Không tìm thấy khách hàng tương ứng. Vui lòng nhập lại');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getConsignmentOrders(customer_id) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE)
      .where('customer_id', '==', customer_id)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  @CustomLoading()
  async onAddRequest() {
    try {
      if (!this.request.customer_id) { throw new Error(('Id khách hàng không hợp lệ. Vui lòng kiểm tra lại.')); }
      if (!this.request.customer_name) { throw new Error(('Id khách hàng không hợp lệ. Vui lòng kiểm tra lại.')); }
      if (!this.request.consignment_orders || !this.request.consignment_orders.length) { throw new Error(('Mã đơn ký gửi không hợp lệ. Vui lòng kiểm tra lại.')); }
      if (!this.request.request_priority) { throw new Error(('Chưa chọn mức độ yêu cầu. Vui lòng kiểm tra lại.')); }

      this.requests.push({
        ...this.request,
        requester: this.auth.auth.currentUser.displayName,
        request_date: await this.commonService.getServerTime(),
        status_key: CONSIGNMENT_ORDER_REQUEST_EXPORT_STATUS.CHUAXULY.KEY,
        status_text: CONSIGNMENT_ORDER_REQUEST_EXPORT_STATUS.CHUAXULY.VALUE,
      });
      this.request = {
        consignment_orders: [],
        requester_note: null,
        request_priority: 'Bình thường'
      };
    } catch (error) {
      throw error;
    }
  }

  onRemoveRequest(i) {
    this.requests.splice(i, 1);
  }

  @CustomLoading()
  async onAddRequests() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      let b = true;
      for (const request of this.requests) {
        b = await this.checkRequest(request);
        const r = { ...request };
        delete r.orders_search;
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_REQUEST_EXPORT.NODE).doc(), r);
      }

      if (b) {
        await batch.commit();

      } else {
        throw new Error('Dữ liệu nhập không đúng');
      }
      this.modalCtrl.dismiss();

      return 'Thêm yêu cầu thành công';
    } catch (error) {
      throw error;
    }
  }

  async checkRequest(request) {
    if (!request.customer_id) { return false; }
    if (!request.customer_name) { return false; }
    if (!request.request_priority) { return false; }
    return true;
  }

}
