import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExcelService } from '../../../services/excel/excel.service';
import { CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { ApiService } from '../../../services/api/api.service';
import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Decimal } from '../../../app.models';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-by-import-excel',
  templateUrl: './create-by-import-excel.component.html',
  styleUrls: ['./create-by-import-excel.component.css']
})
export class CreateByImportExcelComponent implements OnInit {

  file: any;
  transportRecords: any[] = [];
  weightConversionFactor = 0;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public excelService: ExcelService,
    public fs: AngularFirestore,
    public api: ApiService,
    public commonService: CommonUtilsService
  ) { }

  async ngOnInit() {
    this.weightConversionFactor = Number(await this._getWeightConversionFactor());
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  public onChoosen(ev) {
    try {
      const header = ['transport_code', 'transport_real_kg', 'transport_product_quantity', 'transport_product_type', 'transport_length', 'transport_width', 'transport_height', 'transport_exchanged_kg'];
      this.excelService.processFileToJson(ev.target.files[0], header).then(data => {
        const transportRecords = [];

        for (const key in data.sheets) {
          if (data.sheets.hasOwnProperty(key)) {
            const element: any[] = data.sheets[key];
            element.forEach(e => {
              if (e.__rowNum__ > 0) {
                if (!e.transport_real_kg) { e.transport_real_kg = 0; }
                if (!e.transport_product_quantity) { e.transport_product_quantity = 0; }
                if (!e.transport_product_type) { e.transport_product_type = ''; }

                if (!e.transport_length) { e.transport_length = 0; }
                if (!e.transport_width) { e.transport_width = 0; }
                if (!e.transport_height) { e.transport_height = 0; }
                if (!e.transport_exchanged_kg) { e.transport_exchanged_kg = 0; }

                e.transport_exchanged_kg = new Decimal(e.transport_length).mul(e.transport_width).mul(e.transport_height).div(7000).roundUp(2).toNumber();
                transportRecords.push(e);
              }
            });
          }
        }
        this.transportRecords = transportRecords;
      });
    } catch (error) {
      throw error;
    }
  }

  @CustomLoading()
  public async onSubmit() {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          skipauthorization: 'true'
        })
      };
      const transportRecords = Array.from(new Set(this.transportRecords.map(tran => String(tran.transport_code).trim())))
        .map(code => {
          return this.transportRecords.find(tran => String(tran.transport_code).trim() === code);
        });
      await this.api.post('admin/warehouses/create', transportRecords, httpOptions);
      // const result = await this._addTransports();
      await this.modalCtrl.dismiss();
      return 'Thêm mã vận đơn thành công';
    } catch (error) {
      console.log(error);
      return error.error.message;
    }

  }

  private _getWeightConversionFactor() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() as any })),
      first()
    ).toPromise();
  }
}
