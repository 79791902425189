export interface SmsModel {
  address: string;
  amount?: number;
  body: string;
  content?: string;
  date: number;
  date_sent: number;
  dateSuccess?: number;
  paymentId?: number;
  paymentNote?: string;
  service_center: string;
  statusKey?: number;
  statusText?: string;
  uid: string;
  userId?: string;
  userFullName?: string;
  userEmail?: string;
  userUpdatedId?: string;
  userUpdatedFullName?: string;
  user?: any;
  branch_uid: string;
}

export const SMS = {
  ACB: 'ACB',
//   AGRIBANK: "AGRIBANK",
  BIDV: 'BIDV',
  MBBANK: 'MBBANK',
  SACOMBANK: 'Sacombank',
  SCB: 'SCB',
  SHB: 'SHB',
  TECHCOMBANK: 'Techcombank',
  TPBANK: 'TPBank',
  VIB: 'VIB',
  VIETCOMBANK: 'Vietcombank',
  VIETINBANK: 'VietinBank',
  VPBANK: 'VPBank',
};

export const PaymentStatus = {
  SUCCESS: {
    KEY: 1,
    VALUE: 'Giao dịch thành công',
  },
  FAILED: {
    KEY: 2,
    VALUE: 'Giao dịch lỗi',
  },
  OTHER: {
    KEY: 3,
    VALUE: 'Giao dịch khác',
  },
  DELAY: {
    KEY: 4,
    VALUE: 'Giao dịch delay',
  },
};
