import { Pipe, PipeTransform } from '@angular/core';
import { Decimal } from '../../app.models';

const CURRENCIES = {
  CNY: ' ¥',
  VND: ' ₫',
  KG: ' kg',
};

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  transform(value: number, currerncy_code?: string, group_sep?: string, exp?: number): any {
    if (value === null || value === undefined) { value = 0; }
    // value = Math.ceil(value);
    const CURRENCIES_CHAR = currerncy_code ? (CURRENCIES[currerncy_code.toUpperCase()] || null) : null;
    const GROUP_SEP = group_sep ? group_sep : ',';
    const roundUp = (x, e?) => {
      e = e || 0;
      return new Decimal(Math.ceil(new Decimal(x).mul(Math.pow(10, e)).toNumber())).div(Math.pow(10, e)).toNumber();
    };
    const numberWithGroupSepAndCurencyChar = (x) => {
      return roundUp(x, exp).toString().replace(/\B(?=(\d{3})+(?!\d))/g, GROUP_SEP) + CURRENCIES_CHAR;
    };
    return numberWithGroupSepAndCurencyChar(value);
  }

}
