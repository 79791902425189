import { ExternalUrlDirective } from './external-url/external-url.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { NumberInputDirective } from './number-input/number-input.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        NgSelectModule,
        PipesModule
    ],
    declarations: [
        NumberInputDirective,
        ExternalUrlDirective
    ],
    entryComponents: [
    ],
    exports : [
        NumberInputDirective,
        ExternalUrlDirective
    ]
})
export class DirectiveslsModule { }
