import { CommonUtilsService } from '../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { BILL_STATUS, CustomConfirm, CustomLoading, FIREBASE_STRUCT, ORDER_STATUS, SHOP_REFUND_STATUS, validObject } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../services/roles/roles.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Decimal } from 'src/app/app.models';

@Component({
  selector: 'app-bills-sync-commission',
  templateUrl: './bills-sync-commission.html',
  styleUrls: ['./bills-sync-commission.css']
})
export class BillsSyncCommissionComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  syncBills: any[] = [];
  searchInfo = {
    access_token: '',
    platform: '10',
    time_type: '1',
    time_from_date: {
      year: 0,
      month: 0,
      day: 0
    },
    time_to_date: {
      year: 0,
      month: 0,
      day: 0
    },
    time_from: '',
    time_to: '',
    trade_id: '',
    page_index: 1,
    page_size: 50
  };

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public loadingCtrl: LoadingController,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public commonService: CommonUtilsService,
    public httpClient: HttpClient
  ) { }

  ngOnInit() {
    this._initData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  public async onSyncCommissionBills() {
    const ALIBO_API_BASE_URL = 'https://alibo.vn/partner_transaction/';
    const ALIBO_API_ACCESS_TOKEN = 'df6aef389e9be43ed829080b7095ef82';

    const _searchInfo = { ...this.searchInfo };
    let timeFrom = 0;
    let timeFromBills = 0;
    let timeTo = 0;
    _searchInfo.access_token = ALIBO_API_ACCESS_TOKEN;
    if (_searchInfo.time_from_date) {
      const { year, month, day } = _searchInfo.time_from_date;
      _searchInfo.time_from = String(day < 10 ? '0' + day : day) + '-' + String(month < 9 ? '0' + month : month) + '-' + String(year);
      timeFrom = new Date(year, month - 1, day).getTime();
      timeFromBills = new Date(year, 0, day).getTime();
    }

    if (_searchInfo.time_to_date) {
      const { year, month, day } = _searchInfo.time_to_date;
      _searchInfo.time_to = String(day < 10 ? '0' + day : day) + '-' + String(month < 9 ? '0' + month : month) + '-' + String(year);
      timeTo = new Date(year, month - 1, day).getTime();
    }

    const syncCommissions: any[] = [];
    const result: any = JSON.parse(await this.httpClient.post(ALIBO_API_BASE_URL, _searchInfo).pipe(
      map(res => JSON.stringify(res))
    ).toPromise());
    if (result.data) {
      for (const e of result.data) {
        if (e.product_state !== 3) {
          syncCommissions.push(e);
        }
      }
    }

    const totalPages = Math.ceil(new Decimal(result.total_record).div(_searchInfo.page_size).toNumber());
    for (let i = _searchInfo.page_index; i < totalPages; i++) {
      _searchInfo.page_index = i;
      const _result: any = JSON.parse(await this.httpClient.post(ALIBO_API_BASE_URL, _searchInfo).pipe(
        map(res => JSON.stringify(res))
      ).toPromise());
      if (_result.data) {
        for (const e of _result.data) {
          if (e.product_state !== 3) {
            syncCommissions.push(e);
          }
        }
      }
    }

    const fs = firebase.firestore();
    const billsRef = fs.collection(FIREBASE_STRUCT.BILLS.NODE);
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query;

    query = billsRef.orderBy('date_created', 'desc');
    if (timeFromBills) { query = query.where('date_created', '>=', timeFromBills); }
    if (timeTo) { query = query.where('date_created', '<=', timeTo); }

    const synclBills: any[] = [];
    await query.get().then(async (collections) => {
      for (const doc of collections.docs) {
        synclBills.push({ uid: doc.id, ...doc.data() });
      }
      return synclBills;
    });

    this.syncBills = [];
    let updateBills = [];
    for (const syncBill of synclBills) {
      let totalCommissionFee = 0;
      const totalCommissionFeeOrders = 0;
      let tradeId = '';
      for (const syncCommission of syncCommissions) {
        if (syncBill.bill_code === syncCommission.trade_id) {
          totalCommissionFee = new Decimal(totalCommissionFee).add(syncCommission.commission_amount).toNumber();
          tradeId = syncCommission.trade_id;
        }
      }

      this.syncBills.push({
        bill_code: syncBill.bill_code,
        orderUid: syncBill.order_uid,
        trade_id: tradeId,
        commission_fee: totalCommissionFee
      });

      updateBills.push({
        billUid: syncBill.uid,
        orderUid: syncBill.order_uid,
        totalCommissionFee
      });

      if (updateBills.length === 249) {
        const batch = fs.batch();
        for (const updateBill of updateBills) {
          batch.update(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(updateBill.billUid), {
            order_total_commission_fee: updateBill.totalCommissionFee
          });

          batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(updateBill.orderUid), {
            order_total_commission_fee: updateBill.totalCommissionFee
          });
        }
        await batch.commit();
        updateBills = [];
      }
    }

    if (updateBills.length) {
      const batch = fs.batch();
      for (const updateBill of updateBills) {
        batch.update(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(updateBill.billUid), {
          order_total_commission_fee: updateBill.totalCommissionFee
        });
      }
      await batch.commit();
      updateBills = [];
    }

    /* this.modalCtrl.dismiss(); */
    return 'Hoàn thành đồng bộ chiết khấu';
  }

  private _initData() {
    const currentDateTime = new Date();
    this.searchInfo.time_from_date.year = 2022;
    this.searchInfo.time_from_date.month = 2;
    this.searchInfo.time_from_date.day = 1;

    this.searchInfo.time_to_date.year = currentDateTime.getFullYear();
    this.searchInfo.time_to_date.month = currentDateTime.getMonth() + 1;
    this.searchInfo.time_to_date.day = currentDateTime.getDate();
  }
}
