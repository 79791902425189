import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { FIREBASE_STRUCT } from './../../app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomLoading } from '../../app.constant';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { RolesService } from '../../services/roles/roles.service';

@Component({
  selector: 'app-order-link-action',
  templateUrl: './order-link-action.component.html',
  styleUrls: ['./order-link-action.component.css']
})
export class OrderLinkActionComponent implements OnInit, OnDestroy {

  uid: any;
  uid_products: any[];
  order_products: any[];
  order_link_action: any = {};
  links_checked: string;
  order: any;
  unsubscribe$ = new Subject();
  list_roles: any[] = [];
  user_role: any = {};
  user_login: any;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.uid_products = this.navParams.data.modal.componentProps.uid_products;
    this.links_checked = this.navParams.data.modal.componentProps.links_checked;

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });
    this.getOrderLinks(this.uid).subscribe(async (order) => {
      this.order_products = [];
      order.products.forEach(product => {
        this.uid_products.forEach(uid => {
          if (product.uid === uid) {
            this.order_products.push(product);
          }
        });
      });

      this.order = order;
      this.user_role = await rolesService.getRoles(this.order.order_status_key);
    });

    this.order_link_action = {
      is_option1: false,
      is_option2: false,
      is_option3: false,
      is_option4: false,
      is_option5: false,
      is_option6: false,
    };
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getOrderLinks(uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(uid).valueChanges().pipe(
      switchMap((order: any) => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(uid).collection('products').snapshotChanges().pipe(
          map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
          map(products => ({ ...order, products, uid }))
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const fs = firebase.firestore();
      const batch = fs.batch();
      const currentTime = await this.commonService.getServerTime();
      this.order_products.forEach(product => {
        this.updateProduct(batch, product, currentTime);
      });
      await batch.commit();
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      console.log(error);
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  updateProduct(batch: firebase.firestore.WriteBatch, product, currentTime) {
    const updateObj = {
      quantity_order: this.order_link_action.is_option1 ? product.product_quantity : this.order_link_action.is_option3 ? 0 : product.quantity_order,
      quantity_warehouse: this.order_link_action.is_option2 ? product.product_quantity : this.order_link_action.is_option4 ? 0 : product.quantity_warehouse
    };
    batch.update(firebase.firestore().collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('products').doc(product.uid), updateObj);
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
    );
  }

  onCheckQuantityOrder(checkType) {
    switch (String(checkType)) {
      case 'option1':
        this.order_link_action.is_option1 = !this.order_link_action.is_option1;
        if (this.order_link_action.is_option1) {
          this.order_link_action.is_option3 = false;
        }
        break;
      case 'option3':
        this.order_link_action.is_option3 = !this.order_link_action.is_option3;
        if (this.order_link_action.is_option3) {
          this.order_link_action.is_option1 = false;
        }
        break;
      case 'option2':
        this.order_link_action.is_option2 = !this.order_link_action.is_option2;
        if (this.order_link_action.is_option2) {
          this.order_link_action.is_option4 = false;
        }
        break;
      case 'option4':
        this.order_link_action.is_option4 = !this.order_link_action.is_option4;
        if (this.order_link_action.is_option4) {
          this.order_link_action.is_option2 = false;
        }
        break;
      case 'option5':
        this.order_link_action.is_option5 = !this.order_link_action.is_option5;
        if (this.order_link_action.is_option5) {
          this.order_link_action.is_option6 = false;
        }
        break;
      case 'option6':
        this.order_link_action.is_option6 = !this.order_link_action.is_option6;
        if (this.order_link_action.is_option6) {
          this.order_link_action.is_option5 = false;
        }
        break;
      default:
        this.order_link_action.is_option1 = false;
        this.order_link_action.is_option3 = false;
        break;
    }
  }
}
