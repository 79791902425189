import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from '../../services/api/api.service';
import { Decimal } from '../../app.models';
import { firestore } from 'firebase/app';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

@Component({
  selector: 'app-customer-mng-edit',
  templateUrl: './customer-mng-edit.component.html',
  styleUrls: ['./customer-mng-edit.component.css'],
  providers: [DatePipe],
})
export class CustomerMngEditComponent implements OnInit, OnDestroy {
  user: any;
  unsubscribe$ = new Subject();
  vip_types: any[];
  vip_type: any;
  vip_type_sub = new BehaviorSubject<number>(0);
  vips: any[] = [];
  vip: any;
  consignment_vip_types: any[];
  consignment_vip_type: any;
  consignment_vip_type_sub = new BehaviorSubject<number>(0);
  consignment_vips: any[] = [];
  consignment_vip: any;
  edit_level = false;
  is_edit = false;
  is_edit_password = false;
  last_email: string;
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public api: ApiService,
    public commonService: CommonUtilsService,
    private datePipe: DatePipe
  ) {
    const customer_uid = this.navParams.data.modal.componentProps.customer_uid;
    this.is_edit = this.navParams.data.modal.componentProps.is_edit || false;
    this.is_edit_password =
      this.navParams.data.modal.componentProps.is_edit_password || false;

    this.getCustomerInfo(customer_uid).subscribe((u: any) => {
      this.user = u;
      const dateBirthday = this._getYearMonthDayFromDateNumber(u.date_birthday);
      this.user.date_birthday = new NgbDate(
        dateBirthday.year,
        dateBirthday.month,
        dateBirthday.day
      );
      this.last_email = u.email;
      this.vip = u.vip;
      this.vip_type = u.vip.type;
      this.vip_type_sub.next(this.vip_type);
      this.consignment_vip = u.consignment_vip;
      if (u.consignment_vip) {
        this.consignment_vip_type = u.consignment_vip.type;
        this.consignment_vip_type_sub.next(this.consignment_vip_type);
      }
      this.edit_level =
        this.navParams.data.modal.componentProps.edit_level || false;
    });
    this.vip_types = [
      { key: 1, value: 'Vip thường' },
      { key: 2, value: 'Vip đặc biệt' },
    ];

    this.consignment_vip_types = [
      { key: 1, value: 'Vip thường' },
      { key: 2, value: 'Vip đặc biệt' },
    ];

    this.getVips().subscribe((vips) => {
      this.vips = vips;
    });

    this.getConsignmentVips().subscribe((vips) => {
      this.consignment_vips = vips;
    });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onSelectType() {
    this.vip = {
      type: this.vip_type,
    };
    this.vip_type_sub.next(this.vip_type);
  }

  onSelectConsignmentType() {
    this.consignment_vip = {
      type: this.consignment_vip_type,
    };
    this.consignment_vip_type_sub.next(this.consignment_vip_type);
  }

  onChangeRevenueFirst(ev) {
    this.user.revenue_money_first = Number(this.user.revenue_money_first || 0);
    this.user.revenue_money_now = Number(this.user.revenue_money_now || 0);
    this.user.revenue_money = new Decimal(this.user.revenue_money_first)
      .add(this.user.revenue_money_now)
      .toNumber();
  }

  conpareFn(val1, val2) {
    if (!val1 || !val2) {
      return false;
    }
    return val1.uid === val2.uid;
  }

  getVips() {
    return this.vip_type_sub.pipe(
      switchMap((type) => {
        if (Number(type) === 1) {
          return this.fs
            .collection<any[]>(
              `${FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE}`,
              (q) => {
                return q.orderBy('create_date');
              }
            )
            .snapshotChanges()
            .pipe(
              map((sns) =>
                sns.map((sn) => ({
                  uid: sn.payload.doc.id,
                  ...sn.payload.doc.data(),
                }))
              )
            );
        } else if (Number(type) === 2) {
          return this.fs
            .collection<any[]>(
              `${FIREBASE_STRUCT.PARAMETER_VIPS_SPECIAL.NODE}`,
              (q) => {
                return q.orderBy('create_date');
              }
            )
            .snapshotChanges()
            .pipe(
              map((sns) =>
                sns.map((sn) => ({
                  uid: sn.payload.doc.id,
                  ...sn.payload.doc.data(),
                }))
              )
            );
        } else {
          return of([]);
        }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getConsignmentVips() {
    return this.consignment_vip_type_sub.pipe(
      switchMap((type) => {
        if (Number(type) === 1) {
          return this.fs
            .collection<any[]>(
              `${FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE}`,
              (q) => {
                return q.orderBy('create_date');
              }
            )
            .snapshotChanges()
            .pipe(
              map((sns) =>
                sns.map((sn) => ({
                  uid: sn.payload.doc.id,
                  ...sn.payload.doc.data(),
                }))
              )
            );
        } else if (Number(type) === 2) {
          return this.fs
            .collection<any[]>(
              `${FIREBASE_STRUCT.PARAMETER_CONSIGNMENT_VIPS_SPECIAL.NODE}`,
              (q) => {
                return q.orderBy('create_date');
              }
            )
            .snapshotChanges()
            .pipe(
              map((sns) =>
                sns.map((sn) => ({
                  uid: sn.payload.doc.id,
                  ...sn.payload.doc.data(),
                }))
              )
            );
        } else {
          return of([]);
        }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getCustomerInfo(uid) {
    return this.fs
      .doc<any>(`${FIREBASE_STRUCT.USERS.NODE}/${uid}`)
      .snapshotChanges()
      .pipe(
        map((sn) => ({
          uid: sn.payload.id,
          ...sn.payload.data(),
          date_birthday: null,
        })),
        takeUntil(this.unsubscribe$)
      );
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const customer_uid =
        this.navParams.data.modal.componentProps.customer_uid;
      const {
        branch_uid = null,
        full_name = null,
        email = null,
        password = null,
        phone = null,
        receive_address = null,
        sex = null,
        register_channel = null,
        credit_limit = null,
        revenue_money_first = 0,
        revenue_money_now = 0,
        revenue_money = 0,
        receive_warehouse_code = null,
        customer_type = null,
      } = this.user;

      const phoneNumber = this._getPhoneNumberFormat(phone);
      const msgPhoneNumberValid = await this._isValidPhoneNumber(phoneNumber, customer_uid, branch_uid);
      if (msgPhoneNumberValid) throw new Error(msgPhoneNumberValid);

      const branch: any = await this.fs.firestore
        .collection(FIREBASE_STRUCT.BRANCHS.NODE)
        .doc(branch_uid)
        .get()
        .then((snapshot) => ({ uid: snapshot.id, ...snapshot.data() }));
      const { email_prefix } = branch;
      const branch_email = email_prefix + email;
      let date_birthday = null;
      let receive_warehouse_name = null;
      const vip = {
        type: Number(this.vip_type),
        uid: this.vip.uid,
      };
      if (vip.type === 2 && !vip.uid) {
        throw new Error('Vui lòng chọn cấp độ vip đơn hàng');
      }
      if (vip.type === 1) {
        vip.uid = 'ZkL9BNAlsDK1Zt42QvVj';
      }
      const consignment_vip = {
        type: Number(this.consignment_vip_type),
        uid: this.consignment_vip ? this.consignment_vip.uid : null,
      };
      if (consignment_vip.type === 2 && !consignment_vip.uid) {
        throw new Error('Vui lòng chọn cấp độ vip đơn ký gửi');
      }
      if (consignment_vip.type === 1) {
        consignment_vip.uid = 'ZkL9BNAlsDK1Zt42QvVj';
      }
      const availableLimit = new Decimal(credit_limit)
        .add(this.user.account_balance)
        .toNumber();
      if (receive_warehouse_code) {
        receive_warehouse_name =
          receive_warehouse_code === 'HN' ? 'Kho Hà Nội' : 'Kho Sài Gòn';
      }

      if (date_birthday) {
        const { year, month, day } = date_birthday;
        date_birthday = new Date(year, month - 1, day).getTime();
      }

      const updateUser: any = {
        full_name,
        email,
        email_prefix,
        branch_email,
        phone: phoneNumber,
        receive_address,
        date_birthday,
        sex,
        register_channel,
        credit_limit,
        available_limit: availableLimit,
        had_credit_limit: credit_limit > 0 ? true : false,
        date_credit_limit_created:
          credit_limit > 0
            ? await this.commonService.getServerTime()
            : firestore.FieldValue.delete(),
        vip,
        consignment_vip,
        revenue_money_first,
        revenue_money_now,
        revenue_money,
        receive_warehouse_code,
        receive_warehouse_name,
        customer_type: customer_type
          ? customer_type
          : firestore.FieldValue.delete(),
      };

      if (password) {
        updateUser.current_password = password;
      }

      const updateAuth: any = {
        uid: customer_uid,
        email,
        password,
        branch_uid
      };

      await this.api.post('users/updatePassword', updateAuth);
      await this.fs
        .collection(FIREBASE_STRUCT.USERS.NODE)
        .doc(this.user.uid)
        .update(updateUser);
      this.modalCtrl.dismiss();
      return 'Cập nhật user thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  @CustomLoading()
  async onVerifiedEmail() {
    try {
      const user = { ...this.user };
      const customer_uid =
        this.navParams.data.modal.componentProps.customer_uid;
      /* await this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${customer_uid}`).update({
        is_verified: true
      }); */
      await this.api.post('users/verifiedEmail', {
        uid: customer_uid,
        is_verified: true,
        branch_uid: user.branch_uid
      });

      return 'Kích hoạt tài khoản thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private _getYearMonthDayFromDateNumber(dateNumber) {
    if (!dateNumber) {
      return {
        year: 1970,
        month: 1,
        day: 1,
      };
    }

    const userBirthday = new Date(dateNumber);
    const dateString = this.datePipe.transform(userBirthday, 'yyyy-MM-dd');
    const dates = dateString.split('-');
    return {
      year: Number(dates[0]),
      month: Number(dates[1]),
      day: Number(dates[2]),
    };
  }

  private async _isValidPhoneNumber(phoneNumber: string, customerUid: string, branchUid: string): Promise<string> {
    try {
      if (phoneNumber) {
        const customers: any[] = await this.fs.firestore
          .collection(FIREBASE_STRUCT.USERS_ACCOUNT.NODE)
          .where("phone", "==", phoneNumber)
          .where("branch_uid", "==", branchUid)
          .get()
          .then((snaps) => snaps.docs.map((d) => ({ ...d.data(), uid: d.id })));

        if (customers.length) {
          if (customers[0].uid !== customerUid) return "Số điện thoại đã được sử dụng. Vui lòng sử dụng số điện thoại khác.";
        }

        return "";
      } else {
        return "Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại hợp lệ.";
      }
    } catch (error) {
      return "Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại hợp lệ.";
    }
  }

  private _getPhoneNumberFormat(phoneNumber: string) {
    try {
      const isValidPhoneNumber =
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(phoneNumber.replace('+84', '0'));

      if (isValidPhoneNumber) {
        const PNF = PhoneNumberFormat;
        const phoneUltil = PhoneNumberUtil.getInstance();
        const _phoneNumber = phoneUltil.parseAndKeepRawInput(phoneNumber, "vn");
        const retPhoneNumber = phoneUltil.format(_phoneNumber, PNF.E164).trim();

        return retPhoneNumber;
      }

      return null;
    } catch (error) {
      return null;
    }
  }
}
