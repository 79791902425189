import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ReflectiveInjector } from '@angular/core';
import { AlertComponent } from './modals/alert/alert.component';

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
export const PAGE_SIZE = 100;

export const EMAIL_SUFFFIX = `@nhod.com`;


export const PAGINATOR_SIZE = 50;

export const TEMP_ADMIN_UID = 'UPcr823ZEt5D8DGLJYG9';

export const SG_RECEIVE_WAREHOUSE = {
  CODE: 'SG',
  NAME: 'Kho Sài Gòn'
};

export function validObject(object) {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      if (element === null || element === undefined) { delete object[key]; }
    }
  }
}

export function isNullOrEmpty(value) {
  return (!value || value === undefined || value === '' || value.length === 0);
}

export function CustomConfirm(header: string): MethodDecorator {
  // tslint:disable-next-line: deprecation
  const injector = ReflectiveInjector.resolveAndCreate([AlertController, LoadingController, ToastController]);
  const alertCtrl: AlertController = injector.get(AlertController); /* injector.get(AlertController); */
  const loadingCtrl: LoadingController = injector.get(LoadingController);
  const toastCtrl: ToastController = injector.get(ToastController);
  return (target: () => void, key: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      if (!this.modalCtrl) {
        alertCtrl.create({
          header,
          buttons: [
            {
              text: 'Đồng ý',
              cssClass: '',
              handler: () => {
                loadingCtrl.create({
                  spinner: 'dots'
                }).then((loading) => {
                  loading.present().then(() => {
                    return originalMethod.apply(this, args);
                  }).then((msgSuccess) => {
                    loading.dismiss();
                    toastCtrl.create({
                      message: msgSuccess,
                      position: 'top',
                      cssClass: 'msg-success',
                      color: 'success',
                      duration: 2000,
                    }).then((toast) => {
                      toast.present();
                    });
                  }).catch(msgError => {
                    loading.dismiss();
                    toastCtrl.create({
                      message: msgError.message,
                      position: 'top',
                      cssClass: 'msg-error',
                      color: 'danger',
                      duration: 2000,
                    }).then((toast) => {
                      toast.present();
                    });
                  });
                });
              }
            },
            {
              role: 'cancel',
              text: 'Hủy'
            }
          ]
        }).then(alert => {
          alert.present();
        });
      } else {
        (this.modalCtrl as ModalController).create({
          component: AlertComponent,
          componentProps: { header },
          cssClass: 'modal-xsm'
        }).then(modal => {
          modal.present();
          modal.onDidDismiss().then((v) => {
            if (v.data) {
              loadingCtrl.create({
                spinner: 'dots'
              }).then((loading) => {
                loading.present().then(() => {
                  return originalMethod.apply(this, args);
                }).then((msgSuccess) => {
                  loading.dismiss();
                  toastCtrl.create({
                    message: msgSuccess,
                    position: 'top',
                    cssClass: 'msg-success',
                    color: 'success',
                    duration: 2000,
                  }).then((toast) => {
                    toast.present();
                  });
                }).catch(msgError => {
                  loading.dismiss();
                  toastCtrl.create({
                    message: msgError.message,
                    position: 'top',
                    cssClass: 'msg-error',
                    color: 'danger',
                    duration: 2000,
                  }).then((toast) => {
                    toast.present();
                  });
                });
              });
            }
          });
        });
      }
    };
    return descriptor;
  };
}

export function CustomLoading(): MethodDecorator {
  // tslint:disable-next-line: deprecation
  const injector = ReflectiveInjector.resolveAndCreate([AlertController, LoadingController, ToastController]);
  const loadingCtrl: LoadingController = injector.get(LoadingController);
  const toastCtrl: ToastController = injector.get(ToastController);
  return (target: () => void, key: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      /* this.translateService.get('DELIVER_BAG.TITLE').subscribe(val => {
          console.log(val)
      }); */
      loadingCtrl.create({
        spinner: 'dots'
      }).then((loading) => {
        loading.present().then(() => {
          return originalMethod.apply(this, args);
        }).then((msgSuccess) => {
          loading.dismiss();
          if (msgSuccess) {
            toastCtrl.create({
              message: msgSuccess,
              position: 'top',
              cssClass: 'msg-success',
              duration: 2000,
            }).then((toast) => {
              toast.present();
            });
          }
        }).catch(msgError => {
          console.log(msgError);
          loading.dismiss();
          toastCtrl.create({
            message: msgError.message || msgError,
            position: 'top',
            cssClass: 'msg-error',
            duration: 2000,
          }).then((toast) => {
            toast.present();
          });
        });
      });
    };
    return descriptor;
  };
}

export const PARAM_ORDER_DEFAULT_DOC_ID = {
  RATE_FOR_CUSTOMER: 'rate_for_customer',
  RATE_REAL: 'rate_real',
  CLOSE_WOOD_FEE_FIRST_KG: 'close_wood_fee_first_kg',
  CLOSE_WOOD_FEE_CONTINUE_KG: 'close_wood_fee_continue_kg',
  TRANFER_FEE: 'tranfer_fee',
  SERVICE_FEE_MIN: 'service_fee_min',
  STORAGE_FEE: 'storage_fee',
  STORAGE_FEE_START_CALCULATE: 'storage_fee_start_calculate',
  ORDER_AUTO_FINISH_DATE: 'order_auto_finish_date',
  ORDER_AUTO_DELETE_DATE: 'order_auto_delete_date',
  PRODUCT_AUTO_DELETE_DATE: 'product_auto_delete_date'
};

export const ORDER_STATUS = {
  CHOBAOGIA: {
    KEY: 0,
    // VALUE: 'Chờ báo giá',
    VALUE: 'CHỜ BÁO GIÁ'
  },
  DABAOGIA: {
    KEY: 1,
    // VALUE: 'Đã báo giá'
    VALUE: 'ĐÃ BÁO GIÁ'
  },
  DATCOC: {
    KEY: 2,
    // VALUE: 'Đặt cọc'
    VALUE: 'ĐẶT CỌC'
  },
  CHOXACNHAN: {
    KEY: 2.1,
    VALUE: 'CHỜ XÁC NHẬN',
    SLUG: 'cho-xac-nhan'
  },
  DAXACNHAN: {
    KEY: 2.2,
    VALUE: 'ĐÃ XÁC NHẬN',
    SLUG: 'da-xac-nhan'
  },
  DATHANG: {
    KEY: 3,
    // VALUE: 'Đặt hàng'
    VALUE: 'ĐẶT HÀNG'
  },
  THANHTOANCHOSHOP: {
    KEY: 4,
    // VALUE: 'Thanh toán cho shop'
    VALUE: 'THANH TOÁN CHO SHOP'
  },
  DAVEKHOTQ: {
    KEY: 5,
    // VALUE: 'Đã về kho TQ'
    VALUE: 'ĐÃ VỀ KHO TQ'
  },
  VANCHUYENTQVN: {
    KEY: 6,
    // VALUE: 'Vận chuyển TQ-VN'
    VALUE: 'VẬN CHUYỂN TQ-VN'
  },
  /* DAVEVN: {
    KEY: 7,
    // VALUE: 'Đã về VN'
    VALUE: 'ĐÃ VỀ VN'
  }, */
  VANCHUYEN_HN_SG: {
    KEY: 7.1,
    VALUE: 'VẬN CHUYỂN HN-SG'
  },
  DAVEKHO: {
    KEY: 7.2,
    VALUE: 'ĐÃ VỀ KHO'
  },
  DATHANHTOAN: {
    KEY: 8,
    // VALUE: 'Đã thanh toán'
    VALUE: 'ĐÃ THANH TOÁN'
  },
  GIAOHANG: {
    KEY: 9,
    VALUE: 'GIAO HÀNG'
  },
  DAGIAO: {
    KEY: 10,
    // VALUE: 'Đã giao'
    VALUE: 'ĐÃ GIAO'
  },
  HOANTHANH: {
    KEY: 11,
    // VALUE: 'Hoàn thành'
    VALUE: 'HOÀN THÀNH'
  },
  HUYDON: {
    KEY: 12,
    // VALUE: 'Hủy đơn'
    VALUE: 'HỦY ĐƠN'
  }
};

export const ORDER_INTERNAL_STATUS = {
  EXTERNAL_STATUS_CONTINUOUS: {
    KEY: 99,
    VALUE: 'EXTERNAL STATUS CONTINUOUS'
  }
};

export const PAYMENT_HISTORY_TYPE = {
  NAPTIEN: {
    KEY: 0,
    VALUE: 'Nạp tiền'
  },
  DATCOC: {
    KEY: 1,
    VALUE: 'Đặt cọc'
  },
  THANHTOAN: {
    KEY: 2,
    VALUE: 'Thanh toán'
  },
  RUTTIEN: {
    KEY: 3,
    VALUE: 'Rút tiền'
  },
  HOANTIEN: {
    KEY: 4,
    VALUE: 'Hoàn tiền'
  },
  KHUYENMAI: {
    KEY: 5,
    VALUE: 'Khuyến mãi'
  }
};

export const PAYMENT_REQUEST_STATUS = {
  GUIYEUCAU: {
    KEY: 0,
    VALUE: 'Gửi yêu cầu'
  },
  DANGXULY: {
    KEY: 1,
    VALUE: 'Đang xử lý'
  },
  HOANTHANH: {
    KEY: 2,
    VALUE: 'Hoàn thành'
  },
  HUY: {
    KEY: 99,
    VALUE: 'Huỷ'
  }
}

export const BILL_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  },
};

export const SHOP_REFUND_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  },
};

export const COMPLAIN_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DANGXULY: {
    KEY: 1,
    VALUE: 'Đang xử lý'
  },
  HOANTHANH: {
    KEY: 2,
    VALUE: 'Hoàn thành'
  }
};

export const ORDER_REQUEST_EXPORT_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  },
};

export const CONSIGNMENT_ORDER_REQUEST_EXPORT_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  },
};

export const CONSIGNMENT_STATUS = {
  CHUAVE: {
    KEY: 0,
    // VALUE: 'Chưa về'
    VALUE: 'CHƯA VỀ'
  },
  NHAPKHOTQ: {
    KEY: 1,
    // VALUE: 'Nhập Kho TQ'
    VALUE: 'NHẬP KHO TQ'
  },
  VANCHUYENTQVN: {
    KEY: 2,
    // VALUE: 'Vận chuyển TQ-VN'
    VALUE: 'VẬN CHUYỂN TQ-VN'
  },
  VANCHUYENHNSG: {
    KEY: 3.1,
    VALUE: 'VẬN CHUYỂN HN-SG'
  },
  DAVEKHO: {
    KEY: 3.2,
    VALUE: 'ĐÃ VỀ KHO'
  },
  /* NHAPKHOVN: {
    KEY: 3,
    // VALUE: 'Nhập kho VN'
    VALUE: 'NHẬP KHO VN'
  }, */
  DATHANHTOAN: {
    KEY: 4,
    // VALUE: 'Đã thanh toán'
    VALUE: 'ĐÃ THANH TOÁN'
  },
  GIAOHANG: {
    KEY: 5,
    // VALUE: 'Giao hàng'
    VALUE: 'GIAO HÀNG'
  },
  DAGIAO: {
    KEY: 6,
    VALUE: 'ĐÃ GIAO'
  },
  HOANTHANH: {
    KEY: 7,
    // VALUE: 'Hoàn thành'
    VALUE: 'HOÀN THÀNH'
  }
};

export const CONSIGNMENT_INTERNAL_STATUS = {
  EXTERNAL_STATUS_CONTINUOUS: {
    KEY: 99,
    VALUE: 'EXTERNAL STATUS CONTINUOUS'
  }
};

export const FIREBASE_STRUCT = {
  ACTIONS_CONSIGMENT: {
    NODE: 'ACTIONS_CONSIGMENT'
  },
  ACTIONS_ORDER: {
    NODE: 'ACTIONS_ORDER'
  },
  BRANCHS: {
    NODE: 'BRANCHS'
  },
  COUNT: {
    NODE: 'COUNT',
    TOTAL_COMPLAINS: 'TOTAL_COMPLAINS'
  },
  ADMIN_CONSIGNMENT: {
    NODE: 'ADMIN_CONSIGNMENT'
  },
  BAGS: {
    NODE: 'BAGS'
  },
  USER_INFORS: {
    NODE: 'USER_INFOS',
    ACCOUNT_BALANCE: 'account_balance',
    CREDIT_LIMIT: 'credit_limit',
    AVAILABLE_LIMIT: 'available_limit',
    LACK_OF_PAID: 'lack_of_paid'
  },
  ORDERS: {
    NODE: 'ORDERS'
  },
  ORDERS_AUTO_FINISH: {
    NODE: 'ORDERS_AUTO_FINISH'
  },
  CONSIGNMENT_TRANSPORT: {
    NODE: 'CONSIGNMENT_TRANSPORT'
  },
  ORDER_TRANSPORT: {
    NODE: 'ORDER_TRANSPORT'
  },
  ORDERS_ALARM_TRANSPORT_CODE: {
    NODE: 'ORDERS_ALARM_TRANSPORT_CODE'
  },
  ORDERS_ALARM_WEIGHT: {
    NODE: 'ORDERS_ALARM_WEIGHT'
  },
  PARAMETER_DEFAULT: {
    NODE: 'PARAMETER_ORDER_DEFAULT'
  },
  PARAMETER_LEVEL_EMPLOYEE: {
    NODE: 'PARAMETER_LEVEL_EMPLOYEE'
  },
  PARAMETER_TRANSPORT_FEE_FAST: {
    NODE: 'PARAMETER_ORDER_TRANSPORT_FEE_FAST'
  },
  PARAMETER_TRANSPORT_FEE_SLOW: {
    NODE: 'PARAMETER_ORDER_TRANSPORT_FEE_SLOW'
  },
  PARAMETER_TRANSPORT_FEE_SG_WAREHOUSE: {
    NODE: 'PARAMETER_ORDER_TRANSPORT_FEE_SG_WAREHOUSE'
  },
  PARAMETER_SERVICE_FEE: {
    NODE: 'PARAMETER_ORDER_SERVICE_FEE'
  },
  PARAMETER_PERCENT_DEPOSIT: {
    NODE: 'PARAMETER_ORDER_PERCENT_DEPOSIT'
  },
  PARAMETER_TRANSPORT_FEE: {
    NODE: 'PARAMETER_ORDER_TRANSPORT_FEE'
  },
  PARAMETER_GOODS_CHECK_FEE: {
    NODE: 'PARAMETER_ORDER_GOODS_CHECK_FEE'
  },
  PARAMETER_PHONE_RECEIPT_COMMODITY: {
    NODE: 'PARAMETER_PHONE_RECEIPT_COMMODITY'
  },
  CONSIGNMENT_PARAMETER_DEFAULT: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_DEFAULT'
  },
  CONSIGNMENT_PARAMETER_TRANSPORT_FEE: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_TRANSPORT_FEE'
  },
  CONSIGNMENT_PARAMETER_TRANSPORT_FEE_NORMAL: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_TRANSPORT_FEE_NORMAL'
  },
  CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SPECIAL: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_TRANSPORT_FEE_SPECIAL'
  },
  CONSIGNMENT_PARAMETER_TRANSPORT_FEE_SG_WAREHOUSE: {
    NODE: 'PARAMETER_CONSIGNMENT_TRANSPORT_FEE_SG_WAREHOUSE'
  },
  CONSIGNMENT_PARAMETER_INSURRANCE_FEE: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_INSURRANCE_FEE'
  },
  CONSIGNMENT_PARAMETER_TRANSPORT_CONVERTER: {
    NODE: 'PARAMETER_CONSIGNMENT_ORDER_TRANSPORT_CONVERTER'
  },
  PARAMETER_VN_WAREHOUSE: {
    NODE: 'PARAMETER_VN_WAREHOUSE'
  },
  PARAMETER_SALE_ONLINE_DEFAULT: {
    NODE: 'PARAMETER_SALE_ONLINE_DEFAULT'
  },
  PARAMETER_SALE_ONLINE_LEVEL: {
    NODE: 'PARAMETER_SALE_ONLINE_LEVEL'
  },
  PARAMETER_LUCKY_WHEEL_PROMOTION: {
    NODE: 'PARAMETER_LUCKY_WHEEL_PROMOTION'
  },
  PARAMETER_CONTENT: {
    NODE: 'PARAMETER_CONTENT',
    NODE_DOC: 'PARAMETER_CONTENT_DOC',
    HOME_ABOUT: 'home_about',
    HOME_ALARM: 'home_alarm',
    HOME_CNE: 'home_cne',
    HOME_COMMITMENT: 'home_commitment',
    HOME_CONTACT: 'home_contact',
    CONTENT_PAGE_PARENT_NEWS: 'home_news',
    HOME_PAYMENT: 'home_payment',
    HOME_PRICE: 'home_price',
    HOME_ORDER_PRICING: 'home_order_pricing',
    HOME_CONSIGNMENT_ORDER_PRICING: 'home_consignment_order_pricing',
    HOME_VIP_DISCOUNT_PRICING: 'home_vip_discount_pricing',
    HOME_SHIP_PRICING: 'home_ship_pricing',
    HOME_RECRUIMENT: 'home_recruiment',
    HOME_RULE: 'home_rule',
    HOME_SERVICE: 'home_service',
    HOME_TRANSPORT: 'home_transport',
    PROMOTION_REGIST_ACCOUNT: 'promotion_regist_account',
    PROMOTION_REGIST_ACCOUNT_SOCORDER: 'promotion_regist_account_socorder',
    PROMOTION_REGIST_ACCOUNT_PANDAORDER: 'promotion_regist_account_pandaorder',
    // dungdh add new cat
    HOME_PAGE_MNG: 'home_page_mng',
    HOME_POST_MNG: 'home_post_mng',
    HOME_MEDIA_MNG: 'home_media_mng',
    HOME_MENU_MNG: 'home_menu_mng',
    HOME_CONTENT_CONFIG: 'home_content_config',
    // Sub collection
    NODE_PAGE_COL: 'page_collection',
    NODE_POST_COL: 'post_collection',
    CONTENT_PAGE_PARENT_GUIDE: 'home_guide',
    CONTENT_PAGE_PARENT_EXPERIENCE: 'content_page_parent_experience',
    CONTENT_PAGE_PARENT_INFORM: 'home_inform',
    CONTENT_PAGE_PARENT_INFORM_NEW_BRANCHES: 'home_inform_new_branches',
    CONTENT_PAGE_PARENT_INFORM_SALES: 'home_inform_sales',
    CONTENT_PAGE_PARENT_RECRUITMENT: 'home_recruitment',
    CUSTOMER_GUIDE: {
      NODE: 'customer_guide',
      ORDER_DETAIL: 'chi_tiet_don_hang',
      CONSIGNMENT_DETAIL: 'chi_tiet_don_ky_gui',
    }
  },
  CARTS: {
    NODE: 'CARTS',
    UID: 'uid'
  },
  CART_SHOPS: {
    NODE: 'CART_SHOPS',
    SHOP_NAME: 'shop_name',
    PRODUCTS_DEAL: {
      NODE: 'PRODUCTS_DEAL',
      UID: 'uid'
    }
  },
  CART_BADGES: {
    NODE: 'CART_BADGES',
  },
  PRODUCTS_DEAL: {
    NODE: 'PRODUCTS_DEAL',
    ORDER_CODE: 'ladding_code',
    ORDER_STATUS: 'status',
    JOURNEY: 'journey',
    SHOP_NAME: 'shop_name',
    PRODUCT_IMAGE: 'product_image',
    PRODUCT_NAME: 'product_name',
    PRODUCT_QUANTITY: 'product_quantity',
    PRODUCT_WEIGHT: 'product_weight',
    PRODUCT_PRICE: 'product_price',
    PRODUCT_DISCOUNT: 'product_discount',
    PRODUCT_PRICE_USD: 'product_usd',
    PRODUCT_LACK_OF_PAID: 'product_lack_of_paid',
    DATE_ADD_TO_CART: 'date_add_to_cart',


  },
  ROLE_ACTIONS: {
    NODE: 'ROLE_ACTIONS'
  },
  PARAMETER_VIPS_NORMAL: {
    NODE: 'PARAMETER_VIPS_NORMAL'
  },
  PARAMETER_VIPS_SPECIAL: {
    NODE: 'PARAMETER_VIPS_SPECIAL'
  },
  PARAMETER_CONSIGNMENT_VIPS_SPECIAL: {
    NODE: 'PARAMETER_CONSIGNMENT_VIPS_SPECIAL'
  },
  USERS_ADMIN_ORDER_ACTIONS: {
    NODE: 'USERS_ADMIN_ORDER_ACTIONS'
  },
  USERS_ADMIN_CONSIGNMENT_ORDER_ACTIONS: {
    NODE: 'USERS_ADMIN_CONSIGNMENT_ORDER_ACTIONS'
  },
  USERS_ADMIN_ROLES: {
    NODE: 'USERS_ADMIN_ROLES'
  },
  USERS_ADMIN: {
    NODE: 'USERS_ADMIN'
  },
  USERS: {
    NODE: 'USERS'
  },
  CONVERSATIONS: {
    NODE: 'CONVERSATIONS'
  },
  USERS_ACCOUNT: {
    NODE: 'USERS_ACCOUNT'
  },
  BILLS: {
    NODE: 'BILLS',
    BILL_CODE: 'bill_code',
    ACCOUNT_ORDER: 'account_order',
    ORDER_MONEY: 'order_money',
    SHIP_MONEY: 'ship_money',
    TOTAL_MONEY: 'total_money',
    WEB: 'web',
    NOTE: 'note'

  },
  SHOP_REFUND: {
    NODE: 'SHOP_REFUNDS'
  },
  TRANSPORTS: {
    NODE: 'TRANSPORTS'
  },
  TRANSPORTS_AUTO_COUNT_DAY: {
    NODE: 'TRANSPORTS_AUTO_COUNT_DAY'
  },
  COMPLAINS: {
    NODE: 'COMPLAINS',
    ORDER_UID: 'order_uid',
    USER_UID: 'user_uid',
    DATE_CREATED: 'date_create',
    STATUS_KEY: 'status_key',
    STATUS_TXT: 'status_txt',
    CONTENT: 'content'
  },
  BT_WRAREHOUSE: {
    NODE: 'BT_WRAREHOUSE'
  },
  VN_WAREHOUSE: {
    NODE: 'VN_WAREHOUSE'
  },
  UID_INFO: {
    NODE: 'UID_INFO',
    BAG_UID: 'bag_uid',
    BAG_CPC_UID: 'bag_slow_uid',
    ORDER_UID: 'order_uid',
    PRODUCT_UID: 'product_uid',
    PAYMENT_UID: 'payment_uid',
    LEVEL_EMPLOYEE_UID: 'level_employee',
    USER_CUSTOMER_UID: 'user_customer_uid',
    DELIVERY_BILL_UID: 'delivery_bill_uid'
  },
  ACCOUNT_BILL: {
    NODE: 'PARAMETER_ACCOUNT_BILL'
  },
  BILL_ORDER_TIME: {
    NODE: 'PARAMETER_BILL_ORDER_TIME'
  },
  ACCOUNT_REFUND: {
    NODE: 'PARAMETER_ACCOUNT_REFUND'
  },
  LOCATION_PAYMENT_CASH: {
    NODE: 'PARAMETER_LOCATION_PAYMENT_CASH'
  },
  LOCATION_PAYMENT_TRANFER: {
    NODE: 'PARAMETER_LOCATION_PAYMENT_TRANFER'
  },
  PAYMENT_HISTOTY: {
    NODE: 'PAYMENT_HISTORY'
  },
  PAYMENT_REQUESTS: {
    NODE: 'PAYMENT_REQUESTS'
  },
  CONSIGNMENTS: {
    NODE: 'CONSIGNMENTS',
    CONSIGNMENT: 'consignment',
    REFUND_FEES: 'refund_fees'

  },
  CONSIGNMENTS_AUTO_FINISH: {
    NODE: 'CONSIGNMENTS_AUTO_FINISH',
  },
  CONSIGNMENT_EXTRA_FEE: {
    NODE: 'extra_fees'
  },
  PROMOTION: {
    NODE: 'PARAMETER_PROMOTION_CONFIGS'
  },
  ORDER_EXTRA_FEE: {
    NODE: 'ORDER_EXTRA_FEE'
  },
  ORDER_REQUEST_EXPORT: {
    NODE: 'ORDER_REQUEST_EXPORT'
  },
  ORDER_REQUEST_EXPORT_CPN: {
    NODE: 'ORDER_REQUEST_EXPORT_CPN'
  },
  CONSIGNMENT_ORDER_REQUEST_EXPORT: {
    NODE: 'CONSIGNMENT_ORDER_REQUEST_EXPORT'
  },
  ORDER_DETAIL_PAYMENT_HISTORY: {
    NODE: 'payment_histories'
  },
  CONSIGNMENT_ORDER_DETAIL_PAYMENT_HISTORY: {
    NODE: 'payment_histories'
  },
  ORDER_DETAIL_ACTION_HISTORY: {
    NODE: 'action_histories'
  },
  CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY: {
    NODE: 'action_histories'
  },
  STATISTIC: {
    NODE: 'STATISTIC'
  },
  MESSAGE_ROOMS: {
    NODE: 'MESSAGE_ROOMS'
  },
  STATISTIC_GET_LINK: {
    NODE: 'STATISTIC_GET_LINK'
  },
  EXTRA_FEES: {
    NODE: 'extra_fees'
  },
  DELIVERY_BILL: {
    NODE: 'DELIVERY_BILL'
  },
  BARCODE_READER_INPUT_TRANSPORTS_HISTORIES: {
    NODE: 'BARCODE_READER_INPUT_TRANSPORTS_HISTORIES'
  },
  SALES_ONLINE_WITHDRAWAL_REQUESTS: {
    NODE: 'SALES_ONLINE_WITHDRAWAL_REQUESTS'
  },
  SALES_ONLINE_E_WALLET_HISTORIES: {
    NODE: 'SALES_ONLINE_E_WALLET_HISTORIES'
  },
  SALES_SUMMARY: {
    NODE: 'SALES_SUMMARY'
  },
  USER_LUCKY_WHEEL_PROMOTION: {
    NODE: 'USER_LUCKY_WHEEL_PROMOTION'
  }
};

export const MAX_QUICK_ACTION_NUMBER = 3;
export const ACTION_URL = {
  capdokhachhang: {
    router: '/main/customer-level',
    label: 'Cấp độ khách hàng'
  },
  capdonguoidung: {
    router: '/main/employee-level',
    label: 'Cấp độ người dùng'
  },
  capnhatdulieu: {
    router: '/main/maintenance',
    label: 'Maintenance'
  },
  danhsachkhachhang: {
    router: '/main/customer-mng',
    label: 'Danh sách khách hàng'
  },
  phankhachhangchosales: {
    router: '/main/assign-customer-to-sales',
    label: 'Phân khách hàng cho Sales'
  },
  tonghopsales: {
    router: '/main/sales-summary',
    label: 'Tổng hợp Sales'
  },
  thongkekhachhangcuasales: {
    router: '/main/sales-customers',
    label: 'Thống kê khách hàng của Sales'
  },
  /* nhankhachhang: {
    router: '/main/receive-customers',
    label: 'Nhận khách hàng'
  }, */
  tonghopdonhangcuasales: {
    router: '/main/sales-orders',
    label: 'Tổng hợp đơn hàng của Sales'
  },
  tonghopdonkyguicuasales: {
    router: '/main/sales-consignment-orders',
    label: 'Tổng hợp đơn ký gửi của Sales'
  },
  thongkecuasales: {
    router: '/main/sales-statistics',
    label: 'Thống kê của sale'
  },
  /* quanlythunhap: {
    router: '/main/income-management',
    label: 'Quản lý thu nhập'
  }, */
  giamsathethongsales: {
    router: '/main/monitoring-sales',
    label: 'Giám sát Sales'
  },
  donhangchuacomvd: {
    router: '/main/alarm-order-mvd',
    label: 'Đơn hàng chưa có mã VD'
  },
  donhangkhongcotrongluong: {
    router: '/main/alarm-order-weight',
    label: 'Đơn hàng không có trọng lượng'
  },
  hanmuctindung: {
    router: '/main/credit-limit',
    label: 'Hạn mức tín dụng'
  },
  khobangtuong: {
    router: '/main/bt-warehouse',
    label: 'Kho Bằng Tường'
  },
  khovietnam: {
    router: '/main/vn-warehouse',
    label: 'Kho Việt Nam'
  },
  quanlydonhang: {
    router: '/main/orders',
    label: 'Quản lí đơn hàng'
  },
  quanlygiohang: {
    router: '/main/carts',
    label: 'Quản lí đơn hàng chưa đặt cọc'
  },
  quanlydonkygui: {
    router: '/main/consignment-order',
    label: 'Quản lí đơn kí gửi'
  },
  quanlyhinhanh: {
    router: '/main/media-management',
    label: 'Quản lí hình ảnh'
  },
  quanlyhoantien: {
    router: '/main/shop-refund-mng',
    label: 'Quản lí hoàn tiền'
  },
  quanlymahoadon: {
    router: '/main/bills',
    label: 'Quản lí mã hóa đơn'
  },
  quanlymenu: {
    router: '/main/home-menu-mng',
    label: 'Quản lí menu'
  },
  quanlybaiviet: {
    router: '/main/content-page-children',
    label: 'Quản lí bài viết'
  },
  quanlytrang: {
    router: '/main/content-page-parent',
    label: 'Quản lý trang'
  },
  quanlymenu2: {
    router: '/main/home-menu-mng',
    label: 'Quản lí menu 2'
  },
  quanlynguoidung: {
    router: '/main/admin-users',
    label: 'Quản lí người dùng'
  },
  quanlyquyen: {
    router: '/main/role-mng',
    label: 'Quản lí quyền'
  },
  quanlythongbao: {
    router: '/main/order-notification-mng',
    label: 'Quản lí thông báo'
  },
  quanlythongtinwebsite: {
    router: '/main/content-page-parent',
    label: 'Quản lí thông tin website'
  },
  quanpypopup: {
    router: '/main/order',
    label: 'Quản lí popup'
  },
  taikhoancanhan: {
    router: '/main/user-information',
    label: 'Tài khoản cá nhân'
  },
  taikhoanvadiadiemgiaodich: {
    router: '/main/payment-location',
    label: 'Tài khoản và địa điểm giao dịch'
  },
  taodonhangexcel: {
    router: '/main/order-by-excel',
    label: 'Tạo đơn hàng Excel'
  },
  taodonhangngoai: {
    router: '/main/order-by-external',
    label: 'Tạo đơn hàng ngoài'
  },
  thamsodonhang: {
    router: '/main/order-parameters',
    label: 'Tham số đơn hàng'
  },
  thamsodonkygui: {
    router: '/main/consignment-order-parameters',
    label: 'Tham số đơn kí gửi'
  },
  thamsokhuyenmai: {
    router: '/main/promotion-mng',
    label: 'Tham số khuyến mãi'
  },
  thamsosaleonline: {
    router: '/main/sale-online-parameters',
    label: 'Tham số sale online'
  },
  thongke: {
    router: '/main/statistic-get-link',
    label: 'Thống kê'
  },
  thongkegiaodich: {
    router: '/main/statistic-payment',
    label: 'Thống kê giao dịch'
  },
  tonghopdonhang: {
    router: '/main/order-summary',
    label: 'Tổng hợp đơn hàng'
  },
  tonghopdonkygui: {
    router: '/main/consignment-order-summary',
    label: 'Tổng hợp đơn kí gửi'
  },
  tonghopkhieunai: {
    router: '/main/complain-summary',
    label: 'Tổng hợp khiếu nại'
  },
  vidientu: {
    router: '/main/e-wallet',
    label: 'Ví điện tử'
  },
  vidientusalesonline: {
    router: '/main/e-wallet-sales-online',
    label: 'Ví điện tử sales online'
  },
  danhsacchyeucauruttiencuasalesonline: {
    router: '/main/withdrawal-requests-sales',
    label: 'Danh sách yêu cầu rút tiền của Sales'
  },
  yeucauxuat: {
    router: '/main/order-request-export-ship',
    label: 'Yêu cầu xuất ship'
  }
};

export const DELIVERY_BILL_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  }
};

export const SALES_ONLINE_WITHDRAWAL_STATUS = {
  CHUAXULY: {
    KEY: 0,
    VALUE: 'Chưa xử lý'
  },
  DAXULY: {
    KEY: 1,
    VALUE: 'Đã xử lý'
  }
};
