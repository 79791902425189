import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT, ORDER_STATUS } from '../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { CalculateFeeService } from 'src/app/services/calculate-fee/calculate-fee.service';
import { OrderParam } from 'src/app/model/order.param';
import { BRANCH_CONFIG } from 'src/app/constants/constant';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-order-param-default',
  templateUrl: './order-param-default.component.html',
  styleUrls: ['./order-param-default.component.css']
})
export class OrderParamDefaultComponent implements OnInit, OnDestroy {

  uid: any;
  branchUid: any;
  paramName: string;

  default_param: any = {};
  user_login: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService,
    public calcFeeService: CalculateFeeService,
    public fireDb: AngularFireDatabase,
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
    this.paramName = this.navParams.data.modal.componentProps.paramName;

    if (this.isNewBranch()) {
      this.fireDb
        .object<any>(
          `BRANCH_CONFIG/${this.branchUid}/${this.paramName}`
        ).valueChanges().pipe(
          map(doc => ({ name: this.paramName, value: doc })),
          takeUntil(this.unsubscribe$)).subscribe(obj => {
            this.default_param = obj;
          });
    } else {
      if (this.uid !== '') {
        this.db.doc(`${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}/${this.uid}`).valueChanges().pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(obj => {
          this.default_param = obj;
        });

        this.getUserLogin(this.auth.auth.currentUser.uid)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((user_login) => {
            this.user_login = user_login;
          });
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();

      if (this.isNewBranch()) {
        await this.fireDb
          .object<any>(
            `BRANCH_CONFIG/${this.branchUid}`
          ).update({[this.paramName]: Number(this.default_param.value)});
      } else {
        if (this.uid !== '') {
          if (this.uid === 'Pfrruaf39VjFSYc5oRIY') {
            await this._updateOrders({ ...this.default_param });
          }

          await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}/${this.uid}`).update({
            ...this.default_param,
            update_user: this.auth.auth.currentUser.displayName,
            update_date: c
          });
        } else {
          await this.db.collection(`${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}`).add({
            ...this.default_param,
            branchUid: this.branchUid,
            create_user: this.auth.auth.currentUser.displayName,
            create_date: c
          });
        }
      }

      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      console.log(error);
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  private async _updateOrders(default_param) {
    const fs = firebase.firestore();
    const batch = fs.batch();

    const orders = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).where('order_status_key', '>', ORDER_STATUS.DABAOGIA.KEY)
      .where('order_status_key', '<', ORDER_STATUS.THANHTOANCHOSHOP.KEY).get().then(actions => {
        const ret = [];
        actions.forEach(doc => {
          ret.push({
            uid: doc.id,
            ...doc.data()
          });
        });
        return ret;
      });

    for (const order of orders) {
      const user_login = { ...this.user_login };
      const order_update = {
        tygiathuc: Number(default_param.value)
      };
      let normal_param: OrderParam;
      if (order.order_status_key >= ORDER_STATUS.DATCOC.KEY) {
        normal_param = order.normal_param;
      } else {
        normal_param = await this.calcFeeService.getParam(order).pipe(first()).toPromise();
      }
      order.param = normal_param;
      if (!order.param) continue;

      order.param.tygiathuc = Number(default_param.value);
      await this.calcFeeService.revokeOrder(order, order_update, user_login, batch);

      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
        normal_param: normal_param
      });
    }

    await batch.commit();
  }

  getUserLogin(uid) {
    return this.db
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .doc<any>(uid)
      .snapshotChanges()
      .pipe(map((snap) => ({ uid: snap.payload.id, ...snap.payload.data() })));
  }

  isNewBranch() {
    if (this.branchUid === BRANCH_CONFIG.SOC_ORDER.UID || this.branchUid === BRANCH_CONFIG.PANDA_ORDER.UID) {
      return true;
    }

    return false;
  }
}
