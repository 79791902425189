import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', loadChildren: './pages/main/main.module#MainPageModule', canActivate: [AppGuard] },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'order-print-report/:uid', loadChildren: './pages/business/order-print-report/order-print-report.module#OrderPrintReportPageModule', canActivate: [AppGuard] },
  { path: 'consignment-order-print-report/:uid', loadChildren: './pages/business/consignment-order-print-report/consignment-order-print-report.module#ConsignmentOrderPrintReportPageModule', canActivate: [AppGuard] },
  { path: 'delivery-bill-print-report/:uid', loadChildren: './pages/warehouse/delivery-bill-print-report/delivery-bill-print-report.module#DeliveryBillPrintReportPageModule', canActivate: [AppGuard] },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
