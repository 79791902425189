import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CustomConfirm, FIREBASE_STRUCT, PAYMENT_HISTORY_TYPE, SALES_ONLINE_WITHDRAWAL_STATUS } from '../../../app.constant';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Decimal } from '../../../app.models';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-sales-online-recharge-money',
  templateUrl: './sales-online-recharge-money.component.html',
  styleUrls: ['./sales-online-recharge-money.component.css']
})
export class SalesOnlineRechargeMoneyComponent implements OnInit, OnDestroy {
  model: any = {
    amount: 0
  };
  image_file: any;
  uid;
  salesOnlineUser: any;
  withdrawalRequest: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public translateService: TranslateService,
    public commonService: CommonUtilsService,
    private storage: AngularFireStorage
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    if (this.uid) {
      this.fs.doc(`${FIREBASE_STRUCT.SALES_ONLINE_WITHDRAWAL_REQUESTS.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.withdrawalRequest = obj;
        this.model.amount = this.withdrawalRequest.amount;
        const firestore = firebase.firestore();
        firestore.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(this.withdrawalRequest.sales_uid).get().then(doc => {
          return { uid: doc.id, ...doc.data() };
        }).then(_salesOnlineUser => {
          this.salesOnlineUser = _salesOnlineUser;
        });
      });

      /* this._getWithdrawalRequests().subscribe(withdrawalRequest => {
        this.withdrawalRequest = withdrawalRequest;
      }); */
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  public onChoosenPicture(files) {
    this.image_file = files[0];
  }

  @CustomConfirm('Xác nhận nạp tiền?')
  public async onRechargeMoney() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const currentDateTime = await this.commonService.getServerTime();
    const _model = { ...this.model };
    const withdrawalRequest = { ...this.withdrawalRequest };

    if (_model.amount > withdrawalRequest.amount) { throw new Error('Số tiền nạp vượt quá giới hạn yêu cầu rút tiền.'); }

    const imageId = fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE)
                  .doc(FIREBASE_STRUCT.PARAMETER_CONTENT.HOME_MEDIA_MNG)
                  .collection('gallery')
                  .doc().id;
    const store_ref = this.storage.ref(`uploads/gallery/${imageId}`);
    const angularFileUpload = await store_ref.put(this.image_file);
    const imageUrl = await angularFileUpload.ref.getDownloadURL();

    batch.update(fs.collection(FIREBASE_STRUCT.SALES_ONLINE_WITHDRAWAL_REQUESTS.NODE).doc(this.uid), {
      status_key: SALES_ONLINE_WITHDRAWAL_STATUS.DAXULY.KEY,
      status: SALES_ONLINE_WITHDRAWAL_STATUS.DAXULY.VALUE,
      bank_transfer_evidence: imageUrl
    });

    const salesOnlineUser: any = await fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(withdrawalRequest.sales_uid).get().then(doc => {
      return { uid: doc.id, ...doc.data() };
    });
    let eWalletTotalBalance = salesOnlineUser.e_wallet_total_balance || 0;
    let ewalletTotalWithdrawal = salesOnlineUser.ewallet_total_withdrawal || 0;
    let ewalletTotalWithdrawalProcessing = salesOnlineUser.ewallet_total_withdrawal_processing || 0;

    ewalletTotalWithdrawalProcessing = new Decimal(ewalletTotalWithdrawalProcessing).sub(_model.amount).toNumber();
    ewalletTotalWithdrawal = new Decimal(ewalletTotalWithdrawal).add(_model.amount).toNumber();
    eWalletTotalBalance = new Decimal(eWalletTotalBalance).sub(_model.amount).toNumber();

    batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(withdrawalRequest.sales_uid), {
      e_wallet_total_balance: eWalletTotalBalance,
      ewallet_total_withdrawal: ewalletTotalWithdrawal,
      ewallet_total_withdrawal_processing: ewalletTotalWithdrawalProcessing
    });

    batch.set(fs.collection(FIREBASE_STRUCT.SALES_ONLINE_E_WALLET_HISTORIES.NODE).doc(), {
      sales_uid: withdrawalRequest.sales_uid,
      amount: _model.amount,
      type: 2,
      subject: 'Nạp tiền: Theo yêu cầu #' + withdrawalRequest.request_code,
      created_at: currentDateTime
    });

    await batch.commit();
    this.modalCtrl.dismiss();

    return 'Xử lý nạp tiền thành công';
  }

}
