import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomLoading, FIREBASE_STRUCT } from 'src/app/app.constant';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-order-param-transport-fee',
  templateUrl: './order-param-transport-fee.component.html',
  styleUrls: ['./order-param-transport-fee.component.scss'],
})
export class OrderParamTransportFeeComponent implements OnInit, OnDestroy {
  uid: any;
  branchUid: any;

  param_transport_fee: any = {};
  unsubscribe$ = new Subject();
  transport_warehouses: any[] = [
    { key: 0, name: 'Kho HN', value: 'HN' },
    { key: 1, name: 'Kho SG', value: 'SG' }
  ];;
  // service_fee_deposit_value: any[];
  
  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;

    if (this.uid !== '') {
      this.db.doc<any>(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.param_transport_fee = obj;
        this.transport_warehouses = obj.fee_value_warehouse;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const c = await this.commonService.getServerTime();
      const fee_value_warehouse = [...this.transport_warehouses.map(({ key, name, value, fee_value }) => {
        return { key, name, value, fee_value };
      })];

      if (this.uid !== '') {
        await this.db.doc(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE.NODE}/${this.uid}`).update({
          ...this.param_transport_fee,
          fee_value_warehouse: fee_value_warehouse,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        await this.db.collection(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE.NODE}`).add({
          ...this.param_transport_fee,
          branchUid: this.branchUid,
          fee_value_warehouse: fee_value_warehouse,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }
      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }
}
