import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-parameter-vn-warehouse',
  templateUrl: './parameter-vn-warehouse.component.html',
  styleUrls: ['./parameter-vn-warehouse.component.css']
})
export class ParameterVnWarehouseComponent implements OnInit, OnDestroy {

  params: any[];
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore
  ) {
    this.getParameterVnWarehouse().subscribe(p => {
      this.params = p;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getParameterVnWarehouse() {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_VN_WAREHOUSE.NODE).snapshotChanges().pipe(
      map(actions => actions.map(action => ({ uid: action.payload.doc.id, ...action.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const p = [...this.params];
      p.forEach(v => {
        batch.update(fs.collection(FIREBASE_STRUCT.PARAMETER_VN_WAREHOUSE.NODE).doc(v.uid), v);
      });
      await batch.commit();
      await this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      throw error;
    }
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

}
