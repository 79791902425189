import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { firestore } from 'firebase/app';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';

@Component({
    selector: 'app-create-new-page',
    templateUrl: './create-new-page.component.html',
    styleUrls: ['./create-new-page.component.css']
})
export class CreateNewPageComponent implements OnInit, OnDestroy {
    // tslint:disable-next-line: no-input-rename
    @Input('pageId') pageId: string;
    unsubscribe$: any = new Subject();
    content_html: string;
    previewUrl: any;

    constructor(public modalCtrl: ModalController, public auth: AngularFireAuth,
                public fs: AngularFirestore) {
    }

    ngOnInit() {
        if (!this.pageId) {
            return;
        }

        this.getContent().subscribe((c: any) => {
            if (c) {
                this.content_html = c.content_html;
            }
        });
        this.saveDraft();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getContent() {
        return this.fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc(this.pageId).valueChanges().pipe(
            takeUntil(this.unsubscribe$)
        );
    }

    onDismiss() {
        this.modalCtrl.dismiss();
    }

    onChange(event: CKEditor4.EventInfo) {
        // console.log(event.editor.getData());
        this.content_html = event.editor.getData();
    }

    @CustomConfirm('Xác nhận thay đổi trang ?')

    async onSubmit() {
        try {
            const fs = firestore();
            const batch = fs.batch();
            batch.set(fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc(this.pageId), {
                content_html: this.content_html
            }, { merge: true });
            await batch.commit();
            return 'Cập nhật thành công';
        } catch (error) {
            throw error;
        }
    }

    async saveDraft() {
        try {
            const fs = firestore();
            const batch = fs.batch();
            batch.set(fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE).doc('previewPage'), {
                content_html: this.content_html || ''
            }, { merge: true });
            await batch.commit();
            return 'Cập nhật thành công';
        } catch (error) {
            throw error;
        }
    }
}
