import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomConfirm, CustomLoading, FIREBASE_STRUCT, ORDER_STATUS, SALES_ONLINE_WITHDRAWAL_STATUS } from '../../app.constant';
import { map, takeUntil } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Decimal } from 'src/app/app.models';

@Component({
  selector: 'app-sale-online-withdrawal-request',
  templateUrl: './sale-online-withdrawal-request.component.html',
  styleUrls: ['./sale-online-withdrawal-request.component.css']
})
export class SaleOnlineWithdrawalRequestComponent implements OnInit, OnDestroy {
  model: any = {
    amount: 0,
    note: ''
  };
  salesOnlineUser: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this._getSalesOnline().subscribe(salesOnlineUser => {
      this.salesOnlineUser = salesOnlineUser;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Bạn chắc chắn tạo yêu cầu rút tiền này?')
  public async onWithdrawalRequest() {
    const fs = firestore();
    const batch = fs.batch();
    const _model = { ...this.model };
    const salesOnlineUser: any = await fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(this.auth.auth.currentUser.uid).get().then(doc => {
      return {
        uid: doc.id,
        ...doc.data()
      };
    });

    if (_model.amount && salesOnlineUser.e_wallet_total_balance >= _model.amount) {
      const requestCode = await this._getRequestCode();
      const currentDateTime = await this.commonService.getServerTime();
      batch.set(fs.collection(FIREBASE_STRUCT.SALES_ONLINE_WITHDRAWAL_REQUESTS.NODE).doc(), {
        sales_uid: salesOnlineUser.uid,
        request_code: requestCode,
        amount: _model.amount,
        status_key: SALES_ONLINE_WITHDRAWAL_STATUS.CHUAXULY.KEY,
        status: SALES_ONLINE_WITHDRAWAL_STATUS.CHUAXULY.VALUE,
        note: _model.note,
        created_at: currentDateTime,
        bank_name: this.salesOnlineUser.bank_name,
        bank_account_number: this.salesOnlineUser.bank_account_number,
        bank_account_name: this.salesOnlineUser.bank_account_name,
        bank_transfer_evidence: ''
      });

      let ewalletTotalWithdrawalProcessing = salesOnlineUser.ewallet_total_withdrawal_processing || 0;
      ewalletTotalWithdrawalProcessing = new Decimal(ewalletTotalWithdrawalProcessing).add(_model.amount).toNumber();
      batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(this.auth.auth.currentUser.uid), {
        ewallet_total_withdrawal_processing: ewalletTotalWithdrawalProcessing
      });

      batch.set(fs.collection(FIREBASE_STRUCT.SALES_ONLINE_E_WALLET_HISTORIES.NODE).doc(), {
        sales_uid: salesOnlineUser.uid,
        amount: _model.amount,
        type: 1,
        subject: 'Rút tiền: Theo yêu cầu #' + requestCode,
        created_at: currentDateTime
      });

      await batch.commit();
      this.modalCtrl.dismiss();

      return 'Tạo yêu cầu rút tiền thành công';
    } else {
      throw new Error('Số tiền rút không hợp lệ. Vui lòng kiểm tra lại');
    }
  }

  private _getSalesOnline() {
    const salesOnlineUid = this.auth.auth.currentUser.uid;

    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(salesOnlineUid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  private async _getRequestCode() {
    const fs = firestore();
    return fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc('sales_online_withdrawal_request_uid').get().then(doc => {
      return Number((doc.get('id') || 0)) + 1;
    });
  }

}
