import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { firestore } from 'firebase/app';
import { Subject } from 'rxjs';
import { CustomLoading, FIREBASE_STRUCT } from 'src/app/app.constant';
import { ADMIN_USERS } from 'src/app/constants/users';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';

@Component({
  selector: 'app-action-role-user',
  templateUrl: './action-role-user.component.html',
  styleUrls: ['./action-role-user.component.scss'],
})
export class ActionRoleUserComponent implements OnInit, OnDestroy {
  actions: any[];
  check_all: any = {};
  user_actions: any = {};
  unsubscribe$ = new Subject();

  employees_cskh: any[] = [];
  employees_sale: any[] = [];
  employees_warehouse: any[] = [];
  employees_accounting: any[] = [];
  role_uid: string;
  temp_employee_update: any[] = [];
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService
  ) {
    const role_uid = this.role_uid = this.navParams.data.modal.componentProps.role_uid;

    Promise.all([this.getUserActions(role_uid), this.getEmployeeCskh(), this.getEmployeeSale(), this.getEmployeeWarehouse(), this.getEmployeeAccounting()])
      .then(([user_actions, employeeCskh, employeeSale, employeeWarehouse, employeeAccount]) => {
        if (user_actions.length) {
          this.user_actions = user_actions[0];
          // Check data of employee cskh
          employeeCskh.forEach(employee => {
            let isCheck = false;
            if (this.user_actions.hasOwnProperty(employee.uid)) {
              isCheck = this.user_actions[employee.uid] || false;
            }
            employee.isCheck = isCheck;
          });

          // Check data of employee sale
          employeeSale.forEach(employee => {
            let isCheck = false;
            if (this.user_actions.hasOwnProperty(employee.uid)) {
              isCheck = this.user_actions[employee.uid] || false;
            }
            employee.isCheck = isCheck;
          });

          // Check data of employee warehouse
          employeeWarehouse.forEach(employee => {
            let isCheck = false;
            if (this.user_actions.hasOwnProperty(employee.uid)) {
              isCheck = this.user_actions[employee.uid] || false;
            }
            employee.isCheck = isCheck;
          });

          // Check data of employee accounting
          employeeAccount.forEach(employee => {
            let isCheck = false;
            if (this.user_actions.hasOwnProperty(employee.uid)) {
              isCheck = this.user_actions[employee.uid] || false;
            }
            employee.isCheck = isCheck;
          });
        }

        this.employees_cskh = employeeCskh;
        this.employees_sale = employeeSale;
        this.employees_warehouse = employeeWarehouse;
        this.employees_accounting = employeeAccount;
      })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const role_uid = this.navParams.data.modal.componentProps.role_uid;

      if (this.user_actions.uid) {
        const updateObj = { ...this.user_actions };
        updateObj.update_date = await this.commonService.getServerTime();
        delete updateObj.uid;
        batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('user_actions').doc(this.user_actions.uid), updateObj);
      } else {
        const insertObj = { ...this.user_actions };
        insertObj.create_date = await this.commonService.getServerTime();
        delete insertObj.uid;
        batch.set(fs.collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid).collection('user_actions').doc(), insertObj);
      }

      this.temp_employee_update.forEach(user => {
        const userUpdate = {
          upper_role_uid: user.isCheck ? role_uid : ''
        };

        batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(user.uid), userUpdate);
      })


      await batch.commit();
      this.modalCtrl.dismiss();
      return 'Cập nhật thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getMenuActions(role_uid: string) {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid)
      .collection('menu_actions')
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  getUserActions(role_uid: string) {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE).doc(role_uid)
      .collection('user_actions')
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  getEmployeeCskh() {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .where('role_uid', '==', ADMIN_USERS.NHANVIENCHAMSOCKHACHHANG.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id, isCheck: false })));
  }

  getEmployeeSale() {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .where('role_uid', '==', ADMIN_USERS.NHANVIENKINHDOANH.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id, isCheck: false })));
  }

  getEmployeeWarehouse() {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .where('role_uid', '==', ADMIN_USERS.NHANVIENKHOVIETNAM.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id, isCheck: false })));
  }

  getEmployeeAccounting() {
    return this.fs.firestore
      .collection(FIREBASE_STRUCT.USERS_ADMIN.NODE)
      .where('role_uid', '==', ADMIN_USERS.NHANVIENKETOAN.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id, isCheck: false })));
  }

  public onCheckEmployee(employee) {
    this.temp_employee_update.push(employee);
    this.user_actions[employee.uid] = employee.isCheck;
  }

  public isDisableCheckUser(employee) {
    let disabled = true;
    // Not yet set
    if (employee.upper_role_uid === undefined || employee.upper_role_uid === '') {
      disabled = false;
    } else {
      // Is setted in current role
      if (employee.upper_role_uid === this.role_uid) {
        disabled = false;
      } else {
        disabled = true;
      }
    }

    return disabled;
  }
}
