import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { ORDER_STATUS } from '../../../app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomConfirm, FIREBASE_STRUCT, PAYMENT_HISTORY_TYPE } from '../../../app.constant';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Decimal } from '../../../app.models';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.css']
})
export class WithdrawMoneyComponent implements OnInit, OnDestroy {

  uid;
  negative_money;
  user: any;
  recharge_types: any[];
  recharge_type: any;
  recharge_locations: any[];
  recharge_location: any;
  recharge_type_sub = new BehaviorSubject<number>(0);
  recharge_money: number;
  note: string;
  order_debt: number;
  consignment_order_debt: number;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public commonService: CommonUtilsService,
    private apiService: ApiService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.negative_money = Boolean(this.navParams.data.modal.componentProps.negative_money);
    if (this.uid !== '') {
      this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(async (obj: any) => {
        this.user = obj;
        await this.getDebt(obj.id);
        this.user.usage_balance = new Decimal(obj.account_balance)
          .sub(this.order_debt)
          .sub(this.consignment_order_debt)
          .toNumber();
      });

      this.recharge_types = [
        { key: 1, value: 'Tiền mặt' },
        { key: 2, value: 'Chuyển khoản' }
      ];

      this.getRechargeLocations().subscribe(locations => {
        this.recharge_location = null;
        this.recharge_locations = locations;
      });
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectType() {
    this.recharge_type_sub.next(this.recharge_type);
  }

  getOrderByCustomerId(customer_id) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.ORDERS.NODE)
      .where('order_user_id', '==', customer_id)
      .where('order_status_key', '>=', ORDER_STATUS.DATCOC.KEY)
      .where('order_status_key', '<=', ORDER_STATUS.HOANTHANH.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  getConsignmentOrderByCustomerId(customer_id) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE)
      .where('customer_id', '==', customer_id)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  async getDebt(customer_id) {
    const orders = await this.getOrderByCustomerId(customer_id);
    const consignment_orders = await this.getConsignmentOrderByCustomerId(customer_id);
    let order_debt = 0;
    let consignment_order_debt = 0;

    orders.forEach((order: any) => {
      order_debt = new Decimal(order_debt).add(order.order_lack_of_paid).toNumber();
    });

    consignment_orders.forEach((consignment_order: any) => {
      consignment_order_debt = new Decimal(consignment_order_debt).add(consignment_order.lack_of_paid).toNumber();
    });

    this.order_debt = order_debt;
    this.consignment_order_debt = consignment_order_debt;
  }

  getRechargeLocations() {
    return this.recharge_type_sub.pipe(
      switchMap(type => {
        if (Number(type) === 1) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_CASH.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else if (Number(type) === 2) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_TRANFER.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else { return of([]); }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Xác nhận rút tiền?')
  async onSubmit() {
    const fs = firebase.firestore();
    // const batch = fs.batch();
    const p = [];
    try {
      const c = await this.commonService.getServerTime();
      const user = { ...this.user };
      const recharge_money = Number(this.recharge_money || 0);
      const recharge_type = this.recharge_type;
      if (this.uid !== '') {
        if (!this.negative_money && recharge_money > Number(user.usage_balance)) {
          throw new Error('Số tiền rút vượt quá giá trị rút tiền tối đa. Vui lòng kiểm tra lại.');
        }

        await this.apiService.post("users/withdraw-money", {
          uid: this.uid,
          user: { ...this.user },
          recharge_money: recharge_money,
          recharge_type: recharge_type,
          recharge_types: this.recharge_types,
          payment_create_user: this.auth.auth.currentUser.displayName,
          recharge_location: this.recharge_location.name,
          note: this.note
        });
        /* const recharge_type = this.recharge_type - 1;

        p.push(fs.runTransaction(transaction => {
          return transaction.get(fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`)).then(doc => {
            let accountBalance = Number(doc.get('account_balance') || 0);
            let availableLimit = Number(doc.get('available_limit') || 0);
            const creditLimit = Number(doc.get('credit_limit') || 0);
            let withdrawMoney: number = Number(doc.get('withdraw_money') || 0);
            accountBalance = new Decimal(accountBalance).sub(recharge_money).toNumber();
            availableLimit = new Decimal(creditLimit).add(accountBalance).toNumber();
            withdrawMoney = new Decimal(withdrawMoney).add(recharge_money).toNumber();
            transaction.update(doc.ref, {
              account_balance: accountBalance,
              available_limit: availableLimit,
              withdraw_money: withdrawMoney
            });
            return accountBalance;
          });
        }));

        p.push(fs.runTransaction(transaction => {
          return transaction.get(fs.collection(FIREBASE_STRUCT.STATISTIC.NODE).doc('statistic_payment')).then(doc => {
            let totalExpenditureTranfer: number = Number(doc.get('total_expenditure_tranfer') || 0);
            let totalExpenditureCash: number = Number(doc.get('total_expenditure_cash') || 0);
            totalExpenditureTranfer = this.recharge_types[recharge_type].value === 'Chuyển khoản' ? (new Decimal(totalExpenditureTranfer).add(recharge_money).toNumber()) : totalExpenditureTranfer;
            totalExpenditureCash = this.recharge_types[recharge_type].value === 'Tiền mặt' ? (new Decimal(totalExpenditureCash).add(recharge_money).toNumber()) : totalExpenditureCash;

            transaction.set(doc.ref, {
              total_expenditure_tranfer: totalExpenditureTranfer,
              total_expenditure_cash: totalExpenditureCash,
            }, { merge: true });
            return 'ok';
          });
        }));

        p.push(fs.runTransaction(transaction => {
          return transaction.get(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID)).then(doc => {
            const paymentId: number = Number(doc.get('id') || 0) + 1;
            const user_payment_id: number = Number(doc.get(this.uid) || 0) + 1;
            transaction.set(doc.ref, {
              id: paymentId,
              [this.uid]: user_payment_id
            }, { merge: true });

            return { paymentId, user_payment_id };
          });
        }));

        await Promise.all(p).then(([accountBalance, done, uid_info]) => {
          return fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).add({
            payment_date_created: c,
            payment_type_key: PAYMENT_HISTORY_TYPE.RUTTIEN.KEY,
            payment_type: PAYMENT_HISTORY_TYPE.RUTTIEN.VALUE,
            payment_content: `${this.note ? 'Rút tiền - ' + this.note : 'Rút tiền'}`,
            payment_amount: recharge_money,
            payment_code: uid_info.paymentId,
            payment_create_user: this.auth.auth.currentUser.displayName,
            payment_name: this.recharge_types[recharge_type].value,
            customer_account_balance: accountBalance,
            customer_id: user.id,
            customer_full_name: user.full_name,
            customer_phone_number: user.phone,
            customer_email: user.email,
            customer_user_uid: this.uid,
            location: this.recharge_location.name || '',
            note: this.note || '',
            customer_payment_code: uid_info.user_payment_id,
            branch_uid: user.branch_uid
          });
        }); */

        this.modalCtrl.dismiss();
        return 'Rút tiền thành công';
      } else {
        throw new Error('Có lỗi xảy ra khi rút tiền. Vui lòng kiểm tra lại.');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
