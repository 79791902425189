import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { CustomLoading, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Decimal } from '../../../app.models';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-consignment-link',
  templateUrl: './consignment-link.component.html',
  styleUrls: ['./consignment-link.component.css']
})
export class ConsignmentLinkComponent implements OnInit, OnDestroy {

  temp_lading_code: any[];
  authInfo: any;
  unsubscribe$ = new Subject();
  param_inssurrance: any[];
  uid: any;

  constructor(
    public modalCtrl: ModalController,
    public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public translateService: TranslateService,
    public commonService: CommonUtilsService
  ) {

    this.uid = this.navParams.data.modal.componentProps.uid;

    this.fs.collection<any[]>(`${FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_INSURRANCE_FEE.NODE}`).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    ).subscribe(p => {
      this.param_inssurrance = p;
    });
  }

  ngOnInit() {
    this.temp_lading_code = [];
    const temp_lading_code = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      action: null
    };
    this.temp_lading_code.push(temp_lading_code);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onAddTemp() {
    const temp_lading_code = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      action: null
    };
    this.temp_lading_code.push(temp_lading_code);
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    const consignment: any = await fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }));
    try {
      for (let i = 0; i < this.temp_lading_code.length; i++) {
        this.temp_lading_code[i].transport_code = String(this.temp_lading_code[i].transport_code).trim();
        const consignmentOrder = this.temp_lading_code[i];
        if (!consignmentOrder.transport_code) {
          throw new Error(`Chưa nhập mã vận đơn tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
        }

        if (!consignmentOrder.content) {
          throw new Error(`Chưa nhập nội dung hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
        }

        if (!consignmentOrder.product_quantity) {
          throw new Error(`Chưa nhập số lượng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
        }

        if (!consignmentOrder.product_link) {
          throw new Error(`Chưa nhập link hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
        }

        if (!consignmentOrder.shop_name) {
          throw new Error(`Chưa nhập thương hiệu tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
        }

        const isDuplicateTransportCode = await this.checkTransport(consignmentOrder.transport_code);
        if (!isDuplicateTransportCode) {
          throw new Error(`Mã vận đơn ${consignmentOrder.transport_code} đã tồn tại. Vui lòng nhập mã vận đơn khác`);
        }
      }

      for (const consignmentOrder of this.temp_lading_code) {
        const tran: any = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
          .where('transport_code', '==', consignmentOrder.transport_code)
          .get().then(snapshot => {
            if (snapshot.size) {
              const doc = snapshot.docs[0];
              return { exists: doc.exists, ...doc.data(), uid: doc.id };
            } else {
              return { exists: false, uid: undefined };
            }
          });

        const consignment_transport_uid = fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).doc().id;
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).doc(consignment_transport_uid), {
          transport_code: consignmentOrder.transport_code,
          consignment_transport_uid,
          consignment_code: consignment.consignment_code,
          consignment_uid: consignment.uid,
        });

        const update: any = {
          uid: consignment_transport_uid,
          consignment_transport_uid,
          transport_code: consignmentOrder.transport_code,
          content: consignmentOrder.content,
          product_quantity: consignmentOrder.product_quantity,
          product_link: consignmentOrder.product_link,
          shop_name: consignmentOrder.shop_name,
          consignment_transport_date_created: current
        };

        if (tran.exists) {
          update.transport_date_updated = current;
          update.transport_weight = tran.transport_weight || 0;
          update.transport_real_kg = tran.transport_real_kg || 0;
          update.transport_exchanged_kg = tran.transport_exchanged_kg || 0;
          update.transport_size = tran.transport_size || '';
          update.transport_price_mvd = tran.transport_price_mvd || 0;

          batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
            consignment_transport_date_mapping: current,
          });
        }

        batch.set(fs
          .collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid)
          .collection('transports').doc(consignment_transport_uid),
          update
        );

        const user_login: any = await this.getUserLogin(this.auth.auth.currentUser.uid);
        batch.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid)
          .collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY.NODE).doc(),
          {
            element: 'Thêm mã vận đơn',
            date: await this.commonService.getServerTime(),
            user: user_login.full_name,
            old_value: '',
            new_value: consignmentOrder.transport_code
          },
          { merge: true }
        );
      }

      await batch.commit();

      this.modalCtrl.dismiss();

      return 'Thêm mới mã vận đơn đơn ký gửi thành công';
    } catch (error) {
      throw error;
    }
  }

  async checkTransport(transport_code) {
    const fs = firestore();
    const consignment_code = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE).where('transport_code', '==', transport_code).get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.get('consignment_code'));
      });
      return rs;
    });

    const order_code = await fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).where('transport_code', '==', transport_code).get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.get('order_code'));
      });
      return rs;
    });

    return new Promise((resolve, reject) => {
      if (consignment_code.length) {
        resolve(false);
      } else if (order_code.length) {
        resolve(false);
      } else {
        resolve(true);
      }
    });

  }

  onDeleteTemp(index) {
    this.temp_lading_code.splice(index, 1);
  }

  checkTrans() {
    let is_validated = true;
    this.temp_lading_code.forEach(tran => {
      if (
        !tran.transport_code || !tran.content
      ) {
        is_validated = false;
      }
    });
    return is_validated;
  }

  getUserLogin(uid) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(uid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }));
  }
}
