import { takeUntil } from 'rxjs/operators';
import { FIREBASE_STRUCT } from '../../../app.constant';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-customer-infomation',
  templateUrl: './customer-infomation.component.html',
  styleUrls: ['./customer-infomation.component.css']
})
export class CustomerInfomationComponent implements OnInit, OnDestroy {

  uid;
  customer: any;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public _db: AngularFirestore,
    public navParams: NavParams,
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.getCustomerInfo().subscribe(acs => {
      this.customer = acs;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  onSubmit() {
    this.modalCtrl.dismiss();
  }

  getCustomerInfo() {
    return this._db.doc<any>(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }
}
