import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CustomConfirm, FIREBASE_STRUCT } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Decimal } from '../../app.models';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-edit-bag',
  templateUrl: './edit-bag.component.html',
  styleUrls: ['./edit-bag.component.css']
})
export class EditBagComponent implements OnDestroy, OnInit {
  item: any;
  bags: any[];
  selected_bags: any[] = [];
  unsubscribe$ = new Subject();
  bag: any;
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public apiService: ApiService
  ) {
    this.getBags().subscribe(bags => {
      this.bags = bags;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Cập nhật thông tin bao này')
  async onSubmit() {
    const bag = { ...this.bag };
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          skipauthorization: 'true'
        })
      };

      const data: any = {};
      data.bag = bag;
      await this.apiService.post('admin/warehouses/updateBagInfo', data, httpOptions);

      this.modalCtrl.dismiss();
      return 'Cập nhật bao thành công';
    } catch (error) {
      throw error;
    }

  }

  onSelectBag(ev) {
    console.log(ev);
    this.bag = ev;
  }

  getBags() {
    return this.fs.collection<any>(FIREBASE_STRUCT.BAGS.NODE).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  private _getTranByBagCode(bagCode: string): Promise<any[]> {
    return this.fs.collection<any>(FIREBASE_STRUCT.TRANSPORTS.NODE, q => {
      return q.where('bag_code', '==', bagCode).orderBy('transport_date_created', 'desc');
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();
  }

}
