import { CommonUtilsService } from './../utils/common-utils.service';
import { CONSIGNMENT_STATUS, FIREBASE_STRUCT, PAYMENT_HISTORY_TYPE } from './../../app.constant';
import { Injectable } from '@angular/core';
import { ORDER_STATUS } from '../../app.constant';
import { AngularFirestore } from '@angular/fire/firestore';
import { Decimal } from '../../app.models';
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    public fs: AngularFirestore,
    public commonService: CommonUtilsService,
  ) { }

  public async paymentOrderByApp(order_uid: any, user_uid, userLogin: any) {
    const fs = firestore();
    const current = await this.commonService.getServerTime();
    const uid_ref = fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID);
    const user_ref = fs.collection(FIREBASE_STRUCT.USERS.NODE).doc(user_uid);
    const order_ref = fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order_uid);
    return fs.runTransaction(transaction => {
      const promises = [
        transaction.get(uid_ref),
        transaction.get(user_ref),
        transaction.get(order_ref)
      ];
      return Promise.all(promises).then(([uid_doc, user_doc, order_doc]) => {
        const payment_id: number = Number(uid_doc.get('id') || 0) + 1;
        const user_payment_id: number = Number(uid_doc.get(user_doc.id) || 0) + 1;
        const order: any = { uid: order_doc.id, ...order_doc.data() };
        const user: any = { uid: user_doc.id, ...user_doc.data() };
        if (order.order_lack_of_paid > 0) {
          const orderLackOfPaid = order.order_lack_of_paid;
          let orderPaid = order.order_paid || 0;
          let accountBalance = user.account_balance;
          let availableLimit = user.available_limit;
          let usedMoney = user.used_money;
          const creditLimit = user.credit_limit;

          accountBalance = new Decimal(accountBalance).sub(orderLackOfPaid).toNumber();
          usedMoney = new Decimal(usedMoney).add(orderLackOfPaid).toNumber();
          availableLimit = new Decimal(creditLimit).add(accountBalance).toNumber();
          orderPaid = new Decimal(orderPaid).add(orderLackOfPaid).toNumber();

          const order_status_key: number = order.order_status_key;
          let orderStatusKey = 0;
          let orderStatusText = '';
          if (order_status_key < ORDER_STATUS.DATHANHTOAN.KEY) {
            orderStatusKey = ORDER_STATUS.DATHANHTOAN.KEY;
            orderStatusText = ORDER_STATUS.DATHANHTOAN.VALUE;
          } else {
            orderStatusKey = order.order_status_key;
            orderStatusText = order.order_status_text;
          }

          if (order_status_key < ORDER_STATUS.HOANTHANH.KEY) {
            transaction.update(order_ref, {
              order_paid: orderPaid,
              order_lack_of_paid: 0,
              order_status_key: orderStatusKey,
              order_status_text: orderStatusText,
              order_date_paid: current
            });
          } else {
            transaction.update(order_ref, {
              order_paid: orderPaid,
              order_lack_of_paid: 0,
              order_date_paid: current
            });
          }

          transaction.update(user_ref, {
            account_balance: accountBalance,
            available_limit: availableLimit,
            used_money: usedMoney,
          });

          transaction.set(order_ref.collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
            element: 'Thanh toán ví âm',
            date: current,
            user: userLogin.full_name,
            old_value: orderLackOfPaid,
            new_value: 0
          });

          transaction.set(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(), {
            payment_date_created: current,
            payment_type_key: PAYMENT_HISTORY_TYPE.THANHTOAN.KEY,
            payment_type: PAYMENT_HISTORY_TYPE.THANHTOAN.VALUE,
            payment_content: `Thanh toán ví âm đơn hàng #${order.order_code_2}`,
            payment_amount: orderLackOfPaid,
            payment_code: payment_id,
            order_code: order.order_code,
            order_code_2: order.order_code_2,
            payment_create_user: userLogin.full_name,
            payment_name: 'Ví điện tử',
            payment_order_code: order.order_code,
            payment_order_code_2: order.order_code_2,
            payment_order_uid: order.uid,
            customer_account_balance: accountBalance,
            customer_id: user.id,
            customer_full_name: user.full_name,
            customer_phone_number: user.phone,
            customer_email: user.email,
            customer_user_uid: user.uid,
            customer_payment_code: user_payment_id,
            branch_uid: user.branch_uid
          });

          transaction.set(order_ref.collection(FIREBASE_STRUCT.ORDER_DETAIL_PAYMENT_HISTORY.NODE).doc(), {
            payment_type: `Thanh toán ví âm đơn hàng #${order.order_code_2}`,
            payment_date: current,
            payment_value: orderLackOfPaid,
            payment_user: userLogin.full_name,
            payment_site: 'Quản lý'
          });

          transaction.set(uid_ref, {
            id: payment_id,
            [user_uid]: user_payment_id
          }, { merge: true });
        } else {
          throw new Error('Số tiền còn thiếu bằng 0. Xử lý thất bại');
        }
      });
    });

  }

  public async paymentOrderRefund(refund: any, order_uid: string, user_uid: string, userLogin: any) {
    const fs = firestore();
    const current = await this.commonService.getServerTime();
    const uid_ref = fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID);
    const user_ref = fs.collection(FIREBASE_STRUCT.USERS.NODE).doc(user_uid);
    const order_ref = fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order_uid);
    return fs.runTransaction(transaction => {
      const promises = [
        transaction.get(uid_ref),
        transaction.get(user_ref),
        transaction.get(order_ref)
      ];
      return Promise.all(promises).then(([uid_doc, user_doc, order_doc]) => {
        const payment_id: number = Number(uid_doc.get('id') || 0) + 1;
        const user_payment_id: number = Number(uid_doc.get(user_doc.id) || 0) + 1;
        const order: any = { uid: order_doc.id, ...order_doc.data() };
        const user: any = { uid: user_doc.id, ...user_doc.data() };
        const refundValue = Number(refund.value || 0);

        const account_balance = new Decimal(user.account_balance).add(refundValue).toNumber();
        const used_money = new Decimal(user.used_money).sub(refundValue).toNumber();
        const available_limit = new Decimal(user.credit_limit).add(account_balance).toNumber();
        const refunded_money = new Decimal(user.refunded_money).add(refundValue).toNumber();
        refund.created_date = current;
        refund.created_user = userLogin.full_name;

        const order_refund = new Decimal(order.order_refund).add(refundValue).toNumber();
        const order_lack_of_paid = new Decimal(order.order_total_price).sub(order.order_deposit).sub(order.order_paid).add(order_refund).toNumber();

        transaction.set(order_ref.collection('refund_fees').doc(), refund, { merge: true });
        transaction.update(order_ref, {
          order_lack_of_paid,
          order_refund
        });

        transaction.update(user_ref, {
          account_balance,
          available_limit,
          used_money,
          refunded_money
        });

        transaction.set(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(), {
          payment_date_created: current,
          payment_type_key: PAYMENT_HISTORY_TYPE.HOANTIEN.KEY,
          payment_type: PAYMENT_HISTORY_TYPE.HOANTIEN.VALUE,
          payment_content: `Hoàn tiền đơn hàng #${order.order_code_2} - ${refund.note ? refund.note : ''}`,
          payment_amount: refundValue,
          payment_code: payment_id,
          order_code: order.order_code,
          order_code_2: order.order_code_2,
          payment_create_user: userLogin.full_name,
          payment_name: 'Ví điện tử',
          payment_order_code: order.order_code,
          payment_order_code_2: order.order_code_2,
          payment_order_uid: order.uid,
          customer_account_balance: account_balance,
          customer_id: user.id,
          customer_full_name: user.full_name,
          customer_phone_number: user.phone,
          customer_email: user.email,
          note: refund.note ? refund.note : '',
          customer_user_uid: user.uid,
          customer_payment_code: user_payment_id,
          branch_uid: user.branch_uid
        });

        transaction.set(order_ref.collection(FIREBASE_STRUCT.ORDER_DETAIL_PAYMENT_HISTORY.NODE).doc(), {
          payment_type: `Hoàn tiền đơn hàng #${order.order_code}`,
          payment_date: current,
          payment_value: refundValue,
          payment_user: userLogin.full_name,
          payment_site: 'Quản lý'
        });

        transaction.set(uid_ref, {
          id: payment_id,
          [user_uid]: user_payment_id
        }, { merge: true });
      });
    });
  }

  public async paymentConsignmentOrderByApp(consisnment_uid: string, user_uid: string, userLogin: any) {

    const fs = firestore();
    const current = await this.commonService.getServerTime();
    const uid_ref = fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID);
    const user_ref = fs.collection(FIREBASE_STRUCT.USERS.NODE).doc(user_uid);
    const consignment_ref = fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consisnment_uid);
    return fs.runTransaction(transaction => {
      const promises = [
        transaction.get(uid_ref),
        transaction.get(user_ref),
        transaction.get(consignment_ref)
      ];
      return Promise.all(promises).then(([uid_doc, user_doc, consisgnment_doc]) => {
        const payment_id: number = Number(uid_doc.get('id') || 0) + 1;
        const user_payment_id: number = Number(uid_doc.get(user_doc.id) || 0) + 1;
        const consignment_order: any = { uid: consisgnment_doc.id, ...consisgnment_doc.data() };
        const user: any = { uid: user_doc.id, ...user_doc.data() };

        if (consignment_order.status_key < CONSIGNMENT_STATUS.DAVEKHO) {
          return Promise.reject('Hàng chưa về Việt Nam. Không thể thanh toán đơn hàng này.');
        }

        if (consignment_order.lack_of_paid <= 0) {
          return Promise.reject('Không tồn tại giá trị thanh toán (Còn thiếu <= 0). Không thể thanh toán đơn ký gửi này.');
        }

        const consignmentOrderLackOfPaid = consignment_order.lack_of_paid;
        let consignmentOrderPaid = consignment_order.paid || 0;
        let accountBalance = user.account_balance;
        let availableLimit = user.available_limit;
        let usedMoney = user.used_money;
        const creditLimit = user.credit_limit;

        accountBalance = new Decimal(accountBalance).sub(consignmentOrderLackOfPaid).toNumber();
        usedMoney = new Decimal(usedMoney).add(consignmentOrderLackOfPaid).toNumber();
        availableLimit = new Decimal(creditLimit).add(accountBalance).toNumber();
        consignmentOrderPaid = new Decimal(consignmentOrderPaid).add(consignmentOrderLackOfPaid).toNumber();

        const status_key: number = consignment_order.status_key;
        let statusKeyUpdate = status_key > CONSIGNMENT_STATUS.DATHANHTOAN.KEY ? consignment_order.status_key : CONSIGNMENT_STATUS.DATHANHTOAN.KEY;
        let statusTextUpdate = status_key > CONSIGNMENT_STATUS.DATHANHTOAN.KEY ? consignment_order.status_text : CONSIGNMENT_STATUS.DATHANHTOAN.VALUE;
        if (status_key < CONSIGNMENT_STATUS.HOANTHANH.KEY) {
          transaction.update(consignment_ref, {
            paid: consignmentOrderPaid,
            lack_of_paid: 0,
            status_key: statusKeyUpdate,
            status_text: statusTextUpdate,
            consignment_order_date_paid: current
          });
        } else {
          transaction.update(consignment_ref, {
            paid: consignmentOrderPaid,
            lack_of_paid: 0,
            consignment_order_date_paid: current
          });
        }

        transaction.update(user_ref, {
          account_balance: accountBalance,
          available_limit: availableLimit,
          used_money: usedMoney,
        });

        transaction.set(consignment_ref.collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
          element: 'Thanh toán ví âm',
          date: current,
          user: userLogin.full_name,
          old_value: consignmentOrderPaid,
          new_value: 0
        });

        transaction.set(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(), {
          payment_date_created: current,
          payment_type_key: PAYMENT_HISTORY_TYPE.THANHTOAN.KEY,
          payment_type: PAYMENT_HISTORY_TYPE.THANHTOAN.VALUE,
          payment_content: `Thanh toán ví âm đơn ký gửi #${consignment_order.consignment_order_code_2}`,
          payment_amount: consignmentOrderLackOfPaid,
          payment_code: payment_id,
          consignment_order_code: consignment_order.consignment_code,
          payment_create_user: userLogin.full_name,
          payment_name: 'Ví điện tử',
          payment_consignment_order_code: consignment_order.consignment_code,
          customer_account_balance: accountBalance,
          customer_id: user.id,
          customer_full_name: user.full_name,
          customer_phone_number: user.phone,
          customer_email: user.email,
          customer_user_uid: user.uid,
          customer_payment_code: user_payment_id,
          branch_uid: user.branch_uid
        });

        transaction.set(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignment_order.uid).collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_PAYMENT_HISTORY.NODE).doc(), {
          payment_type: `Thanh toán ví âm đơn ký gửi #${consignment_order.consignment_order_code_2}`,
          payment_date: current,
          payment_value: consignmentOrderLackOfPaid,
          payment_user: userLogin.full_name,
          payment_site: 'Quản lý'
        });

        transaction.set(uid_ref, {
          id: payment_id,
          [user_uid]: user_payment_id
        }, { merge: true });
      });
    });

  }

  public async paymentConsignmentOrderRefund(refund: any, consignment_uid: string, user_uid: string, userLogin: any) {
    const fs = firestore();
    const current = await this.commonService.getServerTime();
    const uid_ref = fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.PAYMENT_UID);
    const user_ref = fs.collection(FIREBASE_STRUCT.USERS.NODE).doc(user_uid);
    const consignment_ref = fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignment_uid);

    return fs.runTransaction(transaction => {
      const promises = [
        transaction.get(uid_ref),
        transaction.get(user_ref),
        transaction.get(consignment_ref)
      ];

      return Promise.all(promises).then(([uid_doc, user_doc, consignment_doc]) => {
        const payment_id: number = Number(uid_doc.get('id') || 0) + 1;
        const user_payment_id: number = Number(uid_doc.get(user_doc.id) || 0) + 1;
        const consignment_order: any = { uid: consignment_doc.id, ...consignment_doc.data() };
        const user: any = {
          uid: user_doc.id, ...user_doc.data()
        };

        if (consignment_order.lack_of_paid >= 0) {
          return Promise.reject('Chỉ hoàn tiền khi số tiền còn thiếu < 0');
        }

        if (refund.value > Math.abs(consignment_order.lack_of_paid)) {
          return Promise.reject('Số tiền hoàn lại phải nhỏ hơn lượng tiền thiếu');
        }

        const refundValue = Number(refund.value || 0);
        let consignmentOrderLackOfPaid = consignment_order.lack_of_paid || 0;
        let consignmentOrderRefund = consignment_order.refund_fee || 0;
        const consignmentOrderTotalFee = consignment_order.total_fee || 0;
        const consignmentOrderPaid = consignment_order.paid || 0;
        let accountBalance = user.account_balance;
        let availableLimit = user.available_limit;
        let usedMoney = user.used_money;
        const creditLimit = user.credit_limit;
        let refundedMoney = user.refunded_money || 0;

        accountBalance = new Decimal(accountBalance).add(refundValue).toNumber();
        usedMoney = new Decimal(usedMoney).sub(consignmentOrderLackOfPaid).toNumber();
        availableLimit = new Decimal(creditLimit).add(accountBalance).toNumber();
        refundedMoney = new Decimal(refundedMoney).add(refundValue).toNumber();
        refund.created_date = current;
        refund.created_user = userLogin.full_name;

        consignmentOrderRefund = new Decimal(consignmentOrderRefund).add(refundValue).toNumber();
        consignmentOrderLackOfPaid = new Decimal(consignmentOrderTotalFee)
          .sub(consignmentOrderPaid)
          .add(consignmentOrderRefund).toNumber();

        transaction.update(consignment_ref, {
          lack_of_paid: consignmentOrderLackOfPaid,
          refund_fee: consignmentOrderRefund
        });

        transaction.set(consignment_ref.collection('refund_fees').doc(), refund, { merge: true });

        transaction.update(user_ref, {
          account_balance: accountBalance,
          available_limit: availableLimit,
          used_money: usedMoney,
          refunded_money: refundedMoney,
        });

        const newId = fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc().id;
        transaction.set(fs.collection(FIREBASE_STRUCT.PAYMENT_HISTOTY.NODE).doc(newId), {
          payment_date_created: current,
          payment_type_key: PAYMENT_HISTORY_TYPE.HOANTIEN.KEY,
          payment_type: PAYMENT_HISTORY_TYPE.HOANTIEN.VALUE,
          payment_content: `Hoàn tiền thành công - ${refund.note ? refund.note : ''}`,
          payment_amount: refundValue,
          payment_code: payment_id,
          consignment_order_code: consignment_order.consignment_code,
          payment_create_user: userLogin.full_name,
          payment_name: 'Ví điện tử',
          payment_consignment_order_code: consignment_order.consignment_code,
          customer_account_balance: accountBalance,
          customer_id: user.id,
          customer_full_name: user.full_name,
          customer_phone_number: user.phone,
          customer_email: user.email,
          note: refund.note ? refund.note : '',
          customer_user_uid: consignment_order.user_uid,
          customer_payment_code: user_payment_id,
          branch_uid: user.branch_uid
        });

        transaction.set(consignment_ref.collection(FIREBASE_STRUCT.CONSIGNMENT_ORDER_DETAIL_PAYMENT_HISTORY.NODE).doc(), {
          payment_type: `Hoàn tiền đơn ký gửi #${consignment_order.uid}`,
          payment_date: current,
          payment_value: refundValue,
          payment_user: userLogin.full_name,
          payment_site: 'Quản lý'
        });

        transaction.set(uid_ref, {
          id: payment_id,
          [user_uid]: user_payment_id
        }, { merge: true });
      });
    });
  }

}
