import { CommonUtilsService } from './../utils/common-utils.service';
import { Injectable } from '@angular/core';
import { ORDER_DEFAULT, PHILUUKHOSG } from '../../constants/order';
import { AngularFirestore } from '@angular/fire/firestore';
import { FIREBASE_STRUCT, ORDER_STATUS, SG_RECEIVE_WAREHOUSE } from '../../app.constant';
import { map, switchMap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { firestore } from 'firebase/app';
import { Decimal } from '../../app.models';
import { OrderParam } from '../../model/order.param';
import { OrderUpdate } from '../../model/order.update';
import { BRANCH_CONFIG } from 'src/app/constants/constant';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class CalculateFeeService {

  constructor(
    public fs: AngularFirestore,
    public commonService: CommonUtilsService,
    public fireDb: AngularFireDatabase,
  ) { }

  public getOrderWithParam(order_uid: any): Observable<any> {
    return this.getOrder(order_uid).pipe(
      switchMap((_order: any) => {
        const order_status_key: number = _order.order_status_key;
        const order_special: boolean = _order.order_special;
        let ob: Observable<any>;
        if (order_special) {
          ob = of(_order.special_param);
        } else {
          if (order_status_key < ORDER_STATUS.DATCOC.KEY) {
            ob = this.getParam(_order);
          } else {
            ob = of(_order.normal_param);
          }
        }

        return ob.pipe(
          map((param: OrderParam) => {
            _order.param = param;
            return _order;
          }),
          switchMap(order => {
            return this.getUser(order.order_user_uid).pipe(
              map(user => {
                order.user = user;
                return order;
              })
            );
          }),
          switchMap(order => {
            return this.getProducts(order.uid).pipe(
              map(products => {
                order.products = products;
                return order;
              })
            );
          }),
          switchMap(order => {
            return this.getTransports(order.uid).pipe(
              map(transports => {
                order.transports = transports;
                return order;
              })
            );
          }),
        );
      })
    );
  }

  public calcServiceFee(products: any[], user_info: any, service_fee_params: any[], exchanged_rate: number, min: number, target_obj?: any) {
    if (!target_obj) { target_obj = {}; }

    let price = 0;
    let price_cny = 0;
    let total_price = 0;
    let total_price_cny = 0;
    let service_fee = 0;
    let service_fee_cny = 0;
    let service_fee_org = 0;
    let service_fee_org_cny = 0;
    let service_fee_discount = 0;
    let service_fee_discount_cny = 0;
    let percent_service_fee = 0;
    let product_choosen_count = 0;
    let percent_service_fee_discount = 0;
    let sum_quantity = 0;
    let service_fee_min = false;

    products.forEach(product => {
      product_choosen_count++;
      price_cny = new Decimal(product.product_price_cny).mul(product.product_quantity).add(price_cny).toNumber();
      sum_quantity = new Decimal(product.product_quantity).add(sum_quantity).toNumber();
    });

    price = new Decimal(price_cny).mul(exchanged_rate).toNumber();

    if (user_info.branch_uid === BRANCH_CONFIG.DEFAULT.UID) {
      service_fee_params.forEach(fee => {
        if (Number(fee.from_value) <= price && price < Number(fee.to_value)) {
          percent_service_fee = Number(fee.fee_value);
        }
      });

      if (Number(user_info.vip.type) === 1) {
        percent_service_fee = 0;
        service_fee_params.forEach(fee => {
          if (Number(fee.from_value) <= price && price < Number(fee.to_value)) {
            percent_service_fee = Number(fee.fee_value);
          }
        });
        percent_service_fee_discount = Number(user_info.vip.percent_service_fee_discount);
        service_fee_org_cny = new Decimal(price_cny).mul(percent_service_fee).toNumber();
        service_fee_discount_cny = new Decimal(service_fee_org_cny).mul(percent_service_fee_discount).toNumber();
        service_fee_org = new Decimal(service_fee_org_cny).mul(exchanged_rate).toNumber();
        service_fee_discount = new Decimal(service_fee_discount_cny).mul(exchanged_rate).toNumber();
      } else {
        percent_service_fee = Number(user_info.vip.service_fee);
        percent_service_fee_discount = 0;
        service_fee_org_cny = new Decimal(price_cny).mul(percent_service_fee).toNumber();
        service_fee_discount_cny = 0;
        service_fee_org = new Decimal(service_fee_org_cny).mul(exchanged_rate).toNumber();
        service_fee_discount = 0;
      }

      service_fee_cny = new Decimal(service_fee_org_cny).sub(service_fee_discount_cny).toNumber();
      if (service_fee_cny < min && sum_quantity > 0) {
        service_fee_cny = min;
        service_fee_min = true;
      } else {
        service_fee_min = false;
      }
      service_fee = new Decimal(service_fee_cny).mul(exchanged_rate).toNumber();
    }

    total_price_cny = new Decimal(price_cny).add(service_fee_cny).toNumber();
    total_price = new Decimal(price).add(service_fee).toNumber();

    target_obj.product_choosen_count = product_choosen_count;
    target_obj.percent_service_fee = percent_service_fee;
    target_obj.percent_service_fee_discount = percent_service_fee_discount;
    target_obj.order_price = price;
    target_obj.order_price_cny = price_cny;
    target_obj.order_service_fee_org = service_fee_org;
    target_obj.order_service_fee_org_cny = service_fee_org_cny;
    target_obj.order_service_fee_discount = service_fee_discount;
    target_obj.order_service_fee_discount_cny = service_fee_discount_cny;
    target_obj.order_service_fee = service_fee;
    target_obj.order_service_fee_cny = service_fee_cny;
    target_obj.order_total_price = total_price;
    target_obj.order_total_price_cny = total_price_cny;
    target_obj.order_service_fee_min = service_fee_min;
    return target_obj;
  }

  public getParam(order: any) {
    return combineLatest([
      this.getUser(order.order_user_uid),
      this.getDefaultParam(),
      this.getNewExchangeRate(order.branch_uid),
      this.getServiceFeeParams(order.branch_uid),
      this.getTransportFeeFastParams(),
      this.getTransportFeeSlowParams(),
      this.getTransportFeeParams(order.branch_uid),
      this.getCheckGoodsFeeParams(),
      this.getTransportSGWarehouseParams()
    ]).pipe(
      map(([
        user,
        default_param,
        tygiabaokhach_new,
        service_fee_params,
        transport_fee_fast_params,
        transport_fee_slow_params,
        transport_fee_params,
        check_goods_fee_params,
        transport_sg_warehouse_params
      ]) => {
        let rs: OrderParam = {};
        const order_status_key: number = order.order_status_key;
        const order_special: boolean = order.order_special;
        if (!order_special) {
          if (order_status_key < ORDER_STATUS.DATCOC.KEY) {
            rs.tygiabaokhach = Number(default_param[ORDER_DEFAULT.TYGIABAOKHACH.KEY].value);
            rs.tygiathuc = Number(default_param[ORDER_DEFAULT.TYGIATHUC.KEY].value);
            rs.phidonggokgdau = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGDAU.KEY].value);
            rs.phidongokgtieptheo = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGTIEPTHEO.KEY].value);
            rs.phichuyenkhoan = Number(default_param[ORDER_DEFAULT.PHICHUYENKHOAN.KEY].value);
            rs.phidichvudacbiet = Number(service_fee_params[0].fee_value);
            rs.phidichvutoithieu = Number(default_param[ORDER_DEFAULT.PHIDICHVUTOITHIEU.KEY].value);
            rs.philuukho = Number(default_param[ORDER_DEFAULT.PHILUUKHO.KEY].value);
            rs.ngaybatdauluukho = Number(default_param[ORDER_DEFAULT.NGAYBATDAUTINHLUUKHO.KEY].value);
            rs.ngaytudonghoanthanh = Number(default_param[ORDER_DEFAULT.NGAYTUDONGHOANTHANH.KEY].value);
            rs.cuocvanchuyennhanh = [...transport_fee_fast_params];
            rs.cuocvanchuyencham = [...transport_fee_slow_params];
            rs.cuockiemhang = [...check_goods_fee_params];
            rs.cuocvanchuyenkhosaigon = [...transport_sg_warehouse_params];
            rs.phidichvu = [...service_fee_params];
            rs.vip = { ...user.vip };
          } else {
            if (order.normal_param) {
              rs = order.normal_param;
              return rs;  // Return the param of the order if existed
            } else {
              rs.tygiabaokhach = Number(default_param[ORDER_DEFAULT.TYGIABAOKHACH.KEY].value);
              rs.tygiathuc = Number(default_param[ORDER_DEFAULT.TYGIATHUC.KEY].value);
              rs.phidonggokgdau = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGDAU.KEY].value);
              rs.phidongokgtieptheo = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGTIEPTHEO.KEY].value);
              rs.phichuyenkhoan = Number(default_param[ORDER_DEFAULT.PHICHUYENKHOAN.KEY].value);
              rs.phidichvudacbiet = Number(service_fee_params[0].fee_value);
              rs.phidichvutoithieu = Number(default_param[ORDER_DEFAULT.PHIDICHVUTOITHIEU.KEY].value);
              rs.philuukho = Number(default_param[ORDER_DEFAULT.PHILUUKHO.KEY].value);
              rs.ngaybatdauluukho = Number(default_param[ORDER_DEFAULT.NGAYBATDAUTINHLUUKHO.KEY].value);
              rs.ngaytudonghoanthanh = Number(default_param[ORDER_DEFAULT.NGAYTUDONGHOANTHANH.KEY].value);
              rs.cuocvanchuyennhanh = [...transport_fee_fast_params];
              rs.cuocvanchuyencham = [...transport_fee_slow_params];
              rs.cuockiemhang = [...check_goods_fee_params];
              rs.phidichvu = [...service_fee_params];
              rs.cuocvanchuyenkhosaigon = [...transport_sg_warehouse_params];
              rs.vip = { ...user.vip };
            }
          }
        } else {
          if (order.special_param) {
            rs = order.special_param;
            return rs;  // Return the param of the order if existed
          } else {
            if (order.normal_param) {
              rs.tygiabaokhach = order.normal_param.tygiabaokhach;
              rs.tygiathuc = order.normal_param.tygiathuc;
              rs.phidonggokgdau = order.normal_param.phidonggokgdau;
              rs.phidongokgtieptheo = order.normal_param.phidongokgtieptheo;
              rs.phichuyenkhoan = order.normal_param.phichuyenkhoan;
              rs.phidichvudacbiet = Number(service_fee_params[0].fee_value);
              rs.phidichvutoithieu = order.normal_param.phidichvutoithieu;
              rs.philuukho = order.normal_param.philuukho;
              rs.ngaybatdauluukho = order.normal_param.ngaybatdauluukho;
              rs.ngaytudonghoanthanh = order.normal_param.ngaytudonghoanthanh;
              rs.cuocvanchuyen = [...transport_fee_fast_params];
              rs.phidichvu = [...service_fee_params];
              rs.cuockiemhang = [...check_goods_fee_params];
              rs.cuocvanchuyenkhosaigon = [...transport_sg_warehouse_params];
              rs.vip = { ...user.vip };
            } else {
              rs.tygiabaokhach = Number(default_param[ORDER_DEFAULT.TYGIABAOKHACH.KEY].value);
              rs.tygiathuc = Number(default_param[ORDER_DEFAULT.TYGIATHUC.KEY].value);
              rs.phidonggokgdau = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGDAU.KEY].value);
              rs.phidongokgtieptheo = Number(default_param[ORDER_DEFAULT.PHIDONGGOKGTIEPTHEO.KEY].value);
              rs.phichuyenkhoan = Number(default_param[ORDER_DEFAULT.PHICHUYENKHOAN.KEY].value);
              rs.phidichvudacbiet = Number(service_fee_params[0].fee_value);
              rs.phidichvutoithieu = Number(default_param[ORDER_DEFAULT.PHIDICHVUTOITHIEU.KEY].value);
              rs.philuukho = Number(default_param[ORDER_DEFAULT.PHILUUKHO.KEY].value);
              rs.ngaybatdauluukho = Number(default_param[ORDER_DEFAULT.NGAYBATDAUTINHLUUKHO.KEY].value);
              rs.ngaytudonghoanthanh = Number(default_param[ORDER_DEFAULT.NGAYTUDONGHOANTHANH.KEY].value);
              rs.cuocvanchuyen = [...transport_fee_fast_params];
              rs.phidichvu = [...service_fee_params];
              rs.cuockiemhang = [...check_goods_fee_params];
              rs.cuocvanchuyenkhosaigon = [...transport_sg_warehouse_params];
              rs.vip = { ...user.vip };
            }
          }
        }

        if (order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID
          || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID) {
          rs.tygiabaokhach = tygiabaokhach_new;
          rs.cuocvanchuyen = [...transport_fee_params];
          rs.phidichvudacbiet = Number(order.percent_service_fee);
        }

        return rs;
      })
    );
  }

  public async getOrderUpdate(order) {
    const rs: OrderUpdate = {
      order_deposit: order.order_deposit || 0,
      order_paid: order.order_paid || 0,
      order_refund: order.order_refund || 0,
      order_lack_of_paid: order.order_lack_of_paid || 0,
      order_extra_fee: order.order_extra_fee || 0,
      order_transport_fee: order.order_transport_fee || 0,
      order_transport_fee_org: order.order_transport_fee_org || 0,
      order_transport_fee_discount: order.order_transport_fee_discount || 0,
      order_transport_real_fee: order.order_transport_real_fee || 0,
      order_ship_price: order.order_ship_price || 0,
      order_price: 0,
      order_service_fee_org: 0,
      order_service_fee_discount: 0,
      order_service_fee: 0,
      order_total_price: 0,
      order_warehouse_fee: order.order_warehouse_fee || 0,

      order_price_cny: 0,
      order_service_fee_org_cny: 0,
      order_service_fee_discount_cny: 0,
      order_service_fee_cny: 0,
      order_total_price_cny: 0,
      order_ship_price_cny: order.order_ship_price_cny || 0,
      order_price_real_cny: order.order_price_real_cny || 0,
      order_ship_real_cny: order.order_ship_real_cny || 0,
      order_total_real_cny: order.order_total_real_cny || 0,

      order_discount: 0,

      sg_warehouse_extra_fee: order.sg_warehouse_extra_fee || 0,

      order_profit: 0,

      order_product_quantity: 0,
      order_quantity_order: 0,
      order_quantity_warehouse: 0,
      order_check_goods_fee: 0,
      order_close_wood_fee: 0,

      percent_service_fee: order.percent_service_fee_discount || 0,
      percent_service_fee_discount: order.percent_service_fee_discount || 0,
      percent_transport_fee_discount: order.percent_transport_fee_discount || 0,

      order_promotion_fee: order.order_promotion_fee || 0,

      order_cpn: order.order_cpn,

      order_service_fee_min: order.order_service_fee_min || false,
    };

    const param: OrderParam = order.param;
    const vip = param.vip;
    const products: any[] = order.products;
    const order_cpn: boolean = order.order_cpn;
    const order_special: boolean = order.order_special;
    products.forEach(product => {
      product.product_price = new Decimal(product.product_price_cny).mul(param.tygiabaokhach).toNumber();
      rs.order_price_cny = new Decimal(product.product_price_cny).mul(product.product_quantity).add(rs.order_price_cny).toNumber();
      rs.order_product_quantity = new Decimal(product.product_quantity).add(rs.order_product_quantity).toNumber();
      rs.order_quantity_order = new Decimal(product.quantity_order).add(rs.order_quantity_order).toNumber();
      rs.order_quantity_warehouse = new Decimal(product.quantity_warehouse).add(rs.order_quantity_warehouse).toNumber();
    });

    if (!rs.order_product_quantity) {
      rs.order_ship_price_cny = 0;
    }

    rs.order_ship_price = new Decimal(rs.order_ship_price_cny).mul(param.tygiabaokhach).toNumber();

    rs.order_price = new Decimal(rs.order_price_cny).mul(param.tygiabaokhach).toNumber();

    let percent_service_fee = 0;
    if (order_special) {
      rs.percent_service_fee = percent_service_fee = param.phidichvudacbiet;
      rs.percent_service_fee_discount = 0;
      rs.order_service_fee_discount = 0;
      rs.order_service_fee_org_cny = new Decimal(rs.order_price_cny).mul(param.phidichvudacbiet).toNumber();
      rs.order_service_fee_org = new Decimal(rs.order_service_fee_org_cny).mul(param.tygiabaokhach).toNumber();
    } else {
      if (vip.type === 1) {
        percent_service_fee = 0;
        for (const fee of param.phidichvu) {
          const fromValue = Number(fee.from_value);
          const toValue = Number(fee.to_value);
          if (fee.fee_value && fromValue <= rs.order_price && rs.order_price < toValue) {
            percent_service_fee = Number(fee.fee_value);
          }
        }
        rs.percent_service_fee = percent_service_fee;
        rs.percent_service_fee_discount = Number(vip.percent_service_fee_discount);
        rs.order_service_fee_org_cny = new Decimal(rs.order_price_cny).mul(percent_service_fee).toNumber();
        rs.order_service_fee_discount_cny = new Decimal(rs.order_service_fee_org_cny).mul(rs.percent_service_fee_discount).toNumber();
        rs.order_service_fee_org = new Decimal(rs.order_service_fee_org_cny).mul(param.tygiabaokhach).toNumber();
        rs.order_service_fee_discount = new Decimal(rs.order_service_fee_discount_cny).mul(param.tygiabaokhach).toNumber();
      } else {
        rs.percent_service_fee = Number(vip.service_fee);
        rs.percent_service_fee_discount = 0;
        rs.order_service_fee_discount = 0;
        rs.order_service_fee_org_cny = new Decimal(rs.order_price_cny).mul(vip.service_fee).toNumber();
        rs.order_service_fee_org = new Decimal(rs.order_service_fee_org_cny).mul(param.tygiabaokhach).toNumber();
        rs.order_service_fee_discount_cny = 0;
      }
    }

    // Cal service fee with new branch
    if (order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID
      || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID) {
      const price = rs.order_price;
      if (!order_special) {
        param.phidichvu.forEach(fee => {
          if ((fee.branchUid === BRANCH_CONFIG.SOC_ORDER.UID
            || fee.branchUid === BRANCH_CONFIG.PANDA_ORDER.UID)
            && Number(fee.from_value) <= price && price < Number(fee.to_value)) {
            const fee_value_deposit = fee.fee_value_deposit.find(f => Number(f.value) === Number(order.percent_deposit));
            let fee_value = 0;
            if (fee_value_deposit) {
              fee_value = fee_value_deposit.fee_value;
            }
            percent_service_fee = Number(fee_value);
          }
        });
      }
      
      rs.order_service_fee_org_cny = new Decimal(rs.order_price_cny).mul(percent_service_fee).toNumber();
      rs.order_service_fee_discount_cny = 0;
      rs.order_service_fee_org = new Decimal(rs.order_service_fee_org_cny).mul(param.tygiabaokhach).toNumber();
      rs.percent_service_fee = percent_service_fee;
    }

    rs.order_service_fee_cny = new Decimal(rs.order_service_fee_org_cny).sub(rs.order_service_fee_discount_cny).toNumber();
    if (rs.order_service_fee_cny > 0) {
      if (rs.order_service_fee_cny < param.phidichvutoithieu) {
        rs.order_service_fee_cny = param.phidichvutoithieu;
        rs.order_service_fee_min = true;
      } else {
        rs.order_service_fee_min = false;
      }
    }

    rs.order_service_fee = new Decimal(rs.order_service_fee_cny).mul(param.tygiabaokhach).toNumber();
    // Cal service fee with new branch
    if ((order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID
      || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID)
      && !rs.order_service_fee_min) {
      rs.order_service_fee = new Decimal(rs.order_price).mul(percent_service_fee).toNumber();
    }

    // Tinh trasnport fee
    const order_weight = order.order_weight;
    if (order_weight > 0) {
      if (order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID
        || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID) {
        const transport_params = param.cuocvanchuyen;
        const order_price = order.order_price || 0;
        for (const p of transport_params) {
          if (Number(p.from_value) <= order_price && order_price < Number(p.to_value)) {
            const fee_value_warehouse = p.fee_value_warehouse.find(f => f.value === order.receive_warehouse_code);
            if (fee_value_warehouse !== undefined) {
              const fee_value = new Decimal(fee_value_warehouse.fee_value);
              rs.order_transport_fee_org = fee_value.mul(order_weight).toNumber();
            }
          }
        }

        if (order_special) {
          rs.percent_transport_fee_discount = 0;
          rs.order_transport_fee_discount = 0;
        } else {
          rs.percent_transport_fee_discount = Number(vip.percent_transport_fee_discount);
          rs.order_transport_fee_discount = new Decimal(rs.order_transport_fee_org).mul(rs.percent_transport_fee_discount).toNumber();
        }
        rs.order_transport_fee = new Decimal(rs.order_transport_fee_org).sub(rs.order_transport_fee_discount).toNumber();
      } else {
        let transport_params: any[];
        if (order_special) {
          transport_params = param.cuocvanchuyen;
        } else {
          if (order_cpn) {
            transport_params = param.cuocvanchuyennhanh;
          } else {
            transport_params = param.cuocvanchuyencham;
          }
        }

        if (vip.type === 1) {
          for (const _param of transport_params) {
            if (Number(_param.from_value) <= order_weight && order_weight < Number(_param.to_value)) {
              rs.order_transport_fee_org = new Decimal(_param.fee_value).mul(order_weight).toNumber();
              break;
            }
          }
          if (order_special) {
            rs.percent_transport_fee_discount = 0;
            rs.order_transport_fee_discount = 0;
          } else {
            rs.percent_transport_fee_discount = Number(vip.percent_transport_fee_discount);
            rs.order_transport_fee_discount = new Decimal(rs.order_transport_fee_org).mul(rs.percent_transport_fee_discount).toNumber();
          }
          rs.order_transport_fee = new Decimal(rs.order_transport_fee_org).sub(rs.order_transport_fee_discount).toNumber();
        } else {
          rs.order_transport_fee_org = new Decimal(order_weight).mul(vip.transport_fee).toNumber();
          rs.percent_transport_fee_discount = 0;
          rs.order_transport_fee_discount = 0;
          rs.order_transport_fee = new Decimal(rs.order_transport_fee_org).sub(rs.order_transport_fee_discount).toNumber();
        }
      }
    } else {
      rs.order_transport_fee_org = 0;
      rs.percent_transport_fee_discount = 0;
      rs.order_transport_fee_discount = 0;
      rs.order_transport_fee = 0;
    }

    // Tinh extra fee
    let closeWoodfeeValue = 0;
    if (order_weight === 0) {
      closeWoodfeeValue = 0;
    } else {
      closeWoodfeeValue = new Decimal(order_weight).sub(1).mul(param.phidongokgtieptheo).add(param.phidonggokgdau).mul(param.tygiabaokhach).toNumber();
    }
    const checkGoodsfeeValue = this.getCheckGoodsFeeValue(order, param);

    const check_goods_fee_uid = order.check_goods_fee_uid;
    const close_wood_fee_uid = order.close_wood_fee_uid;
    const fs = firestore();

    if (check_goods_fee_uid) {
      rs.order_check_goods_fee = checkGoodsfeeValue;
    } else {
      rs.order_check_goods_fee = 0;
    }
    if (close_wood_fee_uid) {
      rs.order_close_wood_fee = closeWoodfeeValue;
    } else {
      rs.order_close_wood_fee = 0;
    }
    const receive_sg_warehouse: boolean = order.receive_warehouse_code === SG_RECEIVE_WAREHOUSE.CODE;
    const transferSgWarehouseFeeValue = this.getTransferSgWarehouseFeeValue(order, param);

    const isNewBranches = order.branch_uid === BRANCH_CONFIG.SOC_ORDER.UID || order.branch_uid === BRANCH_CONFIG.PANDA_ORDER.UID;
    if (receive_sg_warehouse && order_weight > 0 && !isNewBranches) {
      await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(PHILUUKHOSG).set({
        name: 'Phí vận chuyển về kho Sài Gòn',
        value: transferSgWarehouseFeeValue,
        note: 'Hệ thống tự động tính',
        created_date: new Date().getTime(),
        created_user: '',
        type: 1,
        disabled: true
      });
    } else {
      await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(PHILUUKHOSG).delete();
    }
    
    const extra_fees: any[] = order.extra_fees;
    rs.order_extra_fee = 0;

    extra_fees.forEach(element => {
      if (element.uid === PHILUUKHOSG && !isNewBranches) {
        element.value = transferSgWarehouseFeeValue;
      }
      if (element.uid === check_goods_fee_uid) {
        element.value = checkGoodsfeeValue;
      }
      if (element.uid === close_wood_fee_uid) {
        element.value = closeWoodfeeValue;
      }
    });
    extra_fees.forEach(element => {
      rs.order_extra_fee = new Decimal(element.value).add(rs.order_extra_fee).toNumber();
    });

    rs.order_discount = new Decimal(rs.order_transport_fee_discount)
      .add(rs.order_service_fee_discount)
      .toNumber();

    const orderPromotion = order.order_promotion;
    let orderPromotionFee = 0;
    if (orderPromotion && orderPromotion.uid) {
      if (orderPromotion.promotion_type === 1 || orderPromotion.promotion_type === 2 || orderPromotion.promotion_type === 3) {
        orderPromotionFee = new Decimal(rs.order_service_fee).mul(orderPromotion.promotion_value).round(0).toNumber();

        if (orderPromotionFee > orderPromotion.max_promotion_amount) {
          orderPromotionFee = orderPromotion.max_promotion_amount;
        }
      }
    }
    rs.order_promotion_fee = orderPromotionFee;

    rs.order_total_price = new Decimal(rs.order_price)
      .add(rs.order_ship_price)
      .add(rs.order_service_fee)
      .add(rs.order_extra_fee)
      .add(rs.order_transport_fee)
      .sub(rs.order_promotion_fee)
      .toNumber();
    rs.order_total_price_cny = new Decimal(rs.order_price_cny)
      .add(rs.order_service_fee_cny)
      .add(rs.order_ship_price_cny)
      .toNumber();
    rs.order_lack_of_paid = new Decimal(rs.order_total_price)
      .sub(rs.order_deposit)
      .sub(rs.order_paid)
      .add(rs.order_refund)
      .toNumber();
    const a = new Decimal(rs.order_total_price_cny).sub(rs.order_total_real_cny).mul(param.tygiabaokhach);
    const b = new Decimal(param.tygiabaokhach).sub(param.tygiathuc).mul(rs.order_total_real_cny);
    rs.order_profit = a
      .add(b)
      .add(rs.order_transport_fee)
      .add(rs.sg_warehouse_extra_fee)
      .sub(rs.order_transport_real_fee)
      .toNumber();

    return rs;
  }

  private getTransferSgWarehouseFeeValue(order, param: OrderParam) {
    let fee = 0;
    const receive_sg_warehouse: boolean = order.receive_warehouse_code === SG_RECEIVE_WAREHOUSE.CODE;
    if (receive_sg_warehouse) {
      const sg_warehouse_params: any[] = param.cuocvanchuyenkhosaigon || [];
      const order_weight = Number(order.order_weight || 0);
      sg_warehouse_params.forEach(p => {
        if (Number(p.from_value) <= order_weight && order_weight < Number(p.to_value)) {
          fee = new Decimal(p.fee_value).mul(order_weight).toNumber();
        }
      });
      /* const _fee = sg_warehouse_params
          .filter(p => Number(p.from_value) <= order_weight && order_weight < Number(p.to_value))
          .map(p => Number(p.fee_value))
          .reduce((prev, cur) => new Decimal(prev).add(cur).toNumber(), 0);
      fee = new Decimal(_fee).mul(order_weight).toNumber(); */
    }
    return fee;
  }

  async revokeOrder(order: any, order_update: any, user_login: any, batch: firestore.WriteBatch) {
    const fs = firestore();
    const param: OrderParam = order.param;
    const current = new Date().getTime();

    batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
      ...order_update,
      tygiabaokhach: param.tygiabaokhach,
      tygiathuc: param.tygiathuc,
    });

    if (order_update.order_check_goods_fee > 0 && order.check_goods_fee_uid) {
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(order.check_goods_fee_uid), {
        value: order_update.order_check_goods_fee
      });
    }

    if (order_update.order_close_wood_fee > 0 && order.close_wood_fee_uid) {
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(order.close_wood_fee_uid), {
        value: order_update.order_close_wood_fee
      });
    }

    if (param.tygiabaokhach !== order.tygiabaokhach) {
      const products: any[] = order.products || [];
      for (const product of products) {
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('products').doc(product.uid), {
          product_price: new Decimal(product.product_price_cny).mul(param.tygiabaokhach).toNumber()
        });
      }
    }

    if (order.order_status_key >= ORDER_STATUS.DAGIAO.KEY && order.order_status_key !== ORDER_STATUS.HUYDON.KEY) {
      const products: any[] = order.products;
      const transports: any[] = order.transports;
      /* let order_match_weight = 'Đã khớp'; */
      let order_match_export_link = 'Đã khớp';
      let order_match_export_mvd = 'Đã khớp';
      products.forEach(p => {
        if (p.product_quantity !== p.quantity_order ||
          p.quantity_order !== p.quantity_warehouse ||
          p.quantity_warehouse !== p.product_quantity
        ) {
          if (!p.order_date_release_customer) {
            order_match_export_link = 'Không khớp';
          }
        }
      });
      transports.forEach(t => {
        if (!t.transport_date_release_customer) {
          order_match_export_mvd = 'Không khớp';
        }
      });
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
        order_match_export_link,
        order_match_export_mvd
      });
    }

    if (order_update.order_status_key === ORDER_STATUS.HUYDON.KEY) {
      order.products.forEach(product => {
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('products').doc(product.uid), {
          product_price: 0
        });
      });
      order.extra_fees.forEach(extra_fee => {
        batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('extra_fees').doc(extra_fee.uid));
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
          order_extra_fee: 0
        });
      });

      if (order.order_lack_of_paid < 0) {
        const refund_fee = {
          value: new Decimal(order.order_lack_of_paid).mul(-1).toNumber(),
          note: null,
          created_user: user_login.full_name,
          created_date: current,
          type: 1
        };

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('refund_fees').doc(), refund_fee);
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
          order_lack_of_paid: 0
        });
      }
    }

    if (order_update.order_status_key === ORDER_STATUS.HOANTHANH.KEY) {
      if (order.order_lack_of_paid < 0) {
        const refund_fee = {
          value: new Decimal(order.order_lack_of_paid).mul(-1).toNumber(),
          note: null,
          created_user: user_login.full_name,
          created_date: current,
          type: 1
        };

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid).collection('refund_fees').doc(), refund_fee);
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.uid), {
          order_lack_of_paid: 0
        });
      }
    }

  }

  public async revokeConsignmentOrder(consignment_order: any, user_login: any, batch: firestore.WriteBatch) {
    const fs = firestore();
    const consignmentOrder = { ...consignment_order };

    const insurranceFee = new Decimal(consignmentOrder.price).mul(consignmentOrder.percent_insurrance_fee).roundUp(0).toNumber();
    batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid).collection('extra_fees').doc('BH'), {
      value: insurranceFee
    });

    let extraFee = insurranceFee;
    let totalPrice = 0;
    let totalFee = 0;
    let lackOfPaid = 0;
    let consignmentOrderProfit = 0;
    const price = consignmentOrder.price || 0;
    const paid = consignmentOrder.paid || 0;
    const transportFee = consignmentOrder.transport_fee || 0;
    const transportRealFee = consignmentOrder.transport_real_fee || 0;
    const extraFeeAuto = consignmentOrder.extra_fee_auto || 0;
    const refundFee = consignmentOrder.refund_fee || 0;
    consignmentOrder.extra_fees.forEach(extraFeeObj => {
      if (extraFeeObj.type !== 0) {
        extraFee = new Decimal(extraFee).add(extraFeeObj.value).toNumber();
      }
    });
    totalPrice = new Decimal(price).add(transportFee).add(extraFee).toNumber();
    totalFee = new Decimal(extraFee).add(transportFee).toNumber();
    lackOfPaid = new Decimal(totalFee).sub(paid).add(refundFee).toNumber();
    consignmentOrderProfit = new Decimal(transportFee).sub(transportRealFee).add(extraFeeAuto).toNumber();

    batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid), {
      price,
      total_price: totalPrice,
      total_fee: totalFee,
      extra_fee: extraFee,
      lack_of_paid: lackOfPaid,
      consignment_order_profit: consignmentOrderProfit
    });

  }

  public async changeConsignmentOrderSpecial(consignmentOrder, isSpecial: boolean, normal_param, batch: firestore.WriteBatch) {
    const fs = firestore();

    if (isSpecial) {
      normal_param.percent_insurrance_fee = Number(consignmentOrder.percent_insurrance_fee);

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid), {
        consignment_special: true,
        special_param: normal_param,
        normal_param
      });
    } else {
      // Tinh Extra Fee
      let extraFee = 0;
      let extraFeeAuto = 0;
      const extraFeeManual = consignmentOrder.extra_fee_manual;
      const price = consignmentOrder.price;
      const percent_insurrance_fee = consignmentOrder.normal_param.percent_insurrance_fee || 0;
      consignmentOrder.extra_fees.forEach(extra_fee => {
        if (extra_fee.type === 0) {
          const calExtraFeeAuto = new Decimal(price).mul(percent_insurrance_fee).toNumber();
          extraFeeAuto = new Decimal(extraFeeAuto).add(calExtraFeeAuto).toNumber();
        }
      });

      extraFee = new Decimal(extraFeeAuto).add(extraFeeManual).toNumber();

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid).collection('extra_fees').doc('BH'), {
        value: extraFeeAuto
      });

      // Tinh Transport Fee
      let transportFee = 0;
      let feeValueTransport = 0;
      let consignmentOrderProfit = 0;
      const transportRealFee = consignmentOrder.transport_real_fee || 0;
      const weight = consignmentOrder.weight;
      normal_param.cuocvanchuyen.forEach(transport => {
        const fromValue = transport.from_value || transport.fromValue;
        const toValue = transport.to_value || transport.toValue;
        const feeValue = transport.fee_value || transport.feeValue;
        if (Number(fromValue) <= weight && weight <= Number(toValue)) {
          feeValueTransport = Number(feeValue);
        }
      });
      transportFee = new Decimal(feeValueTransport).mul(weight).roundUp(0).toNumber();

      const totalFee = new Decimal(transportFee).add(extraFee).toNumber();
      const refundFee = consignmentOrder.refund_fee;
      const paid = consignmentOrder.paid;
      const lackOfPaid = new Decimal(totalFee).sub(paid).add(refundFee).toNumber();
      consignmentOrderProfit = new Decimal(transportFee).sub(transportRealFee).add(extraFeeAuto).toNumber();
      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid), {
        consignment_special: false,
        special_param: null,
        extra_fee_auto: extraFeeAuto,
        extra_fee: extraFee,
        transport_fee: transportFee,
        total_fee: totalFee,
        lack_of_paid: lackOfPaid,
        consignment_order_profit: consignmentOrderProfit,
        percent_insurrance_fee
      });

    }

  }

  private getOrder(order_uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(order_uid).snapshotChanges().pipe(
      map(doc => ({ uid: doc.payload.id, ...doc.payload.data() as any })),
      switchMap((order: any) => {
        return this.getExtraFees(order.uid).pipe(
          map((extra_fees: any[]) => {
            order.extra_fees = extra_fees;
            return order;
          })
        );
      })
    );
  }

  private getUser(user_uid) {
    return this.fs.collection(`${FIREBASE_STRUCT.USERS.NODE}`).doc<any>(user_uid).valueChanges().pipe(
      switchMap(user => {
        let ob: Observable<any>;
        const vip_type = user.vip.type;
        if (vip_type === 1) {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(user.vip.uid).valueChanges();
        } else {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_SPECIAL.NODE).doc(user.vip.uid).valueChanges();
        }
        return ob.pipe(
          map(vip => {
            return { ...user, uid: user_uid, vip: { ...vip, type: vip_type, uid: user.vip.uid } };
          })
        );
      })
    );
  }

  getProducts(order_uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order_uid).collection('products').snapshotChanges().pipe(
      map(actions => actions.map(action => ({ uid: action.payload.doc.id, ...action.payload.doc.data() })))
    );
  }

  getTransports(order_uid: string) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order_uid)
      .collection('transports').snapshotChanges().pipe(
        switchMap(actions => {
          const obs = actions.map(action => {
            const orderTran = action.payload.doc.data();
            return this.fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc<any>(orderTran.transport_uid || 'NOT_EXISTS').snapshotChanges().pipe(
              map(doc => ({ exists: doc.payload.exists, ...orderTran, ...doc.payload.data(), uid: doc.payload.id }))
            );
          });
          if (obs.length > 0) { return combineLatest(obs); } else { return of([]); }
        })
      );
  }

  private getDefaultParam(): Observable<any> {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}`).snapshotChanges().pipe(
      map(actions => {
        const r = {};
        actions.forEach(action => {
          r[action.payload.doc.id] = action.payload.doc.data();
        });
        return r;
      })
    );
  }

  private async getTransportFeeParams(branchUid: string) {
    const fs = firestore();
    return await fs.collection(FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE.NODE).where('branchUid', '==', branchUid).orderBy('create_date', 'asc').get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.data());
      });
      return rs;
    });
  }

  private getNewExchangeRate(branchUid: string) {
    return this.fireDb
      .object(
        `BRANCH_CONFIG/${branchUid}/tygiabaokhach`
      )
      .valueChanges();
  }

  private getNewTransportExchangedKg(branchUid: string) {
    return this.fireDb
      .object(
        `BRANCH_CONFIG/${branchUid}/hesoquydoicongthuccannang`
      )
      .valueChanges();
  }

  private getServiceFeeParams(branchUid: string): Observable<any[]> {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`, q => {
      return q.where('branchUid', '==', branchUid).orderBy('create_date', 'asc');
    }).valueChanges();
  }

  private getTransportFeeFastParams(): Observable<any[]> {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_FAST.NODE}`, q => {
      return q.orderBy('create_date', 'asc');
    }).valueChanges();
  }

  private getTransportFeeSlowParams(): Observable<any[]> {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_SLOW.NODE}`, q => {
      return q.orderBy('create_date', 'asc');
    }).valueChanges();
  }

  private getCheckGoodsFeeParams(): Observable<any[]> {
    return this.fs.collection(FIREBASE_STRUCT.PARAMETER_GOODS_CHECK_FEE.NODE).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() as any }))),
    );
  }

  private getTransportSGWarehouseParams(): Observable<any[]> {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_TRANSPORT_FEE_SG_WAREHOUSE.NODE}`, q => {
      return q.orderBy('create_date', 'asc');
    }).valueChanges();
  }

  private getExtraFees(order_uid): Observable<any[]> {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order_uid).collection('extra_fees').snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
    );
  }

  private getCheckGoodsFeeValue(order, param: OrderParam) {
    let fee = 0;
    let fee_rate;
    let fee_charge;
    let total_product_quantity = 0;
    let total_product_quantity_charge = 0;


    order.products.forEach(product => {
      if (product.product_price_cny < 10) {
        total_product_quantity_charge = new Decimal(product.product_quantity).add(total_product_quantity_charge).toNumber();
      } else {
        total_product_quantity = new Decimal(product.product_quantity).add(total_product_quantity).toNumber();
      }
    });

    const check_goods_fee_params: any[] = [...param.cuockiemhang];
    check_goods_fee_params.forEach(_fee => {
      if (_fee.from_quantity <= total_product_quantity && total_product_quantity <= _fee.to_quantity) {
        fee_rate = _fee.fee_rate;
      }
      if (_fee.from_quantity <= total_product_quantity_charge && total_product_quantity_charge <= _fee.to_quantity) {
        fee_charge = _fee.fee_charge;
      }
    });

    fee = new Decimal(fee_charge).mul(total_product_quantity_charge).add(fee).toNumber();
    fee = new Decimal(fee_rate).mul(total_product_quantity).add(fee).toNumber();

    return fee;
  }

}
