import { RemoveUnicodePipe } from './remove-unicode/remove-unicode.pipe';
import { NgModule } from '@angular/core';
import { AppCurrencyPipe } from './currency/currency.pipe';
import { DatePipe } from '@angular/common';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';

@NgModule({
  declarations: [AppCurrencyPipe, RemoveUnicodePipe, SafeHtmlPipe],
  exports: [AppCurrencyPipe, RemoveUnicodePipe, SafeHtmlPipe],
  providers: [DatePipe]
})
export class PipesModule {}
