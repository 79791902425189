import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { RolesService } from '../../../services/roles/roles.service';
import { Decimal } from '../../../app.models';

@Component({
  selector: 'app-consignment-special-parammeter',
  templateUrl: './consignment-special-parammeter.component.html',
  styleUrls: ['./consignment-special-parammeter.component.css']
})
export class ConsignmentSpecialParammeterComponent implements OnInit, OnDestroy {
  param: any[];
  unsubscribe$ = new Subject();
  uid: any;
  consignment: any = {};
  user_login: any;
  consignment_rate: any;
  consignment_rate_bk: any;
  storage_warehouse_fee: any;
  auto_finish_date: any;
  auto_start_date: any;
  insurrance: any;
  insurrance_limit: any;
  rate: any = {};
  check = false;
  roles: any = {};
  constructor(
    public modalCtrl: ModalController,
    public navaparam: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public rolesService: RolesService
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;
  }

  async ngOnInit() {
    this.getConsignment(this.uid).subscribe(async (con: any) => {
      this.roles = await this.rolesService.getActionRolesConsignmentOrder(con);
      this.consignment = con;
      if (!this.consignment.consignment_special) {
        this.consignment.special_param = this.consignment.normal_param;
      }
    });

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });
  }

  convertParam(param) {
    const result: any = {};
    if (param.param_default) {
      param.param_default.forEach((p, index) => {
        switch (index) {
          case 0:
            result.philuukho = p.value;
            break;
          case 1:
            result.ngaybatdauluukho = p.value;
            break;
          case 2:
            result.ngaytudonghoanthanh = p.value;
            break;
          case 3:
            result.ngaytudongxoadon = p.value;
            break;
          default:
            break;
        }
      });
    }
    if (param.param_insurrance) {
      result.phibaohiem = param.param_insurrance;
    }
    if (param.param_transport) {
      result.cuocvanchuyen = param.param_transport;
    }
    result.percent_insurrance_fee = param.percent_insurrance_fee || 0;
    result.vip = {
      type: 1
    };
    return result;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRate() {
    return this.fs.collection<any>(`${FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_DEFAULT.NODE}`).snapshotChanges().pipe(
      map(snap => snap.map(sns => ({ uid: sns.payload.doc.id, ...sns.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUserLogin(uid) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${uid}`).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      first()
    );
  }

  getConsignment(uid: string) {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  getExtraFee() {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(this.uid).collection('extra_fees').snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();
  }

  @CustomConfirm('Bạn muốn thực hiện cập nhật này?')
  async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const extraFees = await this.getExtraFee();
      const consignmentOrder = { ...this.consignment };
      const specialParam = consignmentOrder.special_param;
      const percent_insurrance_fee = Number(consignmentOrder.percent_insurrance_fee);

      // Tinh Extra Fee
      let extraFee = 0;
      let extraFeeAuto = 0;
      const extraFeeManual = consignmentOrder.extra_fee_manual;
      const price = consignmentOrder.price;
      extraFees.forEach((_extraFee: any) => {
        if (_extraFee.type === 0) {
          const calExtraFeeAuto = new Decimal(price).mul(percent_insurrance_fee).toNumber();
          extraFeeAuto = new Decimal(extraFeeAuto).add(calExtraFeeAuto).toNumber();
        }
      });
      extraFee = new Decimal(extraFeeAuto).add(extraFeeManual).toNumber();

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid).collection('extra_fees').doc('BH'), {
        value: extraFeeAuto
      });

      // Tinh Transport Fee
      let transportFee = 0;
      let feeValueTransport = 0;
      const weight = consignmentOrder.weight;
      specialParam.cuocvanchuyen.forEach(transport => {
        const fromValue = transport.from_value || transport.fromValue;
        const toValue = transport.to_value || transport.toValue;
        const feeValue = transport.fee_value || transport.feeValue;
        if (Number(fromValue) <= weight && weight < Number(toValue)) {
          feeValueTransport = Number(feeValue);
        }
      });
      transportFee = new Decimal(feeValueTransport).mul(weight).toNumber();

      const totalFee = new Decimal(transportFee).add(extraFee).toNumber();
      const refundFee = consignmentOrder.refund_fee;
      const paid = consignmentOrder.paid;
      const lackOfPaid = new Decimal(totalFee).sub(paid).add(refundFee).toNumber();

      batch.update(fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(consignmentOrder.uid), {
        special_param: specialParam,
        extra_fee_auto: extraFeeAuto,
        extra_fee: extraFee,
        transport_fee: transportFee,
        total_fee: totalFee,
        lack_of_paid: lackOfPaid,
        percent_insurrance_fee: consignmentOrder.percent_insurrance_fee
      });

      await batch.commit();
      return 'Cập nhật thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }

  }

  /* async getDefaultParam() {
    const defaultParam: any = {};
    const paramDefault: any = await this.fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_DEFAULT.NODE, query => {
      return query.orderBy('create_date', 'asc');
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();

    const paramTransport: any = await this.fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_TRANSPORT_FEE.NODE, query => {
      return query.orderBy('create_date', 'asc');
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();

    const paramInsurrance: any = await this.fs.collection(FIREBASE_STRUCT.CONSIGNMENT_PARAMETER_INSURRANCE_FEE.NODE, query => {
      return query.orderBy('create_date', 'asc');
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      first()
    ).toPromise();

    defaultParam.param_default = paramDefault;
    defaultParam.param_transport = paramTransport;
    defaultParam.param_insurrance = paramInsurrance;

    return defaultParam;
  } */
}
