import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  @Input() heading: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() { }

}
