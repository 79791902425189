import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, takeUntil } from 'rxjs/operators';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';

@Component({
  selector: 'app-order-request-ship-export-edit',
  templateUrl: './order-request-export-ship-edit.component.html',
  styleUrls: ['./order-request-export-ship-edit.component.css']
})
export class OrderRequestExportShipEditComponent implements OnInit, OnDestroy {

  uid: any;
  request: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    public auth: AngularFireAuth
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;

    this.getRequest(this.uid).subscribe(request => {
      this.request = request;
    });

   }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getRequest(uid: any) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.ORDER_REQUEST_EXPORT.NODE}/${uid}`).snapshotChanges().pipe(
         map(sn => ({ uid: sn.payload.id, ...sn.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomLoading()
  async onEditRequest() {
    try {
      if (!this.request.request_priority) { throw new Error(('Chưa chọn mức độ yêu cầu')); }
      this.request.branch_name = this._getBranchName(this.request.branch_uid);
      await this.fs.doc(`${FIREBASE_STRUCT.ORDER_REQUEST_EXPORT.NODE}/${this.uid}`).update(this.request);
      return 'Thực hiện chỉnh sửa thành công';
    } catch (error) {
      throw error;
    }
  }

  private _getBranchName(branchUid) {
    if (branchUid === 'lqB5AqKzNjT2LFrsfcaI') return 'NHTB';
    if (branchUid === '0MT41D5Z6pFwTLgtQ14c') return 'Panda';
    if (branchUid === 'UhQDbDi42iR7UFdIVpRt') return 'Soc';

    return '';
  }

}
