import { Decimal } from './../../app.models';
import { ApiService } from './../../services/api/api.service';
import { HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { CustomConfirm, FIREBASE_STRUCT } from '../../app.constant';
import { PaymentService } from './../../services/payment/payment.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { RolesService } from '../../services/roles/roles.service';

@Component({
  selector: 'app-order-refund',
  templateUrl: './order-refund.component.html',
  styleUrls: ['./order-refund.component.css']
})
export class OrderRefundComponent implements OnInit, OnDestroy {

  temps_refund = [];
  unsubscribe$ = new Subject();
  uid: string;
  order_refunds: any[];
  order: any;
  user: any;
  customer: any;
  list_roles: any[] = [];
  user_role: any = {};
  user_login: any = {};

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public rolesService: RolesService,
    public paymentService: PaymentService,
    public commonService: CommonUtilsService,
    public api: ApiService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });
  }

  ngOnInit() {
    this.getUser(this.auth.auth.currentUser.uid).subscribe(user => {
      this.user = user;
    });
    this.getOrderRefund(this.uid).subscribe(async (re) => {
      this.order = re;
      this.order.uid = this.uid;
      this.order_refunds = re.refunds;
      this.customer = this.order.customer;
      this.user_role = await this.rolesService.getRoles(this.order.order_status_key);
    });

    this.temps_refund = [];
    const temps = {
      value: null,
      note: null,
      created_user: 'Tự động',
      created_date: 'Tự động',
      type: 1
    };
    this.temps_refund.push(temps);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getOrderRefund(uid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(uid).valueChanges().pipe(
      switchMap((order: any) => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(uid).collection('refund_fees').valueChanges().pipe(
          map(refunds => {
            order.refunds = refunds;
            return order;
          })
        );
      }),
      switchMap(order => {
        return this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${order.order_user_uid}`).valueChanges().pipe(
          map(user => {
            order.customer = user;
            return order;
          })
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }
  getUser(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomConfirm('Xác nhận thực hiện thao tác hoàn tiền này?')
  async addRefund(temp) {
    try {
      const fs = firebase.firestore();
      /* const batch = fs.batch(); */
      const userCustomer: any = await this.getUserCustomer(this.order.order_user_uid);
      /* const userLogin: any = { ...this.user_login }; */
      const order = { ...this.order };
      const orderLackOfPaid = new Decimal(String(order.order_lack_of_paid).split('.')[0]).toNumber();

      if (orderLackOfPaid >= 0) {
        throw new Error('Chỉ hoàn tiền khi số tiền còn thiếu < 0');
      }

      if (Number(temp.value) > Math.abs(orderLackOfPaid)) {
        throw new Error('Số tiền hoàn lại phải nhỏ hơn lượng tiền thiếu');
      }

      // await this.paymentService.paymentOrderRefund(temp, order.uid, userCustomer.uid, userLogin);

      const httpOptions = {
        headers: new HttpHeaders({
          skipauthorization: 'true'
        })
      };
      const result = await this.api.post('orders/paymentRefundAllOrder', {
        refund: { ...temp },
        order_uid: order.uid,
        user_uid: userCustomer.uid,
        user_admin_uid: this.auth.auth.currentUser.uid
      }, httpOptions);

      this.temps_refund = [];
      const temps = {
        value: null,
        note: null,
        created_user: 'Tự động',
        created_date: 'Tự động',
        type: 1
      };
      this.temps_refund.push(temps);
      return 'Hoàn tiền thành công';
    } catch (error) {
      if (error) { throw error; } else { throw new Error('Có lỗi xảy ra khi thực hiện hoàn tiền'); }
    }
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
    );
  }

  getUserCustomer(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(sns => {
        return { uid: sns.payload.id, ...sns.payload.data() };
      }),
      first()
    ).toPromise();
  }
}
