import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  PaymentStatus,
  SmsModel,
} from "../../../pages/accounting/sms-recharge/sms-recharge.model";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { ApiService } from "../../../services/api/api.service";
import { debounceTime, map, switchMap } from "rxjs/operators";
import { AlertController, ModalController } from "@ionic/angular";
import { CustomConfirm, FIREBASE_STRUCT } from "../../../app.constant";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";

@Component({
  selector: "app-sms-recharge-detail",
  templateUrl: "./sms-recharge-detail.component.html",
  styleUrls: ["./sms-recharge-detail.component.scss"],
})
export class SmsRechargeDetailComponent implements OnInit, OnDestroy {
  @Input() payment: SmsModel;
  user;
  userCodeSub = new BehaviorSubject<string>("");
  users: any[];
  PaymentStatus = PaymentStatus;
  uns = new Subject();
  constructor(
    private fireAuth: AngularFireAuth,
    private fireDb: AngularFireDatabase,
    private firestore: AngularFirestore,
    private apiService: ApiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    const paymentId = this.payment.uid;
    this.getPayment(paymentId).subscribe((payment) => {
      this.payment = payment;
      this.user = payment.user;
    });
    this.getUsers().subscribe((users) => {
      this.users = users;
    });
  }

  ngOnDestroy() {
    this.uns.next();
    this.uns.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getPayment(paymentId: string) {
    return this.firestore
      .collection("SMS")
      .doc<SmsModel>(paymentId)
      .snapshotChanges()
      .pipe(
        switchMap((paymentSnap) => {
          const statusKey = paymentSnap.payload.get("statusKey");
          if (statusKey === this.PaymentStatus.SUCCESS.KEY) {
            return this.getUserById(paymentSnap.payload.get("userId")).pipe(
              map((user) => ({
                ...paymentSnap.payload.data(),
                uid: paymentId,
                user,
              }))
            );
          } else {
            return of({ ...paymentSnap.payload.data(), uid: paymentId });
          }
        })
      ) as Observable<SmsModel>;
  }

  getUsers() {
    return this.userCodeSub.pipe(
      debounceTime(500),
      switchMap((userCode) => {
        const code = userCode.toUpperCase();
        return this.firestore
          .collection(FIREBASE_STRUCT.USERS.NODE, (query) => {
            return query
              .orderBy("user_code")
              .startAt(code)
              .endAt(code + "\uf8ff")
              .limit(10);
          })
          .valueChanges({ idField: "uid" });
      })
    );
  }

  getUserById(userId: string) {
    return this.firestore
      .collection("USERS", (query) => {
        return query.where("id", "==", userId).limit(1);
      })
      .valueChanges({ idField: "uid" })
      .pipe(
        map((users) => {
          if (users.length) {
            return users[0];
          } else {
            return null;
          }
        })
      );
  }

  inputUserId(ev) {
    this.userCodeSub.next(ev.target.value);
  }

  inputUserCode(ev) {
    this.userCodeSub.next(ev.target.value);
  }

  selectUserid(selectedUser: any) {
    this.user = selectedUser;
  }

  @CustomConfirm("Xác nhận thực hiện giao dịch này ?")
  async reTransaction(payment: SmsModel) {
    try {
      if (!this.user) {
        throw new Error("Vui lòng chọn mục Khách hàng ID");
      }
      const branch_uid = this.user.branch_uid;
      let smsStructure: string = await this.fireDb.database
        .ref(`SMS_CONFIG/${branch_uid}/structure`)
        .once("value")
        .then((snapshot) => snapshot.val());
      smsStructure = smsStructure.toUpperCase();
      payment.content = `${smsStructure} ${this.user.login_name}`;
      payment.userUpdatedId = this.fireAuth.auth.currentUser.uid;
      payment.userUpdatedFullName = this.fireAuth.auth.currentUser.displayName;
      payment.branch_uid = branch_uid;
      delete payment.user;
      await this.apiService.post("sms/handleSms", { ...payment });
      this.modalCtrl.dismiss();
      return "Thực hiện thành công";
    } catch (error) {
      throw error;
    }
  }

  async setOtherTransaction(a?: any) {
    const alert = await this.alertCtrl.create({
      header: "Chuyển giao dịch khác",
      inputs: [
        {
          label: "Ghi chú",
          name: "note",
          placeholder: "Ghi chú",
        },
      ],
      buttons: [
        {
          text: "Đồng ý",
          handler: (data) => {
            if (data.note && data.note.length) {
              const paymentId = this.payment.uid;
              this.firestore
                .collection("SMS")
                .doc(paymentId)
                .update({
                  statusKey: PaymentStatus.OTHER.KEY,
                  statusText: PaymentStatus.OTHER.VALUE,
                  paymentNote: data.note,
                })
                .then(() => {
                  this.modalCtrl.dismiss();
                });
            }
          },
        },
        {
          text: "Hủy",
          role: "cancel",
        },
      ],
    });
    alert.present();
  }

  @CustomConfirm("Chuyển thành giao dịch lỗi ?")
  async setFailedTransaction(a?: any) {
    const paymentId = this.payment.uid;
    return this.firestore
      .collection("SMS")
      .doc(paymentId)
      .update({
        statusKey: PaymentStatus.FAILED.KEY,
        statusText: PaymentStatus.FAILED.VALUE,
      })
      .then(() => this.modalCtrl.dismiss());
  }

  @CustomConfirm("Xác nhận xóa giao dịch này ?")
  async deleteTransaction(payment: SmsModel) {
    const paymentId = this.payment.uid;
    return this.firestore
      .collection("SMS")
      .doc(paymentId)
      .delete()
      .then(() => this.modalCtrl.dismiss());
  }
}
