import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  constructor(
    public apiService: ApiService,
    public afFunc: AngularFireFunctions
  ) { }

  public toString(obj: any): string {
    if (!obj) { return ''; }
    return obj + '';
  }

  public async getServerTime() {
    try {
      const getServerTime = this.afFunc.functions.httpsCallable('getServerTime');
      const result = await getServerTime();
      return result.data;
    } catch (error) {
      return new Date().getTime();
    }
  }
}
