import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomConfirm, FIREBASE_STRUCT } from './../../app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { CalculateFeeService } from '../../services/calculate-fee/calculate-fee.service';
import { Decimal } from '../../app.models';
import { OrderParam } from '../../model/order.param';

@Component({
  selector: 'app-order-link',
  templateUrl: './order-link.component.html',
  styleUrls: ['./order-link.component.css']
})
export class OrderLinkComponent implements OnInit, OnDestroy {
  uid: any;
  order_products: any[];
  order: any;
  order_add_link: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public calcFeeService: CalculateFeeService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getOrderLinks(this.uid).subscribe(order => {
      this.order_products = order.products;
      this.order = order;
    });

    const product = {
      product_link: null,
      product_image: null,
      shop_name: null,
      product_name: null,
      description: null,
      product_quantity: null,
      product_price_cny: null,
    };
    this.order_add_link = product;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getOrderLinks(uid) {
    return this.calcFeeService.getOrderWithParam(uid).pipe(
      switchMap((order: any) => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(uid).collection('products').snapshotChanges().pipe(
          map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, is_checked: false, ...sn.payload.doc.data() }))),
          map(products => ({ ...order, products, uid }))
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomConfirm('Xác nhận thêm link này?')
  async onAddLink(link) {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const order = { ...this.order };
      const param: OrderParam = order.param;
      if (link.shop_name !== order.order_shop_name) {
        throw new Error('Tên shop phải trùng với shop của đơn hàng');
      } else {
        const product_uid = fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('products').doc().id;
        link.user_uid = this.order.order_user_uid;
        link.quantity_warehouse = 0;
        link.quantity_order = 0;
        link.product_price = new Decimal(link.product_price_cny).mul(param.tygiabaokhach).toNumber();
        link.uid = product_uid;

        let product_id = await fs.collection('UID_INFO').doc('product_uid').get().then(doc => {
          return (doc.get('id') || 0);
        });
        product_id++;
        link.product_id = product_id;

        const products: any[] = order.products;
        products.push(link);
        order.products = products;
        const order_update = await this.calcFeeService.getOrderUpdate(order);

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('products').doc(product_uid), link);
        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), order_update);
        batch.set(fs.collection('UID_INFO').doc('product_uid'), {
          id: product_id
        });

        await batch.commit();

        const product = {
          product_link: null,
          product_image: null,
          shop_name: null,
          product_name: null,
          description: null,
          product_quantity: null,
          product_price_cny: null,
        };
        this.order_add_link = product;

        return 'Thêm mới thành công.';
      }
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Xác nhận xóa sản phẩm này?')
  async onDelete(link, index: number) {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const order = { ...this.order };
      const products: any[] = order.products;
      products.splice(index, 1);
      order.products = products;
      const order_update = await this.calcFeeService.getOrderUpdate(order);
      batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('products').doc(link.uid));
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), order_update);

      await batch.commit();

      return 'Xóa thành công';
    } catch (error) {
      throw (error);
    }
  }

  @CustomConfirm('Xác nhận chỉnh sửa phẩm này?')
  async onUpdateProduct(link, index: number) {
    const fs = firestore();
    const batch = fs.batch();
    try {
      const order = { ...this.order };
      const products: any[] = order.products;
      products[index] = link;
      const order_update = await this.calcFeeService.getOrderUpdate(order);
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid).collection('products').doc(link.uid), link);
      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.uid), order_update);

      await batch.commit();

      return 'Chỉnh sửa thành công';
    } catch (error) {
      throw (error);
    }
  }

}
