import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { FIREBASE_STRUCT } from './../../app.constant';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { CustomLoading } from '../../app.constant';
import { first, map, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-vip-normal-add',
  templateUrl: './vip-normal-add.component.html',
  styleUrls: ['./vip-normal-add.component.css']
})
export class VipNormalAddComponent implements OnInit, OnDestroy {
  uid: any;
  branchUid: any;

  normal_vip: any = {};
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) { }

  ngOnInit() {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.branchUid = this.navParams.data.modal.componentProps.branchUid;
    if (this.uid !== '') {
      this.db.doc(`${FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(obj => {
        this.normal_vip = obj;
      });
    } else {
      this.getCountVip().then(vips => {
        this.normal_vip.vip_name = 'Vip ' + (vips.length + 1);
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomLoading()
  async onSubmit() {
    try {
      const fs = firebase.firestore();
      const batch = fs.batch();
      const c = await this.commonService.getServerTime();

      if (this.uid !== '') {
        batch.update(fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(this.uid), {
          ...this.normal_vip,
          update_user: this.auth.auth.currentUser.displayName,
          update_date: c
        });
      } else {
        batch.set(fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(), {
          ...this.normal_vip,
          branchUid: this.branchUid,
          create_user: this.auth.auth.currentUser.displayName,
          create_date: c
        });
      }

      await batch.commit();

      this.modalCtrl.dismiss();

      return 'Cập nhật tham số thành công';
    } catch (error) {
      throw new Error('Có lỗi xảy ra khi cập nhật');
    }
  }

  getCountVip() {
    return this.db.collection<any>(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).snapshotChanges().pipe(
      map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() }))),
      first()
    ).toPromise();
  }

}
