import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject, of } from 'rxjs';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit, OnDestroy {

  uid;
  roles: any[];
  user: any;
  role_uid_selected = 'choose_role_none';
  branch_uid_selected = 'choose_branch_none';
  branchs: any[] = [];
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getRoles().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(roles => {
      this.roles = roles;
    });

    this.getBranchs().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(branchs => {
      this.branchs = branchs;
    });

    this.getUser().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((user: any) => {
      user.password = '';
      user.repassword = '';
      this.user = user;
      this.role_uid_selected = user.role_uid || 'choose_role_none';
      this.branch_uid_selected = user.branch_uid || 'choose_branch_none';
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getRoles() {
    return this.fs.collection<any[]>(`${FIREBASE_STRUCT.USERS_ADMIN_ROLES.NODE}`, q => {
      return q.orderBy('name', 'asc');
    }).snapshotChanges().pipe(
      map(sns => {
        return sns.map(sn => {
          return { uid: sn.payload.doc.id, ...sn.payload.doc.data() };
        });
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getBranchs() {
    return this.fs.collection(FIREBASE_STRUCT.BRANCHS.NODE).valueChanges({ idField: 'uid' });
  }

  getUser() {
    if (this.uid !== '') {
      return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${this.uid}`).valueChanges();
    }

    return of({});
  }

  @CustomLoading()
  async onSubmit() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    if (this.uid) {
      try {
        batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(this.uid), {
          ...this.user,
          role_uid: this.role_uid_selected || '',
          branch_uid: this.branch_uid_selected || '',
          update_date: await this.commonService.getServerTime()
        });

        await batch.commit();
        this.modalCtrl.dismiss();
        return 'Cập nhật user thành công';
      } catch (error) {
        throw new Error('Có lỗi xảy ra khi cập nhật');
      }
    } else {
      try {
        let maxAdminUserId = await this.getMaxAdminUserId();
        maxAdminUserId = maxAdminUserId + 1;

        const user_uid = fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc().id;
        batch.set(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(user_uid), {
          ...this.user,
          user_id: maxAdminUserId,
          role_uid: this.role_uid_selected || '',
          branch_uid: this.branch_uid_selected || '',
          create_date: await this.commonService.getServerTime(),
          disabled: false
        });

        await batch.commit();
        this.modalCtrl.dismiss();

        return 'Thêm mới user thành công';
      } catch (error) {
        throw new Error('Có lỗi xảy ra khi cập nhật');
      }
    }
  }

  private async getMaxAdminUserId() {
    const fs = firebase.firestore();
    const admminUsers = await fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).orderBy('create_date', 'asc').get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push({ uid: doc.id, ...doc.data() });
      });
      return rs;
    });

    let maxadminUserId = 1;
    for (const admminUser of admminUsers) {
      maxadminUserId = admminUser.user_id;
    }

    return Number(maxadminUserId);
  }
}
