import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from './page-header/page-header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './chat/chat.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PipesModule } from '../pipes/pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    MatToolbarModule, MatIconModule, MatSidenavModule, MatInputModule, MatButtonModule, ScrollingModule, MatSnackBarModule,
    MatListModule, MatDialogModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    FooterComponent,
    ChatComponent
  ],
  entryComponents: [
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    FooterComponent,
    ChatComponent
  ]
})
export class FragmentsModule { }
