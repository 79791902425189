import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomLoading, FIREBASE_STRUCT } from '../../app.constant';
import { takeUntil } from 'rxjs/operators';
import firebase, { firestore } from 'firebase/app';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-sale-verify-identification',
  templateUrl: './sale-verify-identification.component.html',
  styleUrls: ['./sale-verify-identification.component.css']
})
export class SaleVerifyIdentificationComponent implements OnInit, OnDestroy {
  componentProps = {
    salesUser: {
      uid: '',
      user_id: '',
      full_name: '',
      login_name: '',
      mobile_phone_number: '',
      email: '',
      date_of_birth: ''
    }
  };
  model: any = {
    user_uid: '',
    user_id: '',
    full_name: '',
    login_name: '',
    mobile_phone_number: '',
    email: '',
    date_of_birth: ''
  };
  identificationImageFront: any;
  identificationImageBack: any;
  unsubscribe$ = new Subject();

  constructor(
    public modalCtrl: ModalController,
    public db: AngularFirestore,
    public navParams: NavParams,
    public auth: AngularFireAuth,
    public commonService: CommonUtilsService,
    private storage: AngularFireStorage
  ) {
    this.componentProps.salesUser = this.navParams.data.modal.componentProps.salesUser;
    this.model.user_uid = this.componentProps.salesUser.uid;
    this.model.user_id = this.componentProps.salesUser.user_id;
    this.model.full_name = this.componentProps.salesUser.full_name;
    this.model.login_name = this.componentProps.salesUser.login_name;
    this.model.mobile_phone_number = this.componentProps.salesUser.mobile_phone_number;
    this.model.email = this.componentProps.salesUser.email;
    this.model.date_of_birth = this.componentProps.salesUser.date_of_birth;
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  public onChoosenIdentificationImageFront(files) {
    this.identificationImageFront = files[0];
  }

  public onChoosenIdentificationImageBack(files) {
    this.identificationImageBack = files[0];
  }

  @CustomLoading()
  public async onVerifyIdentificationInfo() {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const _model = { ...this.model };


    const identificationImageFrontId = fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE)
                  .doc(FIREBASE_STRUCT.PARAMETER_CONTENT.HOME_MEDIA_MNG)
                  .collection('gallery')
                  .doc().id;
    const store_ref_1 = this.storage.ref(`uploads/gallery/${identificationImageFrontId}`);
    const angularFileUpload_1 = await store_ref_1.put(this.identificationImageFront);
    const identificationImageFrontUrl = await angularFileUpload_1.ref.getDownloadURL();

    const identificationImageBackId = fs.collection(FIREBASE_STRUCT.PARAMETER_CONTENT.NODE)
                  .doc(FIREBASE_STRUCT.PARAMETER_CONTENT.HOME_MEDIA_MNG)
                  .collection('gallery')
                  .doc().id;
    const store_ref_2 = this.storage.ref(`uploads/gallery/${identificationImageBackId}`);
    const angularFileUpload_2 = await store_ref_2.put(this.identificationImageFront);
    const identificationImageBackUrl = await angularFileUpload_2.ref.getDownloadURL();

    batch.update(fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc(_model.uid), {
      identification_image_front_url: identificationImageFrontUrl,
      identification_image_back_url: identificationImageBackUrl
    });

    await batch.commit();
    this.modalCtrl.dismiss();

    return 'Upload ảnh xác thực thành công';
  }

}
