import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CustomConfirm, FIREBASE_STRUCT } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Decimal } from '../../app.models';
import { CommonUtilsService } from 'src/app/services/utils/common-utils.service';
import { ApiService } from 'src/app/services/api/api.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-internal-transport',
  templateUrl: './internal-transport.component.html',
  styleUrls: ['./internal-transport.component.css']
})
export class InternalTransportComponent implements OnDestroy, OnInit {
  item: any;
  bags: any[];
  selected_bags: any[] = [];
  unsubscribe$ = new Subject();
  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public commonService: CommonUtilsService,
    private apiService: ApiService
  ) {
    this.getBags().subscribe(bags => {
      this.bags = [];
      for (const bag of bags) {
        if (bag.bag_code.substring(0, 2) === 'SG' && !bag.is_delivered_sg) {
          this.bags.push(bag);
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @CustomConfirm('Xác nhận vận chuyển bao HN-SG?')
  async onSubmit() {
    /* const fs = firestore();
    const batch = fs.batch();
    const currentDateTime = await this.commonService.getServerTime(); */
    try {
      /* for (const bagSelected of this.selected_bags) {
        batch.update(fs.collection(FIREBASE_STRUCT.BAGS.NODE).doc(bagSelected.uid), {
          is_delivered_sg: true
        })
        const transports = await this.getTranByBagCode(bagSelected.bag_code).pipe(first()).toPromise();
        for (const transport of transports) {
          batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
            date_deliver_hn_sg: currentDateTime
          });
        }
      }

      await batch.commit(); */

      const selectedBags = [ ...this.selected_bags ];
      const httpOptions = {
        headers: new HttpHeaders({
          skipauthorization: 'true'
        })
      };
      await this.apiService.post('admin/warehouses/deliverFromHNToSG', { selected_bags: selectedBags });

      return 'Vận chuyển bao HN-SG thành công';
    } catch (error) {
      throw error;
    }

  }

  private getBags() {
    return this.fs.collection<any>(FIREBASE_STRUCT.BAGS.NODE, query => {
      return query.where('is_delivered', '==', true);
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

  getTranByBagCode(bagCode: string): Observable<any[]> {
    return this.fs.collection<any>(FIREBASE_STRUCT.TRANSPORTS.NODE, q => {
      return q.where('bag_code', '==', bagCode).orderBy('transport_date_created', 'desc');
    }).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
      takeUntil(this.unsubscribe$)
    );
  }

}
