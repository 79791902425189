import { CommonUtilsService } from '../../../services/utils/common-utils.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { CustomConfirm, FIREBASE_STRUCT } from '../../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { RolesService } from '../../../services/roles/roles.service';

@Component({
  selector: 'app-consignment-refund',
  templateUrl: './consignment-refund.component.html',
  styleUrls: ['./consignment-refund.component.css']
})
export class ConsignmentRefundComponent implements OnInit, OnDestroy {

  temps_refund = [];
  unsubscribe$ = new Subject();
  uid: string;
  consignment_refunds: any[];
  consignment: any;
  user: any;
  customer: any;
  roles: any = {};

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public paymentService: PaymentService,
    public rolesService: RolesService,
    public commonService: CommonUtilsService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;

    this.getConsignmentRefund(this.uid).subscribe(async (re) => {
      this.consignment = re;
      this.consignment_refunds = re.refunds;
      this.customer = this.consignment.customer;
      this.roles = await rolesService.getActionRolesConsignmentOrder(this.consignment);
    });
    this.getUserAdmin(this.auth.auth.currentUser.uid).subscribe(user => {
      this.user = user;
    });
    this.temps_refund = [];
    const temps = {
      value: null,
      note: null,
      created_user: 'Tự động',
      created_date: 'Tự động',
      type: 1
    };
    this.temps_refund.push(temps);

  }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getConsignmentRefund(uid) {
    return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc<any>(uid).valueChanges().pipe(
      switchMap((consignment: any) => {
        return this.fs.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(uid).collection('refund_fees').valueChanges().pipe(
          map(refunds => {
            consignment.refunds = refunds;
            return consignment;
          })
        );
      }),
      switchMap(consignment => {
        return this.fs.collection(FIREBASE_STRUCT.USERS.NODE).doc<any>(consignment.user_uid).valueChanges().pipe(
          map(user => {
            consignment.customer = user;
            return consignment;
          })
        );
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  getUserAdmin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @CustomConfirm('Xác nhận thực hiện thao tác hoàn tiền này?')
  async addRefund(temp) {
    try {
      const consignmentOrder = { ...this.consignment };
      consignmentOrder.uid = this.uid;
      const userCustomer: any = await this.getUserCustomer(consignmentOrder.user_uid);
      const userLogin: any = { ...this.user };

      await this.paymentService.paymentConsignmentOrderRefund(temp, consignmentOrder.uid, userCustomer.uid, userLogin);

      this.temps_refund = [];
      const temps = {
        value: null,
        note: null,
        created_user: 'Tự động',
        created_date: 'Tự động',
        type: 1
      };
      this.temps_refund.push(temps);
      return 'Hoàn tiền thành công';
    } catch (error) {
      console.log(error);
      if (error) {
        throw new Error(error);
      } else {
        throw new Error('Có lỗi xảy ra khi thực hiện hoàn tiền');
      }
    }
  }

  getUserCustomer(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(sns => {
        return { uid: sns.payload.id, ...sns.payload.data() };
      }),
      first()
    ).toPromise();
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      first()
    ).toPromise();
  }

}
