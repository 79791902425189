import { CommonUtilsService } from './../../services/utils/common-utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { CustomConfirm, CustomLoading, FIREBASE_STRUCT, isNullOrEmpty } from '../../app.constant';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { CalculateFeeService } from '../../services/calculate-fee/calculate-fee.service';
import { RolesService } from '../../services/roles/roles.service';
import { ComponentProps } from '@ionic/core';
import { OrdersDeliveryComponent } from '../orders-delivery/orders-delivery.component';
import { Decimal } from 'src/app/app.models';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-detail-transport-code-add',
  templateUrl: './detail-transport-code-add.component.html',
  styleUrls: ['./detail-transport-code-add.component.css']
})
export class DetailTransportCodeAddComponent implements OnInit, OnDestroy {
  temp_transports: any[];
  order_uid: any;
  unsubscribe$ = new Subject();
  user_login: any;
  order: any;
  list_roles: any[] = [];
  user_role: any = {};
  customer: any;
  function_action: any = {};
  weightConversionFactor = 0;

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public fs: AngularFirestore,
    public auth: AngularFireAuth,
    public calcFeeService: CalculateFeeService,
    public rolesService: RolesService,
    public commonService: CommonUtilsService
  ) {
    const modal: HTMLIonModalElement = this.navParams.data.modal;
    this.order_uid = modal.componentProps.order_uid;

    this.getUserLogin(this.auth.auth.currentUser.uid).subscribe(user_login => {
      this.user_login = user_login;
    });
    /* this.getTransports(this.order_uid).subscribe(trans => {

    }); */

    this.getOrder(this.order_uid).subscribe(async (order) => {
      this.order = order;
      this.temp_transports = order.transports;
      this.temp_transports.unshift({});
      this.user_role = await rolesService.getRoles(this.order.order_status_key);
      await this._getCustomerById(order.order_user_id).then(u => {
        this.customer = u;
      });
      // console.log(this.user_role);

      await this._getWeightConversionFactor(order).then(_weightConversionFactor => {
        this.weightConversionFactor = _weightConversionFactor;
      });
    });

  }

  async ngOnInit() {
    this.function_action = await this.rolesService.getFunctionAction();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  check_add(i) {
    let result = isNullOrEmpty(this.temp_transports[i].transport_code);
    this.temp_transports.forEach((tran, index) => {
      if (i === index) {
        result = result || false;
      } else {
        result = result || (this.temp_transports[i].transport_code === tran.transport_code);
      }
    });
    return result;
  }

  check_tran(i) {
    return this.temp_transports[i].exists;
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  public async onAddTran(index) {
    this.temp_transports[index].transport_code = String(this.temp_transports[index].transport_code).trim();
    const check = await this.checkTransport(this.temp_transports[index].transport_code);

    if (check) {
      this._addTransport(index);
    }
  }

  @CustomLoading()
  public async onCreateDeliveryBill() {
    const message = this._validateDeliveryBill();
    if (message) { throw new Error(message); }

    const currentDateTime = await this.commonService.getServerTime();

    try {
      const orders = [];

      const order = await this._getOrderByUid(this.order_uid);
      const transports = [];

      for (const _transport of order.transports) {
        for (let i = 1; i < this.temp_transports.length; i++) {
          const transport = this.temp_transports[i];
          if (transport.isChecked && _transport.transport_uid === transport.transport_uid) {
            transports.push(_transport);
          }
        }
      }

      order.transports = transports;
      orders.push(order);

      const pr: ComponentProps = {
        customerId: this.customer.id,
        orders,
        consignmentOrders: []
      };
      const modal = this.modalCtrl.create({
        component: OrdersDeliveryComponent,
        componentProps: pr,
        cssClass: 'modal-sm'
      });

      /* (await modal).onDidDismiss().then(async (data) => {
        const query = this.url;
        await this._getOrders(query).then(orders => {
          this.orders = this._refactorOrders(orders);
        });
      }); */

      (await modal).present();
    } catch (error) {
      throw error;
    }
  }

  @CustomLoading()
  private async _addTransport(index) {
    try {
      const fs = firebase.firestore();
      const batch = fs.batch();
      const current = await this.commonService.getServerTime();

      const order_transport_uid = fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).doc().id;

      const tran: any = await fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE)
        .where('transport_code', '==', this.temp_transports[index].transport_code).limit(1)
        .get().then(snapsohot => {
          if (snapsohot.size) {
            const doc = snapsohot.docs[0];
            return { exists: doc.exists, uid: doc.id, ...doc.data() };
          } else {
            return { exists: false, uid: undefined };
          }
        });

      const order: any = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid).get().then(doc => {
        return { ...doc.data(), uid: doc.id };
      });

      const bills: any[] = await fs.collection(FIREBASE_STRUCT.BILLS.NODE)
        .where('order_code', '==', order.order_code)
        .where('branch_uid', '==', order.branch_uid)
        .get().then(actions => {
          return actions.docs.map(doc => {
            return { ...doc.data(), uid: doc.id };
          });
        });

      batch.set(fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).doc(order_transport_uid),
        {
          order_code: order.order_code,
          order_code_2: order.order_code_2,
          order_uid: order.order_uid,
          transport_code: this.temp_transports[index].transport_code,
          order_transport_uid,
          transport_incharge_emp_full_name: order.in_charge_emp_full_name,
          transport_date_release_customer: 0
        },
        { merge: true }
      );

      // Re-calc the transport exchanged kg
      let transport_exchanged_kg = tran.transport_exchanged_kg || 0;

      transport_exchanged_kg = new Decimal(tran.transport_length)
        .mul(tran.transport_width)
        .mul(tran.transport_height)
        .div(this.weightConversionFactor)
        .roundUp(2)
        .toNumber();

      const tranRealKg = tran.transport_real_kg || 0;
      const transport_weight = this._roundTransportWeight(tranRealKg, transport_exchanged_kg);

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid)
        .collection('transports').doc(order_transport_uid),
        {
          transport_code: this.temp_transports[index].transport_code,
          order_transport_uid,
          order_transport_date_created: current,
          transport_incharge_emp_full_name: order.in_charge_emp_full_name,
          transport_exchanged_kg,
          transport_weight
        }
      );

      batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid), {
        order_had_transport: true,
        order_exchanged_weight: transport_exchanged_kg,
        transport_weight,
        order_weight: transport_weight
      });

      for (const bill of bills) {
        batch.update(fs.collection(FIREBASE_STRUCT.BILLS.NODE).doc(bill.uid), {
          order_had_transport: true
        });
      }

      if (tran.exists) {
        batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
          order_transport_date_mapping: current,
          transport_exchanged_kg,
          transport_weight
        });
      }

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Thêm mới mã vận đơn',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: '',
        new_value: this.temp_transports[index].transport_code
      });

      await batch.commit();

      return 'Thêm mã vận đơn thành công';
    } catch (error) {
      console.log(error);
      throw new Error('Có lỗi xảy ra khi thêm mã vận đơn');
    }
  }

  async checkTransport(transport_code) {
    const fs = firebase.firestore();
    const orderTransports = await fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE)
      .where('transport_code', '==', transport_code).get().then(actions => {
        const rs = [];
        actions.docs.forEach(doc => {
          rs.push(doc.get('order_code'));
        });
        return rs;
      });
    let message = '';
    if (!orderTransports.length) {
      const consignmentOrderTransports = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE)
        .where('transport_code', '==', transport_code).get().then(actions => {
          const rs = [];
          actions.docs.forEach(doc => {
            rs.push(doc.get('consignment_code'));
          });
          return rs;
        });
      if (consignmentOrderTransports.length) {
        message = `Mã vận đơn này đã được thêm tại đơn ký gửi: ${consignmentOrderTransports[0].toString()}.`;
      }
    } else {
      message = `Mã vận đơn này đã được thêm tại đơn hàng: ${orderTransports[0].toString()}.`;
    }

    return new Promise((resolve, reject) => {
      if (message) {
        this.alertCtrl.create({
          header: `Mã vận đơn này đã được thêm tại đơn hàng: ${orderTransports[0].toString()}.`,
          buttons: [
            {
              role: 'cancel',
              text: 'Hủy',
              handler: () => {
                resolve(false);
              }
            }
          ]
        }).then(alert => {
          alert.present();
        });
      } else {
        resolve(true);
      }
    });

  }

  @CustomConfirm('Xác nhận xuất KH ?')
  async onReleaseCustomer(tran) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const current_date = await this.commonService.getServerTime();
    try {
      if (tran.transport_uid) {
        batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
          transport_date_release_customer: current_date
        });
        batch.update(
          fs.collection(FIREBASE_STRUCT.ORDERS.NODE)
            .doc(this.order_uid).collection('transports').doc(tran.order_transport_uid),
          {
            transport_date_release_customer: current_date
          });
      }
      await batch.commit();
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Xác nhận bỏ xuất KH ?')
  async onRejectReleaseCustomer(tran) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    try {
      batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
        transport_date_release_customer: firebase.firestore.FieldValue.delete()
      });
      batch.update(
        fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid)
          .collection('transports').doc(tran.order_transport_uid),
        {
          transport_date_release_customer: firebase.firestore.FieldValue.delete()
        });
      await batch.commit();
    } catch (error) {
      throw error;
    }
  }

  @CustomConfirm('Xác nhận xóa mã vận đơn này ? ')
  async onDeleteTran(tran, index: number) {
    const fs = firebase.firestore();
    const batch = fs.batch();
    const current = await this.commonService.getServerTime();
    try {
      batch.delete(
        fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid)
          .collection('transports').doc(tran.order_transport_uid));

      batch.delete(
        fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).doc(tran.order_transport_uid));

      batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(this.order_uid).collection(FIREBASE_STRUCT.ORDER_DETAIL_ACTION_HISTORY.NODE).doc(), {
        element: 'Xóa mã vận đơn',
        date: await this.commonService.getServerTime(),
        user: this.user_login.full_name,
        old_value: '',
        new_value: tran.transport_code
      });

      if (tran.transport_uid) {
        // Re-calc the transport exchanged kg
        let transport_exchanged_kg = tran.transport_exchanged_kg || 0;
        const defaultWeightConversion = await fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').get().then(doc => {
          return doc.get('value');
        });
        transport_exchanged_kg = new Decimal(tran.transport_length)
          .mul(tran.transport_width)
          .mul(tran.transport_height)
          .div(defaultWeightConversion)
          .roundUp(2)
          .toNumber();

        const tranRealKg = tran.transport_real_kg || 0;
        const transport_weight = this._roundTransportWeight(tranRealKg, transport_exchanged_kg);
        
        batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(tran.transport_uid), {
          transport_date_created: current,
          transport_exchanged_kg,
          transport_weight
        });
      }

      await batch.commit();
      return 'Xóa mã vận đơn thành công';
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getOrder(order_uid) {
    return this.calcFeeService.getOrderWithParam(order_uid).pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  private _getOrderByUid(orderUid: string) {
    // return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(orderUid).ref.get().then(orderDoc => {
    //   const order = {  ...orderDoc.data(),uid: orderDoc.id };
    //   return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('transports').ref.get().then(transportDocs=> {
    //     const transports = transportDocs.docs.map(transportDoc => {
    //       return { ...transportDoc.data(), uid: transportDoc.id };
    //     });
    //     return { ...order, transports } as any;
    //   })
    // })
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(orderUid).snapshotChanges().pipe(
      switchMap(order => {
        return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(orderUid).collection('transports').snapshotChanges().pipe(
          map(transports => ({
            uid: order.payload.id,
            ...order.payload.data() as any,
            transports: transports.map(transport => ({
              uid: transport.payload.doc.id,
              ...transport.payload.doc.data()
            }))
          })),
        );
      }),
      first()
    ).toPromise();
  }

  getUserLogin(uid) {
    return this.fs.collection(FIREBASE_STRUCT.USERS_ADMIN.NODE).doc<any>(uid).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
    );
  }

  private _validateDeliveryBill() {
    let isCheckedTransport = false;

    for (let i = 1; i < this.temp_transports.length; i++) {
      const transport = this.temp_transports[i];
      if (transport.isChecked) {
        isCheckedTransport = true;
        break;
      }
    }

    if (!isCheckedTransport) {
      return 'Chưa chọn mã vận đơn xuất kho';
    }

    return '';
  }

  private _getCustomerById(userId) {
    return this.fs.collection<any>(`${FIREBASE_STRUCT.USERS.NODE}`, q => {
      return q.where('id', '==', userId);
    }).snapshotChanges().pipe(
      map(sns => {
        if (sns.length) { return { uid: sns[0].payload.doc.id, ...sns[0].payload.doc.data() }; } else { return null; }
      }),
      first()
    ).toPromise();
  }

  private async _getWeightConversionFactor(order) {
    const fs = firestore();

    const defaultWeightConversion = await fs.collection(FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE).doc('kl5Gjtebo0wrBchXxPXF').get().then(doc => {
      return doc.get('value');
    });

    const weightConversionFactor = order.normal_param ? order.normal_param.hesoquydoicongthuccannang || Number(defaultWeightConversion) : Number(defaultWeightConversion);
    return Number(weightConversionFactor);
  }

  private _roundTransportWeight(transportRealKg, transportExchangedKg) {
    let transport_weight = 0;
    if ((transportExchangedKg - transportRealKg) < 3) {
      transport_weight = transportRealKg;
    } else {
      transport_weight = transportRealKg >= transportExchangedKg ? transportRealKg : transportExchangedKg;
    }
    transport_weight = new Decimal(transport_weight).roundUp(2).toNumber();
    return transport_weight;
  }
}
